import {withTranslation, WithTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import styles from './service-providers-selector.module.scss';
import {ApplicationState} from '../../../../store';
import {ServiceProviderWithCityCountry} from '../../../../store/service-providers';
import SelectionPanel, {Item} from '../selection-panel/selection-panel';
import {ControlValue} from '../controls-snapshot';
import {ControlBase, ControlProps} from '../control-base';
import {filterServiceProviders} from '../../../../store/filter-control-selection';
import {ServiceProviderSelectionsControlValue} from './types/service-providers-selections-control-value';
import {ServiceProviderSelection} from '../../../../store/analytics';

class ServiceProvidersSelector extends ControlBase<AllProps, AllState, ServiceProviderSelectionsControlValue> {

    constructor(props) {
        super(props);
        const {defaultValue} = this.props;
        const serviceProviders: Item[] = [];
        const selectedServiceProviders: Map<string, Item> = new Map<string, Item>();

        // select all serviceProviders by default
        if (this.props.serviceProviders && this.props.serviceProviders.length > 0) {
            this.props.serviceProviders.forEach(sp => {
                const spItem: Item = {
                    id: sp.id,
                    displayName: sp.name,
                    displayField1: sp.city,
                    displayField2: sp.country
                };
                serviceProviders.push(spItem);

                if (defaultValue) {
                    const selection = defaultValue.value as ServiceProviderSelection;
                    if (selection.includes.find(id => sp.id === id)) {
                        selectedServiceProviders.set(sp.id, spItem);
                    }
                } else {
                    selectedServiceProviders.set(sp.id, spItem);
                }
            });
        }

        this.state = {serviceProviders, selectedServiceProviders};
        // if service providers are available you can trigger and end the initialization flow
        if (serviceProviders.length > 0) {
            this.props.onValueChange(this.getValue());
        }
    }

    public componentDidUpdate(prevProps) {
        // if service providers are set you can trigger and end the initialization flow
        const {serviceProviders, onValueChange, defaultValue} = this.props;
        if (serviceProviders && serviceProviders !== prevProps.serviceProviders) {
            const serviceProviders: Item[] = [];
            const selectedServiceProviders: Map<string, Item> = new Map<string, Item>();

            // select all serviceProviders by default
            if (this.props.serviceProviders && this.props.serviceProviders.length > 0) {
                this.props.serviceProviders.forEach(sp => {
                    const spItem: Item = {
                        id: sp.id,
                        displayName: sp.name,
                        displayField1: sp.city,
                        displayField2: sp.country
                    };
                    serviceProviders.push(spItem);

                    if (defaultValue) {
                        const selection = defaultValue.value as ServiceProviderSelection;
                        if (selection.includes.find(id => sp.id === id)) {
                            selectedServiceProviders.set(sp.id, spItem);
                        }
                    } else {
                        selectedServiceProviders.set(sp.id, spItem);
                    }
                });
            }

            this.setState({serviceProviders, selectedServiceProviders}, () => {
                onValueChange(this.getValue());
            });
        }
    }

    public forceRefresh(value: ServiceProviderSelectionsControlValue): void {
    }

    public render(): JSX.Element {
        const {serviceProviders, selectedServiceProviders} = this.state;
        const {serviceProvidersSearchText, t} = this.props;

        return (
            <div className={styles.selectorContainer}>
                {serviceProviders.length > 0 ?
                    <div className={styles.selectorColumn}>
                        <SelectionPanel
                            items={serviceProviders}
                            initialSearchText={serviceProvidersSearchText}
                            selectedItems={selectedServiceProviders}
                            partialItems={new Map<string, Item>()}
                            searchPlaceholderType={t('service providers')}
                            onToggleSelection={(item: Item) => this.onToggleServiceProviderSelection(item)}
                            onToggleSelectAll={(selectAll: boolean, items: Item[]) => this.onToggleSelectAllServiceProviders(selectAll, items)}
                            onSearchChanged={(searchText: string) => this.onServiceProvidersSearchChanged(searchText)}
                        />
                    </div> : null}
            </div>
        );
    }

    private onServiceProvidersSearchChanged(searchText: string) {
        this.props.dispatchFilterServiceProvidersRequest(searchText);
    }

    private onToggleSelectAllServiceProviders(selectAll: boolean, items: Item[]): void {
        const newServiceProviders = new Map<string, Item>();
        if (selectAll) {
            items.forEach(item => {
                newServiceProviders.set(item.id, item);
            })
        }

        this.setState({selectedServiceProviders: newServiceProviders}, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    private onToggleServiceProviderSelection(serviceProvider: Item) {
        const newServiceProviders = new Map<string, Item>();
        if (this.state.selectedServiceProviders.has(serviceProvider.id)) {
            this.state.selectedServiceProviders.forEach((item, key) => {
                if (key !== serviceProvider.id) {
                    newServiceProviders.set(key, item);
                }
            })
        } else {
            this.state.selectedServiceProviders.forEach((item, key) => {
                newServiceProviders.set(key, item);
            })
            newServiceProviders.set(serviceProvider.id, {
                id: serviceProvider.id,
                displayName: serviceProvider.displayName
            });
        }

        this.setState({selectedServiceProviders: newServiceProviders}, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    private getValue(): ControlValue {
        const selections = {includes: Array.from(this.state.selectedServiceProviders.keys())};
        const display = this.getDisplay();
        return new ServiceProviderSelectionsControlValue(display, selections);
    }

    private getDisplay(): string {
        const {size} = this.state.selectedServiceProviders;
        return size === 0 ? `None` : size === this.state.serviceProviders.length ? 'All' : `${size} Service Provider${size > 1 ? 's' : ''}`;
    }
}

const mapStateToProps = ({serviceProvider, filterControlSelection}: ApplicationState) => ({
    serviceProviders: serviceProvider.serviceProvidersForGroup,
    serviceProvidersSearchText: filterControlSelection.serviceProvidersFilter,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchFilterServiceProvidersRequest: (filter: string) => dispatch(filterServiceProviders(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ServiceProvidersSelector));

interface OwnProps extends ControlProps<ServiceProviderSelectionsControlValue> {
}

interface PropsFromState {
    serviceProviders?: ServiceProviderWithCityCountry[];
    serviceProvidersSearchText?: string;
}

interface PropsFromDispatch {
    dispatchFilterServiceProvidersRequest: typeof filterServiceProviders;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    selectedServiceProviders: Map<string, Item>;
    serviceProviders: Item[];
}

type AllState = OwnState;
