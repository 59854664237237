import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import { CurrencyInfo } from '../../../../store/service-provider-groups';
import { PurchasingDistributionRequest } from '../../../../store/analytics/types/purchasing-distribution.types';
import {
    createDateRangeWithServiceProvidersAndSoldTosRequest,
} from '../utils/widget-helper';
import { ParamKey } from '../../../../store/dashboard';

export function buildRequest(snapshot: ControlsSnapshot, currency: CurrencyInfo): PurchasingDistributionRequest {
    const request = Object.assign(
        createDateRangeWithServiceProvidersAndSoldTosRequest(snapshot), {
            currencyType: currency.type,
        });

    return request;
}

export function isValidSnapshot(snapshot: ControlsSnapshot): boolean {
    return snapshot.has(ParamKey.InspectionPeriod);
}
