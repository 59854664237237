import PrivacyPolicyApi, {PrivacyPolicyInfo} from '../api/privacy-policy-api';
import {User} from '../store/authentication';
import AuthenticationApi from '../api/authentication-api';

export interface PrivacyPolicySettings {
    hasConfirmed: boolean;
    policyDocument?: string;
}

function getMatchingPolicy(
    languageForPolicy: string,
    availablePolicies: { [languageKey: string]: PrivacyPolicyInfo },
): PrivacyPolicyInfo {
    return availablePolicies[languageForPolicy]
        ? availablePolicies[languageForPolicy]
        : availablePolicies[Object.keys(availablePolicies)[0]];
}

export async function getPrivacyPolicySettings(user: User, language: string): Promise<PrivacyPolicySettings> {
    const fallbackLanguage = 'EN';
    const availablePolicies = await PrivacyPolicyApi.getAvailablePrivacyPolicies();

    if (Object.keys(availablePolicies).length === 0) {
        return {policyDocument: undefined, hasConfirmed: true};
    }

    const languageForPolicy = language || fallbackLanguage;
    const policyInfo = getMatchingPolicy(languageForPolicy, availablePolicies);
    const policyDocument = await PrivacyPolicyApi.downloadPrivacyPolicy(policyInfo.url);
    const confirmDate = (await AuthenticationApi.getCustomerPolicyVersion()) || undefined;

    if (!confirmDate) {
        return {policyDocument, hasConfirmed: false};
    } else {
        const policyDate = policyInfo.date;
        const hasConfirmed = new Date(confirmDate).getTime() >= new Date(policyDate).getTime();
        return {policyDocument, hasConfirmed};
    }
}

export async function getLastPrivacyPolicy(language: string): Promise<string> {
    const fallbackLanguage = 'EN';

    const availablePolicies = await PrivacyPolicyApi.getAvailablePrivacyPolicies();
    const languageForPolicy = language || fallbackLanguage;
    const policyInfo = getMatchingPolicy(languageForPolicy, availablePolicies);
    return PrivacyPolicyApi.downloadPrivacyPolicy(policyInfo.url);
}

export async function confirmPrivacyPolicy(): Promise<void> {
    await PrivacyPolicyApi.confirmPrivacyPolicy();

    // to make sure the current id token contains the latest privacy policy confirmation information
    await AuthenticationApi.refreshSession();
}
