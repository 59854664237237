import {Reducer} from 'redux';
import {RequestStatus, RequestStatusType, SharedActionTypes} from '../shared/types';
import {
    ScheduledReportsAction,
    ScheduledReportsActionTypes,
    ScheduledReportsState
} from './types';

function initialState(): ScheduledReportsState {
    return {
        adminScheduledReports: undefined,
    };
}

const reducer: Reducer<ScheduledReportsState, ScheduledReportsAction> = (state = initialState(), action): ScheduledReportsState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_SERVICE_PROVIDER_GROUP: {
            return initialState();
        }
        case ScheduledReportsActionTypes.FETCH_ADMIN_SCHEDULED_REPORTS_REQUEST: {
            return { ...state, getAdminScheduledReportsRequestStatus: new RequestStatus(RequestStatusType.InProgress) };
        }
        case ScheduledReportsActionTypes.FETCH_ADMIN_SCHEDULED_REPORTS_SUCCESS: {
            return {
                ...state,
                adminScheduledReports: action.payload,
                getAdminScheduledReportsRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case ScheduledReportsActionTypes.FETCH_ADMIN_SCHEDULED_REPORTS_FAILURE: {
            return { ...state, getAdminScheduledReportsRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload) };
        }
        default: {
            return state;
        }
    }
};

export { reducer as scheduledReportsReducer };
export { initialState as initialScheduledReportsState };
