import * as am4core from '@amcharts/amcharts4/core';
import {Color} from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {WithTranslation, withTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import ReactTooltip from 'react-tooltip';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {
    JobsDistributionData,
    JobsDistributionRequest,
} from '../../../../store/analytics/types/jobs-distribution.types';
import {ApplicationState} from '../../../../store';
import {getJobsDistributionDataRequest} from '../../../../store/analytics';
import {DropdownOptionKey} from '../../../../components/widget-header-dropdown/dropdown-option-key';
import {generateUUID} from '../../../../utils/uuid-helpers';
import {
    colorBlue2,
    colorBlue4,
    colorBlue5,
    colorBlue8,
} from '../utils/chart-colors';
import WidgetLoading from '../widget-loading';
import styles from './jobs-distribution-widget.module.scss';
import WidgetHeaderDropdown, {DropdownOption} from '../../../../components/widget-header-dropdown/widget-header-dropdown';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {
    createDateRangeWithServiceProvidersAndSoldTosRequest,
    hasInspectionPeriod,
} from '../utils/widget-helper';
import {
    buildFosVsEjobChartData, buildFosVsEjobWithInspectionsChartData, inspectionCountField,
    jobCountField,
    jobTypeField,
    noInspectionCountField,
    typeField,
} from './chart-data-adapter';
import {
    addStandardTooltipStyle,
    addStrokeToColumnChart,
    createXYChart, dynamicallySetColorBySeriesAndType,
    dynamicallySetColorByType,
} from '../utils/chart-utils';
import {
    getWithInspectionLabel
} from '../../../../utils/translations/job-type-translation-helper';
import {JobCategory} from '../../../../store/jobs';
import {getTooltipDateRangeTextForWidget, WidgetDateRangeUsedType} from '../../../../utils/tooltip-helper';

am4core.useTheme(am4themes_animated);

class JobsDistributionWidget extends SingleChartWidgetBase<AllProps, AllState, JobsDistributionData[]> {
    private chartId: string;

    private chartColors: Map<string, Color>;

    private readonly OPTION_FOS_VS_EJOB = {id: '1', key: DropdownOptionKey.FosVsEjob};

    private readonly OPTION_INCLUDE_DETAILS = {id: '2', key: DropdownOptionKey.IncludeDetails};

    constructor(props) {
        super(props);
        this.chartId = generateUUID();

        this.chartColors = new Map<string, Color>();
        this.chartColors.set(JobCategory.FOS, am4core.color(colorBlue4));
        this.chartColors.set(JobCategory.Ejob, am4core.color(colorBlue8));
        this.chartColors.set(noInspectionCountField + JobCategory.FOS, am4core.color(colorBlue2));
        this.chartColors.set(inspectionCountField + JobCategory.FOS, am4core.color(colorBlue5));
        this.chartColors.set(noInspectionCountField + JobCategory.Ejob, am4core.color(colorBlue2));
        this.chartColors.set(inspectionCountField + JobCategory.Ejob, am4core.color(colorBlue5));

        this.state = {
            options: [this.OPTION_FOS_VS_EJOB, this.OPTION_INCLUDE_DETAILS],
            selectedOption: this.OPTION_FOS_VS_EJOB,
        };
    }

    public render(): JSX.Element {
        const {t, data} = this.props;
        const {selectedOption, options} = this.state;

        return (
            <div className={styles.widgetContainer}>
                {data ? <div className={styles.chartView}>
                    <div className={styles.chartHeader}>
                        <div className={styles.headerTitle}>
                            {t('Jobs Distribution')}
                            <div className={styles.infoIcon}
                                 data-tip={getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.JOB_DATE_RANGE_USED, t)}
                                 data-for="jobs-distribution-info">
                                <FontAwesomeIcon icon={faInfoCircle}/></div>
                            <ReactTooltip className={styles.tooltip} html border effect="solid"
                                          backgroundColor="#000000" id="jobs-distribution-info"/>
                        </div>
                        <div className={styles.interactions}>
                            <WidgetHeaderDropdown options={options}
                                                  selection={selectedOption}
                                                  onSelectionChanged={selection => this.onSelect(selection)}/>
                        </div>
                    </div>
                    <div className={styles.chartContent}>
                        <div id={this.chartId} style={{width: '100%'}}/>
                    </div>
                </div> : <WidgetLoading/>}
            </div>
        );
    }

    protected fetchData(snapshot: ControlsSnapshot) {
        this.props.getJobsDistributionRequest(createDateRangeWithServiceProvidersAndSoldTosRequest(this.props.controlsSnapshot));
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    private onSelect(option: DropdownOption) {
        this.setState({selectedOption: option}, () => {
            this.redraw(this.props.data);
        });
    }

    protected createChart(data: JobsDistributionData[]): am4charts.XYChart {
        const {t}=this.props;
        const chart = createXYChart(this.chartId);

        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = jobTypeField;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = t('# Jobs');

        if (this.state.selectedOption === this.OPTION_FOS_VS_EJOB) {
            this.addFlatSeries(chart, jobCountField);
            chart.data = buildFosVsEjobChartData(data, this.props.t);
        } else if (this.state.selectedOption === this.OPTION_INCLUDE_DETAILS) {
            this.addStackedSeries(chart, inspectionCountField, true);
            this.addStackedSeries(chart, noInspectionCountField, false);
            chart.data = buildFosVsEjobWithInspectionsChartData(data, this.props.t);
        }

        return chart;
    }

    public addFlatSeries(chart: any, seriesField: string) {
        const {t}=this.props;
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = seriesField;
        series.dataFields.categoryX = jobTypeField;
        series.columns.template.tooltipText = `{categoryX}: [bold]{valueY} ${t('jobs')}[/]`;
        series.columns.template.fillOpacity = .8;

        addStandardTooltipStyle(series);
        addStrokeToColumnChart(series);
        dynamicallySetColorByType(series, typeField, (type: string) => this.chartColors.get(type)!);
    }

    public addStackedSeries(chart: any, seriesField: string, withInspection: boolean) {
        const {t}=this.props;
        const stackedSeries = chart.series.push(new am4charts.ColumnSeries());
        stackedSeries.name = seriesField;
        stackedSeries.label = getWithInspectionLabel(this.props.t, withInspection);
        stackedSeries.stacked = true;
        stackedSeries.dataFields.valueY = seriesField;
        stackedSeries.dataFields.categoryX = jobTypeField;
        stackedSeries.columns.template.tooltipText = `{label}: [bold]{valueY} ${t('jobs')}[/]`;
        stackedSeries.columns.template.fillOpacity = .8;

        addStandardTooltipStyle(stackedSeries);
        addStrokeToColumnChart(stackedSeries);
        dynamicallySetColorBySeriesAndType(stackedSeries, typeField, (seriesName: string, type: string) => this.chartColors.get(seriesName + type)!);
    }
}

const mapStateToProps = ({analytics}: ApplicationState) => ({
    data: analytics.jobsDistributionData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getJobsDistributionRequest: (request: JobsDistributionRequest) => dispatch(getJobsDistributionDataRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobsDistributionWidget));

interface PropsFromState {
    data: JobsDistributionData[];
}

interface PropsFromDispatch {
    getJobsDistributionRequest: typeof getJobsDistributionDataRequest;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    options: DropdownOption[];
    selectedOption: DropdownOption;
}

type AllState = OwnState;
