import { TFunction } from 'i18next';
import { TireBrandType, TireType } from '../../store/analytics/types/tires-distribution.types';

export function getTireTypeLabel(t: TFunction, tireType: TireType): string {
    switch (tireType) {
        case TireType.NewTire:
            return t('tireType_NewTire');
        case TireType.RetreadTire:
            return t('tireType_RetreadTire');
        default:
            throw new Error(`Tire type ${tireType} is not supported`);
    }
}

export function getTireBrandTypeLabel(t: TFunction, tireBrandType: TireBrandType): string {
    switch (tireBrandType) {
        case TireBrandType.Corporate:
            return t('tireBrandType_Corporate');
        case TireBrandType.NonCorporate:
            return t('tireBrandType_NonCorporate');
        default:
            throw new Error(`Tire brand type ${tireBrandType} is not supported`);
    }
}


