import { Reducer } from 'redux';
import { JobsAction, JobsActionTypes, JobsState } from './types';
import { RequestStatus, RequestStatusType, SharedActionTypes } from '../shared/types';

function initialState(): JobsState {
    return {
        jobs: undefined,
    };
}

const reducer: Reducer<JobsState, JobsAction> = (state = initialState(), action): JobsState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_SERVICE_PROVIDER_GROUP: {
            return initialState();
        }
        case JobsActionTypes.GET_JOBS_REQUEST: {
            return {...state, jobs: undefined, jobsRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case JobsActionTypes.GET_JOBS_SUCCESS: {
            return {...state, jobs: action.payload, jobsRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case JobsActionTypes.GET_JOBS_FAILURE: {
            return {...state, jobsRequestStatus: new RequestStatus(RequestStatusType.Failed)};
        }
        case JobsActionTypes.GET_TIRE_SERVICE_REQUEST: {
            return {...state, tireService: undefined, tireServiceRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case JobsActionTypes.GET_TIRE_SERVICE_SUCCESS: {
            return {...state, tireService: action.payload, tireServiceRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case JobsActionTypes.GET_TIRE_SERVICE_FAILURE: {
            return {...state, tireServiceRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)};
        }
        default: {
            return state;
        }
    }
};

export {reducer as jobsReducer};
export {initialState as initialJobsState};
