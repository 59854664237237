import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import styles from './service-provider-selection-control.module.scss';
import {ServiceProvider} from '../../../../store/service-providers';

class ServiceProviderSelectionControl extends Component<AllProps, OwnState> {
    constructor(props) {
        super(props);
        const {serviceProviderMappedIds} = this.props;

        this.state = {
            caption: this.getCaption(serviceProviderMappedIds),
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>) {
        const {serviceProviderMappedIds} = this.props;

        if (serviceProviderMappedIds?.length !== prevProps.serviceProviderMappedIds?.length) {
            this.setState({caption: this.getCaption(serviceProviderMappedIds)});
        }
    }

    public render(): JSX.Element {
        const {onChange, t, canDownloadStructure, onDownloadStructure,canChange} = this.props;
        const {caption} = this.state;

        return (
            <>
                <div className={styles.serviceProviderSelectionControl} onClick={() => onChange()}>
                    {caption}
                </div>
                <div className={styles.serviceProviderLinks}>
                    <div className={styles.serviceProviderLink} onClick={() => onChange()}>
                        {canChange ? t('Change') : t('View')}
                    </div>
                    {canDownloadStructure ?
                        <div className={styles.serviceProviderDownloadStructure}>
                            <div className={styles.serviceProviderDivider}>/</div>
                            <div className={styles.serviceProviderLink} onClick={() => onDownloadStructure()}>
                                {t('Download structure')}
                            </div>
                        </div>
                        : null
                    }
                </div>
            </>
        );
    }

    private getCaption(serviceProviderMappedIds?: string[]): string {
        const {t} = this.props;

        if (!serviceProviderMappedIds || serviceProviderMappedIds.length === 0) {
            return t('None');
        } else if (serviceProviderMappedIds.length === 1) {
            return t('1 item selected');
        } else {
            return t('{{itemCount}} items selected', {itemCount: serviceProviderMappedIds.length});
        }
    }
}

export default withTranslation()(ServiceProviderSelectionControl);

interface OwnProps {
    serviceProviderMappedIds?: string[];
    serviceProviders?: ServiceProvider[];
    onChange: () => void;
    canChange: boolean;
    canDownloadStructure: boolean;
    onDownloadStructure: () => void;
}

interface OwnState {
    caption: string;
}

type AllProps = OwnProps & WithTranslation;
