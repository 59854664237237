import {push} from 'connected-react-router';
import {Location, LocationDescriptorObject} from 'history';

import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Component} from 'react';
import {ApplicationState} from '../../../store';
import {getJobsRequest, Job, JobsExplorerRequest, JobsSortColumn, JobType} from '../../../store/jobs';
import {formatDateInBrusselsTime} from '../../../utils/date-helper';
import {buildSearchParameters, QueryParameter} from '../../../utils/query-parameter-helpers';
import {generateUUID} from '../../../utils/uuid-helpers';
import {ControlsSnapshot} from '../../analytics/dashboard-controls/controls-snapshot';
import {hasInspectionPeriod} from '../../analytics/dashboard-widgets/utils/widget-helper';
import WidgetLoading from '../../analytics/dashboard-widgets/widget-loading';
import TableHeader, {TableHeaderFilterType} from '../../shared/components/table-header';
import styles from './jobs-table.module.scss';
import tableStyles from '../../shared/components/table.module.scss';
import Paging from './paging/paging';
import {buildRequest} from './request-builder';
import {ServiceProviderGroupWithConfiguration} from '../../../store/service-provider-groups';
import {
    updateServiceProviderDetailsQueryParameters
} from '../../../components/service-provider-details-popup/service-provider-details-popup';
import {
    updateTireServiceQueryParameters
} from '../../../components/tire-service-details-popup/tire-service-details-popup';
import {Paged, RequestStatus, SortDirection} from '../../../store/shared/types';
import {updateVehicleDetailsQueryParameters} from '../../../components/vehicle-details-popup/vehicle-details-popup';
import EllipsisWithTooltip from '../../../components/ellipsis-with-tooltip/ellipsis-with-tooltip';
import {SelectionOption} from '../../shared/components/types/selection-option';
import {getJobTypeLabel} from '../../../utils/translations/job-type-translation-helper';

class JobsTable extends Component<AllProps, AllState> {
    private rowHeight = 40;

    constructor(props) {
        super(props);
        const {t} = this.props;
        const jobTypeOptions = [{
            key: JobType.Regular,
            label: getJobTypeLabel(JobType.Regular, t)
        }, {key: JobType.Breakdown, label: getJobTypeLabel(JobType.Breakdown, t)}];
        const isEjobOptions = [{key: true.toString(), label: t('Yes')}, {key: false.toString(), label: t('No')}];
        const urlState = this.getStateFromUrl(isEjobOptions, jobTypeOptions);

        this.state = {...urlState, isEjobOptions, jobTypeOptions};
    }

    public componentDidMount() {
        const {controlsSnapshot} = this.props;
        if (controlsSnapshot && this.validateControlsSnapshot(controlsSnapshot)) {
            this.fetchData();
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {isEjobOptions, jobTypeOptions} = this.state;
        const urlParams = this.getStateFromUrl(isEjobOptions, jobTypeOptions);

        const {controlsSnapshot} = this.props;
        if (this.stateDiffersFromUrlParams(urlParams)) {
            this.setState({...urlParams}, () => {
                if (this.validateControlsSnapshot(controlsSnapshot)) {
                    this.fetchData();
                }
            });
        } else if (controlsSnapshot && !controlsSnapshot.equals(prevProps.controlsSnapshot)) {
            this.fetchData();
        }
    }

    public render(): JSX.Element {
        const {t, jobs, jobsRequestStatus} = this.props;
        const {
            sortColumn,
            sortDirection,
            page,
            itemsPerPage,
            isEjobExcludedOptions,
            isEjobOptions,
            jobTypeExcludedOptions,
            jobTypeOptions
        } = this.state;

        const tableBodyHeight = (jobs && jobs.pageData.length < itemsPerPage ? jobs.pageData.length : itemsPerPage) * this.rowHeight;

        const {
            jobReferenceContains,
            serviceProviderNameContains,
            licensePlateContains,
            fleetNameContains,
            ejobUserContains,
        } = this.state;
        return (
            <div>
                <div className={styles.table}>
                    <div className={styles.tableHead}>
                        <div className={`${styles.tableRow} ${styles.tableRowHeader}`}>
                            <TableHeader
                                title={t('Date').toUpperCase()}
                                column={JobsSortColumn.Date}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                small
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.NONE}
                            />
                            <TableHeader
                                title={t('Job reference').toUpperCase()}
                                column={JobsSortColumn.JobReference}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                small
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.SEARCH}
                                onApplySearchFilter={(text) => this.onApplySearchFilter(JobsSortColumn.JobReference, text)}
                                filterText={jobReferenceContains}
                            />
                            <TableHeader
                                title={t('eJob').toUpperCase()}
                                column={JobsSortColumn.IsEjob}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                tiny
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.SELECT}
                                filterOptions={isEjobOptions}
                                filterExcludedOptions={isEjobExcludedOptions}
                                onApplySelectFilter={(options) => this.onApplySelectFilter(JobsSortColumn.IsEjob, options)}
                            />
                            <TableHeader
                                title={t('eJob User').toUpperCase()}
                                column={JobsSortColumn.EjobUser}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                small
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.SEARCH}
                                onApplySearchFilter={(text) => this.onApplySearchFilter(JobsSortColumn.EjobUser, text)}
                                filterText={ejobUserContains}
                            />
                            <TableHeader
                                title={t('Job type').toUpperCase()}
                                column={JobsSortColumn.JobType}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                small
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.SELECT}
                                filterOptions={jobTypeOptions}
                                filterExcludedOptions={jobTypeExcludedOptions}
                                onApplySelectFilter={(options) => this.onApplySelectFilter(JobsSortColumn.JobType, options)}
                            />
                            <TableHeader
                                title={t('License plate').toUpperCase()}
                                column={JobsSortColumn.LicensePlate}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.SEARCH}
                                onApplySearchFilter={(text) => this.onApplySearchFilter(JobsSortColumn.LicensePlate, text)}
                                filterText={licensePlateContains}
                            />
                            <TableHeader
                                title={t('Service provider').toUpperCase()}
                                column={JobsSortColumn.ServiceProviderName}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.SEARCH}
                                onApplySearchFilter={(text) => this.onApplySearchFilter(JobsSortColumn.ServiceProviderName, text)}
                                filterText={serviceProviderNameContains}
                            />
                            <TableHeader
                                title={t('Fleet').toUpperCase()}
                                column={JobsSortColumn.FleetName}
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                onToggleSort={(column) => this.onToggleSort(column)}
                                filterType={TableHeaderFilterType.SEARCH}
                                onApplySearchFilter={(text) => this.onApplySearchFilter(JobsSortColumn.FleetName, text)}
                                filterText={fleetNameContains}
                            />
                        </div>
                    </div>
                    <div className={styles.tableBody} style={{height: tableBodyHeight}}>
                        {jobs && jobs.pageData.length > 0 && jobsRequestStatus?.isSuccess ? (
                            <div className={styles.tableRows}>
                                {jobs.pageData.map((j: Job) => {
                                    const rowId = generateUUID();
                                    return (
                                        <div className={styles.tableRow} key={rowId}>
                                            <span className={`${tableStyles.tableColumn} ${tableStyles.tableColumnSmall}`}>
                                                {formatDateInBrusselsTime(j.startDate, 'YYYY-MM-DD HH:mm')}
                                            </span>
                                            <span
                                                className={`${tableStyles.tableColumn} ${tableStyles.tableColumnLink} ${tableStyles.tableColumnSmall}`}
                                                onClick={() => this.onShowTireServicePopup(j.id)}>{j.jobReference}</span>
                                            <span
                                                className={`${tableStyles.tableColumn} ${tableStyles.tableColumnTiny}`}>{j.isEjob ? t('Yes') : t('No')}
                                            </span>
                                            <span className={`${tableStyles.tableColumn} ${tableStyles.tableColumnSmall}`}>{j.ejobUser}</span>
                                            <span className={`${tableStyles.tableColumn} ${tableStyles.tableColumnSmall}`}>
                                                  <EllipsisWithTooltip id={generateUUID()}
                                                                       placement="top"> {getJobTypeLabel(j.jobType,t).toUpperCase()}
                                                 </EllipsisWithTooltip>
                                            </span>
                                            <span
                                                className={`${tableStyles.tableColumn} ${tableStyles.tableColumnLink}`}
                                                onClick={() => this.onShowVehicleDetailsPopup(j.vehicleId)}>
                                                {j.licensePlate}
                                            </span>
                                            <span
                                                className={`${tableStyles.tableColumn} ${tableStyles.tableColumnLink}`}
                                                onClick={() => this.onShowServiceProviderPopup(j.serviceProviderId)}>
                                                {j.serviceProviderName.toUpperCase()}
                                            </span>
                                            <span className={tableStyles.tableColumn}>{j.fleetName.toUpperCase()}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : jobsRequestStatus?.isInProgress ? (
                            <div className={`${styles.tableRow} ${styles.tableRowLoading}`}>
                                <WidgetLoading/>
                            </div>
                        ) : (
                            <div className={`${styles.tableRow} ${styles.tableRowLoading}`}>{t('No data found.')}</div>
                        )}
                    </div>
                </div>
                {jobs ? (
                    <Paging
                        currentPage={page}
                        initialItemsPerPage={itemsPerPage}
                        onPreviousPage={() => this.onPreviousPage()}
                        onNextPage={() => this.onNextPage()}
                        onJumpToPage={(index: number) => this.onJumpToPage(index)}
                        total={jobs!.totalCount}
                        onItemsPerPageChanged={(items: number) => this.onItemsPerPageChanged(items)}
                        itemsPerPageBackground="white"
                    />
                ) : null}
            </div>
        );
    }

    private onItemsPerPageChanged(items: number): void {
        const {sortColumn, sortDirection} = this.state;
        this.updateUrl(1, items, sortColumn, sortDirection);
    }

    private onPreviousPage(): void {
        const {page, sortColumn, sortDirection, itemsPerPage} = this.state;
        this.updateUrl(page - 1, itemsPerPage, sortColumn, sortDirection);
    }

    private onNextPage(): void {
        const {page, sortColumn, sortDirection, itemsPerPage} = this.state;
        this.updateUrl(page + 1, itemsPerPage, sortColumn, sortDirection);
    }

    private onJumpToPage(pageIndex: number): void {
        const {sortColumn, sortDirection, itemsPerPage} = this.state;
        if (!Number.isNaN(pageIndex)) {
            this.updateUrl(pageIndex, itemsPerPage, sortColumn, sortDirection);
        }
    }

    private stateDiffersFromUrlParams(urlParams: UrlState): boolean {
        const {
            page,
            itemsPerPage,
            sortColumn,
            sortDirection,
            serviceProviderNameContains,
            licensePlateContains,
            jobReferenceContains,
            purchasingRefContains,
            fleetNameContains,
            ejobUserContains,
            jobTypeExcludedOptions,
            isEjobExcludedOptions,
        } = this.state;

        return (
            urlParams.page !== page ||
            urlParams.itemsPerPage !== itemsPerPage ||
            urlParams.sortColumn !== sortColumn ||
            urlParams.sortDirection !== sortDirection ||
            urlParams.serviceProviderNameContains !== serviceProviderNameContains ||
            urlParams.licensePlateContains !== licensePlateContains ||
            urlParams.jobReferenceContains !== jobReferenceContains ||
            urlParams.purchasingRefContains !== purchasingRefContains ||
            urlParams.fleetNameContains !== fleetNameContains ||
            urlParams.ejobUserContains !== ejobUserContains ||
            urlParams.jobTypeExcludedOptions?.map(o => o.key).sort().toString() !== jobTypeExcludedOptions?.map(o => o.key).sort().toString() ||
            urlParams.isEjobExcludedOptions?.map(o => o.key).sort().toString() !== isEjobExcludedOptions?.map(o => o.key).sort().toString()
        );
    }

    private getStateFromUrl(isEjobOptions: SelectionOption[], jobTypeOptions: SelectionOption[]): UrlState {
        const {currentLocation} = this.props;
        const queryParameters = new URLSearchParams(currentLocation.search);
        const sortColumn = (queryParameters.get(QueryParameter.SortColumn) as JobsSortColumn) || JobsSortColumn.Date;
        const sortDirection =
            (queryParameters.get(QueryParameter.SortDirection) as SortDirection) || SortDirection.Descending;
        const page = queryParameters.get(QueryParameter.Page)
            ? parseInt(queryParameters.get(QueryParameter.Page)!, 10)
            : 1;
        const itemsPerPage = queryParameters.get(QueryParameter.ItemsPerPage)
            ? parseInt(queryParameters.get(QueryParameter.ItemsPerPage)!, 10)
            : 15;
        const serviceProviderNameContains = queryParameters.get(QueryParameter.ServiceProviderNameContains) as string;
        const licensePlateContains = queryParameters.get(QueryParameter.LicensePlateContains) as string;
        const jobReferenceContains = queryParameters.get(QueryParameter.JobReferenceContains) as string;
        const purchasingRefContains = queryParameters.get(QueryParameter.PurchasingRefContains) as string;
        const fleetNameContains = queryParameters.get(QueryParameter.FleetNameContains) as string;
        const ejobUserContains = queryParameters.get(QueryParameter.EjobUserContains) as string;
        let jobTypeExcludedOptions: SelectionOption[] = [];
        if (queryParameters.has(QueryParameter.JobTypeExclude)) {
            const optionKeys = (queryParameters.get(QueryParameter.JobTypeExclude) as string).split(',');
            jobTypeExcludedOptions = jobTypeOptions.filter(o => optionKeys.includes(o.key));
        }
        let isEjobExcludedOptions: SelectionOption[] = [];
        if (queryParameters.has(QueryParameter.IsEjobExclude)) {
            const optionKeys = (queryParameters.get(QueryParameter.IsEjobExclude) as string).split(',');
            isEjobExcludedOptions = isEjobOptions.filter(o => optionKeys.includes(o.key));
        }

        return {
            page,
            itemsPerPage,
            sortDirection,
            sortColumn,
            serviceProviderNameContains,
            licensePlateContains,
            jobReferenceContains,
            purchasingRefContains,
            fleetNameContains,
            ejobUserContains,
            jobTypeExcludedOptions,
            isEjobExcludedOptions
        };
    }

    private onShowTireServicePopup(jobId: string): void {
        const {currentLocation, navigateTo} = this.props;

        if (jobId) {
            const queryParameters = updateTireServiceQueryParameters(jobId, currentLocation.search);
            navigateTo({search: queryParameters});
        }
    }

    private onShowVehicleDetailsPopup(vehicleId: string): void {
        const {currentLocation, navigateTo} = this.props;

        if (vehicleId) {
            const queryParameters = updateVehicleDetailsQueryParameters(vehicleId, currentLocation.search);
            navigateTo({search: queryParameters});
        }
    }

    private onShowServiceProviderPopup(serviceProviderId: string): void {
        const {currentLocation, navigateTo} = this.props;

        if (serviceProviderId) {
            const queryParameters = updateServiceProviderDetailsQueryParameters(serviceProviderId, currentLocation.search);
            navigateTo({search: queryParameters});
        }
    }

    private onApplySearchFilter(column: JobsSortColumn, text: string): void {
        const {
            serviceProviderNameContains,
            licensePlateContains,
            jobReferenceContains,
            purchasingRefContains,
            fleetNameContains,
            ejobUserContains,
            sortColumn,
            sortDirection,
            itemsPerPage,
            jobTypeExcludedOptions,
            isEjobExcludedOptions
        } = this.state;

        const serviceProviderContainsState =
            column === JobsSortColumn.ServiceProviderName ? text : serviceProviderNameContains;
        const licensePlateContainsState = column === JobsSortColumn.LicensePlate ? text : licensePlateContains;
        const jobIdContainsState = column === JobsSortColumn.JobReference ? text : jobReferenceContains;
        const purchasingRefContainsState = column === JobsSortColumn.PurchasingRef ? text : purchasingRefContains;
        const fleetNameContainsState = column === JobsSortColumn.FleetName ? text : fleetNameContains;
        const ejobUserContainsState = column === JobsSortColumn.EjobUser ? text : ejobUserContains;
        const jobTypeExcludedOptionsState = jobTypeExcludedOptions;
        const isEjobExcludedOptionsState = isEjobExcludedOptions;

        this.updateUrl(
            1,
            itemsPerPage,
            sortColumn,
            sortDirection,
            serviceProviderContainsState,
            licensePlateContainsState,
            jobIdContainsState,
            purchasingRefContainsState,
            fleetNameContainsState,
            ejobUserContainsState,
            jobTypeExcludedOptionsState,
            isEjobExcludedOptionsState
        );
    }

    private onApplySelectFilter(column: JobsSortColumn, options: SelectionOption[]): void {
        const {
            serviceProviderNameContains,
            licensePlateContains,
            jobReferenceContains,
            purchasingRefContains,
            fleetNameContains,
            ejobUserContains,
            sortColumn,
            sortDirection,
            itemsPerPage,
            jobTypeExcludedOptions,
            isEjobExcludedOptions
        } = this.state;

        const serviceProviderContainsState = serviceProviderNameContains;
        const licensePlateContainsState = licensePlateContains;
        const jobIdContainsState = jobReferenceContains;
        const purchasingRefContainsState = purchasingRefContains;
        const fleetNameContainsState = fleetNameContains;
        const ejobUserContainsState = ejobUserContains;
        const jobTypeExcludedOptionsState = column === JobsSortColumn.JobType ? options : jobTypeExcludedOptions;
        const isEjobExcludedOptionsState = column === JobsSortColumn.IsEjob ? options : isEjobExcludedOptions;

        this.updateUrl(
            1,
            itemsPerPage,
            sortColumn,
            sortDirection,
            serviceProviderContainsState,
            licensePlateContainsState,
            jobIdContainsState,
            purchasingRefContainsState,
            fleetNameContainsState,
            ejobUserContainsState,
            jobTypeExcludedOptionsState,
            isEjobExcludedOptionsState
        );
    }

    private fetchData(): void {
        const {
            page,
            itemsPerPage,
            sortColumn,
            sortDirection,
            serviceProviderNameContains,
            licensePlateContains,
            jobReferenceContains,
            purchasingRefContains,
            fleetNameContains,
            ejobUserContains,
            jobTypeExcludedOptions,
            isEjobExcludedOptions
        } = this.state;

        const {dispatchGetJobsRequest, controlsSnapshot, serviceProviderGroup} = this.props;

        const request = buildRequest(
            controlsSnapshot,
            page,
            itemsPerPage,
            serviceProviderNameContains || '',
            licensePlateContains || '',
            jobReferenceContains || '',
            purchasingRefContains || '',
            fleetNameContains || '',
            ejobUserContains || '',
            jobTypeExcludedOptions ? jobTypeExcludedOptions.map(o => o.key as JobType) : [],
            isEjobExcludedOptions ? isEjobExcludedOptions.map(o => o.key === 'true') : [],
            {column: sortColumn, direction: sortDirection},
            serviceProviderGroup!.activeLanguage.type
        );
        dispatchGetJobsRequest(request);
    }

    private validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    public onChangePage(page: number): void {
        this.setState({page});
    }

    public updateUrl(
        page: number,
        itemsPerPage: number,
        sortColumn: JobsSortColumn,
        sortDirection: SortDirection,
        serviceProviderNameContains?: string,
        licensePlateContains?: string,
        jobReferenceContains?: string,
        purchasingRefContains?: string,
        fleetNameContains?: string,
        ejobUserContains?: string,
        jobTypeExcludedOptions?: SelectionOption[],
        isEjobExcludedOptions?: SelectionOption[]
    ): void {
        const {navigateTo, currentLocation} = this.props;
        const parameters = {sortColumn, sortDirection, page, itemsPerPage};

        if (serviceProviderNameContains !== null) {
            parameters[QueryParameter.ServiceProviderNameContains] = serviceProviderNameContains;
        }
        if (licensePlateContains !== null) {
            parameters[QueryParameter.LicensePlateContains] = licensePlateContains;
        }
        if (jobReferenceContains !== null) {
            parameters[QueryParameter.JobReferenceContains] = jobReferenceContains;
        }

        if (purchasingRefContains !== null) {
            parameters[QueryParameter.PurchasingRefContains] = purchasingRefContains;
        }
        if (fleetNameContains !== null) {
            parameters[QueryParameter.FleetNameContains] = fleetNameContains;
        }
        console.log('bal');
        if (ejobUserContains !== null) {
            parameters[QueryParameter.EjobUserContains] = ejobUserContains;
        }
        if (jobTypeExcludedOptions) {
            parameters[QueryParameter.JobTypeExclude] = jobTypeExcludedOptions.map(i => i.key.toString()).join(',');
        }
        if (isEjobExcludedOptions) {
            parameters[QueryParameter.IsEjobExclude] = isEjobExcludedOptions.map(i => i.key.toString()).join(',');
        }

        navigateTo({
            search: buildSearchParameters(parameters, currentLocation.search),
        });
    }

    private onToggleSort(newSortColumn: JobsSortColumn): void {
        const {sortColumn, sortDirection, page, itemsPerPage} = this.state;
        const newSortDirection =
            newSortColumn === sortColumn ? this.toggleSortDirection(sortDirection) : SortDirection.Descending;
        this.updateUrl(page, itemsPerPage, newSortColumn, newSortDirection);
    }

    private toggleSortDirection(direction: SortDirection): SortDirection {
        switch (direction) {
            case SortDirection.Descending:
                return SortDirection.Ascending;
            case SortDirection.Ascending:
                return SortDirection.None;
            case SortDirection.None:
            default:
                return SortDirection.Descending;
        }
    }
}

const mapStateToProps = ({router, authentication, jobs}: ApplicationState): PropsFromState => ({
    currentLocation: router.location,
    serviceProviderGroup: authentication.serviceProviderGroup,
    jobs: jobs.jobs,
    jobsRequestStatus: jobs.jobsRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
    navigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchGetJobsRequest: (request: JobsExplorerRequest) => dispatch(getJobsRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobsTable));

interface PropsFromState {
    currentLocation: Location;
    serviceProviderGroup?: ServiceProviderGroupWithConfiguration;
    jobs?: Paged<Job>;
    jobsRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    navigateTo: (location: LocationDescriptorObject) => void;
    dispatchGetJobsRequest: typeof getJobsRequest;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    jobTypeOptions: SelectionOption[];
    isEjobOptions: SelectionOption[];
}

interface UrlState {
    page: number;
    itemsPerPage: number;
    sortColumn: JobsSortColumn;
    sortDirection: SortDirection;
    serviceProviderNameContains?: string;
    licensePlateContains?: string;
    jobReferenceContains?: string;
    purchasingRefContains?: string;
    fleetNameContains?: string;
    ejobUserContains?: string;
    jobTypeExcludedOptions?: SelectionOption[];
    isEjobExcludedOptions?: SelectionOption[];
}

type AllState = UrlState & OwnState;
