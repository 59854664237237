import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ServiceProviderGroup } from '../../../../../store/service-provider-groups';
import { conditionalClassLister } from '../../../../../utils/class-helpers';
import ToggleDisplay from '../../../../../utils/toggle-display';
import styles from './service-provider-group-selection-item.module.scss';

class ServiceProviderGroupSelectionItem extends Component<AllProps> {
    public render(): JSX.Element {
        const { serviceProviderGroup, selected, canSelect } = this.props;

        const itemClasses = conditionalClassLister(styles)({
            fcItem: true,
            isDisabled: !canSelect,
        });

        return (
            <div key={serviceProviderGroup.id} className={itemClasses} onClick={() => this.onSelect()}>
                <div className={styles.indicator}>
                    <ToggleDisplay if={selected}>
                        <FontAwesomeIcon icon={faCheck} />
                    </ToggleDisplay>
                </div>
                <div className={styles.name}>{serviceProviderGroup.name}</div>
            </div>
        );
    }

    private onSelect(): void {
        const { canSelect, onSelect } = this.props;

        if (canSelect && onSelect) {
            onSelect();
        }
    }
}

export default withTranslation()(ServiceProviderGroupSelectionItem);

interface OwnProps {
    canSelect: boolean;
    serviceProviderGroup: ServiceProviderGroup;
    onSelect?: () => void;
    selected: boolean;
}

type AllProps = OwnProps & WithTranslation;
