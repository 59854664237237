import { TFunction } from 'i18next';
import { PurchasingItemType } from '../../store/analytics/types/purchasing-distribution.types';
import { PurchasingItemDetailsType } from '../../store/analytics/types/purchasing-evolution.types';

export function getPurchasingTypeLabel(purchasingItemType: PurchasingItemType, t: TFunction): string {
    switch (purchasingItemType) {
        case PurchasingItemType.Services:
            return t('purchasingItemType_Services');
        case PurchasingItemType.Tires:
            return t('purchasingItemType_Tires');
        default:
            throw new Error(`Purchasing Item Type ${purchasingItemType} is not supported.`);
    }
}

export function getPurchasingItemDetailsTypeLabel(purchasingItemDetailsType: PurchasingItemDetailsType, t: TFunction): string {
    switch (purchasingItemDetailsType) {
        case PurchasingItemDetailsType.BreakdownServices: {
            return t('purchasingItemDetailsType_BreakdownServices');
        }
        case PurchasingItemDetailsType.RegularServices: {
            return t('purchasingItemDetailsType_RegularServices');
        }
        case PurchasingItemDetailsType.NewTires: {
            return t('purchasingItemDetailsType_NewTires');
        }
        case PurchasingItemDetailsType.RetreadTires: {
            return t('purchasingItemDetailsType_RetreadTires');
        }
        default:
            throw new Error(`Billing Item Details Type ${purchasingItemDetailsType} is not supported.`);
    }
}
