import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {LocationDescriptorObject} from 'history';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {getReportName, ReportCatalog, ReportCatalogEntry, ReportType} from '../../report-catalog';
import styles from './customer-service-locations-report.module.scss';
import PageHeader from '../../../../components/page-header/page-header';
import {RouteUrl} from '../../../../routes';
import {ApplicationState} from '../../../../store';
import * as layoutActions from '../../../../store/layout/actions';
import {ServiceProviderGroupWithConfiguration} from '../../../../store/service-provider-groups';
import {ServiceProviderGroupMatchParameters} from '../../../../types/service-provider-group';
import {
    CustomerServiceLocationsCustomReportParameters,
    CustomerServiceLocationsReportRequest,
    fetchCustomerServiceLocationsReportRequest,
    ReportPeriodType,
} from '../../../../store/reports';
import {FileType, RequestStatus} from '../../../../store/shared/types';
import {ServiceProviderSelectionsControlValue} from '../../../analytics/dashboard-controls/service-providers-selector/types/service-providers-selections-control-value';
import {SoldToSelectionsControlValue} from '../../../analytics/dashboard-controls/sold-tos-selector/types/sold-tos-selections-control-value';
import CustomerServiceLocationsComponent
    from "../../report-scheduler/popup/report-data-components/customer-service-locations/customer-service-locations.component";

class CustomerServiceLocationsReportPage extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {dispatchToggleSidebar} = this.props;
        dispatchToggleSidebar(true);

        this.state = {};
    }

    public render(): JSX.Element {
        const {t, location, reportRequestStatus, serviceProviderGroup} = this.props;
        const {serviceProviderSelection, soldToSelection} = this.state;
        const report: ReportCatalogEntry = new ReportCatalog().getCatalogEntry(ReportType.CustomerServiceLocations);

        const isValid = serviceProviderSelection && serviceProviderSelection.value.includes.length > 0
            && soldToSelection && soldToSelection.value.includes.length > 0;

        return (
            <div className={styles.pageContainer}>
                <PageHeader headerTitle={getReportName(report.type,t)} location={location} bottomComponent={<div/>}/>
                <div className={styles.pageContent}>
                    <CustomerServiceLocationsComponent
                        periodType={ReportPeriodType.Custom}
                        serviceProviderGroup={serviceProviderGroup}
                        onCustomParametersChanged={(p) => this.onParametersChanged(p)}
                        onScheduledParametersChanged={() => {
                        }}
                    />
                    <div className={styles.buttons}>
                        <button type="button"
                                className={`${styles.button} ${styles.isNegative}`}
                                disabled={reportRequestStatus?.isInProgress}
                                onClick={() => this.goBack()}>
                            {t('Back')}
                        </button>
                        <button type='button'
                                className={styles.button}
                                disabled={!isValid || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.ZIP)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download ZIP')}</button>
                        <button type='button'
                                className={styles.button}
                                disabled={!isValid || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.XLSX)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download XLSX')}</button>
                    </div>
                </div>
            </div>
        );
    }

    private onParametersChanged(parameters:CustomerServiceLocationsCustomReportParameters) {
        this.setState({
            serviceProviderSelection: parameters.serviceProviders,
            soldToSelection: parameters.soldTos,
        });
    }

    private download(fileType:FileType) {
        const {serviceProviderSelection,soldToSelection} = this.state;
        const {dispatchFetchCustomerServiceLocationsReport} = this.props
        if (serviceProviderSelection && serviceProviderSelection.value.includes.length > 0
            && soldToSelection && soldToSelection.value.includes.length > 0) {
            const request: CustomerServiceLocationsReportRequest = {
                fileType,
                serviceProviders: serviceProviderSelection.value,
                soldTos: soldToSelection.value,
            }

            dispatchFetchCustomerServiceLocationsReport(request);
        }
    }

    private goBack(): void {
        const {dispatchNavigateTo, serviceProviderGroup} = this.props;
        dispatchNavigateTo({pathname: `/${serviceProviderGroup?.id}${RouteUrl.Reports}`});
    }
}

const mapStateToProps = ({authentication, reports}: ApplicationState) => ({
    serviceProviderGroup: authentication.serviceProviderGroup,
    reportRequestStatus: reports.customerServiceLocationsReportRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchFetchCustomerServiceLocationsReport: (request: CustomerServiceLocationsReportRequest) => dispatch(fetchCustomerServiceLocationsReportRequest(request)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomerServiceLocationsReportPage));

interface PropsFromState {
    serviceProviderGroup?: ServiceProviderGroupWithConfiguration;
    reportRequestStatus: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: typeof push;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchFetchCustomerServiceLocationsReport: typeof fetchCustomerServiceLocationsReportRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    WithTranslation &
    OwnProps &
    RouteComponentProps<ServiceProviderGroupMatchParameters>;

interface OwnState {
    serviceProviderSelection?: ServiceProviderSelectionsControlValue;
    soldToSelection?: SoldToSelectionsControlValue;
}

type AllState = OwnState;
