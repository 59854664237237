export class AuthenticationErrors {
    public static INCORRECT_CREDENTIALS = 'Incorrect username/password combination, please try again.';

    public static GENERAL_ERROR = 'Something went wrong. Please try again or contact your admin.';

    public static USER_NOT_FOUND = 'Username not found. Please verify that the email you are using is correct.';

    public static VERIFICATION_CODE_MISMATCH =
        'The verification code you have provided does not match our records. Please try again.';
}
