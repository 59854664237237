import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {LocationDescriptorObject} from 'history';
import {push} from 'connected-react-router';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {getReportName, ReportCatalog, ReportCatalogEntry, ReportType} from '../../report-catalog';
import styles from './inspection-details-report.module.scss';
import PageHeader from '../../../../components/page-header/page-header';
import {RouteUrl} from '../../../../routes';
import {ApplicationState} from '../../../../store';
import * as layoutActions from '../../../../store/layout/actions';
import {ServiceProviderGroupWithConfiguration} from '../../../../store/service-provider-groups';
import {ServiceProviderGroupMatchParameters} from '../../../../types/service-provider-group';
import {
    fetchInspectionDetailsReportRequest,
    InspectionDetailsCustomReportParameters,
    InspectionDetailsReportRequest,
    ReportPeriodType,
} from '../../../../store/reports';
import {endOfDay, forceDateToBrusselsTime, startOfDay} from '../../../../utils/date-helper';
import {FileType, RequestStatus} from '../../../../store/shared/types';
import InspectionDetailsComponent
    from '../../report-scheduler/popup/report-data-components/inspection-details/inspection-details.component';
import {ServiceProviderSelectionsControlValue} from '../../../analytics/dashboard-controls/service-providers-selector/types/service-providers-selections-control-value';
import {SoldToSelectionsControlValue} from '../../../analytics/dashboard-controls/sold-tos-selector/types/sold-tos-selections-control-value';

class InspectionDetailsReportPage extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {dispatchToggleSidebar} = this.props;
        dispatchToggleSidebar(true);

        this.state = {};
    }

    public render(): JSX.Element {
        const {t, location, reportRequestStatus, serviceProviderGroup} = this.props;
        const {fromDate, toDate, serviceProviderSelection, soldToSelection} = this.state;
        const report: ReportCatalogEntry = new ReportCatalog().getCatalogEntry(ReportType.InspectionDetails);

        const isValid = serviceProviderSelection && serviceProviderSelection.value.includes.length > 0
            && soldToSelection && soldToSelection.value.includes.length > 0
            && fromDate && toDate;

        return (
            <div className={styles.pageContainer}>
                <PageHeader headerTitle={getReportName(report.type,t)} location={location} bottomComponent={<div/>}/>
                <div className={styles.pageContent}>
                    <InspectionDetailsComponent
                        periodType={ReportPeriodType.Custom}
                        serviceProviderGroup={serviceProviderGroup}
                        onCustomParametersChanged={(p) => this.onParametersChanged(p)}
                        onScheduledParametersChanged={() => {
                        }}
                    />
                    <div className={styles.buttons}>
                        <button type="button"
                                className={`${styles.button} ${styles.isNegative}`}
                                disabled={reportRequestStatus?.isInProgress}
                                onClick={() => this.goBack()}>
                            {t('Back')}
                        </button>
                        <button type='button'
                                className={styles.button}
                                disabled={!isValid || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.ZIP)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download ZIP')}</button>
                        <button type='button'
                                className={styles.button}
                                disabled={!isValid || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.XLSX)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download XLSX')}</button>
                    </div>
                </div>
            </div>
        );
    }

    private onParametersChanged(parameters: InspectionDetailsCustomReportParameters) {
        this.setState({
            serviceProviderSelection: parameters.serviceProviders,
            soldToSelection: parameters.soldTos,
            fromDate: parameters.fromDate,
            toDate: parameters.toDate,
        });
    }

    private download(fileType:FileType) {
        const {fromDate, toDate, serviceProviderSelection,soldToSelection} = this.state;
        const {dispatchFetchInspectionDetailsReport} = this.props
        if (serviceProviderSelection && serviceProviderSelection.value.includes.length > 0
            && soldToSelection && soldToSelection.value.includes.length > 0
            && fromDate && toDate) {
            const request: InspectionDetailsReportRequest = {
                fileType,
                serviceProviders: serviceProviderSelection.value,
                soldTos: soldToSelection.value,
                fromDate: forceDateToBrusselsTime(startOfDay(fromDate)).toISOString(),
                toDate: forceDateToBrusselsTime(endOfDay(toDate)).toISOString(),
            }

            dispatchFetchInspectionDetailsReport(request);
        }
    }

    private goBack(): void {
        const {dispatchNavigateTo, serviceProviderGroup} = this.props;
        dispatchNavigateTo({pathname: `/${serviceProviderGroup?.id}${RouteUrl.Reports}`});
    }
}

const mapStateToProps = ({authentication, reports}: ApplicationState) => ({
    serviceProviderGroup: authentication.serviceProviderGroup,
    reportRequestStatus: reports.inspectionDetailsReportRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchFetchInspectionDetailsReport: (request: InspectionDetailsReportRequest) => dispatch(fetchInspectionDetailsReportRequest(request)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(InspectionDetailsReportPage));

interface PropsFromState {
    serviceProviderGroup?: ServiceProviderGroupWithConfiguration;
    reportRequestStatus: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: typeof push;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchFetchInspectionDetailsReport: typeof fetchInspectionDetailsReportRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    WithTranslation &
    OwnProps &
    RouteComponentProps<ServiceProviderGroupMatchParameters>;

interface OwnState {
    fromDate?: Date;
    toDate?: Date;
    serviceProviderSelection?: ServiceProviderSelectionsControlValue;
    soldToSelection?: SoldToSelectionsControlValue;
}

type AllState = OwnState;
