import { ActionType } from 'typesafe-actions';
import * as sharedActions from '../shared/actions';
import { RequestStatus } from '../shared/types';
import * as actions from './actions';

export enum DataStatusActionTypes {
    FETCH_STATUS_REQUEST = '@@data-status/FETCH_STATUS_REQUEST',
    FETCH_STATUS_SUCCESS = '@@data-status/FETCH_STATUS_SUCCESS',
    FETCH_STATUS_FAILURE = '@@data-status/FETCH_STATUS_FAILURE',
}

export type DataStatusAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface DataStatusState {
    readonly dataStatus: DataStatusContract[];

    readonly getDataStatusRequestStatus?: RequestStatus;
}

export interface DataStatusContract {
    id: string;
    dataTable: string;
    dataField: string;
    maxDate: string;
    updatedOn: string;
}
