import { action } from 'typesafe-actions';
import { ControlValue } from '../../pages/analytics/dashboard-controls/controls-snapshot';
import { ParamKey } from '../dashboard';
import {
    AnalyticsActionTypes,
} from './types';
import {
    KeyPerformanceIndicatorData,
    KeyPerformanceIndicatorRequest
} from './types/key-performance-indicator.types';
import { JobsDistributionData, JobsDistributionRequest } from './types/jobs-distribution.types';
import { PurchasingDistributionData, PurchasingDistributionRequest } from './types/purchasing-distribution.types';
import {
    ServiceProvidersMonthlyOverviewData,
    ServiceProvidersMonthlyOverviewRequest,
} from './types/service-providers-monthly-overview.types';
import { TiresDistributionData, TiresDistributionRequest } from './types/tires-distribution.types';
import { PurchasingEvolutionData, PurchasingEvolutionRequest } from './types/purchasing-evolution.types';
import { TireBrandDistributionData, TireBrandDistributionRequest } from './types/tire-brand-distribution.types';
import {TopSoldTosData, TopSoldTosRequest} from './types/top-sold-tos-types';
import {
    CorporateFitmentDistributionData,
    CorporateFitmentDistributionRequest
} from "./types/corporata-fitment-distribution.types";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getKPIDataRequest = (request: KeyPerformanceIndicatorRequest) =>
    action(AnalyticsActionTypes.KEY_PERFORMANCE_INDICATOR_REQUEST, request);
export const getKPIDataSuccess = (data: KeyPerformanceIndicatorData) =>
    action(AnalyticsActionTypes.KEY_PERFORMANCE_INDICATOR_SUCCESS, data);

export const updateDefaultControlValues = (defaultValues: Map<ParamKey, ControlValue>) =>
    action(AnalyticsActionTypes.UPDATE_DEFAULT_CONTROL_VALUES, defaultValues);
export const updateSingleDefaultControlValue = (defaultValueKey: ParamKey, defaultControlValue: ControlValue) =>
    action(AnalyticsActionTypes.UPDATE_SINGLE_DEFAULT_CONTROL_VALUE, { defaultValueKey, defaultControlValue });

export const getJobsDistributionDataRequest = (request: JobsDistributionRequest) =>
    action(AnalyticsActionTypes.JOBS_DISTRIBUTION_REQUEST, request);
export const getJobsDistributionDataSuccess = (data: JobsDistributionData[]) =>
    action(AnalyticsActionTypes.JOBS_DISTRIBUTION_SUCCESS, data);

export const getTopSoldTosRequest = (request: TopSoldTosRequest) =>
    action(AnalyticsActionTypes.TOP_SOLD_TOS_REQUEST, request);
export const getTopSoldTosSuccess = (data: TopSoldTosData[]) =>
    action(AnalyticsActionTypes.TOP_SOLD_TOS_SUCCESS, data);

export const getPurchasingDistributionDataRequest = (request: PurchasingDistributionRequest) =>
    action(AnalyticsActionTypes.PURCHASING_DISTRIBUTION_REQUEST, request);
export const getPurchasingDistributionDataSuccess = (data: PurchasingDistributionData[]) =>
    action(AnalyticsActionTypes.PURCHASING_DISTRIBUTION_SUCCESS, data);

export const getServiceProvidersMonthlyOverviewRequest = (request: ServiceProvidersMonthlyOverviewRequest) =>
    action(AnalyticsActionTypes.SERVICE_PROVIDERS_MONTHLY_OVERVIEW_REQUEST, request);
export const getServiceProvidersMonthlyOverviewSuccess = (data: ServiceProvidersMonthlyOverviewData[]) =>
    action(AnalyticsActionTypes.SERVICE_PROVIDERS_MONTHLY_OVERVIEW_SUCCESS, data);

export const getTiresDistributionRequest = (request: TiresDistributionRequest) =>
    action(AnalyticsActionTypes.TIRES_DISTRIBUTION_REQUEST, request);
export const getTiresDistributionSuccess = (data: TiresDistributionData[]) =>
    action(AnalyticsActionTypes.TIRES_DISTRIBUTION_SUCCESS, data);

export const getPurchasingEvolutionDataRequest = (request: PurchasingEvolutionRequest) =>
    action(AnalyticsActionTypes.PURCHASING_EVOLUTION_REQUEST, request);
export const getPurchasingEvolutionDataSuccess = (data: PurchasingEvolutionData[]) =>
    action(AnalyticsActionTypes.PURCHASING_EVOLUTION_SUCCESS, data);

export const getTireBrandDistributionRequest = (request: TireBrandDistributionRequest) =>
    action(AnalyticsActionTypes.TIRE_BRAND_DISTRIBUTION_REQUEST, request);
export const getTireBrandDistributionSuccess = (data: TireBrandDistributionData[]) =>
    action(AnalyticsActionTypes.TIRE_BRAND_DISTRIBUTION_SUCCESS, data);

export const getCorporateFitmentDistributionRequest = (request: CorporateFitmentDistributionRequest) =>
    action(AnalyticsActionTypes.CORPORATE_FITMENT_DISTRIBUTION_REQUEST, request);
export const getCorporateFitmentDistributionSuccess = (data: CorporateFitmentDistributionData[]) =>
    action(AnalyticsActionTypes.CORPORATE_FITMENT_DISTRIBUTION_SUCCESS, data);
