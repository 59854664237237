import { TFunction } from 'i18next';
import {
    JobsDistributionData,
} from '../../../../store/analytics/types/jobs-distribution.types';
import {
    getJobCategoryLabel,
} from '../../../../utils/translations/job-type-translation-helper';
import { JobCategory } from '../../../../store/jobs';

export const jobTypeField = 'jobType';
export const jobCountField = 'jobCount';
export const inspectionCountField = 'inspectionCount';
export const noInspectionCountField = 'noInspectionCount';
export const typeField = 'type';

export function buildFosVsEjobChartData(data: JobsDistributionData[], translate: TFunction): any[] {
    const fosRow = {};
    fosRow[jobTypeField] = getJobCategoryLabel(translate, JobCategory.FOS);
    fosRow[typeField] = JobCategory.FOS;
    fosRow[jobCountField] = 0;

    const ejobRow = {};
    ejobRow[jobTypeField] = getJobCategoryLabel(translate, JobCategory.Ejob);
    ejobRow[typeField] = JobCategory.Ejob;
    ejobRow[jobCountField] = 0;

    data.forEach(row => {
        if (row.jobCategory === JobCategory.Ejob) {
            ejobRow[jobCountField] += row.jobCount;
        } else {
            fosRow[jobCountField] += row.jobCount;
        }
    });
    return [fosRow, ejobRow];
}

export function buildFosVsEjobWithInspectionsChartData(data: JobsDistributionData[], translate: TFunction): any[] {
    const fosRow = {};
    fosRow[jobTypeField] = getJobCategoryLabel(translate, JobCategory.FOS);
    fosRow[typeField] = JobCategory.FOS;
    fosRow[inspectionCountField] = 0;
    fosRow[noInspectionCountField] = 0;

    const ejobRow = {};
    ejobRow[jobTypeField] = getJobCategoryLabel(translate, JobCategory.Ejob);
    ejobRow[typeField] = JobCategory.Ejob;
    ejobRow[inspectionCountField] = 0;
    ejobRow[noInspectionCountField] = 0;

    data.forEach(row => {
        switch (row.jobCategory) {
            case JobCategory.FOS:
                if (row.inspectionType) {
                    fosRow[inspectionCountField] += row.jobCount;
                } else {
                    fosRow[noInspectionCountField] += row.jobCount;
                }
                break;
            case JobCategory.Ejob:
                if (row.inspectionType) {
                    ejobRow[inspectionCountField] += row.jobCount;
                } else {
                    ejobRow[noInspectionCountField] += row.jobCount;
                }
                break;
            default:
                throw Error(`Encountered unknown job category when building chart data ${row.jobCategory}`);
        }
    });
    return [fosRow, ejobRow];
}
