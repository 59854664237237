import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, {ChangeEvent, Component, KeyboardEvent, RefObject} from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SearchResult } from '../../store/search';
import { conditionalClassLister } from '../../utils/class-helpers';
import ToggleDisplay from '../../utils/toggle-display';
import HeaderSearchResult from './header-search-result';
import styles from './header-search.module.scss';

export class HeaderSearch extends Component<AllProps, AllState> {
    private disableCloseOnBlur = false;

    private searchInput: RefObject<HTMLInputElement>;

    private resultsThreshold = 5;

    constructor(props:AllProps) {
        super(props);
        this.searchInput = React.createRef();

        this.state = {
            isSearchPanelOpen: false,
            searchQuery: '',
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const { searchResults } = this.props;

        if (searchResults !== prevProps.searchResults) {
            if (this.searchInput.current) {
                this.searchInput.current.focus();
            }
        }
    }

    public render(): JSX.Element {
        const { isSearchPanelOpen } = this.state;
        const { t, isSearching, searchResults, lastSearchQuery } = this.props;

        const resultsContainerClasses = conditionalClassLister(styles)({
            resultsContainer: true,
            isOpen: isSearchPanelOpen && (isSearching || lastSearchQuery !== ''),
        });

        return (
            <div className={styles.searchContainer}>
                <div className={styles.inputPanel}>
                    <FontAwesomeIcon icon={faSearch} />
                    <input
                        className={styles.searchInput}
                        type='text'
                        placeholder={t('Search')}
                        onKeyDown={(e): void => this.handleKeyDown(e)}
                        onChange={(e): void => this.onSearchQueryChange(e)}
                        onFocus={(): void => this.toggleResultsBox(true)}
                        onBlur={(): void => this.toggleResultsBox(false)}
                        ref={this.searchInput}
                        disabled={isSearching}
                    />
                </div>

                <div className={resultsContainerClasses}>
                    <ToggleDisplay show={lastSearchQuery !== ''}>
                        <div className={styles.showingResultsFor}>
                            {`${t('Showing results for')}: `}
                            <span className={styles.lastSearchQuery}>{lastSearchQuery}</span>
                        </div>
                    </ToggleDisplay>
                    <ToggleDisplay show={isSearching}>
                        <div className={styles.searching}>
                            <FontAwesomeIcon icon={faSpinner} spin /> {t('Searching...')}
                        </div>
                    </ToggleDisplay>
                    <ToggleDisplay show={lastSearchQuery !== ''}>
                        <div className={styles.resultsPanel}>
                            {searchResults.map((searchResult: SearchResult, index: number) => {
                                if (index < this.resultsThreshold) {
                                    return (
                                        <HeaderSearchResult
                                            searchResult={searchResult}
                                            key={searchResult.id}
                                            onDisableCloseOnBlur={(disable): void => this.onDisableCloseOnBlur(disable)}
                                            onResultSelected={(result): void => this.onResultSelected(result)}
                                        />
                                    );
                                }
                                return '';
                            })}
                            <ToggleDisplay if={searchResults.length > this.resultsThreshold}>
                                <div className={styles.showingThreshold}>
                                    {t('Showing first {{threshold}} out of {{total}}', {
                                        threshold: this.resultsThreshold,
                                        total: searchResults.length,
                                    })}
                                </div>
                            </ToggleDisplay>
                            <ToggleDisplay
                                if={!isSearching && searchResults.length != null && searchResults.length === 0}>
                                <div className={styles.showingThreshold}>{t('No Results Found')}</div>
                            </ToggleDisplay>
                        </div>
                    </ToggleDisplay>
                </div>
            </div>
        );
    }

    private onDisableCloseOnBlur(disable: boolean): void {
        this.disableCloseOnBlur = disable;
    }

    private onSearchQueryChange(e: ChangeEvent<HTMLInputElement>): void {
        this.setState({ searchQuery: e.target.value });
    }

    private onResultSelected(result: SearchResult): void {
        const { onResultSelected } = this.props;

        this.setState({ isSearchPanelOpen: false });
        onResultSelected(result);
    }

    private handleKeyDown(e: KeyboardEvent<HTMLInputElement>): void {
        const { searchQuery } = this.state;
        const { onClearSearch, onSearch } = this.props;

        if (e.key === 'Enter') {
            if (searchQuery !== '') {
                onSearch(searchQuery);
            } else {
                onClearSearch();
            }
        }
        if (e.key === 'Escape') {
            onClearSearch();
            if (this.searchInput.current) {
                this.searchInput.current.value = '';
            }
        }
    }

    private toggleResultsBox(open: boolean): void {
        if (open || (!open && !this.disableCloseOnBlur)) {
            this.setState({ isSearchPanelOpen: open });
        }
    }
}

export default withTranslation()(HeaderSearch);

interface OwnProps {
    onSearch: (searchQuery: string) => void;
    onClearSearch: () => void;
    onResultSelected: (result: SearchResult) => void;
    isSearching?: boolean;
    lastSearchQuery: string;
    searchResults: SearchResult[];
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    isSearchPanelOpen: boolean;
    searchQuery: string;
}

type AllState = OwnState;
