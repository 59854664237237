import {push} from 'connected-react-router';
import {Location, LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {TFunction} from 'i18next';
import ConfirmationPopup from '../../components/confirmation-popup/confirmation-popup';
import PageHeader from '../../components/page-header/page-header';
import {ApplicationState} from '../../store';
import {Permission, User} from '../../store/authentication';
import {
    createDashboardRequest,
    Dashboard,
    deleteDashboardRequest,
    fetchDashboardsRequest,
    restoreDefaultDashboards,
    updateDashboardRequest,
} from '../../store/dashboard';
import {ServiceProviderGroup} from '../../store/service-provider-groups';
import * as layoutActions from '../../store/layout/actions';
import {closePopup, Popup, PopupType, showPopup} from '../../store/popup';
import {buildSearchParameters, QueryParameter} from '../../utils/query-parameter-helpers';
import {hasPermission} from '../../utils/user-helper';
import styles from './analytics.module.scss';
import DashboardsMenu from './dashboard-menu/dashboards-menu';
import DashboardNamePopup from './dashboard-popups/dashboard-name-popup';
import DashboardViewer from './dashboard-viewer';
import {createBlankDashboard} from './utils/blank-dashboard-builder';
import {createDefaultDashboardConfig} from './utils/default-dashboards';

class AnalyticsPage extends Component<AllProps, AllState> {
    private dashboardViewerRef: any;

    constructor(props) {
        super(props);
        const {dispatchToggleSidebar} = this.props;
        const urlParams = this.getStateFromUrl();
        dispatchToggleSidebar(true);

        this.state = {
            editMode: false,
            activeDashboardId: urlParams.dashboardId,
            fixedDashboards: []
        };
    }

    public componentDidMount() {
        const {serviceProviderGroup} = this.props;
        const urlParams = this.getStateFromUrl();
        if (serviceProviderGroup) {
            this.loadDashboardsAndSelectActive(serviceProviderGroup, urlParams);
        }
    }

    public componentDidUpdate(prevProps): void {
        const urlParams = this.getStateFromUrl();
        const {serviceProviderGroup} = this.props;

        if (urlParams.dashboardId && !this.stateEqualsUrlParams(urlParams)) {
            this.setState({
                activeDashboardId: urlParams.dashboardId,
            });
        }

        if (serviceProviderGroup !== prevProps.serviceProviderGroup) {
            this.loadDashboardsAndSelectActive(serviceProviderGroup, urlParams);
        }
    }

    private loadDashboardsAndSelectActive(serviceProviderGroup: ServiceProviderGroup, urlParams: UrlParameters) {
        const {dispatchFetchDashboardsRequest, t} = this.props;
        const fixedDashboards = createDefaultDashboardConfig(t);
        this.setState({
            fixedDashboards,
            activeDashboardId: urlParams.dashboardId ? urlParams.dashboardId : fixedDashboards[0].id
        });
        dispatchFetchDashboardsRequest();
    }

    public render(): JSX.Element {
        const {t, dashboards, location, user} = this.props;
        const {editMode, activeDashboardId,fixedDashboards} = this.state;

        const showEditButtons = hasPermission(user, Permission.EditDashboards);
        const allDashboards = fixedDashboards.sort((d1, d2) => d1.index - d2.index)
            .concat(dashboards.sort((d1, d2) => d1.index - d2.index));
        const activeDashboard = allDashboards.find((d) => d.id === activeDashboardId);
        return (
            <div className={styles.pageContainer}>
                <PageHeader
                    headerTitle={t('Analytics Dashboard')}
                    location={location}
                    bottomComponent={
                        <DashboardsMenu
                            showEditButtons={showEditButtons}
                            dashboards={allDashboards}
                            activeDashboard={activeDashboard}
                            editMode={editMode}
                            onDeleteDashboard={(dashboard) => this.onDeleteDashboard(dashboard)}
                            onRenameDashboard={() => this.onRenameDashboard()}
                            onEditDashboard={() => this.onEditDashboard()}
                            onCreateDashboard={() => this.onCreateDashboard()}
                            onRestoreDefault={() => this.onRestoreDefault()}
                            onSelectDashboard={(dashboard) => this.onSelectDashboard(dashboard)}
                        />
                    }
                />
                <DashboardViewer
                    setDashboardViewerRef={(ref) => (this.dashboardViewerRef = ref)}
                    activeDashboard={activeDashboard}
                    onSaveDashboardChanges={() => this.onSaveDashboardChanges()}
                    onDiscardDashboardChanges={() => this.onDiscardDashboardChanges()}
                    editMode={editMode}
                />
            </div>
        );
    }

    private onSelectDashboard(dashboard: Dashboard): void {
        const {dispatchNavigateTo, currentLocation} = this.props;
        const {activeDashboardId} = this.state;

        if (dashboard.id !== activeDashboardId) {
            dispatchNavigateTo({
                search: buildSearchParameters(
                    {
                        dashboard: dashboard.id,
                    },
                    currentLocation.search,
                ),
            });
        }
    }

    private onRenameDashboard(): void {
        const {dashboards, dispatchShowPopup, dispatchClosePopup, dispatchUpdateDashboard} = this.props;
        const {activeDashboardId} = this.state;

        const dashboard = dashboards.find((d) => d.id === activeDashboardId);

        if (dashboard) {
            dispatchShowPopup({
                type: PopupType.ServiceProviderGroupCreation,
                content: (
                    <DashboardNamePopup
                        oldName={dashboard.name}
                        onCancel={() => dispatchClosePopup()}
                        onConfirm={(name) => {
                            dashboard.name = name;
                            dispatchUpdateDashboard(dashboard);
                            dispatchClosePopup();
                        }}
                    />
                ),
            });
            this.setState({
                editMode: false,
            });
        }
    }

    private onCreateDashboard(): void {
        const {dispatchClosePopup, dispatchShowPopup, dispatchCreateDashboard, dashboards} = this.props;
        const {t} = this.props;
        dispatchShowPopup({
            type: PopupType.ServiceProviderGroupCreation,
            content: (
                <DashboardNamePopup
                    oldName=""
                    onCancel={() => dispatchClosePopup()}
                    onConfirm={(name) => {
                        const dashboard = createBlankDashboard(name, dashboards.length, t);
                        dispatchCreateDashboard(dashboard);
                        dispatchClosePopup();
                    }}
                />
            ),
        });
        this.setState({
            editMode: false,
        });
    }

    private onDeleteDashboard(dashboard: Dashboard): void {
        const {dispatchClosePopup, dispatchShowPopup, dispatchDeleteDashboard,t} = this.props;
        const {fixedDashboards} = this.state;
        dispatchShowPopup({
            type: PopupType.ServiceProviderGroupCreation,
            content: (
                <ConfirmationPopup
                    message={t('Are you sure you want to delete this dashboard?')}
                    onCancel={() => dispatchClosePopup()}
                    onConfirm={() => {
                        dispatchDeleteDashboard(dashboard);
                        dispatchClosePopup();
                        this.onSelectDashboard(fixedDashboards[0]);
                    }}
                />
            ),
        });
        this.setState({
            editMode: false,
        });
    }

    private onSaveDashboardChanges(): void {
        const {dispatchUpdateDashboard} = this.props;
        dispatchUpdateDashboard(this.dashboardViewerRef.getConfiguration());

        this.setState({
            editMode: false,
        });
    }

    private onDiscardDashboardChanges(): void {
        this.setState({
            editMode: false,
        });
    }

    private onEditDashboard(): void {
        this.setState({
            editMode: true,
        });
    }

    private onRestoreDefault(): void {
        const {t} = this.props;
        const {dispatchShowPopup, dispatchRestoreDefaultDashboards, dispatchClosePopup} = this.props;

        dispatchShowPopup({
            type: PopupType.ServiceProviderGroupCreation,
            content: (
                <ConfirmationPopup
                    message={t('Are you sure you want to restore the default dashboards?')}
                    onCancel={() => dispatchClosePopup()}
                    onConfirm={() => {
                        dispatchRestoreDefaultDashboards(t);
                        dispatchClosePopup();
                    }}
                />
            ),
        });
        this.setState({
            editMode: false,
        });
    }

    private getStateFromUrl(): UrlParameters {
        const {currentLocation} = this.props;
        const queryParameters = new URLSearchParams(currentLocation.search);
        const dashboardId = queryParameters.get(QueryParameter.Dashboard);
        return {dashboardId};
    }

    private stateEqualsUrlParams(urlParams: UrlParameters): boolean {
        const {activeDashboardId} = this.state;
        return urlParams.dashboardId === activeDashboardId;
    }
}

const mapStateToProps = ({router, dashboard, authentication, analytics}: ApplicationState) => ({
    user: authentication.user,
    currentLocation: router.location,
    dashboards: dashboard.dashboards,
    inProgress: dashboard.inProgress,
    serviceProviderGroup: authentication.serviceProviderGroup,
    defaultControlValues: analytics.defaultControlValues,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchFetchDashboardsRequest: () => dispatch(fetchDashboardsRequest()),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchUpdateDashboard: (dashboard: Dashboard) => dispatch(updateDashboardRequest(dashboard)),
    dispatchDeleteDashboard: (dashboard: Dashboard) => dispatch(deleteDashboardRequest(dashboard)),
    dispatchCreateDashboard: (dashboard: Dashboard) => dispatch(createDashboardRequest(dashboard)),
    dispatchRestoreDefaultDashboards: (t: TFunction) => dispatch(restoreDefaultDashboards(t)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AnalyticsPage));

interface OwnState {
    activeDashboardId: string | null;
    editMode: boolean;
    fixedDashboards: Dashboard[];
}

type AllState = OwnState;

interface PropsFromState {
    user: User;
    currentLocation: Location;
    inProgress: boolean;
    dashboards: Dashboard[];
    serviceProviderGroup: ServiceProviderGroup;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchFetchDashboardsRequest: typeof fetchDashboardsRequest;
    dispatchRestoreDefaultDashboards: typeof restoreDefaultDashboards;
    dispatchShowPopup: typeof showPopup;
    dispatchClosePopup: typeof closePopup;
    dispatchUpdateDashboard: typeof updateDashboardRequest;
    dispatchDeleteDashboard: typeof deleteDashboardRequest;
    dispatchCreateDashboard: typeof createDashboardRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface UrlParameters {
    dashboardId: string | null;
}
