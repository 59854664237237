
import {Component, Suspense} from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {History} from 'history';
import {i18n} from 'i18next';
import ErrorBoundary from './components/error-boundary/error-boundary';
import App from './app';

class Main extends Component<AllProps> {

    public render(): JSX.Element {
        const {history} = this.props;

        return (
          <Suspense fallback="">
            <ConnectedRouter history={history}>
              <ErrorBoundary>
                <App history={history}/>
              </ErrorBoundary>
            </ConnectedRouter>
          </Suspense>
        );
    }
}

export default Main;

interface OwnProps {
    history: History;
    i18n: i18n;
}

type AllProps = OwnProps;
