import { Reducer } from 'redux';
import { ControlValue } from '../../pages/analytics/dashboard-controls/controls-snapshot';
import { ParamKey } from '../dashboard';
import { SharedActionTypes } from '../shared/types';
import { AnalyticsAction, AnalyticsActionTypes, AnalyticsState } from './types';

export function initialState(): AnalyticsState {
    return {
        keyPerformanceIndicatorData: [],
        defaultControlValues: new Map<ParamKey, ControlValue>(),
    };
}

const reducer: Reducer<AnalyticsState, AnalyticsAction> = (state = initialState(), action): AnalyticsState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_SERVICE_PROVIDER_GROUP: {
            return initialState();
        }
        case AnalyticsActionTypes.KEY_PERFORMANCE_INDICATOR_REQUEST: {
            const data = state.keyPerformanceIndicatorData.filter((d) => d.type !== action.payload.type);
            return { ...state, keyPerformanceIndicatorData: data };
        }
        case AnalyticsActionTypes.KEY_PERFORMANCE_INDICATOR_SUCCESS: {
            const data = [...state.keyPerformanceIndicatorData];
            data.push(action.payload);
            return { ...state, keyPerformanceIndicatorData: data };
        }
        case AnalyticsActionTypes.UPDATE_DEFAULT_CONTROL_VALUES: {
            const newDefaultValues = action.payload;
            const oldDefaultValues = state.defaultControlValues;
            return { ...state, defaultControlValues: new Map([...oldDefaultValues, ...newDefaultValues]) };
        }
        case AnalyticsActionTypes.UPDATE_SINGLE_DEFAULT_CONTROL_VALUE: {
            const oldDefaultValues = state.defaultControlValues;
            const newDefaultValues = new Map([...oldDefaultValues]);
            newDefaultValues.set(action.payload.defaultValueKey, action.payload.defaultControlValue);
            return { ...state, defaultControlValues: newDefaultValues };
        }
        case AnalyticsActionTypes.JOBS_DISTRIBUTION_REQUEST: {
            return { ...state, jobsDistributionData: undefined };
        }
        case AnalyticsActionTypes.JOBS_DISTRIBUTION_SUCCESS: {
            return { ...state, jobsDistributionData: action.payload };
        }
        case AnalyticsActionTypes.PURCHASING_DISTRIBUTION_REQUEST: {
            return { ...state, purchasingDistributionData: undefined };
        }
        case AnalyticsActionTypes.PURCHASING_DISTRIBUTION_SUCCESS: {
            return { ...state, purchasingDistributionData: action.payload };
        }
        case AnalyticsActionTypes.PURCHASING_EVOLUTION_REQUEST: {
            return { ...state, purchasingEvolutionData: undefined };
        }
        case AnalyticsActionTypes.PURCHASING_EVOLUTION_SUCCESS: {
            return { ...state, purchasingEvolutionData: action.payload };
        }
        case AnalyticsActionTypes.SERVICE_PROVIDERS_MONTHLY_OVERVIEW_REQUEST: {
            return { ...state, serviceProvidersMonthlyOverviewData: undefined };
        }
        case AnalyticsActionTypes.SERVICE_PROVIDERS_MONTHLY_OVERVIEW_SUCCESS: {
            return { ...state, serviceProvidersMonthlyOverviewData: action.payload };
        }
        case AnalyticsActionTypes.TOP_SOLD_TOS_REQUEST: {
            return { ...state, topSoldTosData: undefined };
        }
        case AnalyticsActionTypes.TOP_SOLD_TOS_SUCCESS: {
            return { ...state, topSoldTosData: action.payload };
        }
        case AnalyticsActionTypes.TIRES_DISTRIBUTION_REQUEST: {
            return { ...state, tiresDistributionData: undefined };
        }
        case AnalyticsActionTypes.TIRES_DISTRIBUTION_SUCCESS: {
            return { ...state, tiresDistributionData: action.payload };
        }
        case AnalyticsActionTypes.TIRE_BRAND_DISTRIBUTION_REQUEST: {
            return { ...state, tireBrandDistributionData: undefined };
        }
        case AnalyticsActionTypes.TIRE_BRAND_DISTRIBUTION_SUCCESS: {
            return { ...state, tireBrandDistributionData: action.payload };
        }
        case AnalyticsActionTypes.CORPORATE_FITMENT_DISTRIBUTION_REQUEST: {
            return { ...state, corporateFitmentDistributionData: undefined };
        }
        case AnalyticsActionTypes.CORPORATE_FITMENT_DISTRIBUTION_SUCCESS: {
            return { ...state, corporateFitmentDistributionData: action.payload };
        }
        default: {
            return state;
        }
    }
};

export { reducer as analyticsReducer };
export { initialState as initialAnalyticsState };
