import {KpiTypes} from '../../../store/analytics';
import {WidgetType} from '../../../store/dashboard';

export interface WidgetCatalogItem {
    name: string;
    type: WidgetType;

    configuration: any;
    defaultWidth: number;
    defaultHeight: number;
}

export interface WidgetCatalogGroup {
    id: string;
    name: string;
    widgets: WidgetCatalogItem[];
}

export const allCatalogGroups: WidgetCatalogGroup[] = [
    {
        id: 'graphs',
        name: 'Graphs',
        widgets: [
            {
                name: 'Job Distribution',
                type: WidgetType.JobsDistribution,
                configuration: {},
                defaultWidth: 7,
                defaultHeight: 5,
            },
            {
                name: 'Purchasing Distribution',
                type: WidgetType.PurchasingDistribution,
                configuration: {},
                defaultWidth: 7,
                defaultHeight: 5,
            },
            {
                name: 'Purchasing Evolution',
                type: WidgetType.PurchasingEvolution,
                configuration: {},
                defaultWidth: 8,
                defaultHeight: 6,
            },
            {
                name: 'Top 20 fleets by inspection count',
                type: WidgetType.TopSoldTos,
                configuration: {},
                defaultWidth: 8,
                defaultHeight: 12,
            },
            {
                name: 'Tires Distribution',
                type: WidgetType.TiresDistribution,
                configuration: {},
                defaultWidth: 7,
                defaultHeight: 5,
            },
            {
                name: 'Tire Brand Distribution',
                type: WidgetType.TireBrandDistribution,
                configuration: {},
                defaultWidth: 6,
                defaultHeight: 15,
            },
            {
                name: 'Corporate Fitment Distribution',
                type: WidgetType.CorporateFitmentDistribution,
                configuration: {},
                defaultWidth: 6,
                defaultHeight: 15,
            },
        ],
    },
    {
        id: 'kpis',
        name: 'KPIs',
        widgets: [
            {
                name: 'Total Invoiced',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.TotalInvoiced},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Total Job Count',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.TotalJobCount},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Total Inspection Count',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.TotalInspectionCount},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Inspection Ratio',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.InspectionRatio},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'eJob Utilization',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.EjobUtilization},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Retread Ratio',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.RetreadRatio},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'eCasing Returns Ratio',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.ECasingReturnsRatio},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Total Tire Count',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.TotalTiresCount},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Average Process Time',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.JobsAvgProcessTime},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Average Acceptance Time',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.JobsAvgAcceptanceTime},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Corporate Brand Ratio',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.CorporateBrandRatio},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Average Tread Depth',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.AverageTreadDepth},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Regroove Ratio',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.RegrooveRatio},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Regroove Count',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.RegrooveCount},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Part Worn Tyre Usage',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.PWTStockAge},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Part Worn Tyre Cycle Time',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.PWTCycleTime},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Part Worn Tyre Current Stock',
                type: WidgetType.KpiBox,
                configuration: {type: KpiTypes.PWTCurrentStock},
                defaultWidth: 3,
                defaultHeight: 3,
            },
            {
                name: 'Purchasing Distribution KPI',
                type: WidgetType.PurchasingDistributionKpi,
                configuration: {},
                defaultWidth: 3,
                defaultHeight: 3,
            },
        ],
    },
];
