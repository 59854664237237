import {Reducer} from 'redux';
import {FilterControlSelectionAction, FilterControlSelectionActionTypes, FilterControlSelectionState} from './types';
import {SharedActionTypes} from '../shared/types';

function initialState(): FilterControlSelectionState {
    return {};
}

const reducer: Reducer<FilterControlSelectionState, FilterControlSelectionAction> = (state = initialState(), action): FilterControlSelectionState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS: {
            return initialState();
        }
        case FilterControlSelectionActionTypes.FILTER_RESET: {
            return {...state, serviceProvidersFilter: undefined, soldTosFilter: undefined};
        }
        case FilterControlSelectionActionTypes.FILTER_SERVICE_PROVIDERS: {
            return {...state, serviceProvidersFilter: action.payload};
        }
        case FilterControlSelectionActionTypes.FILTER_SOLD_TOS: {
            return {...state, soldTosFilter: action.payload};
        }
        default: {
            return state;
        }
    }
};

export {reducer as filterControlSelectionReducer};
export {initialState as initialFilterControlSelectionState};
