import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import { PurchasingEvolutionRequest } from '../../../../store/analytics/types/purchasing-evolution.types';
import { CurrencyInfo } from '../../../../store/service-provider-groups';
import {
    createDateRangeWithServiceProvidersAndSoldTosRequest,
} from '../utils/widget-helper';
import { Frequency } from '../../../../store/analytics';

export function buildRequest(snapshot: ControlsSnapshot, currency: CurrencyInfo): PurchasingEvolutionRequest {
    const request = Object.assign(
        createDateRangeWithServiceProvidersAndSoldTosRequest(snapshot), {
            currencyType:currency.type,
            frequency: Frequency.MONTHLY,
        });

    return request;
}
