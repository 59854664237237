import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import styles from './terms-of-use.module.scss';

class TermsOfUse extends Component<AllProps, OwnState> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    public render(): JSX.Element {
        return (
            <div className={styles.termsContainer}>
                <div className={styles.document}>
                    <div className={styles.subTitle}>Copyright</div>
                    <div className={styles.paragraph}>{`The text, images, graphics, sound files, animation files, video
                        files and other content, and their arrangement on Goodyear's web sites are all subject to
                        copyright and other intellectual property protection. Such content may not be copied for
                        commercial use or distribution, nor may it be modified or posted to other sites. Some Goodyear
                        web sites also contain content that is subject to copyright rights of Goodyear affiliates or
                        third party providers.`}
                    </div>
                    <div className={styles.subTitle}>Disclaimer</div>
                    <div className={styles.paragraph}> By using a Goodyear site, you signify your assent to the Goodyear
                        On-line Privacy Policy, available <a
                            href="https://corporate.goodyear.com/en-US/terms-conditions-and-privacy-policy/global-privacy-policy.html"
                            target="_blank" rel='noopener noreferrer'>here</a>.
                        If you do not
                        agree to this policy, please do not use our sites. We reserve the right, at our discretion, to
                        change, modify, add, or remove portions of this policy at any time. Please check this page
                        periodically for changes. Your continued use of our sites following the posting of changes to
                        these terms will mean you accept those changes.
                    </div>
                    <div className={styles.subTitle}>Product and product related information</div>
                    <div className={styles.paragraph}> The information on these websites is provided exclusively for
                        information purposes and is not binding. For more detailed information, please contact your
                        local Goodyear office or your Goodyear representative.
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TermsOfUse);

interface OwnProps {
}

interface OwnState {
}

type AllProps = OwnProps & WithTranslation;
