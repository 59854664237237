import {WithTranslation, withTranslation} from 'react-i18next';
import {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckSquare} from '@fortawesome/pro-light-svg-icons/faCheckSquare';
import {faSquare} from '@fortawesome/pro-light-svg-icons/faSquare';
import styles from './table-header-select-panel.module.scss';
import {conditionalClassLister} from '../../../utils/class-helpers';
import ToggleDisplay from '../../../utils/toggle-display';
import {SelectionOption} from './types/selection-option';

export class TableHeaderSelect extends Component<AllProps, OwnState> {
    constructor(props: AllProps) {
        super(props);

        const {excludedOptions} = this.props;

        this.state = {
            excludedOptions,
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {excludedOptions} = this.props;
        if (prevProps.excludedOptions !== excludedOptions) {
            this.setState({excludedOptions});
        }
    }

    public render(): JSX.Element {
        const {t, isOpen, options} = this.props;
        const {excludedOptions} = this.state;

        const selectPanelClasses = conditionalClassLister(styles)({
            selectPanel: true,
            isOpen,
        });

        const invertedButtonClasses = conditionalClassLister(styles)({
            button: true,
            invert: true,
        });

        return (
            <div className={selectPanelClasses}>
                <div className={styles.selectContent}>
                    <div className={styles.optionsTitle}>{t('Include')}:</div>
                    <div className={styles.optionsList}>
                        {options.map((o: SelectionOption) =>
                            <div className={styles.checkBoxItem} key={o.key}
                                 onClick={() => this.onChanged(o)}>
                                <div className={styles.checkBox}>
                                    <ToggleDisplay if={!excludedOptions.includes(o)}>
                                        <FontAwesomeIcon icon={faCheckSquare}/>
                                    </ToggleDisplay>
                                    <ToggleDisplay if={excludedOptions.includes(o)}>
                                        <FontAwesomeIcon icon={faSquare}/>
                                    </ToggleDisplay>
                                </div>
                                <div className={styles.checkBoxText}>
                                    {o.label}
                                </div>
                            </div>)
                        }
                    </div>
                    <div className={styles.buttons}>
                        <div className={invertedButtonClasses} onClick={() => this.onClear()}>{t('Clear')}</div>
                        <div className={styles.button} onClick={() => this.onApply()}>{t('Apply')}</div>
                    </div>
                </div>
            </div>
        );
    }

    private onChanged(option:SelectionOption) {
        const {excludedOptions} = this.state;
        if(excludedOptions.includes(option)){
            this.setState({excludedOptions:excludedOptions.filter(f=>f!==option)});
        }else{
            this.setState({excludedOptions:excludedOptions.concat([option])});
        }
   }


   public onApply(): void {
      const {excludedOptions} = this.state;
       const {onApply} = this.props;
       onApply(excludedOptions);
    }

    public onClear(): void {
      const {onApply} = this.props;
        onApply([]);
        this.setState({
            excludedOptions: [],
        })
    }
}

export default withTranslation()(TableHeaderSelect);

interface OwnProps {
    isOpen: boolean;
    options: SelectionOption[];
    excludedOptions: SelectionOption[];
    onApply: (selectedOptions: SelectionOption[]) => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    excludedOptions: SelectionOption[];
}
