import {Reducer} from 'redux';
import {LayoutAction, LayoutActionTypes, LayoutState} from './types';
import {SharedActionTypes} from '../shared/types';

function initialState(): LayoutState {
    return {
        showSidebar: false,
    };
}

const reducer: Reducer<LayoutState, LayoutAction> = (state = initialState(), action): LayoutState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS: {
            return initialState();
        }
        case LayoutActionTypes.TOGGLE_SIDEBAR: {
            return {...state, showSidebar: action.payload};
        }
        default: {
            return state;
        }
    }
};

export {reducer as layoutReducer};
export {initialState as initialLayoutState};
