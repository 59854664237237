import {Component} from 'react';

class ToggleDisplay extends Component<AllProps> {

    public render(): JSX.Element | null {
        const {if: if1, children} = this.props;
        const content = children;
        if (if1 === false) {
            return null;
        }

        const style: any = {};
        if (this.shouldHide(this.props)) {
            style.display = 'none';
        }

        // prevent our props from being leaked down onto the children
        const newProps = this.pickProps(this.props);

        return (
            <span style={style} {...newProps} >{content}</span>
        );
    }

    private shouldHide(props) {
        if (this.isDefined(props.show)) {
            return !props.show;
        }
        if (this.isDefined(props.hide)) {
            return props.hide;
        }
        return false;
    }

    private pickProps(props) {
        const newProps = {...props};
        ['show', 'hide', 'if'].forEach((prop) => {
            if (prop in newProps) {
                delete newProps[prop];
            }
        });
        return newProps;
    }

    private isDefined(val) {
        return val !== null && val !== undefined;
    }
}

export default ToggleDisplay;

interface OwnProps {
    hide?: boolean;
    show?: boolean;
    if?: boolean;
    children: React.ReactNode;
}

type AllProps = OwnProps;
