import {Redirect, Route, RouteProps} from 'react-router';
import {Component} from 'react';
import {RouteUrl} from '../../routes';
import AuthenticationApi from '../../api/authentication-api';

class AuthenticatedRoute extends Component<AllProps, AllState> {

    constructor(props:AllProps) {
        super(props);
        this.state = {
            loading: true,
            isAuthenticated: false,
        };
    }

    public componentDidMount(): void {
        AuthenticationApi.isAuthenticated()
            .then((isAuthenticated) => {
                this.setState({
                    isAuthenticated,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    isAuthenticated:false,
                    loading: false,
                });
            });
    }

    public render(): JSX.Element {
        const {location} = this.props;
        const returnUrl = location!.search ? `${location!.pathname}${location!.search}` : location!.pathname;
        const returnUrlEncoded = encodeURIComponent(returnUrl);
        const redirectUrl = `${RouteUrl.Login}?${new URLSearchParams({returnUrl: returnUrlEncoded}).toString()}`;

        const {loading, isAuthenticated} = this.state;
        if (loading) {
            return <div />;
        }

        return isAuthenticated ? <Route {...this.props}/> : <Redirect to={redirectUrl}/>;
    }
}

export default AuthenticatedRoute;

type AllProps = RouteProps;

interface OwnState {
    loading: boolean;
    isAuthenticated: boolean;
}

type AllState = OwnState;
