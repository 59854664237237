import {Reducer} from 'redux';
import {RequestStatus, RequestStatusType, SharedActionTypes} from '../shared/types';
import {MaintenanceAction, MaintenanceActionTypes, MaintenanceState} from './types';

function initialState(): MaintenanceState {
    return {
        underMaintenance: false,
    };
}

const reducer: Reducer<MaintenanceState, MaintenanceAction> = (state = initialState(), action): MaintenanceState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_SERVICE_PROVIDER_GROUP: {
            return initialState();
        }
        case MaintenanceActionTypes.FETCH_STATUS_REQUEST: {
            return { ...state, getMaintenanceStatusRequestStatus: new RequestStatus(RequestStatusType.InProgress) };
        }
        case MaintenanceActionTypes.FETCH_STATUS_SUCCESS: {
            return {
                ...state,
                underMaintenance:action.payload.underMaintenance,
                getMaintenanceStatusRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case MaintenanceActionTypes.FETCH_STATUS_FAILURE: {
            return { ...state, getMaintenanceStatusRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload) };
        }
        default: {
            return state;
        }
    }
};

export { reducer as maintenanceReducer };
export { initialState as initialMaintenanceState };
