import {action} from 'typesafe-actions';
import {SearchActionTypes, SearchResult} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const searchRequest = (query: string) =>
  action(SearchActionTypes.SEARCH_REQUEST, query);
export const searchSuccess = (results: SearchResult[]) =>
  action(SearchActionTypes.SEARCH_SUCCESS, results);
export const searchFailure = () =>
  action(SearchActionTypes.SEARCH_FAILURE);
export const clearSearch = () =>
  action(SearchActionTypes.SEARCH_CLEAR);
