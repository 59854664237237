import {action} from 'typesafe-actions';
import {VehicleRequest, VehiclesActionTypes, VehicleWithFitmentsAndJobs} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getVehicleRequest = (request:VehicleRequest) =>
    action(VehiclesActionTypes.GET_VEHICLE_REQUEST, request);
export const getVehicleSuccess = (vehicle: VehicleWithFitmentsAndJobs) =>
    action(VehiclesActionTypes.GET_VEHICLE_SUCCESS, vehicle);
export const getVehicleFailure = (message: string) =>
    action(VehiclesActionTypes.GET_VEHICLE_FAILURE, message)
