import {action} from 'typesafe-actions';
import {ServiceProviderGroupWithConfiguration} from '../service-provider-groups';
import {
    AuthenticationActionTypes,
    AuthenticationRequest,
    ConfirmResetPasswordRequestPayload,
    FederatedAuthenticationRequest,
    ForgotPasswordRequestPayload,
    User, UserSettings,
} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const federatedLoginRequest = (request: FederatedAuthenticationRequest) =>
    action(AuthenticationActionTypes.FEDERATED_LOGIN_REQUEST, request);
export const loginRequest = (request: AuthenticationRequest) =>
    action(AuthenticationActionTypes.LOGIN_REQUEST, request);
export const confirmResetPasswordRequest = (request: ConfirmResetPasswordRequestPayload) =>
    action(AuthenticationActionTypes.CONFIRM_RESET_PASSWORD_REQUEST, request);
export const resetForgotPasswordForm = () => action(AuthenticationActionTypes.RESET_FORGOT_PASSWORD_FORM);
export const confirmResetPasswordSuccess = () => action(AuthenticationActionTypes.CONFIRM_RESET_PASSWORD_SUCCESS);
export const forgotPasswordRequest = (request: ForgotPasswordRequestPayload) =>
    action(AuthenticationActionTypes.FORGOT_PASSWORD_REQUEST, request);
export const forgotPasswordSuccess = () => action(AuthenticationActionTypes.FORGOT_PASSWORD_SUCCESS);
export const forgotPasswordFailure = (message?: string) =>
    action(AuthenticationActionTypes.FORGOT_PASSWORD_FAILURE, message);
export const loginSuccess = () => action(AuthenticationActionTypes.LOGIN_SUCCESS);
export const loginNewPasswordRequired = () => action(AuthenticationActionTypes.LOGIN_NEW_PASSWORD_REQUIRED);
export const loginPasswordExpired = () => action(AuthenticationActionTypes.LOGIN_PASSWORD_EXPIRED);
export const loginFailure = (message?: string) => action(AuthenticationActionTypes.LOGIN_FAILURE, message);
export const logoutRequest = (): { type: AuthenticationActionTypes.LOGOUT_REQUEST } => action(AuthenticationActionTypes.LOGOUT_REQUEST);

export const fetchCurrentUserRequest = () => action(AuthenticationActionTypes.FETCH_CURRENT_USER_REQUEST);
export const fetchCurrentUserSuccess = (user: User) =>
    action(AuthenticationActionTypes.FETCH_CURRENT_USER_SUCCESS, user);
export const fetchCurrentUserFailure = (message: string) =>
    action(AuthenticationActionTypes.FETCH_CURRENT_USER_FAILURE, message);

export const updateCurrentUserSettingsRequest = (serviceProviderGroupId: string, settings: UserSettings) =>
    action(AuthenticationActionTypes.UPDATE_CURRENT_USER_SETTINGS_REQUEST, {
        serviceProviderGroupId,
        settings,
    });
export const updateCurrentUserSettingsSuccess = (settings: UserSettings) =>
    action(AuthenticationActionTypes.UPDATE_CURRENT_USER_SETTINGS_SUCCESS, settings);
export const updateCurrentUserSettingsFailure = (message: string) =>
    action(AuthenticationActionTypes.UPDATE_CURRENT_USER_SETTINGS_FAILURE, message);

export const setCurrentUser = (user?: User) => action(AuthenticationActionTypes.SET_CURRENT_USER, user);
export const fetchSelectedServiceProviderGroupRequest = (serviceProviderGroupId: string) =>
    action(AuthenticationActionTypes.FETCH_SELECTED_SERVICE_PROVIDER_NETWORK_REQUEST, serviceProviderGroupId);
export const fetchSelectedServiceProviderGroupSuccess = (serviceProviderGroupWithConfiguration: ServiceProviderGroupWithConfiguration) =>
    action(AuthenticationActionTypes.FETCH_SELECTED_SERVICE_PROVIDER_NETWORK_SUCCESS, serviceProviderGroupWithConfiguration);
