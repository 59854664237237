import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { Color, IDisposer } from '@amcharts/amcharts4/core';
import am4lang_nl_NL from '@amcharts/amcharts4/lang/nl_NL';

export function createXYChart(chartId: string): am4charts.XYChart {
    const chart = am4core.create(chartId, am4charts.XYChart);
    chart.language.locale = am4lang_nl_NL;
    return chart;
}

export function addStrokeToColumnChart(series: am4charts.ColumnSeries):void {
    series.columns.template.stroke = am4core.color('#ffffff');
    series.columns.template.strokeWidth = 1;
    series.columns.template.strokeOpacity = 1;
}

export function rotateAxisLabels(axis: am4charts.Axis):void {
    axis.renderer.labels.template.rotation = -45;
    axis.renderer.labels.template.horizontalCenter = 'right';
    axis.renderer.labels.template.verticalCenter = 'middle';
}

export function addClickEvent(series: am4charts.ColumnSeries, clickHandler: (datapoint: any) => void): IDisposer {
    series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    return series.columns.template.events.on('hit', (ev) => {
        if (ev.target.dataItem) {
            clickHandler(ev.target.dataItem.dataContext);
        }
    });
}

export function addLineSeriesClickEvent(series: am4charts.LineSeries, clickHandler: (datapoint: any) => void): IDisposer {
    series.segments.template.interactionsEnabled = true;
    series.segments.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    return series.segments.template.events.on('hit', (ev) => {
        if (ev.target.dataItem && ev.target.dataItem.component) {
            clickHandler(ev.target.dataItem.component.tooltipDataItem.dataContext);
        }
    });
}

export function dynamicallySetColorByCategory(series: am4charts.ColumnSeries, mapping: (categoryX: string) => Color):void {
    series.columns.template.adapter.add('fill', (fill, target) => mapping(getCategoryX(target)));
}

export function dynamicallySetColorByType(series: am4charts.ColumnSeries, typeField: string, mapping: (type: string) => Color):void {
    series.columns.template.adapter.add('fill', (fill, target) => mapping(getType(typeField, target)));
}

export function dynamicallySetColorBySeriesAndType(series: am4charts.ColumnSeries, typeField: string, mapping: (seriesName: string, type: string) => Color):void {
    series.columns.template.adapter.add('fill', (fill, target) => mapping(series.name, getType(typeField, target)));
}

export function addStandardTooltipStyle(series: am4charts.XYSeries):void {
    series.tooltip!.getFillFromObject = false;
    series.tooltip!.background.fill = am4core.color('#000000');
}

function getCategoryX(target): string {
    return (target.dataItem! as any).categoryX as string;
}

function getType(typeField, target): string {
    return (target.dataItem.dataContext[typeField]);
}
