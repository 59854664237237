import {JobsExplorerRequest, JobsSort, JobType} from '../../../store/jobs';
import {ControlsSnapshot} from '../../analytics/dashboard-controls/controls-snapshot';
import {
    createDateRangeWithServiceProvidersAndSoldTosRequest,
} from '../../analytics/dashboard-widgets/utils/widget-helper';
import {LocalLanguageType} from '../../../store/shared/types';

export function buildRequest(snapshot: ControlsSnapshot,
                             page: number,
                             itemsPerPage: number,
                             serviceProviderNameContains: string,
                             licensePlateContains: string,
                             jobReferenceContains: string,
                             purchasingRefContains: string,
                             fleetNameContains: string,
                             ejobUserContains: string,
                             jobTypeExcludedOptions:JobType[],
                             isEjobExcludedOptions:boolean[],
                             sort: JobsSort,
                             language:LocalLanguageType): JobsExplorerRequest {
    const request = createDateRangeWithServiceProvidersAndSoldTosRequest(snapshot);
    return Object.assign(request, {
        page,
        itemsPerPage,
        sort,
        serviceProviderNameContains,
        licensePlateContains,
        jobReferenceContains,
        purchasingRefContains,
        fleetNameContains,
        ejobUserContains,
        jobTypeExcludedOptions,
        isEjobExcludedOptions,
        language
    });
}
