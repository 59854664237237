import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './paging.module.scss';
import {conditionalClassLister} from '../../../../utils/class-helpers';
import Dropdown, {DropdownOption} from '../../../../components/dropdown/dropdown';

class Paging extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);

        const {initialItemsPerPage} = this.props;

        const pagingOptions: DropdownOption[] = [
            {id: ItemsPerPage.Ten, label: ItemsPerPage.Ten},
            {id: ItemsPerPage.Fifteen, label: ItemsPerPage.Fifteen},
            {id: ItemsPerPage.TwentyFive, label: ItemsPerPage.TwentyFive},
            {id: ItemsPerPage.Fifty, label: ItemsPerPage.Fifty},
            {id: ItemsPerPage.Hundred, label: ItemsPerPage.Hundred},
        ];

        this.state = {
            itemsPerPage: initialItemsPerPage,
            pagingOptions,
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any) {
        const {itemsPerPage} = this.state;
        const {onItemsPerPageChanged} = this.props;
        if (itemsPerPage !== prevState.itemsPerPage) {
            onItemsPerPageChanged(itemsPerPage);
        }
    }

    private placeHolderText = '...';

    public render(): JSX.Element {
        const {total, t, currentPage, itemsPerPageBackground,disabled} = this.props;
        const {pagingOptions, itemsPerPage} = this.state;
        const info = this.buildState(currentPage);

        const pagingContainerStyles = conditionalClassLister(styles)({
            pagingContainer: true,
            disabled
        });

        return (
            <div className={pagingContainerStyles}>
                <div>{t('Showing {{from}} to {{to}} of {{total}} results', {
                    total,
                    from: info.from,
                    to: total > info.to ? info.to : total,
                })}</div>
                <div className={styles.rightPanel}>
                    <div className={styles.changePage} onClick={() => this.onPreviousPage()}>{t('Previous')}</div>
                    <div className={styles.pageNumberContainer}>
                        {info.shortCuts.map((pageIndex, i) => {
                            const pageClasses = conditionalClassLister(styles)({
                                placeholderNumber: pageIndex === this.placeHolderText,
                                pageNumber: pageIndex !== this.placeHolderText,
                                pageNumberActive: pageIndex === currentPage.toString(),
                                disabled
                            });
                            return <div key={i} className={pageClasses}
                                        onClick={() => this.onJumpToPage(pageIndex)}>{pageIndex}</div>;
                        })}
                    </div>
                    <div className={styles.changePage} onClick={() => this.onNextPage(info.totalPages)}>{t('Next')}</div>
                </div>
                <div>
                    <span className={styles.itemsPerPage}>{`${t('Items per page')}: `} </span>
                    <Dropdown options={pagingOptions}
                              selection={pagingOptions.find(o => parseInt(o.label, 10) === itemsPerPage)}
                              onSelectionChanged={(option: DropdownOption) => this.onItemsPerPageChanged(option)}
                              background={itemsPerPageBackground}
                              width='100px'
                              disabled={disabled}
                    />
                </div>
            </div>
        );
    }

    private onItemsPerPageChanged(option: DropdownOption): void {
        const itemsPerPage = parseInt(option.label, 10);
        if (!Number.isNaN(itemsPerPage)) {
            this.setState({itemsPerPage});
        }
    }

    private buildState(currentPage: number): PageInfo {
        const {currentPage: currentPage1, total} = this.props;
        const {itemsPerPage} = this.state;
        const from = ((currentPage1 - 1) * itemsPerPage) + 1;
        const to = (currentPage1 * itemsPerPage);
        const totalPages = Math.ceil(total / itemsPerPage);
        const allPages: string[] = [];

        if (totalPages <= 6) {
            let pageCount = 1;
            while (pageCount <= totalPages) {
                allPages.push(pageCount.toString());
                pageCount += 1;
            }
        } else if (currentPage < 4) {
            allPages.push('1');
            allPages.push('2');
            allPages.push('3');
            allPages.push('4');
            allPages.push(this.placeHolderText);
            allPages.push(totalPages.toString());
        } else if (currentPage + 2 >= totalPages) {
            allPages.push('1');
            allPages.push(this.placeHolderText);
            allPages.push((totalPages - 3).toString());
            allPages.push((totalPages - 2).toString());
            allPages.push((totalPages - 1).toString());
            allPages.push(totalPages.toString());
        } else {
            allPages.push('1');
            allPages.push(this.placeHolderText);
            allPages.push((currentPage - 1).toString());
            allPages.push((currentPage).toString());
            allPages.push((currentPage + 1).toString());
            allPages.push(this.placeHolderText);
            allPages.push(totalPages.toString());
        }

        return {from, to, totalPages, shortCuts: allPages};
    }

    public onPreviousPage() {
        const {currentPage, onPreviousPage: onPreviousPage1} = this.props;
        if (currentPage > 1) {
            onPreviousPage1();
        }
    }

    public onNextPage(maxPages: number) {
        const {currentPage, onNextPage: onNextPage1} = this.props;
        if (currentPage < maxPages) {
            onNextPage1();
        }
    }

    public onJumpToPage(index: string) {
        const {onJumpToPage: onJumpToPage1} = this.props;
        onJumpToPage1(parseInt(index, 10));
    }
}

export default withTranslation()(Paging);

interface OwnProps {
    disabled?:boolean;
    currentPage: number;
    initialItemsPerPage: number;
    total: number;
    onPreviousPage: () => void;
    onNextPage: () => void;
    onJumpToPage: (index: number) => void;
    onItemsPerPageChanged: (items: number) => void;
    itemsPerPageBackground?: string;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    itemsPerPage: number;
    pagingOptions: DropdownOption[];
}

type AllState = OwnState;

interface PageInfo {
    from: number;
    to: number;
    totalPages: number;
    shortCuts: string[];
}

enum ItemsPerPage {
    Ten = '10',
    Fifteen = '15',
    TwentyFive = '25',
    Fifty = '50',
    Hundred = '100',
}
