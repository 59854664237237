import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './scheduled-period-selector.module.scss';
import Dropdown, {DropdownOption} from '../../../../../components/dropdown/dropdown';
import {ScheduledReportPeriod} from '../../../../../store/reports';

class ScheduledPeriodSelectorComponent extends Component<AllProps> {

    public periodOptions: DropdownOption[];

    constructor(props) {
        super(props);

        const {t} = this.props;

        this.periodOptions = [
            {key: ScheduledReportPeriod.PAST_WEEK, label: t('Past Week')},
            {key: ScheduledReportPeriod.PAST_MONTH, label: t('Past Month')},
            {key: ScheduledReportPeriod.PAST_YEAR, label: t('Past Year')},
            {key: ScheduledReportPeriod.MONTH_TO_DATE, label: t('Month to date')},
            {key: ScheduledReportPeriod.YEAR_TO_DATE, label: t('Year to date')},
        ];
    }

    public render(): JSX.Element {
        const {defaultPeriod} = this.props;

        return (
            <div className={styles.dropdown}>
                <Dropdown
                    background='white'
                    width='200px'
                    options={this.periodOptions}
                    selection={defaultPeriod ? this.periodOptions.find(p => p.key === defaultPeriod) : this.periodOptions[0]}
                    onSelectionChanged={(option) => this.onPeriodChanged(option)}/>
            </div>
        );
    }

    private onPeriodChanged(option: DropdownOption): void {
        const {onPeriodChange} = this.props;
        if (option && option.key) {
            onPeriodChange(option.key as ScheduledReportPeriod);
        }
    }

}

export default withTranslation()(ScheduledPeriodSelectorComponent);

interface OwnProps {
    defaultPeriod?: ScheduledReportPeriod;
    onPeriodChange: (period: ScheduledReportPeriod) => void;
}

type AllProps = OwnProps & WithTranslation;
