import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {RouteUrl} from '../../../routes';
import {ApplicationState} from '../../../store';
import * as layoutActions from '../../../store/layout/actions';
import {RequestStatus} from '../../../store/shared/types';
import AdminPageHeader from '../components/admin-page-header/admin-page-header';
import styles from './data-status.module.scss';
import {DataStatusContract, getDataStatusRequest} from '../../../store/data-status';
import {conditionalClassLister} from '../../../utils/class-helpers';
import {formatStringInBrusselsTime, getDifferenceInDays} from '../../../utils/date-helper';

class DataStatusPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {dispatchGetDataStatus, dispatchToggleSidebar} = this.props;
        dispatchGetDataStatus();
        dispatchToggleSidebar(false);
    }

    public render(): JSX.Element {
        const {
            t,
            dataStatus,
            dispatchNavigateTo,
            getDataStatusRequestStatus,
        } = this.props;

        return dataStatus && !getDataStatusRequestStatus?.isInProgress ? (
            <div className={styles.container}>
                <AdminPageHeader
                    title={t('Data Status')}
                    onBack={() => dispatchNavigateTo({pathname: RouteUrl.Administration})}
                />
                <div className={styles.content}>
                    <div className={styles.contentPanel}>
                        <div className={styles.table}>
                            <div className={`${styles.row} ${styles.header}`}>
                                <div className={styles.cell} style={{flexGrow: 2}}>
                                    {t('Table')}
                                </div>
                                <div className={styles.cell} style={{flexGrow: 2}}>
                                    {t('Field')}
                                </div>
                                <div className={styles.cell}>{t('Latest Date')}</div>
                                <div className={styles.cell}>{t('Status')}</div>
                                <div className={styles.cell}>{t('Updated On')}</div>
                            </div>
                            <div className={styles.rows}>
                                {getDataStatusRequestStatus?.isInProgress ? (
                                    <div className={styles.loading}>
                                        <FontAwesomeIcon icon={faSpinner} spin/>
                                    </div>
                                ) : (
                                    dataStatus?.map((dataStatus) => {
                                        const statusClasses = conditionalClassLister(styles)({
                                            cell: true,
                                            bold:true,
                                            inSync: getDifferenceInDays(new Date(), new Date(dataStatus.maxDate)) < 2,
                                            smallDelay: getDifferenceInDays(new Date(), new Date(dataStatus.maxDate)) >= 2 && getDifferenceInDays(new Date(), new Date(dataStatus.maxDate)) <= 3,
                                            bigDelay: getDifferenceInDays(new Date(), new Date(dataStatus.maxDate)) > 3,
                                        });

                                        const statusText = getDifferenceInDays(new Date(), new Date(dataStatus.maxDate)) < 2
                                            ? t('In sync')
                                            : getDifferenceInDays(new Date(), new Date(dataStatus.maxDate)) >= 2 && getDifferenceInDays(new Date(), new Date(dataStatus.maxDate)) <= 3
                                                ? t('Small delay')
                                                : t('Big delay');
                                        return (
                                            <div
                                                className={styles.row}
                                                key={dataStatus.id}>
                                                <div className={styles.cell} style={{flexGrow: 2}}>
                                                    {dataStatus.dataTable}
                                                </div>
                                                <div className={styles.cell} style={{flexGrow: 2}}>
                                                    {dataStatus.dataField}
                                                </div>
                                                <div className={styles.cell}>
                                                    {dataStatus.maxDate
                                                        ? `${formatStringInBrusselsTime(dataStatus.maxDate,'YYYY-MM-DD HH:mm')}`
                                                        : ''}
                                                </div>
                                                <div className={statusClasses}>
                                                    {statusText}
                                                </div>
                                                <div className={styles.cell}>
                                                    {dataStatus.updatedOn
                                                        ? `${formatStringInBrusselsTime(dataStatus.updatedOn,'YYYY-MM-DD HH:mm')}`
                                                        : ''}
                                                </div>
                                            </div>)
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
        );
    }
}

const mapStateToProps = ({dataStatus}: ApplicationState): PropsFromState => ({
    dataStatus: dataStatus.dataStatus,
    getDataStatusRequestStatus: dataStatus.getDataStatusRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchGetDataStatus: () => dispatch(getDataStatusRequest()),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DataStatusPage));

interface PropsFromState {
    dataStatus: DataStatusContract[];
    getDataStatusRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchGetDataStatus: typeof getDataStatusRequest;
}

interface OwnProps {
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
}

type AllState = OwnState;
