import { action } from 'typesafe-actions';
import {
    CreateServiceProviderGroupRequest,
    ServiceProviderGroup,
    ServiceProviderGroupActionTypes, ServiceProviderGroupsSearchRequest,
    ServiceProviderGroupWithConfigurationAndUsers,
    UpdateServiceProviderGroupRequest,
} from './types';
import { Paged } from '../shared/types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const createServiceProviderGroupRequest = (createRequest: CreateServiceProviderGroupRequest) =>
    action(ServiceProviderGroupActionTypes.CREATE_SERVICE_PROVIDER_GROUP_REQUEST, createRequest);
export const addServiceProviderGroupSuccess = (serviceProviderGroup: ServiceProviderGroup) =>
    action(ServiceProviderGroupActionTypes.CREATE_SERVICE_PROVIDER_GROUP_SUCCESS, serviceProviderGroup);

export const deleteServiceProviderGroupRequest = (serviceProviderGroup: ServiceProviderGroup) =>
    action(ServiceProviderGroupActionTypes.DELETE_SERVICE_PROVIDER_GROUP_REQUEST, serviceProviderGroup);
export const deleteServiceProviderGroupSuccess = (serviceProviderGroup: ServiceProviderGroup) =>
    action(ServiceProviderGroupActionTypes.DELETE_SERVICE_PROVIDER_GROUP_SUCCESS, serviceProviderGroup);
export const deleteServiceProviderGroupFailure = (message: string) =>
    action(ServiceProviderGroupActionTypes.DELETE_SERVICE_PROVIDER_GROUP_FAILURE, message);

export const getServiceProviderGroupsRequest = () => action(ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUPS_REQUEST);
export const getServiceProviderGroupsSuccess = (serviceProviderGroups: ServiceProviderGroup[]) =>
    action(ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUPS_SUCCESS, serviceProviderGroups);
export const getServiceProviderGroupsFailure = (message: string) =>
    action(ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUPS_FAILURE, message);

export const searchServiceProviderGroupsRequest = (request: ServiceProviderGroupsSearchRequest) =>
    action(ServiceProviderGroupActionTypes.SEARCH_SERVICE_PROVIDER_GROUPS_REQUEST, request);
export const searchServiceProviderGroupsSuccess = (serviceProviderGroups: Paged<ServiceProviderGroup>) =>
    action(ServiceProviderGroupActionTypes.SEARCH_SERVICE_PROVIDER_GROUPS_SUCCESS, serviceProviderGroups);
export const searchServiceProviderGroupsFailure = (message: string) =>
    action(ServiceProviderGroupActionTypes.SEARCH_SERVICE_PROVIDER_GROUPS_FAILURE, message);

export const clearServiceProviderGroupWithConfigurationAndUsers = () =>
    action(ServiceProviderGroupActionTypes.CLEAR_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS);
export const getServiceProviderGroupWithConfigurationAndUsersRequest = (serviceProviderGroupId: string) =>
    action(ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS_REQUEST, serviceProviderGroupId);
export const getServiceProviderGroupWithConfigurationAndUsersSuccess = (serviceProviderGroupWithConfigurationAndUsers: ServiceProviderGroupWithConfigurationAndUsers) =>
    action(ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS_SUCCESS, serviceProviderGroupWithConfigurationAndUsers);
export const getServiceProviderGroupWithConfigurationAndUsersFailure = (message: string) =>
    action(ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS_FAILURE, message);

export const updateServiceProviderGroupRequest = (updateRequest: UpdateServiceProviderGroupRequest) =>
    action(ServiceProviderGroupActionTypes.UPDATE_SERVICE_PROVIDER_GROUP_REQUEST, updateRequest);
export const updateServiceProviderGroupSuccess = (updateRequest: UpdateServiceProviderGroupRequest) =>
    action(ServiceProviderGroupActionTypes.UPDATE_SERVICE_PROVIDER_GROUP_SUCCESS, updateRequest);
