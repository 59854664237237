import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCogs} from '@fortawesome/pro-regular-svg-icons/faCogs';
import {conditionalClassLister} from '../../../utils/class-helpers';
import styles from './dashboard-global-menu-button.module.scss';

class DashboardGlobalMenuButton extends Component<AllProps, AllState> {

    private disableCloseOnBlur = false;

    constructor(props) {
        super(props);

        this.state = {
            isSubmenuOpen: false,
        };
    }

    public render(): JSX.Element {
        const {t} = this.props;
        const {isSubmenuOpen} = this.state;

        const subMenuClasses = conditionalClassLister(styles)({
            subMenu: true,
            isOpen: isSubmenuOpen,
        });

        return <div className={styles.menuButtonContainer}>
            <div className={styles.menuButton}>
                <div className={styles.iconPanel}>
                    <div className={styles.iconButton} onClick={() => this.onOpenSubmenu(!isSubmenuOpen)}
                         onBlur={() => this.onOpenSubmenu(false)}>
                        <FontAwesomeIcon icon={faCogs}/>
                    </div>
                </div>
                <div className={subMenuClasses}>
                    <div className={styles.submenuPanel}>
                        <div className={styles.submenuItem}
                             onMouseDown={() => this.onDisableCloseOnBlur(true)}
                             onMouseUp={() => this.onDisableCloseOnBlur(false)}
                             onClick={() => this.onCreateDashboard()}>
                            {t('Create Dashboard')}
                        </div>

                        <div className={styles.submenuItem}
                             onMouseDown={() => this.onDisableCloseOnBlur(true)}
                             onMouseUp={() => this.onDisableCloseOnBlur(false)}
                             onClick={() => this.onRestoreDefault()}>
                            {t('Restore Default')}
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    private onDisableCloseOnBlur(disable: boolean) {
        this.disableCloseOnBlur = disable;
    }

    private onOpenSubmenu(open: boolean) {
        if (open || (!open && !this.disableCloseOnBlur)) {
            this.setState({isSubmenuOpen: open});
        }
    }

    private onCreateDashboard() {
        const {onCreateDashboard} = this.props;
        onCreateDashboard();
        this.setState({isSubmenuOpen: false});
    }

    private onRestoreDefault() {
        const {onRestoreDefault} = this.props;
        onRestoreDefault();
        this.setState({isSubmenuOpen: false});
    }
}

export default withTranslation()(DashboardGlobalMenuButton);

interface OwnProps {
    onCreateDashboard: () => void;
    onRestoreDefault: () => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    isSubmenuOpen: boolean;
}

type AllState = OwnState;
