import {ActionType} from 'typesafe-actions';
import * as actions from './actions';
import * as sharedActions from '../shared/actions';

export enum LayoutActionTypes {
    TOGGLE_SIDEBAR = '@@layout/TOGGLE_SIDEBAR',
}

export type LayoutAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface LayoutState {
    readonly showSidebar: boolean;
}
