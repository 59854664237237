import {withTranslation, WithTranslation} from 'react-i18next';
import styles from './date-range-selector.module.scss';
import DateRangeYearPanel from './date-range-year-panel';
import {createMonthRange, toMonthRangeDisplay} from './helpers/date-range-helper';
import {ControlValue} from '../controls-snapshot';
import {ControlBase, ControlProps} from '../control-base';
import {DateRangeControlValue} from './types/date-range-control-value';
import {DateRange} from '../../../../store/analytics';
import {
    addYears,
    endOfMonth,
    getEarliestDate,
    getLatestDate,
    isBefore,
    startOfMonth,
    subYears
} from '../../../../utils/date-helper';

class DateRangeSelector extends ControlBase<AllProps, AllState, DateRangeControlValue> {

    constructor(props) {
        super(props);

        let startMonth: Date;
        let endMonth: Date;

        if (this.props.defaultValue) {
            const dateRange = this.props.defaultValue.value as DateRange;
            startMonth = dateRange.start;
            endMonth = dateRange.end;
        } else {
            startMonth = startOfMonth(new Date());
            startMonth.setFullYear(new Date().getFullYear() - 1);
            endMonth = endOfMonth(new Date());
        }

        const latestYear = endMonth.getFullYear();

        this.state = {
            dateRange: {
                start: startMonth,
                end: endMonth,
            },
            editMode: false,
            hoverRange: undefined,
            visibleYears: [latestYear-1, latestYear],
        };

        this.props.onValueChange(this.getValue());
    }

    public render(): JSX.Element {
        const {dateRange, editMode, hoverRange, visibleYears} = this.state;

        return (
            <div className={styles.selectorContainer}>
                <DateRangeYearPanel editMode={editMode}
                                    year={visibleYears[0]}
                                    canCycleYearBack
                                    canCycleYearForward={false}
                                    hoverRange={hoverRange}
                                    onHover={(date: Date) => this.onMonthHover(date)}
                                    onSelect={(date: Date) => this.onSelect(date)}
                                    onCycleYear={(forward: boolean) => this.onCycleYear(forward)}
                                    selectedRange={dateRange}/>
                <DateRangeYearPanel editMode={editMode}
                                    year={visibleYears[1]}
                                    canCycleYearBack={false}
                                    canCycleYearForward
                                    hoverRange={hoverRange}
                                    onCycleYear={(forward: boolean) => this.onCycleYear(forward)}
                                    onHover={(date: Date) => this.onMonthHover(date)}
                                    onSelect={(date: Date) => this.onSelect(date)}
                                    selectedRange={dateRange}/>
            </div>
        );
    }

    public beforeRedraw(): void {
        this.setState({editMode: false});
    }

    private onSelect(date: Date) {
        let {editMode} = this.state;
        let dateRange = Object.assign(this.state.dateRange);

        if (!editMode) {
            editMode = true;
            dateRange = {start: date, end: endOfMonth(date)};
        } else {
            editMode = false;
            const startDate = this.state.dateRange.start;
            const allowedDate = this.determineAllowedDate(startDate, date);
            if (startOfMonth(allowedDate).getTime() !== startOfMonth(date).getTime()) {
                editMode = true;
                dateRange = {start: date, end: endOfMonth(date)};
            } else {
                dateRange = createMonthRange(startDate, date);
            }
        }

        this.setState({editMode, dateRange, hoverRange: undefined}, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    public forceRefresh(value: DateRangeControlValue): void {
        this.setState({dateRange: value.value}, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    private onMonthHover(date: Date) {
        const dateStart = this.state.dateRange.start;
        const allowedDate = this.determineAllowedDate(dateStart, date);
        const hoverRange = createMonthRange(dateStart, allowedDate);
        this.setState({hoverRange});
    }

    private determineAllowedDate(startDate: Date, date: Date): Date {
        if (isBefore(startDate, date)) {
            return getEarliestDate(date, addYears(startDate, 2));
        } else {
            return getLatestDate(date, subYears(startDate, 2));
        }
    }

    private onCycleYear(forward: boolean) {
        if (forward) {
            this.setState({visibleYears: [this.state.visibleYears[0] + 1, this.state.visibleYears[1] + 1]});
        } else {
            this.setState({visibleYears: [this.state.visibleYears[0] - 1, this.state.visibleYears[1] - 1]});
        }
    }

    private getValue(): ControlValue {
        return new DateRangeControlValue(toMonthRangeDisplay(this.state.dateRange, this.props.t), this.state.dateRange);
    }
}

export default withTranslation()(DateRangeSelector);

interface OwnProps extends ControlProps<DateRangeControlValue> {
}

interface PropsFromState {
}

interface PropsFromDispatch {
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    dateRange: DateRange;
    hoverRange?: DateRange;
    editMode: boolean;
    visibleYears: number[];
}

type AllState = OwnState;
