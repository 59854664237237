import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {Dispatch} from 'redux';
import Dropdown, {DropdownOption} from '../../../components/dropdown/dropdown';
import {RouteUrl} from '../../../routes';
import {ApplicationState} from '../../../store';
import {Permission, User} from '../../../store/authentication';
import {
    createServiceProviderGroupRequest,
    CreateServiceProviderGroupRequest,
    getServiceProviderGroupsRequest,
    getServiceProviderGroupWithConfigurationAndUsersRequest,
    UpdatableConfiguration,
    UpdateServiceProviderGroupRequest,
    updateServiceProviderGroupRequest,
    ServiceProviderGroupWithConfigurationAndUsers,
    clearServiceProviderGroupWithConfigurationAndUsers,
} from '../../../store/service-provider-groups';
import * as layoutActions from '../../../store/layout/actions';
import {Popup, PopupType, showPopup} from '../../../store/popup';
import {
    getServiceProviderMappingsRequest,
    ServiceProvider,
    ServiceProviderMapping,
} from '../../../store/service-providers';
import {addToast} from '../../../store/toast';
import {AvailableCurrencies} from '../../../types/currencies';
import {getUserEmailDomain, hasPermission} from '../../../utils/user-helper';
import AdminPageHeader from '../components/admin-page-header/admin-page-header';
import AllowedDomain from './components/allowed-domain';
import styles from './service-provider-group-details.module.scss';
import ServiceProviderSelectionControl from './components/service-provider-selection-control';
import ServiceProviderSelectionPopup from './popups/service-provider-selection-popup/service-provider-selection-popup';
import {Paged} from '../../../store/shared/types';
import {getSupportedLanguageOptions} from '../../../utils/translations/translations-helper';
import ServiceProvidersApi from '../../../api/service-providers-api';
import {conditionalClassLister} from '../../../utils/class-helpers';

enum ServiceProviderGroupDetailsPageMode {
    create,
    edit,
}

enum ServiceProviderGroupDetailsTab {
    General,
    Users,
}

class ServiceProviderGroupDetailsPage extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {
            t,
            match,
            dispatchToggleSidebar,
            dispatchGetServiceProviderGroupWithConfigurationAndUsers,
            dispatchGetServiceProviderMappings,
            loggedInUser,
        } = this.props;
        const {serviceProviderGroupId} = match.params;

        dispatchToggleSidebar(false);

        const currencyOptions: DropdownOption[] = [{id: undefined, key: 'none', label: t('None')}];
        currencyOptions.push(
            ...AvailableCurrencies.map((c) => ({id: c, label: c})),
        );

        const languageOptions: DropdownOption[] = [{id: undefined, key: 'none', label: t('None')}];
        languageOptions.push(...getSupportedLanguageOptions(t,true));

        if (serviceProviderGroupId === 'new') {
            this.state = {
                currencyOptions,
                languageOptions,
                serviceProviderGroupId,
                pageMode: ServiceProviderGroupDetailsPageMode.create,
                updatedServiceProviderGroupConfiguration: this.getDefaultConfiguration(),
                serviceProviderGroupLinkedUserDomains: [],
                updatedAllowedDomains: loggedInUser ? [getUserEmailDomain(loggedInUser)] : [],
                serviceProviderMappedIds: [],
                activeTab: ServiceProviderGroupDetailsTab.General,
            };
        } else {
            dispatchGetServiceProviderGroupWithConfigurationAndUsers(serviceProviderGroupId);
            this.state = {
                currencyOptions,
                languageOptions,
                serviceProviderGroupId,
                pageMode: ServiceProviderGroupDetailsPageMode.edit,
                serviceProviderGroupLinkedUserDomains: [],
                updatedAllowedDomains: [],
                serviceProviderMappedIds: [],
                activeTab: ServiceProviderGroupDetailsTab.General,
            };
        }
        dispatchGetServiceProviderMappings(serviceProviderGroupId);
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const {serviceProviderGroupWithConfigurationAndUsers, loggedInUser, initialServiceProviderMappings} = this.props;
        const {pageMode, updatedAllowedDomains} = this.state;

        if (pageMode === ServiceProviderGroupDetailsPageMode.edit) {
            if (serviceProviderGroupWithConfigurationAndUsers && initialServiceProviderMappings) {
                if (
                    serviceProviderGroupWithConfigurationAndUsers !== prevProps.serviceProviderGroupWithConfigurationAndUsers
                    || initialServiceProviderMappings !== prevProps.initialServiceProviderMappings
                ) {
                    const emailDomainsForUsersLinkedToServiceProviderGroup = serviceProviderGroupWithConfigurationAndUsers.users
                        .map((u) => getUserEmailDomain(u));
                    const serviceProviderGroupLinkedUserDomains = serviceProviderGroupWithConfigurationAndUsers.allowedDomains.filter((ad) =>
                        emailDomainsForUsersLinkedToServiceProviderGroup.includes(ad),
                    );

                    this.setState({
                        serviceProviderGroupLinkedUserDomains,
                        updatedServiceProviderGroupConfiguration: {...serviceProviderGroupWithConfigurationAndUsers.configuration},
                        serviceProviderGroupName: serviceProviderGroupWithConfigurationAndUsers!.name,
                        updatedAllowedDomains: serviceProviderGroupWithConfigurationAndUsers.allowedDomains,
                        serviceProviderMappedIds: initialServiceProviderMappings.serviceProviderIds,
                    });
                }
            }
        } else if (loggedInUser && updatedAllowedDomains.length === 0) {
            this.setState({updatedAllowedDomains: [getUserEmailDomain(loggedInUser)]});
        } else if (initialServiceProviderMappings && prevProps.initialServiceProviderMappings !== initialServiceProviderMappings) {
            this.setState({
                serviceProviderMappedIds: initialServiceProviderMappings.serviceProviderIds,
            });
        }
    }

    public render(): JSX.Element {
        const {t, serviceProviderGroupWithConfigurationAndUsers, serviceProviders,loggedInUser} = this.props;
        const {
            serviceProviderGroupId,
            serviceProviderGroupName,
            pageMode,
            updatedServiceProviderGroupConfiguration,
            serviceProviderGroupLinkedUserDomains,
            updatedAllowedDomains,
            currencyOptions,
            languageOptions,
            serviceProviderMappedIds,
            activeTab
        } = this.state;
        const hasEditPermission = hasPermission(loggedInUser, Permission.ManageServiceProviderGroups)
        const initialized =
            updatedServiceProviderGroupConfiguration &&
            (pageMode === ServiceProviderGroupDetailsPageMode.create || (serviceProviderGroupId && serviceProviderGroupWithConfigurationAndUsers));

        const titleKey =
            pageMode === ServiceProviderGroupDetailsPageMode.create ? t('Create Service Provider Group') : t('Service Provider Group Details');

        const usersForServiceProviderGroup = (serviceProviderGroupWithConfigurationAndUsers && serviceProviderGroupWithConfigurationAndUsers)?.users ? serviceProviderGroupWithConfigurationAndUsers.users : [];

        const generalTabStyles = conditionalClassLister(styles)({
            tab: true,
            active: activeTab === ServiceProviderGroupDetailsTab.General,
        });

        const usersTabStyles = conditionalClassLister(styles)({
            tab: true,
            active: activeTab === ServiceProviderGroupDetailsTab.Users,
        });

        const serviceProvidersFieldStyles = conditionalClassLister(styles)({
            vField: true,
            unlimited: true
        });

        const contentPanelStyles = conditionalClassLister(styles)({
            contentPanel: true,
            isDisabled: !hasEditPermission
        });

        return initialized ? (
            <div className={styles.container}>
                <AdminPageHeader
                    title={titleKey}
                    onBack={() => this.onNavigateBack()}
                />
                <div className={styles.content}>
                    <div className={styles.tabs}>
                        <div className={generalTabStyles}
                             onClick={() => this.switchTab(ServiceProviderGroupDetailsTab.General)}>{t('General')}
                        </div>
                        <div className={usersTabStyles}
                             onClick={() => this.switchTab(ServiceProviderGroupDetailsTab.Users)}>{t('Users')}
                        </div>
                    </div>
                    {activeTab === ServiceProviderGroupDetailsTab.General ?
                        <div className={contentPanelStyles}>
                            <div className={styles.fields}>
                                <div className={styles.vField}>
                                    <div className={styles.label}>{t('Name')}</div>
                                    <div className={styles.value}>
                                        <input
                                            type="text"
                                            name="fcName"
                                            defaultValue={serviceProviderGroupName}
                                            autoFocus
                                            className={styles.fieldInput}
                                            onChange={(e): void => this.onNameChanged(e)}
                                        />
                                    </div>
                                </div>
                                <div className={styles.vFieldGroup}>
                                    <div className={styles.vField}>
                                        <div className={styles.label}>{t('Base Currency 1')}</div>
                                        <div className={styles.value}>
                                            <div className={styles.select}>
                                                <Dropdown
                                                    options={currencyOptions}
                                                    selection={currencyOptions.find(
                                                        (a) => a.id === updatedServiceProviderGroupConfiguration!.baseCurrency1,
                                                    )}
                                                    onSelectionChanged={(c) => this.onBaseCurrency1Changed(c)}
                                                />
                                            </div>
                                            <div className={styles.availableIndicator}>
                                                {updatedServiceProviderGroupConfiguration!.baseCurrency1 !== undefined
                                                    ? updatedServiceProviderGroupConfiguration!.baseCurrency1 ===
                                                    serviceProviderGroupWithConfigurationAndUsers?.configuration?.baseCurrency1 &&
                                                    serviceProviderGroupWithConfigurationAndUsers?.configuration?.baseCurrency1Processed
                                                        ? t('Data available')
                                                        : t('Data not available')
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.vField}>
                                        <div className={styles.label}>{t('Base Currency 2')}</div>
                                        <div className={styles.value}>
                                            <div className={styles.select}>
                                                <Dropdown
                                                    options={currencyOptions}
                                                    selection={currencyOptions.find(
                                                        (a) => a.id === updatedServiceProviderGroupConfiguration!.baseCurrency2,
                                                    )}
                                                    onSelectionChanged={(c): void => this.onBaseCurrency2Changed(c)}
                                                />
                                            </div>
                                            <div className={styles.availableIndicator}>
                                                {updatedServiceProviderGroupConfiguration!.baseCurrency2 !== undefined
                                                    ? updatedServiceProviderGroupConfiguration!.baseCurrency2 ===
                                                    serviceProviderGroupWithConfigurationAndUsers?.configuration?.baseCurrency2 &&
                                                    serviceProviderGroupWithConfigurationAndUsers?.configuration?.baseCurrency2Processed
                                                        ? t('Data available')
                                                        : t('Data not available')
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.vField}>
                                        <div className={styles.label}>{t('Base Currency 3')}</div>
                                        <div className={styles.value}>
                                            <div className={styles.select}>
                                                <Dropdown
                                                    options={currencyOptions}
                                                    selection={currencyOptions.find(
                                                        (a) => a.id === updatedServiceProviderGroupConfiguration!.baseCurrency3,
                                                    )}
                                                    onSelectionChanged={(c): void => this.onBaseCurrency3Changed(c)}
                                                />
                                            </div>
                                            <div className={styles.availableIndicator}>
                                                {updatedServiceProviderGroupConfiguration!.baseCurrency3 !== undefined
                                                    ? updatedServiceProviderGroupConfiguration!.baseCurrency3 ===
                                                    serviceProviderGroupWithConfigurationAndUsers?.configuration?.baseCurrency3 &&
                                                    serviceProviderGroupWithConfigurationAndUsers?.configuration?.baseCurrency3Processed
                                                        ? t('Data available')
                                                        : t('Data not available')
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.vFieldGroup}>
                                    <div className={styles.vField}>
                                        <div className={styles.label}>{t('Local Language 1')}</div>
                                        <div className={styles.value}>
                                            <div className={styles.select}>
                                                <Dropdown
                                                    options={languageOptions}
                                                    selection={languageOptions.find(
                                                        (a) => a.id === updatedServiceProviderGroupConfiguration!.localLanguage1,
                                                    )}
                                                    onSelectionChanged={(c) => this.onLocalLanguage1Changed(c)}
                                                />
                                            </div>
                                            <div className={styles.availableIndicator}>
                                                {updatedServiceProviderGroupConfiguration!.localLanguage1 !== undefined
                                                    ? updatedServiceProviderGroupConfiguration!.localLanguage1 ===
                                                    serviceProviderGroupWithConfigurationAndUsers?.configuration?.localLanguage1 &&
                                                    serviceProviderGroupWithConfigurationAndUsers?.configuration?.localLanguage1Processed
                                                        ? t('Translations available')
                                                        : t('Translations not available')
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.vField}>
                                        <div className={styles.label}>{t('Local Language 2')}</div>
                                        <div className={styles.value}>
                                            <div className={styles.select}>
                                                <Dropdown
                                                    options={languageOptions}
                                                    selection={languageOptions.find(
                                                        (a) => a.id === updatedServiceProviderGroupConfiguration!.localLanguage2,
                                                    )}
                                                    onSelectionChanged={(c): void => this.onLocalLanguage2Changed(c)}
                                                />
                                            </div>
                                            <div className={styles.availableIndicator}>
                                                {updatedServiceProviderGroupConfiguration!.localLanguage2 !== undefined
                                                    ? updatedServiceProviderGroupConfiguration!.localLanguage2 ===
                                                    serviceProviderGroupWithConfigurationAndUsers?.configuration?.localLanguage2 &&
                                                    serviceProviderGroupWithConfigurationAndUsers?.configuration?.localLanguage2Processed
                                                        ? t('Translations available')
                                                        : t('Translations not available')
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.vField}>
                                        <div className={styles.label}>{t('Local Language 3')}</div>
                                        <div className={styles.value}>
                                            <div className={styles.select}>
                                                <Dropdown
                                                    options={languageOptions}
                                                    selection={languageOptions.find(
                                                        (a) => a.id === updatedServiceProviderGroupConfiguration!.localLanguage3,
                                                    )}
                                                    onSelectionChanged={(c): void => this.onLocalLanguage3Changed(c)}
                                                />
                                            </div>
                                            <div className={styles.availableIndicator}>
                                                {updatedServiceProviderGroupConfiguration!.localLanguage3 !== undefined
                                                    ? updatedServiceProviderGroupConfiguration!.localLanguage3 ===
                                                    serviceProviderGroupWithConfigurationAndUsers?.configuration?.localLanguage3 &&
                                                    serviceProviderGroupWithConfigurationAndUsers?.configuration?.localLanguage3Processed
                                                        ? t('Translations available')
                                                        : t('Translations not available')
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.vField}>
                                <div className={styles.label}>{t('Allowed Email Domains')}</div>
                                <div className={styles.domains}>
                                    {updatedAllowedDomains.map((allowedDomain, index) => (
                                        <AllowedDomain
                                            key={index}
                                            value={allowedDomain}
                                            onChange={(val): void => this.onAllowedDomainChange(index, val)}
                                            onRemove={(): void => this.onAllowedDomainRemove(index)}
                                            onBlur={(): void => this.onAllowedDomainBlur()}
                                            canRemove={index > 0 && !serviceProviderGroupLinkedUserDomains.includes(allowedDomain)}
                                            isReadonly={serviceProviderGroupLinkedUserDomains.includes(allowedDomain)}
                                        />
                                    ))}
                                    {hasEditPermission
                                        ? <div className={styles.addDomain}
                                               onClick={(): void => this.onAllowedDomainAdd()}>{`${t(
                                            'Add',
                                        )} ...`}</div>
                                        : null}
                                </div>
                            </div>
                            <div className={serviceProvidersFieldStyles}>
                                <div className={styles.label}>{t('Service Provider(s)')}</div>
                                <div className={styles.control}>
                                    <ServiceProviderSelectionControl
                                        serviceProviderMappedIds={serviceProviderMappedIds}
                                        serviceProviders={serviceProviders?.pageData}
                                        canChange={hasEditPermission}
                                        onChange={() => this.showServiceProviderMappingSelectionPopup(hasEditPermission)}
                                        canDownloadStructure={serviceProviderGroupId !== undefined && serviceProviderGroupId !== 'new'}
                                        onDownloadStructure={() => this.downloadServiceProvidersStructure()}
                                    />
                                </div>
                            </div>
                            <div className={styles.buttons}>
                                <button
                                    type="button"
                                    className={`${styles.button} ${styles.isNegative}`}
                                    onClick={(): void => {
                                        this.onNavigateBack();
                                    }}>
                                    {t('Back')}
                                </button>
                                {hasEditPermission
                                    ? <button
                                        type="button"
                                        className={`${styles.button} ${!this.canSave() ? styles.isDisabled : ''}`}
                                        onClick={(): void => {
                                            this.updateServiceProviderGroup();
                                        }}>
                                        {t('Save')}
                                    </button>
                                    : null}
                            </div>
                        </div>
                        : null
                    }
                    {activeTab === ServiceProviderGroupDetailsTab.Users ? <div className={contentPanelStyles}>
                        <div className={styles.table}>
                            <div className={`${styles.row} ${styles.header}`}>
                                <div className={styles.cell} style={{flexGrow: 2}}>
                                    {t('UMF Username')}
                                </div>
                                <div className={styles.cell} style={{flexGrow: 2}}>
                                    {t('Email')}
                                </div>
                                <div className={styles.cell}>{t('Last Login')}</div>
                            </div>
                            <div className={styles.rows}>
                                {
                                    usersForServiceProviderGroup.map((u) =>
                                        <div
                                            className={`${styles.row} ${!hasEditPermission?styles.isDisabled:''}`}
                                            key={u.email}>
                                            <div className={styles.cell} style={{flexGrow: 2}}>
                                                {u.cognitoUsername}
                                            </div>
                                            <div className={styles.cell} style={{flexGrow: 2}}>
                                                {u.email}
                                            </div>
                                            <div className={styles.cell}>
                                                {u.lastLogin
                                                    ? `${new Date(u.lastLogin).toLocaleDateString()} ${new Date(
                                                        u.lastLogin,
                                                    ).toLocaleTimeString()}`
                                                    : ''}
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div> : null}
                </div>
            </div>
        ) : (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
        );
    }

    public showServiceProviderMappingSelectionPopup(hasEditPermissions:boolean): void {
        const {dispatchShowPopup} = this.props;
        const {serviceProviderMappedIds} = this.state;

        dispatchShowPopup({
            type: PopupType.ServiceProviderMappingSelection,
            content: (
                <ServiceProviderSelectionPopup
                    canChange={hasEditPermissions}
                    originalServiceProviderMappedIds={serviceProviderMappedIds}
                    onServiceProviderMappedIdsChanged={(ids) => this.onServiceProviderMappingsChanged(ids)}
                />
            ),
        });
    }

    private switchTab(tab: ServiceProviderGroupDetailsTab): void {
        this.setState({activeTab: tab});
    }

    private downloadServiceProvidersStructure(): void {
        // eslint-disable-next-line react/destructuring-assignment
        ServiceProvidersApi.downloadServiceProviderGroupStructure(this.props.serviceProviderGroupWithConfigurationAndUsers!.id, this.props.serviceProviderGroupWithConfigurationAndUsers!.name).then(() => {
        });
    }

    private onServiceProviderMappingsChanged(serviceProviderMappedIds: string[]): void {
        this.setState({serviceProviderMappedIds: [...serviceProviderMappedIds]});
    }

    private getDefaultConfiguration(): UpdatableConfiguration {
        return {
            baseCurrency1: 'EUR',
            baseCurrency2: undefined,
            baseCurrency3: undefined
        };
    }

    private onNameChanged(e): void {
        this.setState({serviceProviderGroupName: e.target.value});
    }

    private onBaseCurrency1Changed(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.baseCurrency1 = option.id;
        });
    }

    private onBaseCurrency2Changed(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.baseCurrency2 = option.id;
        });
    }

    private onBaseCurrency3Changed(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.baseCurrency3 = option.id;
        });
    }

    private onLocalLanguage1Changed(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.localLanguage1 = option.id;
        });
    }

    private onLocalLanguage2Changed(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.localLanguage2 = option.id;
        });
    }

    private onLocalLanguage3Changed(option: DropdownOption) {
        this.updateConfiguration((config: UpdatableConfiguration) => {
            config.localLanguage3 = option.id;
        });
    }

    private onAllowedDomainChange(index: number, allowedDomain: string) {
        const {updatedAllowedDomains} = this.state;
        const newDomains = updatedAllowedDomains.slice();
        newDomains[index] = allowedDomain;
        this.setState({updatedAllowedDomains: newDomains});
    }

    private onAllowedDomainAdd() {
        const {updatedAllowedDomains} = this.state;
        const newDomains = updatedAllowedDomains.slice();
        newDomains.push('');
        this.setState({updatedAllowedDomains: newDomains});
    }

    private onAllowedDomainRemove(index: number) {
        const {updatedAllowedDomains} = this.state;
        const newDomains = updatedAllowedDomains.slice();
        newDomains.splice(index, 1);
        this.setState({updatedAllowedDomains: newDomains});
    }

    private onAllowedDomainBlur() {
        const {updatedAllowedDomains} = this.state;
        const newDomains = updatedAllowedDomains.slice();
        this.setState({updatedAllowedDomains: newDomains});
    }

    private updateConfiguration(updateFunc: (config: UpdatableConfiguration) => void) {
        const {updatedServiceProviderGroupConfiguration} = this.state;
        if (updatedServiceProviderGroupConfiguration) {
            const newConfig = {...updatedServiceProviderGroupConfiguration};
            updateFunc(newConfig);
            this.setState({updatedServiceProviderGroupConfiguration: newConfig});
        }
    }

    private canSave(): boolean {
        const {serviceProviderGroupName, updatedServiceProviderGroupConfiguration, updatedAllowedDomains} = this.state;
        return (
            serviceProviderGroupName !== '' &&
            updatedServiceProviderGroupConfiguration !== undefined &&
            updatedServiceProviderGroupConfiguration.baseCurrency1 !== undefined &&
            updatedServiceProviderGroupConfiguration.baseCurrency1 !== '' &&
            updatedAllowedDomains.filter((d) => d !== undefined && d !== '').length !== 0
        );
    }

    public onNavigateBack(): void {
        const {dispatchNavigateTo, dispatchClearServiceProviderGroupWithConfigurationAndUsers} = this.props;
        dispatchClearServiceProviderGroupWithConfigurationAndUsers();
        dispatchNavigateTo({pathname: RouteUrl.ServiceProviderGroupsManagement});
    }

    private updateServiceProviderGroup(): void {
        const {dispatchUpdateServiceProviderGroup, dispatchCreateServiceProviderGroup} = this.props;
        if (this.canSave()) {
            const {
                serviceProviderGroupId,
                serviceProviderGroupName,
                updatedServiceProviderGroupConfiguration,
                updatedAllowedDomains,
                pageMode,
                serviceProviderMappedIds,
            } = this.state;
            const serviceProviderMappings: ServiceProviderMapping = {serviceProviderIds: serviceProviderMappedIds}
            if (pageMode === ServiceProviderGroupDetailsPageMode.create) {
                dispatchCreateServiceProviderGroup({
                    serviceProviderGroupName: serviceProviderGroupName!,
                    serviceProviderGroupAllowedDomains: updatedAllowedDomains.filter((d) => d !== undefined && d !== ''),
                    configuration: updatedServiceProviderGroupConfiguration!,
                    serviceProviderMappings,
                });
            } else {
                dispatchUpdateServiceProviderGroup({
                    serviceProviderGroupId: serviceProviderGroupId!,
                    serviceProviderGroupName: serviceProviderGroupName!,
                    serviceProviderGroupAllowedDomains: updatedAllowedDomains.filter((d) => d !== undefined && d !== ''),
                    configuration: updatedServiceProviderGroupConfiguration!,
                    serviceProviderMappings,
                });
            }
            this.onNavigateBack();
        }
    }
}

const mapStateToProps = ({authentication, serviceProviderGroups, users, serviceProvider}: ApplicationState): PropsFromState => ({
    loggedInUser: authentication.user,
    serviceProviderGroupWithConfigurationAndUsers: serviceProviderGroups.serviceProviderGroupsWithConfigurationAndUsers,
    initialServiceProviderMappings: serviceProvider.serviceProviderMappings,
    serviceProviders: serviceProvider.serviceProviders
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchClearServiceProviderGroupWithConfigurationAndUsers: () =>
        dispatch(clearServiceProviderGroupWithConfigurationAndUsers()),
    dispatchGetServiceProviderGroupWithConfigurationAndUsers: (serviceProviderGroupId: string) =>
        dispatch(getServiceProviderGroupWithConfigurationAndUsersRequest(serviceProviderGroupId)),
    dispatchUpdateServiceProviderGroup: (request: UpdateServiceProviderGroupRequest) => dispatch(updateServiceProviderGroupRequest(request)),
    dispatchCreateServiceProviderGroup: (serviceProviderGroupRequest: CreateServiceProviderGroupRequest) =>
        dispatch(createServiceProviderGroupRequest(serviceProviderGroupRequest)),
    dispatchGetServiceProviderMappings: (serviceProviderGroupId: string) => dispatch(getServiceProviderMappingsRequest(serviceProviderGroupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ServiceProviderGroupDetailsPage));

interface PropsFromState {
    loggedInUser?: User;
    serviceProviderGroupWithConfigurationAndUsers?: ServiceProviderGroupWithConfigurationAndUsers;
    initialServiceProviderMappings?: ServiceProviderMapping;
    serviceProviders?: Paged<ServiceProvider>;
}

interface PropsFromDispatch {
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchShowPopup: typeof showPopup;
    dispatchAddToast: typeof addToast;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchClearServiceProviderGroupWithConfigurationAndUsers: typeof clearServiceProviderGroupWithConfigurationAndUsers;
    dispatchGetServiceProviderGroupWithConfigurationAndUsers: typeof getServiceProviderGroupWithConfigurationAndUsersRequest;
    dispatchGetServiceProviderGroups: typeof getServiceProviderGroupsRequest;
    dispatchUpdateServiceProviderGroup: typeof updateServiceProviderGroupRequest;
    dispatchCreateServiceProviderGroup: typeof createServiceProviderGroupRequest;
    dispatchGetServiceProviderMappings: typeof getServiceProviderMappingsRequest;
}

interface OwnProps {
}

export interface WorkspaceMatchParameters {
    serviceProviderGroupId: string;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    RouteComponentProps<WorkspaceMatchParameters> &
    WithTranslation &
    OwnProps;

interface OwnState {
    serviceProviderGroupId?: string;
    serviceProviderGroupName?: string;
    currencyOptions: DropdownOption[];
    languageOptions: DropdownOption[];
    updatedServiceProviderGroupConfiguration?: UpdatableConfiguration;
    serviceProviderGroupLinkedUserDomains: string[];
    updatedAllowedDomains: string[];
    pageMode: ServiceProviderGroupDetailsPageMode;
    activeTab:ServiceProviderGroupDetailsTab;
    serviceProviderMappedIds: string[];
}

type AllState = OwnState;
