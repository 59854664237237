import {action} from 'typesafe-actions';
import {FilterControlSelectionActionTypes} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const filterServiceProviders = (filter: string) =>
    action(FilterControlSelectionActionTypes.FILTER_SERVICE_PROVIDERS, filter);
export const filterSoldTos = (filter: string) =>
    action(FilterControlSelectionActionTypes.FILTER_SOLD_TOS, filter);
export const resetFilter = () =>
    action(FilterControlSelectionActionTypes.FILTER_RESET);
