import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Toast} from '../../store/toast';
import * as toastActions from '../../store/toast/actions';

class ErrorBoundary extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        this.state = { };
    }

    public render() {
        const {children} = this.props;
        return children;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addToast: (toast: Toast) => dispatch(toastActions.addToast(toast)),
});

export default withTranslation()(connect(null, mapDispatchToProps)(ErrorBoundary));

interface PropsFromState {
    addToast: typeof toastActions.addToast;
}

type AllProps = PropsFromState & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
