import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {TFunction} from 'i18next';
import styles from './reports-menu.module.scss';
import {conditionalClassLister} from '../../../utils/class-helpers';

class ReportsMenuComponent extends Component<AllProps> {

    public render(): JSX.Element {
        const {reportTabs, activeReportTab, t} = this.props;

        return (
            <div className={styles.tabsContainer}>
                <div className={styles.tabs}>
                    {reportTabs.map((tab) => {
                        const menuButtonClasses = conditionalClassLister(styles)({
                            menuButton: true,
                            tabItemActive: tab === activeReportTab,
                        });

                        return (
                            <div className={styles.menuButtonContainer} key={tab}>
                                <div className={menuButtonClasses} onClick={() => this.onClicked(tab)}>
                                    <div className={styles.caption}>{this.getTabTitle(tab, t).toUpperCase()}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }

    private onClicked(reportTab: ReportTab): void {
        const {onSelectReportTab} = this.props;
        onSelectReportTab(reportTab);
    }

    private getTabTitle(tab: ReportTab, t: TFunction): string {
        switch (tab) {
            case ReportTab.DownloadableReports:
                return t('Downloadable Reports');
            case ReportTab.ReportScheduler:
                return t('Report Scheduler');
            default:
                throw new Error(`No mapping found for Report Tab: ${tab}`);
        }
    }
}

export enum ReportTab {
    DownloadableReports = 'DownloadableReports',
    ReportScheduler = 'ReportScheduler',
}

export default withTranslation()(ReportsMenuComponent);

interface OwnProps {
    reportTabs: ReportTab[];
    activeReportTab?: ReportTab;
    onSelectReportTab: (reportTab: ReportTab) => void;
}

type AllProps = OwnProps & WithTranslation;
