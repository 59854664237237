import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {
    CustomerServiceLocationsCustomReportParameters,
    CustomerServiceLocationsScheduledReportParameters,
    ReportPeriodType,
} from '../../../../../../store/reports';
import styles from './customer-service-locations.module.scss';
import {
    ServiceProviderSelection,
    SoldToSelection
} from '../../../../../../store/analytics';
import {ServiceProviderGroupWithConfiguration} from '../../../../../../store/service-provider-groups';
import {SoldToSelectionsControlValue} from '../../../../../analytics/dashboard-controls/sold-tos-selector/types/sold-tos-selections-control-value';
import {ServiceProviderSelectionsControlValue} from '../../../../../analytics/dashboard-controls/service-providers-selector/types/service-providers-selections-control-value';
import ServiceProvidersSelectorComponent from '../../../../components/service-providers-selector.component';
import SoldTosSelectorComponent from '../../../../components/sold-tos-selector.component';

class CustomerServiceLocationsComponent extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {parameters} = this.props;

        let serviceProviders: ServiceProviderSelection = {includes: []};
        if (parameters && parameters.serviceProviders) {
            serviceProviders = parameters.serviceProviders;
        }
        let soldTos: SoldToSelection = {includes: [], includeAll: false};
        if (parameters && parameters.soldTos) {
            soldTos = parameters.soldTos;
        }
        this.state = {
            soldToSelection: new SoldToSelectionsControlValue('', soldTos),
            serviceProviderSelection: new ServiceProviderSelectionsControlValue('', serviceProviders),
        };
    }

    public render(): JSX.Element {
        const {periodType, t} = this.props;
        const {serviceProviderSelection, soldToSelection} = this.state;

        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <div className={styles.title}>{t('Parameters')}</div>
                    : null
                }
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <div className={styles.info}>{t('Select a service provider')}</div>
                        <ServiceProvidersSelectorComponent
                            defaultServiceProviderSelection={serviceProviderSelection && serviceProviderSelection.value.includes.length > 0 ? serviceProviderSelection : undefined}
                            onServiceProviderSelectionChange={(controlValue) => this.onServiceProviderSelectionChanged(controlValue)}
                        />
                        <div className={styles.info}>{t('Select a fleet')}</div>
                        <SoldTosSelectorComponent
                            defaultSoldToSelection={soldToSelection && soldToSelection.value.includes.length > 0 ? soldToSelection : undefined}
                            onSoldToSelectionChange={(controlValue) => this.onSoldToSelectionChanged(controlValue)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private onSoldToSelectionChanged(controlValue: SoldToSelectionsControlValue): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {soldToSelection} = this.state;

        if (!soldToSelection || (soldToSelection && !controlValue.equals(soldToSelection))) {
            this.setState({
                soldToSelection: controlValue,
            }, () => {
                // Make sure previous state has been applied, as service provider and sold to selection changes will trigger at the same time
                const {serviceProviderSelection} = this.state;
                if (periodType === ReportPeriodType.Custom) {
                    onCustomParametersChanged({
                        serviceProviders: serviceProviderSelection,
                        soldTos: controlValue,
                    });
                }
                if (periodType === ReportPeriodType.Scheduled) {
                    onScheduledParametersChanged({
                        serviceProviders: serviceProviderSelection?.value,
                        soldTos: controlValue.value,
                    });
                }
            });
        }
    }

    private onServiceProviderSelectionChanged(controlValue: ServiceProviderSelectionsControlValue): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {serviceProviderSelection} = this.state;

        if (!serviceProviderSelection || (serviceProviderSelection && !controlValue.equals(serviceProviderSelection))) {
            this.setState({
                serviceProviderSelection: controlValue,
            }, () => {
                // Make sure previous state has been applied, as service provider and sold to selection changes will trigger at the same time
                const {soldToSelection} = this.state;

                if (periodType === ReportPeriodType.Custom) {
                    onCustomParametersChanged({
                        serviceProviders: controlValue,
                        soldTos: soldToSelection,
                    });
                }
                if (periodType === ReportPeriodType.Scheduled) {
                    onScheduledParametersChanged({
                        serviceProviders: controlValue.value,
                        soldTos: soldToSelection?.value,
                    });
                }
            });
        }
    }
}

export default withTranslation()(CustomerServiceLocationsComponent);

interface OwnProps {
    periodType: ReportPeriodType;
    serviceProviderGroup?: ServiceProviderGroupWithConfiguration;
    parameters?: CustomerServiceLocationsScheduledReportParameters;
    onCustomParametersChanged: (parameters: CustomerServiceLocationsCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: CustomerServiceLocationsScheduledReportParameters) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    serviceProviderSelection?: ServiceProviderSelectionsControlValue;
    soldToSelection?: SoldToSelectionsControlValue;
}

type AllState = OwnState;
