import {TFunction} from 'i18next';
import {Component} from 'react';

import {withTranslation, WithTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import {ApplicationState} from '../../../../store';
import {getKPIDataRequest, KpiTypes} from '../../../../store/analytics';
import {
    KeyPerformanceIndicatorHelper
} from '../../../../store/analytics/types/helpers/key-performance-indicator.helper';
import {
    KeyPerformanceIndicatorData,
    KeyPerformanceIndicatorRequest,
} from '../../../../store/analytics/types/key-performance-indicator.types';
import {CurrencyInfo} from '../../../../store/service-provider-groups';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import WidgetLoading from '../widget-loading';
import styles from './kpi-box.module.scss';
import {kpiMetaStore, NumberFormatter} from './kpi-meta-store';
import {buildRequest, isValidSnapshot} from './request-builder';
import {getTooltipDateRangeTextForWidget, WidgetDateRangeUsedType} from '../../../../utils/tooltip-helper';

class KpiBox extends Component<AllProps> {
    public componentDidMount(): void {
        const {controlsSnapshot, dispatchKeyPerformanceIndicatorRequest, configuration, activeCurrency} = this.props;

        if (controlsSnapshot && isValidSnapshot(controlsSnapshot)) {
            dispatchKeyPerformanceIndicatorRequest(buildRequest(controlsSnapshot, configuration.type, activeCurrency));
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {dispatchKeyPerformanceIndicatorRequest, controlsSnapshot, configuration, activeCurrency} = this.props;

        if (
            controlsSnapshot &&
            !controlsSnapshot.equals(prevProps.controlsSnapshot) &&
            isValidSnapshot(controlsSnapshot)
        ) {
            dispatchKeyPerformanceIndicatorRequest(buildRequest(controlsSnapshot, configuration.type, activeCurrency));
        }
    }

    public render(): JSX.Element {
        const {t, configuration, data, controlsSnapshot} = this.props;
        const keyPerformanceData = data.find((d) => d.type === configuration.type);
        if (keyPerformanceData) {
            KeyPerformanceIndicatorHelper.formatBigNumber(keyPerformanceData);
        }

        const tooltipText = this.getTooltipTextForKPIType(configuration.type, t);

        const formatter = this.getFormatter(configuration.type);
        return (
            <div className={styles.widgetContainer}>
                {keyPerformanceData ? (
                    <div className={styles.chartView}>
                        <div className={styles.titlePanel}>
                            {this.getTitle(configuration.type, controlsSnapshot, t).toUpperCase()}
                            {tooltipText ?
                                <>
                                    <div className={styles.infoIcon}
                                         data-tip={tooltipText}
                                         data-for={configuration.type}>
                                        <FontAwesomeIcon icon={faInfoCircle}/></div>
                                    <ReactTooltip className={styles.tooltip} html border effect="solid"
                                                  backgroundColor="#000000" id={configuration.type}/>
                                </>
                                : null}
                        </div>
                        <div className={styles.valuePanel}>
                            <div className={styles.value}>
                                {keyPerformanceData.value ? <>
                                    <NumberFormat
                                        value={keyPerformanceData.value}
                                        displayType="text"
                                        decimalSeparator={formatter.decimalSeparator}
                                        thousandSeparator={formatter.thousandsSeparator}
                                        fixedDecimalScale={formatter.fixedDecimalScale}
                                        decimalScale={formatter.decimalScale}
                                    />
                                    <span className={styles.unit}>{keyPerformanceData.unit}</span>
                                </> : ''}
                                {keyPerformanceData.stringValue ? keyPerformanceData.stringValue : ''}
                            </div>
                            <div className={styles.label}>
                                {this.getSubtitle(configuration.type, controlsSnapshot, t)}
                            </div>
                        </div>
                    </div>
                ) : (
                    <WidgetLoading/>
                )}
            </div>
        );
    }

    private getSubtitle(type: KpiTypes, controlsSnapshot: ControlsSnapshot, t: TFunction): string {
        return kpiMetaStore.get(type)!.subtitleFunction(controlsSnapshot, t);
    }

    private getTitle(type: KpiTypes, controlsSnapshot: ControlsSnapshot, t: TFunction): string {
        return kpiMetaStore.get(type)!.titleFunction(controlsSnapshot, t);
    }

    private getFormatter(type: KpiTypes): NumberFormatter {
        if (kpiMetaStore.has(type)) {
            return kpiMetaStore.get(type)!.numberFormatter;
        }
        return new NumberFormatter('.', ',', false, 0);
    }

    private getTooltipTextForKPIType(kpiType: KpiTypes, t: TFunction): string | undefined {
        switch (kpiType) {
            case KpiTypes.TotalInvoiced: {
                return getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.PURCHASING_DATE_RANGE_USED, t);
            }
            case KpiTypes.TotalJobCount:
            case KpiTypes.TotalInspectionCount:
            case KpiTypes.InspectionRatio:
            case KpiTypes.TotalTiresCount:
            case KpiTypes.JobsAvgProcessTime:
            case KpiTypes.CorporateBrandRatio:
            case KpiTypes.RegrooveCount:
            case KpiTypes.RetreadRatio: {
                return getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.JOB_DATE_RANGE_USED, t);
            }
            case KpiTypes.ECasingReturnsRatio: {
                const infoText = t('The eCasing Returns Ratio is the percentage of dismounted tires (New and Retread) returned to Goodyear using FOS eCasing.');
                return `${infoText}</br>${getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.JOB_DATE_RANGE_USED, t)}`;
            }
            case KpiTypes.RegrooveRatio: {
                const infoText = t('The Regroove Ratio is calculated as a percentage of regrooves completed against a base of total new and retread tyres fitted plus regrooves.');
                return `${infoText}</br>${getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.JOB_DATE_RANGE_USED, t)}`;
            }
            case KpiTypes.JobsAvgAcceptanceTime: {
                const infoText = t('The average acceptance time is the time elapsed between job submission and of purchase order creation.');
                return `${infoText}</br>${getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.JOB_DATE_RANGE_USED, t)}`;
            }
            case KpiTypes.AverageTreadDepth: {
                const infoText = t('The average tread depth is the average tread depth of all tires that were demounted in regular service (breakdowns and damaged tires excluded).');
                return `${infoText}</br>${getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.JOB_DATE_RANGE_USED, t)}`;
            }
            case KpiTypes.PWTStockAge: {
                const infoText = t('The Part Worn Tyre Usage is the percentage of mounted tires that have been part of the PWT Stock for less than 90 days against a base of all tires that are part of the PWT Stock.');
                return `${infoText}</br>${getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.JOB_DATE_RANGE_USED, t)}`;
            }
            case KpiTypes.PWTCycleTime: {
                const infoText = t('The Part Worn Tyre Cycle Time is the average time beginning from the date where tyre goes into stock until to the job date where tyre was refitted.');
                return `${infoText}</br>${getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.JOB_DATE_RANGE_USED, t)}`;
            }
            case KpiTypes.PWTCurrentStock: {
                const infoText = t('The Part Worn Tyre Current Stock is the amount of tyres located at the vendor, which are the property of Goodyear and can be refitted to a Goodyear fleet customer.');
                return `${infoText}</br>${getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.JOB_DATE_RANGE_USED, t)}`;
            }
            case KpiTypes.EjobUtilization: {                
                const infoText = t('% of total FOS jobs submitted on eJob.');
                return `${infoText}</br>${getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.JOB_DATE_RANGE_USED, t)}`;
            }
            default:
                return '';
        }
    }
}

const mapStateToProps = ({analytics, authentication}: ApplicationState) => ({
    data: analytics.keyPerformanceIndicatorData,
    activeCurrency: authentication.serviceProviderGroup?.activeCurrency,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchKeyPerformanceIndicatorRequest: (requestBody: KeyPerformanceIndicatorRequest) =>
        dispatch(getKPIDataRequest(requestBody)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(KpiBox));

interface PropsFromState {
    data: KeyPerformanceIndicatorData[];
    activeCurrency: CurrencyInfo;
}

interface PropsFromDispatch {
    dispatchKeyPerformanceIndicatorRequest: typeof getKPIDataRequest;
}

export interface OwnProps {
    configuration: Configuration;
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & WithTranslation & PropsFromState & PropsFromDispatch;

export interface Configuration {
    type: KpiTypes;
}
