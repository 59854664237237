import {ActionType} from 'typesafe-actions';
import * as actions from './actions';
import * as sharedActions from '../shared/actions';

export enum SearchActionTypes {
    SEARCH_REQUEST = '@@search/SEARCH_REQUEST',
    SEARCH_SUCCESS = '@@search/SEARCH_SUCCESS',
    SEARCH_FAILURE = '@@search/SEARCH_FAILURE',
    SEARCH_CLEAR = '@@search/CLEAR_SEARCH',
}

export type SearchAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface SearchState {
    readonly isSearching: boolean;
    readonly searchQuery: string;
    readonly searchResults: SearchResult[];
}

export interface SearchResult {
    id: string;
    name: string;
    type: SearchResultType;
    matchType: SearchMatchType;
}

export enum SearchResultType {
    Vehicle = 'Vehicle',
    ServiceProvider = 'ServiceProvider',
    Job = 'Job',
    Ejob = 'Ejob',
}

export enum SearchMatchType {
    Name = 'Name',
    LicensePlate = 'LicensePlate',
    Reference = 'Reference',
    FleetReference = 'FleetReference',
}
