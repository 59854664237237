
import ReactTooltip from 'react-tooltip';
import {Component} from 'react';
import styles from './ellipsis-with-tooltip.module.scss';

class EllipsisWithTooltip extends Component<AllProps, AllState> {
    constructor(props:AllProps) {
        super(props);
        this.state = {
            hasOverflowingChildren: false,
            text: undefined,
        };
        this.updateOverflow = this.updateOverflow.bind(this);
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {id} = this.props;
        if (id && id !== prevProps.id) {
            this.setState({hasOverflowingChildren: false});
        }
    }

    public componentWillUnmount(): void {
        this.setState({hasOverflowingChildren: false});
    }

    private updateOverflow(e) {
        const el = e.target;
        const {hasOverflowingChildren, text} = this.state;

        if (!hasOverflowingChildren && el.scrollWidth > el.clientWidth) {
            this.setState({hasOverflowingChildren: true});
            if (el.textContent !== text) {
                this.setState({text: el.textContent});
            }
        } else {
            this.setState({hasOverflowingChildren: false});
        }
    }

    public render(): JSX.Element {
        const {hasOverflowingChildren, text} = this.state;
        const {placement = 'top', children, id} = this.props;

        return hasOverflowingChildren ? (
            <div className={styles.ellipsisDefaultStyle} data-tip data-for={id}>
                {children}
                <ReactTooltip id={id} arai-has-popup place={placement}  effect='solid' type="dark">{text}</ReactTooltip>
            </div>
        ) : (
            <div className={styles.ellipsisDefaultStyle}
                 onMouseEnter={event => this.updateOverflow(event)}>{children}</div>
        );
    }
}

export default EllipsisWithTooltip;

interface OwnProps {
    id: string;
    placement: PlacementType;
    children: any;
}

type AllProps = OwnProps;

interface OwnState {
    hasOverflowingChildren: boolean;
    text?: string;
}

type AllState = OwnState;

type PlacementType = 'top' | 'bottom' | 'left' | 'right';
