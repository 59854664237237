import {put} from 'redux-saga/effects';
import i18n, {TFunction} from 'i18next';
import {addToast, ToastType} from './toast';
import {generateUUID} from '../utils/uuid-helpers';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleUnexpectedErrorWithToast(err: any) {
    // todo#Sam probably want to do something better here...
    if (err instanceof Error) {
        console.log(err);
        console.log(err.stack!);
    } else {
        console.log(err);
    }
    const t:TFunction = i18n.t.bind(i18n);
    yield put(addToast({id: generateUUID(), type: ToastType.Error, messages: [t('Something went wrong'), t('Please try again or contact your admin')]}));
}
