import {ControlValue} from '../../controls-snapshot';
import {DateRange} from '../../../../../store/analytics';

export class DateRangeControlValue implements ControlValue {
    constructor(public displayValue: string, public value: DateRange) {}

    public equals(other: ControlValue):boolean {
        if (other instanceof DateRangeControlValue) {
            return (this.value.start.toISOString() === other.value.start.toISOString()) &&
                (this.value.end.toISOString() === other.value.end.toISOString());
        }
        return false;
    }
}
