import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import {ApiResult} from '../../types/api-result';
import { Job, JobsActionTypes, JobsExplorerRequest, TireServiceDetails } from './types';
import {
    getJobsFailure,
    getJobsRequest,
    getJobsSuccess, getTireServiceFailure, getTireServiceRequest, getTireServiceSuccess,
} from './actions';
import JobsApi from '../../api/jobs-api';
import {handleUnexpectedErrorWithToast} from '../http-error-handler';
import {ApplicationState} from '../index';
import {Paged} from '../shared/types';

const fetchServiceProviderGroupIdFromState = () => select((state: ApplicationState) => state.authentication.serviceProviderGroup!.id);

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleGet(action: ActionType<typeof getJobsRequest>) {
    try {
        const request: JobsExplorerRequest = action.payload;
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const result: ApiResult<Paged<Job>> = yield call(JobsApi.getJobs, request, serviceProviderGroupId);

        if (result.error) {
            yield call(getJobsFailure, result.error);
        } else {
            yield put(getJobsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleGetTiresService(action: ActionType<typeof getTireServiceRequest>) {
    try {
        const request = action.payload;
        const result: ApiResult<TireServiceDetails> = yield call(JobsApi.getTireService, request);

        if (result.error) {
            yield call(getTireServiceFailure, result.error);
        } else {
            yield put(getTireServiceSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

function* watchRequests() {
    yield takeEvery(JobsActionTypes.GET_JOBS_REQUEST, handleGet);
    yield takeEvery(JobsActionTypes.GET_TIRE_SERVICE_REQUEST, handleGetTiresService);
}

function* jobsSaga() {
    yield all([fork(watchRequests)]);
}

export default jobsSaga;
