import {TFunction} from 'i18next';

export enum WidgetDateRangeUsedType {
    PURCHASING_DATE_RANGE_USED = 'PURCHASING_DATE_RANGE_USED',
    JOB_DATE_RANGE_USED = 'JOB_DATE_RANGE_USED',
}

export const getTooltipDateRangeTextForWidget = (type: WidgetDateRangeUsedType, t: TFunction):string => {
    switch (type) {
        case WidgetDateRangeUsedType.PURCHASING_DATE_RANGE_USED:
            return t('The number(s) shown are calculated applying the specified date range to PO dates');
        case WidgetDateRangeUsedType.JOB_DATE_RANGE_USED:
            return t('The number(s) shown are calculated applying the specified date range to job dates');
        default:
            throw new Error(`No mapping found for WidgetDateRangeUsedType: ${type}`);
    }
}
