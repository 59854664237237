import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {DashboardWidget} from '../../../store/dashboard';
import {generateUUID} from '../../../utils/uuid-helpers';
import {allCatalogGroups, WidgetCatalogGroup, WidgetCatalogItem} from './widget-catalog-items';
import styles from './widgets-catalog-popup.module.scss';

class WidgetsCatalogPopup extends Component<AllProps, OwnState> {
    constructor(props) {
        super(props);

        this.state = {
            selectedGroupId: allCatalogGroups[0].id,
        };
    }

    public render(): JSX.Element {
        const {selectedGroupId} = this.state;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.groupSelectionBar}>
                    {allCatalogGroups.map((group) => {
                        const groupClasses =
                            group.id === selectedGroupId
                                ? `${styles.groupLink} ${styles.active}`
                                : `${styles.groupLink}`;
                        return (
                            <div key={group.name} className={groupClasses} onClick={(): void => this.onSelectGroup(group)}>
                                {group.name}
                            </div>
                        );
                    })}
                </div>
                <div className={styles.widgetsGroup}>
                    {allCatalogGroups
                        .find((g) => g.id === selectedGroupId)!
                        .widgets.map((item, index) => (
                            <div
                                className={styles.widgetType}
                                key={item.name}
                                onClick={(): void => this.onAddWidget(item, false)}>
                                {item.name}
                            </div>
                        ))}
                </div>
            </div>
        );
    }

    private onSelectGroup(group: WidgetCatalogGroup): void {
        this.setState({selectedGroupId: group.id});
    }

    private onAddWidget(item: WidgetCatalogItem, closePopup: boolean): void {
        const {onAddWidget} = this.props;
        const id = generateUUID();
        onAddWidget(
            {
                id,
                type: item.type,
                configuration: item.configuration,
                // y: infinity makes sure the new item is added at the bottom
                layout: {x: 0, y: Infinity, w: item.defaultWidth, h: item.defaultHeight, i: id},
            },
            closePopup,
        );
    }
}

export default withTranslation()(WidgetsCatalogPopup);

interface OwnProps {
    onAddWidget: (widget: DashboardWidget, closePopup: boolean) => void;
}

interface OwnState {
    selectedGroupId: string;
}

type AllProps = OwnProps & WithTranslation;
