import {push} from 'connected-react-router';
import {Location, LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';
import {Dispatch} from 'redux';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageHeader from '../../../../components/page-header/page-header';
import {RouteUrl} from '../../../../routes';
import {ApplicationState} from '../../../../store';
import * as layoutActions from '../../../../store/layout/actions';
import {ServiceProviderGroupMatchParameters} from '../../../../types/service-provider-group';
import {getReportName, ReportCatalog, ReportCatalogEntry, ReportType} from '../../report-catalog';
import styles from './extended-inspection-report.module.scss';
import {
    InspectionStatus,
    ExtendedInspectionReportRequest,
    fetchExtendedInspectionReportRequest,
    ReportPeriodType,
    ExtendedInspectionCustomReportParameters,
} from '../../../../store/reports';
import {FileType, RequestStatus} from '../../../../store/shared/types';
import ExtendedInspectionComponent
    from '../../report-scheduler/popup/report-data-components/extended-inspection/extended-inspection.component';
import {ServiceProviderGroup} from '../../../../store/service-provider-groups';
import {ServiceProviderSelectionsControlValue} from '../../../analytics/dashboard-controls/service-providers-selector/types/service-providers-selections-control-value';
import {SoldToSelectionsControlValue} from '../../../analytics/dashboard-controls/sold-tos-selector/types/sold-tos-selections-control-value';

class ExtendedInspectionReportPage extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {dispatchToggleSidebar} = this.props;
        dispatchToggleSidebar(true);

        this.state = {
            selectedInspectionStatus: InspectionStatus.All,
        };
    }

    public render(): JSX.Element {
        const { t, location, reportRequestStatus } = this.props;
        const { soldToSelection,serviceProviderSelection } = this.state;
        const report: ReportCatalogEntry = new ReportCatalog().getCatalogEntry(ReportType.ExtendedInspection);

        const isValid = serviceProviderSelection &&  serviceProviderSelection.value.includes.length > 0
        && soldToSelection &&  soldToSelection.value.includes.length > 0;
        return (
            <div className={styles.pageContainer}>
                <PageHeader headerTitle={getReportName(report.type,t)} location={location} bottomComponent={<div />} />
                <div className={styles.pageContent}>
                    <ExtendedInspectionComponent
                        periodType={ReportPeriodType.Custom}
                        onCustomParametersChanged={(p) => this.onParametersChanged(p)}
                        onScheduledParametersChanged={() => {}}
                    />
                    <div className={styles.buttons}>
                        <button type='button'
                                className={`${styles.button} ${styles.isNegative}`}
                                disabled={reportRequestStatus?.isInProgress}
                                onClick={() => this.goBack()}>
                            {t('Back')}
                        </button>
                        <button type='button'
                                className={styles.button}
                                disabled={!isValid || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.ZIP)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download ZIP')}</button>
                        <button type='button'
                                className={styles.button}
                                disabled={!isValid || reportRequestStatus?.isInProgress}
                                onClick={() => this.download(FileType.XLSX)}>
                            {reportRequestStatus?.isInProgress ?
                                <FontAwesomeIcon icon={faSpinner} spin/> : t('Download XLSX')}</button>
                    </div>
                </div>
            </div>
        );
    }

    private onParametersChanged(parameters: ExtendedInspectionCustomReportParameters) {
        this.setState({
            serviceProviderSelection: parameters.serviceProviders,
            soldToSelection: parameters.soldTos,
            selectedInspectionStatus: parameters.selectedInspectionStatus,
        });
    }

    private download(fileType:FileType) {
        const {selectedInspectionStatus, soldToSelection,serviceProviderSelection} = this.state;
        const {dispatchFetchExtendedInspectionReport} = this.props;
        if (serviceProviderSelection && serviceProviderSelection.value.includes.length > 0 && soldToSelection && soldToSelection.value.includes.length > 0 &&selectedInspectionStatus) {
            dispatchFetchExtendedInspectionReport({
                fileType,
                serviceProviders: serviceProviderSelection.value,
                soldTos: soldToSelection.value,
                inspectionStatus: selectedInspectionStatus,
            });
        }
    }

    private goBack(): void {
        const { dispatchNavigateTo, serviceProviderGroup } = this.props;
        dispatchNavigateTo({ pathname: `/${serviceProviderGroup?.id}${RouteUrl.Reports}` });
    }
}

// eslint-disable-next-line
const mapStateToProps = ({ authentication, reports, router }: ApplicationState) => ({
    serviceProviderGroup: authentication.serviceProviderGroup,
    reportRequestStatus: reports.extendedInspectionReportRequestStatus,
    currentLocation: router.location,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchFetchExtendedInspectionReport: (request: ExtendedInspectionReportRequest) => dispatch(fetchExtendedInspectionReportRequest(request)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ExtendedInspectionReportPage));

interface PropsFromState {
    serviceProviderGroup?: ServiceProviderGroup;
    reportRequestStatus: RequestStatus;
    currentLocation: Location;
}

interface PropsFromDispatch {
    dispatchNavigateTo: typeof push;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchFetchExtendedInspectionReport: typeof fetchExtendedInspectionReportRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    WithTranslation &
    OwnProps &
    RouteComponentProps<ServiceProviderGroupMatchParameters>;

interface OwnState {
    selectedInspectionStatus?: InspectionStatus;
    serviceProviderSelection?: ServiceProviderSelectionsControlValue;
    soldToSelection?: SoldToSelectionsControlValue;
}

type AllState = OwnState;
