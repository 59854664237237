import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import styles from './controls-summary-item.module.scss';
import {conditionalClassLister} from '../../../../utils/class-helpers';

class ControlsSummaryItem extends Component<AllProps, AllState> {

    public render(): JSX.Element {
        const {displayValue, title, isFirst, isLast, isOpen, isAnyOpen, t} = this.props;

        const summaryClasses = conditionalClassLister(styles)({
            isFirst,
            isLast,
            isOpen,
            isAnyOpen,
            summaryPanel: true,
        });

        return (
            <div className={summaryClasses} onClick={() => this.onToggleOpen()}>
                <div className={styles.summaryCaption}>{title ? t(title) : ''}</div>
                <div className={styles.summaryValue}>{displayValue || ''}</div>
            </div>
        );
    }

    private onToggleOpen() {
        const {onToggleOpen} = this.props;
        onToggleOpen();
    }
}

export default withTranslation()(ControlsSummaryItem);

interface OwnProps {
    isFirst?: boolean;
    isLast?: boolean;
    isOpen: boolean;
    isAnyOpen: boolean;
    onToggleOpen: () => void;
    displayValue?: string;
    title: string;
}

interface PropsFromState {
}

interface PropsFromDispatch {
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
