import {generateUUID} from '../../utils/uuid-helpers';
import {ControlType, Dashboard, ParamKey, WidgetType} from '../../store/dashboard';
import {TFunction} from "i18next";

export function getDashboardConfiguration(t: TFunction): Dashboard {
    return {
        id: generateUUID(),
        index: 0,
        name: 'JOB EXPLORER',
        controls: [
            {
                type: ControlType.DateRange,
                summaryTitle: t('Date Range'),
                key: ParamKey.InspectionPeriod,
                configuration: {},
            },
            {
                type: ControlType.ServiceProviders,
                key: ParamKey.ServiceProviderSelections,
                summaryTitle: t('Service Providers'),
                configuration: {},
            },
            {
                type: ControlType.SoldTos,
                key: ParamKey.SoldToSelections,
                summaryTitle: t('Fleets'),
                configuration: {},
            },
        ],
        widgets: [
            {
                type: WidgetType.JobsTable,
                configuration: {},
                id: generateUUID(),
                layout: {i: generateUUID(), x: 0, y: 0, w: 12, h: 12, static: true},
            },
        ],
        isConfigurable: false
    }
}
