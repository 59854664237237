import { Layout } from 'react-grid-layout';
import { ActionType } from 'typesafe-actions';
import * as sharedActions from '../shared/actions';
import * as actions from './actions';

export enum DashboardActionTypes {
    SKIP_DASHBOARDS_REQUEST = '@@dashboard/SKIP_DASHBOARDS_REQUEST',
    FETCH_DASHBOARDS_REQUEST = '@@dashboard/FETCH_DASHBOARDS_REQUEST',
    DELETE_DASHBOARD_REQUEST = '@@dashboard/DELETE_DASHBOARD_REQUEST',
    CREATE_DASHBOARD_REQUEST = '@@dashboard/CREATE_DASHBOARD_REQUEST',
    UPDATE_DASHBOARD_REQUEST = '@@dashboard/UPDATE_DASHBOARD_REQUEST',
    SET_DASHBOARDS = '@@dashboard/SET_DASHBOARDS',
    RESTORE_DEFAULT_DASHBOARDS = '@@dashboard/RESTORE_DEFAULT_DASHBOARDS',
    DASHBOARD_ACTION_FAILURE = '@@dashboard/ACTION_FAILURE',
}

export type DashboardAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface Dashboard {
    id: string;
    name: string;
    index: number;
    controls: DashboardControl[];
    widgets: DashboardWidget[];
    isConfigurable:boolean;
}

export interface DashboardWidget {
    type: WidgetType;
    id: string;
    configuration: any;
    layout: Layout;
}

export interface DashboardControl {
    type: ControlType;
    key: ParamKey;
    summaryTitle;
    configuration: any;
}

export interface DashboardState {
    readonly dashboards?: Dashboard[];
    readonly inProgress: boolean;
}

export enum WidgetType {
    KpiBox = 'kpiBox',
    JobsDistribution = 'jobsDistribution',
    TopSoldTos = 'topSoldTos',
    PurchasingDistribution = 'purchasingDistribution',
    PurchasingEvolution = 'purchasingEvolution',
    JobsTable = 'jobsTable',
    TiresDistribution = 'tiresDistribution',
    TireBrandDistribution = 'tireBrandDistribution',
    CorporateFitmentDistribution = 'corporateFitmentDistribution',
    PurchasingDistributionKpi = 'purchasingDistributionKpi',
}

export enum ControlType {
    DateRange = 'dateRange',
    ServiceProviders = 'serviceProviders',
    SoldTos = 'soldTos',
}

export enum ParamKey {
    InspectionPeriod = 'inspectionPeriod',
    ServiceProviderSelections = 'serviceProviderSelections',
    SoldToSelections = 'soldToSelections',
}
