import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {generateUUID} from '../../../utils/uuid-helpers';
import styles from './service-providers-selector.module.scss';
import ServiceProvidersSelector
    from '../../analytics/dashboard-controls/service-providers-selector/service-providers-selector';
import {ServiceProviderSelectionsControlValue} from '../../analytics/dashboard-controls/service-providers-selector/types/service-providers-selections-control-value';

class ServiceProvidersSelectorComponent extends Component<AllProps, AllState> {

    private serviceProviderSelection?: ServiceProviderSelectionsControlValue;

    constructor(props) {
        super(props);

        const {defaultServiceProviderSelection} = this.props;
        this.serviceProviderSelection = defaultServiceProviderSelection || undefined;
    }

    public render(): JSX.Element {
        return (
            <div className={styles.selectorPanel}>
                <ServiceProvidersSelector
                    onValueChange={(value => this.serviceProviderValueChange(value))}
                    defaultValue={this.serviceProviderSelection}
                    setControlRef={() => this.setControlRef()}
                    key={generateUUID()}
                />
            </div>
        );
    }

    private setControlRef() {
    }

    private serviceProviderValueChange(controlValue: ServiceProviderSelectionsControlValue) {
        const {onServiceProviderSelectionChange} = this.props;
        this.serviceProviderSelection = controlValue;
        onServiceProviderSelectionChange(controlValue);
    }
}

export default withTranslation()(ServiceProvidersSelectorComponent);

interface OwnProps {
    defaultServiceProviderSelection?: ServiceProviderSelectionsControlValue;
    onServiceProviderSelectionChange: (serviceProviderSelections: ServiceProviderSelectionsControlValue) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
}

type AllState = OwnState;
