import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ApiResult } from '../../types/api-result';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import {
    fetchMaintenanceStatusFailure,
    fetchMaintenanceStatusRequest, fetchMaintenanceStatusSuccess,
} from './actions';
import {MaintenanceActionTypes, MaintenanceStatusContract} from './types';
import MaintenanceApi from '../../api/maintenance-api';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleFetch(action: ActionType<typeof fetchMaintenanceStatusRequest>) {
    try {
        const result: ApiResult<MaintenanceStatusContract> = yield call(MaintenanceApi.getStatus);

        if (result.error) {
            yield call(fetchMaintenanceStatusFailure, result.error);
        } else {
            yield put(fetchMaintenanceStatusSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}


function* watchFetchRequest() {
    yield takeEvery(MaintenanceActionTypes.FETCH_STATUS_REQUEST, handleFetch);
}

function* maintenanceSaga() {
    yield all([fork(watchFetchRequest)]);
}

export default maintenanceSaga;
