import {faTimes} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {removeToast, Toast, ToastType} from '../../store/toast';
import {conditionalClassLister} from '../../utils/class-helpers';
import styles from './toast-container.module.scss';

class ToastContainer extends Component<AllProps> {
    public componentDidUpdate(previousProps: Readonly<AllProps>): void {
        const {toasts, dispatchRemoveToast} = this.props;

        const newToasts = toasts.filter(
            (toast) => !previousProps.toasts.find((previousToast) => toast.id === previousToast.id),
        );

        newToasts.forEach((toast) => {
            if (toast.removeAfterMilliseconds) {
                setTimeout(() => {
                    dispatchRemoveToast(toast);
                }, toast.removeAfterMilliseconds);
            }
        });
    }

    public render(): JSX.Element | null {
        const {dispatchRemoveToast, toasts} = this.props;

        const toastClasses = (toast: Toast) =>
            conditionalClassLister(styles)({
                toast: true,
                toastSuccess: toast.type === ToastType.Success,
                toastError: toast.type === ToastType.Error,
                toastWarning: toast.type === ToastType.Warning,
                toastInfo: toast.type === ToastType.Info,
            });
        return toasts.length > 0 ? (
            <div className={styles.toasterContainer}>
                {toasts.map((t) => (
                    <div className={toastClasses(t)} key={t.id}>
                        <div className={styles.toastTitle}>
                            <span>{this.translateToastType(t.type)}</span>
                            <FontAwesomeIcon
                                className={styles.toastClose}
                                icon={faTimes}
                                onClick={() => dispatchRemoveToast(t)}
                            />
                        </div>
                        <div className={styles.toastMessage}>
                            {t.messages.map((m, i) => (
                                <div className={styles.toastMessageText} key={i}>
                                    {m}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        ) : null;
    }

    private translateToastType(toastType: ToastType): string {
        const {t} = this.props;

        switch (toastType) {
            case ToastType.Success:
                return t('Success');
            case ToastType.Error:
                return t('Error');
            case ToastType.Warning:
                return t('Warning');
            case ToastType.Info:
            default:
                return t('Info');
        }
    }
}

const mapStateToProps = ({toast}: ApplicationState) => ({
    toasts: toast.toasts,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchRemoveToast: (toast: Toast) => dispatch(removeToast(toast)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ToastContainer));

interface PropsFromState {
    toasts: Toast[];
}

interface PropsFromDispatch {
    dispatchRemoveToast: typeof removeToast;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;
