import {withTranslation, WithTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import styles from './sold-tos-selector.module.scss';
import {ApplicationState} from '../../../../store';
import SelectionPanel, {Item} from '../selection-panel/selection-panel';
import {ControlValue} from '../controls-snapshot';
import {ControlBase, ControlProps} from '../control-base';
import {SoldToSelectionsControlValue} from './types/sold-tos-selections-control-value';
import {SoldTo} from '../../../../store/sold-tos';
import {filterSoldTos} from '../../../../store/filter-control-selection';
import {SoldToSelection} from '../../../../store/analytics';

class SoldTosSelector extends ControlBase<AllProps, AllState, SoldToSelectionsControlValue> {

    constructor(props) {
        super(props);
        const {defaultValue} = this.props;
        const soldTos: Item[] = [];
        const selectedSoldTos: Map<string, Item> = new Map<string, Item>();

        // select all serviceProviders by default
        if (this.props.soldTos && this.props.soldTos.length > 0) {
            this.props.soldTos.forEach(st => {
                const stItem: Item = {
                    id: st.id,
                    displayName: st.name,
                };
                soldTos.push(stItem);

                if (defaultValue) {
                    const selection = defaultValue.value as SoldToSelection;
                    if (selection.includes.find(id => st.id === id)) {
                        selectedSoldTos.set(st.id, stItem);
                    }
                } else {
                    selectedSoldTos.set(st.id, stItem);
                }
            });
        }

        this.state = {soldTos, selectedSoldTos};
        // if service providers are available you can trigger and end the initialization flow
        if (selectedSoldTos.size > 0) {
            this.props.onValueChange(this.getValue());
        }
    }

    public componentDidUpdate(prevProps) {
        // if service providers are set you can trigger and end the initialization flow
        const {soldTos: currentSoldTos, onValueChange, defaultValue} = this.props;
        if (currentSoldTos && currentSoldTos !== prevProps.soldTos) {
            const soldTos: Item[] = [];
            const selectedSoldTos: Map<string, Item> = new Map<string, Item>();

            // select all serviceProviders by default
            if (this.props.soldTos && this.props.soldTos.length > 0) {
                this.props.soldTos.forEach(st => {
                    const stItem: Item = {
                        id: st.id,
                        displayName: st.name,
                    };
                    soldTos.push(stItem);

                    if (defaultValue) {
                        const selection = defaultValue.value as SoldToSelection;
                        if (selection.includes.find(id => st.id === id)) {
                            selectedSoldTos.set(st.id, stItem);
                        }
                    } else {
                        selectedSoldTos.set(st.id, stItem);
                    }
                });
            }

            this.setState({soldTos, selectedSoldTos}, () => {
                onValueChange(this.getValue());
            });
        }
    }

    public forceRefresh(value: SoldToSelectionsControlValue): void {
    }

    public render(): JSX.Element {
        const {soldTos, selectedSoldTos} = this.state;
        const {soldTosSearchText, t} = this.props;

        return (
            <div className={styles.selectorContainer}>
                {soldTos.length > 0 ?
                    <div className={styles.selectorColumn}>
                        <SelectionPanel
                            items={soldTos}
                            initialSearchText={soldTosSearchText}
                            selectedItems={selectedSoldTos}
                            partialItems={new Map<string, Item>()}
                            searchPlaceholderType={t('fleet')}
                            onToggleSelection={(item: Item) => this.onToggleSoldToSelection(item)}
                            onToggleSelectAll={(selectAll: boolean, items: Item[]) => this.onToggleSelectAllServiceProviders(selectAll, items)}
                            onSearchChanged={(searchText: string) => this.onSoldTosSearchChanged(searchText)}
                        />
                    </div> : null}
            </div>
        );
    }

    private onSoldTosSearchChanged(searchText: string) {
        this.props.dispatchFilterSoldTosRequest(searchText);
    }

    private onToggleSelectAllServiceProviders(selectAll: boolean, items: Item[]): void {
        const newServiceProviders = new Map<string, Item>();
        if (selectAll) {
            items.forEach(item => {
                newServiceProviders.set(item.id, item);
            })
        }

        this.setState({selectedSoldTos: newServiceProviders}, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    private onToggleSoldToSelection(soldTo: Item) {
        const newSoldTo = new Map<string, Item>();
        if (this.state.selectedSoldTos.has(soldTo.id)) {
            this.state.selectedSoldTos.forEach((item, key) => {
                if (key !== soldTo.id) {
                    newSoldTo.set(key, item);
                }
            })
        } else {
            this.state.selectedSoldTos.forEach((item, key) => {
                newSoldTo.set(key, item);
            })
            newSoldTo.set(soldTo.id, soldTo);
        }

        this.setState({selectedSoldTos: newSoldTo}, () => {
            this.props.onValueChange(this.getValue());
        });
    }

    private getValue(): ControlValue {
        const {soldTos} = this.props;
        const soldToIds = soldTos ? soldTos.map(st => st.id) : [];
        const {selectedSoldTos} = this.state;
        const allSelectedSoldToKeys = Array.from(selectedSoldTos.keys());
        const includeAll = soldToIds.every(id => allSelectedSoldToKeys.includes(id));
        const selections = {includes: allSelectedSoldToKeys, includeAll};
        const display = this.getDisplay();
        return new SoldToSelectionsControlValue(display, selections);
    }

    private getDisplay(): string {
        const {size} = this.state.selectedSoldTos;
        return size === 0 ? `None` : size === this.state.soldTos.length ? 'All' : `${size} Fleet${size > 1 ? 's' : ''}`;
    }
}

const mapStateToProps = ({soldTos, filterControlSelection}: ApplicationState) => ({
    soldTos: soldTos.soldTos,
    soldTosSearchText: filterControlSelection.soldTosFilter,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchFilterSoldTosRequest: (filter: string) => dispatch(filterSoldTos(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SoldTosSelector));

interface OwnProps extends ControlProps<SoldToSelectionsControlValue> {
}

interface PropsFromState {
    soldTos?: SoldTo[];
    soldTosSearchText?: string;
}

interface PropsFromDispatch {
    dispatchFilterSoldTosRequest: typeof filterSoldTos;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    selectedSoldTos: Map<string, Item>;
    soldTos: Item[];
}

type AllState = OwnState;
