import {WithTranslation, withTranslation} from 'react-i18next';
import {Component} from 'react';
import styles from './axle-view.module.scss';
import {Axle} from '../../types/vehicle-configuration';
import TireGroupView from './tire-group-view';

export class AxleComponent extends Component<AllProps, AllState> {
    public render(): JSX.Element {
        const {axle} = this.props;
        return (
            <div
                className={`${styles.axle} ${axle.leftTires.tires.length < 2 && axle.rightTires.tires.length < 2 ? `${styles.single}` : `${styles.double}`}`}>
                <div className={styles.horizontalAxle}/>
                <div className={styles.axleInfo}>
                    {axle.position}
                </div>
                <div className={styles.axleGroup}>
                    <TireGroupView group={axle.leftTires} axlePosition={axle.position}/>
                </div>
                <div className={styles.axleGroup}>
                    <TireGroupView group={axle.rightTires} axlePosition={axle.position}/>
                </div>
            </div>
        );
    }
}

export default withTranslation()(AxleComponent);

interface OwnProps {
    axle: Axle;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
