import {ControlValue} from '../../controls-snapshot';
import {SoldToSelection} from '../../../../../store/analytics';

export class SoldToSelectionsControlValue implements ControlValue {
    constructor(public displayValue: string,
                public value: SoldToSelection) {
    }

    public equals(other: ControlValue): boolean {
        if (!other ||
            !other.value ||
            !(other instanceof SoldToSelectionsControlValue) ||
            this.value.includes.length !== other.value.includes.length ||
            this.value.includeAll !== other.value.includeAll) {
            return false;
        }

        return this.value.includes.sort().toString() === other.value.includes.sort().toString() && this.value.includeAll === other.value.includeAll;
    }
}
