import {push} from 'connected-react-router';
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import i18n, {TFunction} from 'i18next';
import AuthenticationApi from '../../api/authentication-api';
import ServiceProviderGroupsApi from '../../api/service-provider-groups-api';
import UsersApi from '../../api/users-api';
import {RouteUrl} from '../../routes';
import {ApiResult} from '../../types/api-result';
import {hasAdminPermission} from '../../utils/user-helper';
import {generateUUID} from '../../utils/uuid-helpers';
import {ServiceProviderGroupWithConfiguration} from '../service-provider-groups';
import {handleUnexpectedErrorWithToast} from '../http-error-handler';
import {logoutSuccess} from '../shared/actions';
import {addToast, ToastType} from '../toast';
import {
    confirmResetPasswordRequest,
    federatedLoginRequest,
    fetchCurrentUserFailure,
    fetchCurrentUserRequest,
    fetchCurrentUserSuccess,
    fetchSelectedServiceProviderGroupRequest,
    fetchSelectedServiceProviderGroupSuccess,
    forgotPasswordFailure,
    forgotPasswordRequest,
    forgotPasswordSuccess,
    loginFailure,
    loginNewPasswordRequired, loginPasswordExpired,
    loginRequest,
    loginSuccess,
    logoutRequest,
    setCurrentUser,
    updateCurrentUserSettingsFailure,
    updateCurrentUserSettingsRequest,
    updateCurrentUserSettingsSuccess,
} from './actions';
import {
    AuthenticationActionTypes,
    AuthenticationRequest,
    AuthenticationResult,
    ResetPassswordResult,
    User,
} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function* handleFederatedLogin(action: ActionType<typeof federatedLoginRequest>) {
    try {
        yield call(AuthenticationApi.authenticateFederated, action.payload.searchUrl);
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(loginFailure());
    }
}

function* handleLogin(action: ActionType<typeof loginRequest>) {
    try {
        const request: AuthenticationRequest = action.payload;
        const result: ApiResult<AuthenticationResult> = yield call(
            AuthenticationApi.authenticate,
            request.credentials,
            request.newPassword,
        );

        if (result.error) {
            yield put(loginFailure(result.error));
        } else if (result.data === AuthenticationResult.Success) {
            yield all([put(loginSuccess()), put(fetchCurrentUserRequest())]);
        } else if (result.data === AuthenticationResult.NewPasswordRequired) {
            yield put(loginNewPasswordRequired());
        } else if (result.data === AuthenticationResult.PasswordExpired) {
            yield put(loginPasswordExpired());
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(loginFailure());
    }
}

function* handleLogout(action: ActionType<typeof logoutRequest>) {
    try {
        yield call(AuthenticationApi.logout);
        yield all([put(setCurrentUser(undefined)), put(push({pathname: RouteUrl.Login})), put(logoutSuccess())]);
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

function* handleForgotPassword(action: ActionType<typeof forgotPasswordRequest>) {
    try {
        const result: ApiResult<ResetPassswordResult> = yield call(
            AuthenticationApi.forgotPassword,
            action.payload.email,
        );

        if (result.error) {
            yield put(forgotPasswordFailure(result.error));
        } else if (result.data === ResetPassswordResult.Success) {
            yield put(forgotPasswordSuccess());
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

function* handleConfirmResetPassword(action: ActionType<typeof confirmResetPasswordRequest>) {
    try {
        const result: ApiResult<ResetPassswordResult> = yield call(
            AuthenticationApi.confirmForgotPassword,
            action.payload.email,
            action.payload.verificationCode,
            action.payload.newPassword,
        );
        const t: TFunction = i18n.t.bind(i18n);
        if (result.error) {
            yield put(forgotPasswordFailure(result.error));
        } else {
            yield all([
                put(
                    addToast({
                        id: generateUUID(),
                        type: ToastType.Success,
                        messages: [t('Successfully changed password')],
                    }),
                ),
                put(push({pathname: RouteUrl.Login})),
            ]);
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchCurrentUser(action: ActionType<typeof fetchCurrentUserRequest>) {
    try {
        const result: ApiResult<User> = yield call(UsersApi.getCurrentUser);

        if (result.error) {
            yield put(fetchCurrentUserFailure(result.error));
        } else {
            if (result.data!) {
                yield i18n.changeLanguage(result.data!.language!);
            }

            if (result.data!.serviceProviderGroups.length === 0) {
                const targetPath = hasAdminPermission(result.data!)
                    ? RouteUrl.Administration
                    : RouteUrl.UserConfigurationIssue;

                yield all([put(setCurrentUser(result.data!)), put(push({pathname: targetPath}))]);
            } else {
                yield put(fetchCurrentUserSuccess(result.data!));
            }
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
        yield put(fetchCurrentUserFailure('Something went wrong. Please try again or contact your admin.'));
    }
}

export function* handleUpdateCurrentUserSettings(
    action: ActionType<typeof updateCurrentUserSettingsRequest>,
) {
    try {
        const updateResult: ApiResult<User> = yield call(UsersApi.updateCurrentUserSettings, action.payload.settings);

        if (updateResult.error) {
            yield put(updateCurrentUserSettingsFailure(updateResult.error));
        } else {
            // yield put(fetchCurrentUserSuccess(updateResult.data!));

            const serviceProviderGroupResult: ApiResult<ServiceProviderGroupWithConfiguration> = yield call(
                ServiceProviderGroupsApi.getServiceProviderGroupWithConfiguration,
                action.payload.serviceProviderGroupId,
            );
            const t: TFunction = i18n.t.bind(i18n);
            if (serviceProviderGroupResult.error) {
                yield call(handleUnexpectedErrorWithToast, serviceProviderGroupResult.error);
            } else {
                yield i18n.changeLanguage(updateResult.data!.language!);
                yield put(fetchSelectedServiceProviderGroupSuccess(serviceProviderGroupResult.data!));
                yield put(updateCurrentUserSettingsSuccess({
                    preferredCurrency: updateResult.data!.preferredCurrency,
                    language: updateResult.data!.language
                }));
                yield put(
                    addToast({
                        id: generateUUID(),
                        type: ToastType.Success,
                        messages: [t('Successfully updated user\'s profile settings')],
                    }),
                );
            }
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

function* handleFetchSelectedServiceProviderGroup(action: ActionType<typeof fetchSelectedServiceProviderGroupRequest>) {
    try {
        const serviceProviderGroupId: string = action.payload;
        const result: ApiResult<ServiceProviderGroupWithConfiguration> = yield call(
            ServiceProviderGroupsApi.getServiceProviderGroupWithConfiguration,
            serviceProviderGroupId,
        );

        if (result.error) {
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(fetchSelectedServiceProviderGroupSuccess(result.data!));
        }
    } catch (error) {
        yield call(handleUnexpectedErrorWithToast, error);
    }
}

function* watchRequests() {
    yield takeEvery(AuthenticationActionTypes.FEDERATED_LOGIN_REQUEST, handleFederatedLogin);
    yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
    yield takeEvery(AuthenticationActionTypes.LOGOUT_REQUEST, handleLogout);
    yield takeEvery(AuthenticationActionTypes.FORGOT_PASSWORD_REQUEST, handleForgotPassword);
    yield takeEvery(AuthenticationActionTypes.CONFIRM_RESET_PASSWORD_REQUEST, handleConfirmResetPassword);
    yield takeEvery(AuthenticationActionTypes.FETCH_CURRENT_USER_REQUEST, handleFetchCurrentUser);
    yield takeEvery(AuthenticationActionTypes.FETCH_SELECTED_SERVICE_PROVIDER_NETWORK_REQUEST, handleFetchSelectedServiceProviderGroup);
    yield takeEvery(
        AuthenticationActionTypes.UPDATE_CURRENT_USER_SETTINGS_REQUEST,
        handleUpdateCurrentUserSettings,
    );
}

function* authenticationSaga() {
    yield all([fork(watchRequests)]);
}

export default authenticationSaga;
