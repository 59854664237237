import {TFunction} from 'i18next';
import {RouteUrl} from '../../routes';

export interface ReportCatalogEntry {
    type: ReportType;
    route: RouteUrl;
    icon: ReportIcon;
    order:number;
}

export enum ReportType {
    InspectionDetails = 'InspectionDetails',
    TCUSensorFitments = 'TCUSensorFitments',
    Casings = 'Casings',
    ExtendedInspection = 'ExtendedInspection',
    VehiclesDueForInspectionDetails = 'VehiclesDueForInspectionDetails',
    TireStock = 'TireStock',
    CustomerServiceLocations = 'CustomerServiceLocations',
}

export enum ReportIcon {
    truck,
    tire,
    inspection,
    jobs,
    due,
    stock,
    location,
}

export class ReportCatalog {
    private entries: ReportCatalogEntry[] = [
        {
            type: ReportType.Casings,
            route: RouteUrl.CasingsReport,
            icon: ReportIcon.tire,
            order:0
        },
        {
            type: ReportType.ExtendedInspection,
            route: RouteUrl.ExtendedInspectionReport,
            icon: ReportIcon.inspection,
            order:1
        },
        {
            type: ReportType.InspectionDetails,
            route: RouteUrl.InspectionDetailsReport,
            icon: ReportIcon.inspection,
            order:2
        },
        {
            type: ReportType.VehiclesDueForInspectionDetails,
            route: RouteUrl.VehiclesDueForInspectionReport,
            icon: ReportIcon.due,
            order:3
        },
        {
            type: ReportType.TireStock,
            route: RouteUrl.TireStockReport,
            icon: ReportIcon.stock,
            order:4
        },
        {
            type: ReportType.TCUSensorFitments,
            route: RouteUrl.TCUSensorFitmentsReport,
            icon: ReportIcon.tire,
            order:5
        },
        {
            type: ReportType.CustomerServiceLocations,
            route: RouteUrl.CustomerServiceLocationsReport,
            icon: ReportIcon.location,
            order:6
        },
    ];

    public getAllReports(): ReportCatalogEntry[] {
        return this.entries.sort((a, b) => a.order-b.order);
    }

    public getCatalogEntry(type: ReportType): ReportCatalogEntry {
        const entry = this.entries.find((i) => i.type === type);
        if (!entry) throw new Error(`No report catalog item found for ${type}`);
        return entry;
    }
}

export function getReportName(reportType: ReportType,t:TFunction): string {
    switch (reportType) {
        case ReportType.InspectionDetails:
            return t('Inspection Details');
        case ReportType.ExtendedInspection:
            return t('Extended Inspection');
        case ReportType.VehiclesDueForInspectionDetails:
            return t('Vehicles Due For Inspection');
        case ReportType.Casings:
            return t('Casings');
        case ReportType.TireStock:
            return t('Tire Stock');
        case ReportType.TCUSensorFitments:
            return t('TCU & Sensor Fitments');
        case ReportType.CustomerServiceLocations:
            return t('Customer Service Locations');
        default:
            throw new Error(`No mapping found for ReportType: ${reportType}`);
    }
}

export function getReportDescription(reportType: ReportType,t:TFunction): string {
    switch (reportType) {
        case ReportType.InspectionDetails:
            return t('Details on the inspections');
        case ReportType.ExtendedInspection:
            return t('Details on vehicle inspection & service');
        case ReportType.VehiclesDueForInspectionDetails:
            return t('Details on vehicles due for inspection');
        case ReportType.Casings:
            return t('Details on casings');
        case ReportType.TireStock:
            return t('Reusable tire stock per dealer');
        case ReportType.TCUSensorFitments:
            return t('Details on TCU and sensor fitments');
        case ReportType.CustomerServiceLocations:
            return t('Details on Customer Service Locations');
        default:
            throw new Error(`No mapping found for ReportType: ${reportType}`);
    }
}
