import {Reducer} from 'redux';
import {ServiceProviderAction, ServiceProviderActionTypes, ServiceProviderState} from './types';
import {RequestStatus, RequestStatusType, SharedActionTypes} from '../shared/types';

function initialState(): ServiceProviderState {
    return {
        serviceProviderMappingsRequestStatus: new RequestStatus(RequestStatusType.New),
        serviceProvidersForGroupRequestStatus: new RequestStatus(RequestStatusType.New),
    };
}

const reducer: Reducer<ServiceProviderState, ServiceProviderAction> = (state = initialState(), action): ServiceProviderState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_SERVICE_PROVIDER_GROUP: {
            return initialState();
        }
        case ServiceProviderActionTypes.GET_SERVICE_PROVIDER_MAPPINGS_REQUEST: {
            return {...state, serviceProviderMappingsRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ServiceProviderActionTypes.GET_SERVICE_PROVIDER_MAPPINGS_SUCCESS: {
            return {
                ...state,
                serviceProviderMappingsRequestStatus: new RequestStatus(RequestStatusType.Success),
                serviceProviderMappings: {...action.payload}
            };
        }
        case ServiceProviderActionTypes.GET_SERVICE_PROVIDER_MAPPINGS_FAILURE: {
            return {...state, serviceProviderMappingsRequestStatus: new RequestStatus(RequestStatusType.Failed)}
        }
        case ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_FOR_GROUP_REQUEST: {
            return {...state, serviceProvidersForGroupRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_FOR_GROUP_SUCCESS: {
            return {...state, serviceProvidersForGroup: action.payload, serviceProvidersForGroupRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_FOR_GROUP_FAILURE: {
            return {...state, serviceProvidersForGroupRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)};
        }
        case ServiceProviderActionTypes.GET_SERVICE_PROVIDER_DETAILS_REQUEST: {
            return {...state, serviceProviderMappingsRequestStatus: new RequestStatus(RequestStatusType.InProgress), serviceProviderDetails: undefined};
        }
        case ServiceProviderActionTypes.GET_SERVICE_PROVIDER_DETAILS_SUCCESS: {
            return {...state, serviceProviderMappingsRequestStatus: new RequestStatus(RequestStatusType.Success), serviceProviderDetails: action.payload};
        }
        case ServiceProviderActionTypes.GET_SERVICE_PROVIDER_DETAILS_FAILURE: {
            return {...state, serviceProviderMappingsRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)};
        }
        case ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_REQUEST: {
            return {...state, serviceProvidersRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_SUCCESS: {
            return {...state, serviceProviders: action.payload, serviceProvidersRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_FAILURE: {
            return {...state, serviceProvidersRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)};
        }

        default: {
            return state;
        }
    }
};

export {reducer as serviceProviderReducer};
export {initialState as initialServiceProviderState};
