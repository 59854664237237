import React, {Component, RefObject} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './date-range-selector.module.scss';
import {addYears, formatDateInBrusselsTime, subYears} from '../../../utils/date-helper';


class DateRangeSelectorComponent extends Component<AllProps, AllState> {
    private toInput: RefObject<HTMLInputElement>;

    private fromInput: RefObject<HTMLInputElement>;

    constructor(props) {
        super(props);
        this.toInput = React.createRef();
        this.fromInput = React.createRef();
        this.state = {
            // fromDateSelection: DateSelection.Date,
            // toDateSelection: DateSelection.Date,
            fromMinDate: new Date(2000, 0, 1),
            fromMaxDate: new Date(),
            toMinDate: subYears(new Date(), 1),
            toMaxDate: new Date(),
        }
    }

    public render(): JSX.Element {
        const {t, earliestDate, latestDate} = this.props;
        const {
            // fromDateSelection, toDateSelection,
            fromMinDate, fromMaxDate, toMinDate, toMaxDate
        } = this.state;

        return (
            <div>
                <div className={styles.dateSelect}>
                    From
                    <label htmlFor="fromDate">
                        {/* <input type="radio" id="fromDate"
                               checked={fromDateSelection === DateSelection.Date}
                               onChange={() => this.onFromDateSelectionChanged(DateSelection.Date)}
                        /> */}
                        <div className={styles.dateInput}>
                            <input type="date"
                                   min={formatDateInBrusselsTime(fromMinDate, 'YYYY-MM-DD')}
                                   max={formatDateInBrusselsTime(fromMaxDate, 'YYYY-MM-DD')}
                                   className={styles.fieldInputSmall}
                                   ref={this.fromInput}
                                   onChange={(event: any) => this.onFromDateChanged(event)}/>
                            {earliestDate ? <div className={styles.dateLink}
                                                 onClick={() => this.setEarliestDate()}>{t('Earliest date')}</div> : ''}

                        </div>
                    </label>
                    {/*  <label htmlFor="fromEarliest">
                        <input type="radio" id="fromEarliest"
                               checked={fromDateSelection === DateSelection.Fixed}
                               onChange={() => this.onFromDateSelectionChanged(DateSelection.Fixed)}
                        />{t('Earliest Date')}
                    </label> */}
                </div>
                <div className={styles.dateSelect}>
                    To
                    <label htmlFor="toDate">
                        {/* <input type="radio" id="toDate"
                               checked={toDateSelection === DateSelection.Date}
                               onChange={() => this.onToDateSelectionChanged(DateSelection.Date)}
                        /> */}
                        <div className={styles.dateInput}>
                            <input type="date"
                                   min={formatDateInBrusselsTime(toMinDate, 'YYYY-MM-DD')}
                                   max={formatDateInBrusselsTime(toMaxDate, 'YYYY-MM-DD')}
                                   className={styles.fieldInputSmall}
                                   ref={this.toInput}
                                   onChange={(event: any) => this.onToDateChanged(event)}/>
                            {latestDate ? <div className={styles.dateLink}
                                               onClick={() => this.setLatestDate()}>{t('Latest date')}</div> : ''}
                        </div>
                    </label>
                    {/* <label htmlFor="toLatest">
                        <input type="radio" id="toLatest"
                               checked={toDateSelection === DateSelection.Fixed}
                               onChange={() => this.onToDateSelectionChanged(DateSelection.Fixed)}
                        />{t('Latest Date')}
                    </label> */}
                </div>
            </div>
        );
    }

    private setEarliestDate() {
        const {earliestDate} = this.props;
        if (earliestDate) {
            if (this.fromInput && this.fromInput.current) {
                this.fromInput.current.value = formatDateInBrusselsTime(earliestDate, 'YYYY-MM-DD')
                this.changeFromDate(earliestDate);
            }
        }
    }

    private setLatestDate() {
        const {latestDate} = this.props;
        if (latestDate) {
            if (this.toInput && this.toInput.current) {
                this.toInput.current.value = formatDateInBrusselsTime(latestDate, 'YYYY-MM-DD')
                this.changeToDate(latestDate);
            }
        }
    }

    private onFromDateChanged(event: any) {
        const date = event.target.value;
        if (date) {
            const fromDate = new Date(date)
            this.changeFromDate(fromDate);
        }
    }

    private changeFromDate(fromDate: Date) {
        const {fromDateChanged, toDateChanged} = this.props;
        fromDateChanged(fromDate);
        const oneYearAfterFrom = addYears(fromDate, 1);
        const toMaxDate = oneYearAfterFrom > new Date() ? new Date() : oneYearAfterFrom;
        if (this.toInput && this.toInput.current && this.toInput.current.valueAsDate) {
            const toDate = this.toInput.current.valueAsDate;
            if (toDate > toMaxDate) {
                this.toInput.current.value = formatDateInBrusselsTime(toMaxDate, 'YYYY-MM-DD')
                toDateChanged(toMaxDate);
            } else if (toDate < fromDate) {
                this.toInput.current.value = formatDateInBrusselsTime(fromDate, 'YYYY-MM-DD')
            }
        }
        this.setState({toMinDate: fromDate, fromMinDate: this.getMinDate(), toMaxDate})
    }

    private getMinDate(): Date {
        const {earliestDate} = this.props;
        return earliestDate || new Date(2000, 0, 1);
    }

    private onToDateChanged(event: any) {
        const date = event.target.value;
        if (date) {
            const toDate = new Date(date)
            this.changeToDate(toDate);
        }
    }

    private changeToDate(toDate: Date) {
        const {fromDateChanged, toDateChanged} = this.props;
        toDateChanged(toDate);
        const oneYearBeforeTo = subYears(toDate, 1);
        const fromMinDate = oneYearBeforeTo;
        if (this.fromInput && this.fromInput.current && this.fromInput.current.valueAsDate) {
            const fromDate = this.fromInput.current.valueAsDate;
            if (fromDate < fromMinDate) {
                this.fromInput.current.value = formatDateInBrusselsTime(fromMinDate, 'YYYY-MM-DD')
                fromDateChanged(fromMinDate);
            } else if (fromDate > toDate) {
                this.fromInput.current.value = formatDateInBrusselsTime(toDate, 'YYYY-MM-DD')
            }
        }
        this.setState({fromMaxDate: toDate, toMaxDate: new Date(), fromMinDate})
    }

    /* private onFromDateSelectionChanged(selection: DateSelection) {
        const {fromDateChanged} = this.props;
        this.setState({
            fromDateSelection: selection
        });

        if (selection === DateSelection.Fixed) {
            fromDateChanged(subYears(new Date(), 1));
        }
    }

    private onToDateSelectionChanged(selection: DateSelection) {
        const {toDateChanged} = this.props;
        this.setState({
            toDateSelection: selection
        });

        if (selection === DateSelection.Fixed) {
            toDateChanged(new Date());
        }
    } */
}

export default withTranslation()(DateRangeSelectorComponent);

interface OwnProps {
    earliestDate?: Date;
    latestDate?: Date;
    fromDateChanged: (fromDate: Date) => void;
    toDateChanged: (toDate: Date) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    // fromDateSelection: DateSelection,
    // toDateSelection: DateSelection,
    fromMinDate: Date,
    fromMaxDate: Date,
    toMinDate: Date,
    toMaxDate: Date,
}

type AllState = OwnState;
