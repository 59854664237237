import {TopSoldTosData} from '../../../../store/analytics/types/top-sold-tos-types';
import {groupBy} from "../../../../utils/collection-helper";

export const soldToIdField = 'soldToIdField';
export const soldToNameField = 'soldToNameField';
export const soldToInspectionCountField = 'soldToNamesoldToInspectionCountFieldField';

export function buildChartData(data: TopSoldTosData[]): any[] {
    const datapoints: any[] = [];
    const byName = groupBy(data, 'name');
    byName.forEach((rows, name) => {
        const datapoint = {};
        datapoint[soldToIdField] = rows[0].id;
        datapoint[soldToNameField] = name.toUpperCase();
        datapoint[soldToInspectionCountField] = rows.reduce((acc, row) => acc + row.inspectionCount, 0);
        datapoints.push(datapoint);
    });
    return datapoints.reverse();
}
