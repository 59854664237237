import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import i18n, {TFunction} from 'i18next';
import ServiceProviderGroupsApi from '../../api/service-provider-groups-api';
import { ApiResult } from '../../types/api-result';
import { generateUUID } from '../../utils/uuid-helpers';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import { addToast, ToastType } from '../toast';
import {
    addServiceProviderGroupSuccess,
    createServiceProviderGroupRequest,
    deleteServiceProviderGroupRequest,
    deleteServiceProviderGroupSuccess,
    getServiceProviderGroupsRequest,
    getServiceProviderGroupsSuccess,
    getServiceProviderGroupWithConfigurationAndUsersRequest,
    getServiceProviderGroupWithConfigurationAndUsersSuccess,
    searchServiceProviderGroupsFailure,
    searchServiceProviderGroupsRequest, searchServiceProviderGroupsSuccess,
    updateServiceProviderGroupRequest,
    updateServiceProviderGroupSuccess,
} from './actions';
import {
    ServiceProviderGroup,
    ServiceProviderGroupActionTypes,
    ServiceProviderGroupConfiguration, ServiceProviderGroupsSearchRequest,
    ServiceProviderGroupWithConfigurationAndUsers,
    UpdateServiceProviderGroupRequest,
} from './types';
import { Paged } from '../shared/types';

function* handleFailure(err: any) {
    yield call(handleUnexpectedErrorWithToast, err);
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleCreate(action: ActionType<typeof createServiceProviderGroupRequest>) {
    try {
        const serviceProviderGroupRequest = action.payload;
        const result: ApiResult<ServiceProviderGroup> = yield call(
            ServiceProviderGroupsApi.createServiceProviderGroup,
            serviceProviderGroupRequest,
        );
        const t:TFunction = i18n.t.bind(i18n);
        if (result.error) {
            yield call(handleFailure, result.error);
        } else {
            yield put(addServiceProviderGroupSuccess(result.data!));
            yield put(
                addToast({
                    id: generateUUID(),
                    type: ToastType.Success,
                    messages: [t('Successfully added Service Provider Group')],
                }),
            );
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleDelete(action: ActionType<typeof deleteServiceProviderGroupRequest>) {
    try {
        const serviceProviderGroup: ServiceProviderGroup = action.payload;
        const result: ApiResult<ServiceProviderGroup> = yield call(ServiceProviderGroupsApi.deleteServiceProviderGroup, serviceProviderGroup);

        if (result.error) {
            yield call(handleFailure, result.error);
        } else {
            yield put(deleteServiceProviderGroupSuccess(serviceProviderGroup));
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleUpdate(action: ActionType<typeof updateServiceProviderGroupRequest>) {
    try {
        const request: UpdateServiceProviderGroupRequest = action.payload;

        const result: ApiResult<ServiceProviderGroupConfiguration> = yield call(
            ServiceProviderGroupsApi.updateServiceProviderGroup,
            request.serviceProviderGroupId,
            request,
        );
        const t:TFunction = i18n.t.bind(i18n);
        if (result.error) {
            yield call(handleFailure, result.error);
        } else {
            yield put(updateServiceProviderGroupSuccess(request));
            yield put(
                addToast({
                    id: generateUUID(),
                    type: ToastType.Success,
                    messages: [t('Successfully updated Service Provider Group')],
                }),
            );
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleGetWithConfigurationAndUsers(action: ActionType<typeof getServiceProviderGroupWithConfigurationAndUsersRequest>) {
    try {
        const serviceProviderGroupId: string = action.payload;

        const result: ApiResult<ServiceProviderGroupWithConfigurationAndUsers> = yield call(
            ServiceProviderGroupsApi.getServiceProviderGroupWithConfiguration,
            serviceProviderGroupId,
        );

        if (result.error) {
            yield call(handleFailure, result.error);
        } else {
            yield put(getServiceProviderGroupWithConfigurationAndUsersSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleGetAll(action: ActionType<typeof getServiceProviderGroupsRequest>) {
    try {
        const result: ApiResult<ServiceProviderGroup[]> = yield call(ServiceProviderGroupsApi.getServiceProviderGroups);

        if (result.error) {
            yield call(handleFailure, result.error);
        } else {
            yield put(getServiceProviderGroupsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

export function* handleSearch(action: ActionType<typeof searchServiceProviderGroupsRequest>) {
    try {
        const request: ServiceProviderGroupsSearchRequest = action.payload;
        const result: ApiResult<Paged<ServiceProviderGroup>> = yield call(ServiceProviderGroupsApi.searchServiceProviderGroups, request);

        if (result.error) {
            yield call(searchServiceProviderGroupsFailure, result.error);
        } else {
            yield put(searchServiceProviderGroupsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

function* watchRequests() {
    yield takeEvery(ServiceProviderGroupActionTypes.CREATE_SERVICE_PROVIDER_GROUP_REQUEST, handleCreate);
    yield takeEvery(ServiceProviderGroupActionTypes.DELETE_SERVICE_PROVIDER_GROUP_REQUEST, handleDelete);
    yield takeEvery(ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUPS_REQUEST, handleGetAll);
    yield takeEvery(ServiceProviderGroupActionTypes.UPDATE_SERVICE_PROVIDER_GROUP_REQUEST, handleUpdate);
    yield takeEvery(ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS_REQUEST, handleGetWithConfigurationAndUsers);
    yield takeEvery(ServiceProviderGroupActionTypes.SEARCH_SERVICE_PROVIDER_GROUPS_REQUEST, handleSearch);
}

function* serviceProviderGroupsSaga() {
    yield all([fork(watchRequests)]);
}

export default serviceProviderGroupsSaga;
