import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './redraw-mask.module.scss';

class RedrawMask extends Component<AllProps> {

    public render(): JSX.Element {
        const {t} = this.props;

        return (
            <div className={styles.redrawContainer}>
                <div className={styles.redrawMask}/>
                <div className={styles.redrawVisuals} onClick={() => this.onRedrawTriggered()}>
                    <div className={styles.redrawBar}>{t('Click below to update')}</div>
                    <div className={styles.redrawCaption}>
                        <span className={styles.captionText}>{t('Click here to update...')}</span>
                    </div>
                </div>
            </div>
        );
    }

    private onRedrawTriggered() {
        const {onRedrawTriggered} = this.props;
        onRedrawTriggered();
    }
}

export default withTranslation()(RedrawMask);

interface OwnProps {
    onRedrawTriggered: ()=>void;
    isRedrawing: boolean;
}

type AllProps = OwnProps & WithTranslation;
