import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ServiceProviderGroup } from '../../../../store/service-provider-groups';
import styles from './service-provider-group-selection-control.module.scss';

class ServiceProviderGroupSelectionControl extends Component<AllProps, OwnState> {
    constructor(props) {
        super(props);
        const { serviceProviderGroups } = this.props;

        this.state = {
            caption: this.getCaption(serviceProviderGroups),
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const { serviceProviderGroups } = this.props;

        if (serviceProviderGroups !== prevProps.serviceProviderGroups) {
            this.setState({ caption: this.getCaption(serviceProviderGroups) });
        }
    }

    public render(): JSX.Element {
        const { onChange, t,canChange } = this.props;
        const { caption } = this.state;
        return (
            <>
                <div className={styles.serviceProviderGroupSelectionControl} onClick={() => onChange()}>
                    {caption}
                </div>
                <div className={styles.serviceProviderGroupSelectionLink} onClick={() => onChange()}>
                    {canChange ? t('change') : t('view')}
                </div>
            </>
        );
    }

    private getCaption(serviceProviderGroups?: ServiceProviderGroup[]): string {
        const { t } = this.props;

        if (!serviceProviderGroups) {
            return t('None');
        } else if (serviceProviderGroups.length === 1) {
            return serviceProviderGroups[0].name;
        } else {
            return t('{{itemCount}} items selected', { itemCount: serviceProviderGroups.length });
        }
    }
}

export default withTranslation()(ServiceProviderGroupSelectionControl);

interface OwnProps {
    serviceProviderGroups?: ServiceProviderGroup[];
    canChange: boolean;
    onChange: () => void;
}

interface OwnState {
    caption: string;
}

type AllProps = OwnProps & WithTranslation;
