import {WithTranslation, withTranslation} from 'react-i18next';
import { Component } from 'react';
import styles from './table-header-search-panel.module.scss';
import {conditionalClassLister} from '../../../utils/class-helpers';

export class TableHeaderSearch extends Component<AllProps, OwnState> {

    constructor(props:AllProps) {
        super(props);

        const {filterText} = this.props;

        this.state = {
            text: filterText,
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {filterText} = this.props;
        if (prevProps.filterText !== filterText) {
            this.setState({text: filterText});
        }
    }

    public render(): JSX.Element {
        const {t, isOpen} = this.props;
        const {text} = this.state;

        const searchPanelClasses = conditionalClassLister(styles)({
            searchPanel: true,
            isOpen,
        });

        const invertedButtonClasses = conditionalClassLister(styles)({
            button: true,
            invert: true,
        });

        return (
            <div className={searchPanelClasses}>
                <div className={styles.searchContent}>
                    <div>{t('Contains')}:</div>
                    <div>
                        <input className={styles.filterInput}
                               type="text"
                               value={text}
                               onChange={e => this.onChange(e)}
                               onKeyUp={e => this.onKeyUp(e)} />
                    </div>
                    <div className={styles.buttons}>
                        <div className={invertedButtonClasses} onClick={() => this.onClear()}>{t('Clear')}</div>
                        <div className={styles.button} onClick={() => this.onApply()}>{t('Apply')}</div>
                    </div>
                </div>
            </div>
        );
    }

    private onChange(e) {
        this.setState({text: e.target.value});
    }

    private onKeyUp(e) {
        if (e.keyCode === 13) {
            this.onApply();
        }
    }

    public onApply():void {
        const {text} = this.state;
        const {onApply} = this.props;
        onApply(text);
    }

    public onClear():void {
        const {onApply} = this.props;
        onApply('');
        this.setState({
            text: '',
        })
    }
}

export default withTranslation()(TableHeaderSearch);

interface OwnProps {
    isOpen: boolean;
    filterText: string;
    onApply: (string) => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    text: string;
}
