import {TFunction} from 'i18next';
import {DropdownOption} from '../../components/dropdown/dropdown';

export const LANGUAGE_EN = 'en';
export const LANGUAGE_DE = 'de';
export const LANGUAGE_FR = 'fr';
export const LANGUAGE_NL = 'nl';
export const LANGUAGE_PL = 'pl';
export const LANGUAGE_PT = 'pt';
export const LANGUAGE_ES = 'es';
export const LANGUAGE_IT = 'it';
export const LANGUAGE_CS = 'cs';
export const LANGUAGE_SL = 'sl';
export const LANGUAGE_SK = 'sk';
export const LANGUAGE_RO = 'ro';
export const LANGUAGE_HU = 'hu';
export const LANGUAGE_EL = 'el';
export const LANGUAGE_DA = 'da';
export const SUPPORTED_LANGUAGES = [LANGUAGE_EN, LANGUAGE_DE, LANGUAGE_FR, LANGUAGE_NL, LANGUAGE_PL, LANGUAGE_PT, LANGUAGE_ES, LANGUAGE_IT,LANGUAGE_CS,LANGUAGE_SL,LANGUAGE_SK,LANGUAGE_RO,LANGUAGE_HU,LANGUAGE_EL];
export const DEFAULT_LANGUAGE = LANGUAGE_EN;

export const getSupportedLanguageOptions = (t: TFunction, translate: boolean): DropdownOption[] => [
    {id: LANGUAGE_EN, label: translate ? t('English') : 'English'},
    {id: LANGUAGE_DE, label: translate ? t('German') : 'German'},
    {id: LANGUAGE_FR, label: translate ? t('French') : 'French'},
    {id: LANGUAGE_NL, label: translate ? t('Dutch') : 'Dutch'},
    {id: LANGUAGE_PL, label: translate ? t('Polish') : 'Polish'},
    {id: LANGUAGE_PT, label: translate ? t('Portuguese') : 'Portuguese'},
    {id: LANGUAGE_ES, label: translate ? t('Spanish') : 'Spanish'},
    {id: LANGUAGE_IT, label: translate ? t('Italian') : 'Italian'},
    {id: LANGUAGE_CS, label: translate ? t('Czech') : 'Czech'},
    {id: LANGUAGE_SL, label: translate ? t('Slovenian') : 'Slovenian'},
    {id: LANGUAGE_SK, label: translate ? t('Slovak') : 'Slovak'},
    {id: LANGUAGE_RO, label: translate ? t('Romanian') : 'Romanian'},
    {id: LANGUAGE_HU, label: translate ? t('Hungarian') : 'Hungarian'},
    {id: LANGUAGE_EL, label: translate ? t('Greek') : 'Greek'},
    {id: LANGUAGE_DA, label: translate ? t('Danish') : 'Danish'},
];
