import {ApiResult} from '../types/api-result';
import {
    GlobalServiceProvider,
    ServiceProvider,
    ServiceProviderDetails,
    ServiceProviderMapping,
    ServiceProviderRequest,
} from '../store/service-providers';
import {Http} from '../http/http';
import {ApiUrl, UrlParamBuilder} from './api-urls';
import {Base64Data, Paged} from '../store/shared/types';
import {performBase64Download} from '../utils/download-helper';

class ServiceProvidersApi {

    public static async getAllMappingsForServiceProviderGroup(serviceProviderGroupId: string): Promise<ApiResult<ServiceProviderMapping>> {
        const data = await Http.get<ServiceProviderMapping>(ApiUrl.ServiceProviderMappings,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId.toString()).build());
        return {data};
    }

    public static async getAllForGroup(serviceProviderGroupId: string): Promise<ApiResult<ServiceProvider[]>> {
        const data = await Http.get<ServiceProvider[]>(ApiUrl.ServiceProvidersForGroup,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());
        return {data};
    }

    public static async getById(serviceProviderGroupId: string, serviceProviderId: string): Promise<ApiResult<ServiceProviderDetails>> {
        const data = await Http.get<ServiceProviderDetails>(ApiUrl.ServiceProviderDetails,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).addServiceProviderId(serviceProviderId).build());
        return {data};
    }

    public static async getAll(request: ServiceProviderRequest): Promise<ApiResult<Paged<GlobalServiceProvider>>> {
        const pagedServiceProviders = await Http.post<ServiceProviderRequest, Paged<GlobalServiceProvider>>(ApiUrl.ServiceProviders, request);
        return {data: {pageData: pagedServiceProviders.pageData.map(sp => sp), totalCount: pagedServiceProviders.totalCount}};
    }

    public static async downloadServiceProviderGroupStructure(serviceProviderGroupId: string, serviceProviderGroupName: string): Promise<void> {
        const result= await Http.get<Base64Data>(ApiUrl.ServiceProviderStructure,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());
        performBase64Download(result.data,'vnd.openxmlformats-officedocument.spreadsheetml.sheet', `Service provider structure - ${serviceProviderGroupName}.xlsx`);
    }

    public static async downloadServiceProviderGroupsStructure(): Promise<void> {
        const result = await Http.get<Base64Data>(ApiUrl.ServiceProvidersStructure);
        performBase64Download(result.data,'vnd.openxmlformats-officedocument.spreadsheetml.sheet', `Service provider structure - All.xlsx`);
    }
}

export default ServiceProvidersApi;
