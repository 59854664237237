import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {generateUUID} from '../../../utils/uuid-helpers';
import styles from './sold-tos-selector.module.scss';
import SoldTosSelector from '../../analytics/dashboard-controls/sold-tos-selector/sold-tos-selector';
import {SoldToSelectionsControlValue} from '../../analytics/dashboard-controls/sold-tos-selector/types/sold-tos-selections-control-value';

class SoldTosSelectorComponent extends Component<AllProps, AllState> {

    private soldToSelection?: SoldToSelectionsControlValue;

    constructor(props) {
        super(props);

        const {defaultSoldToSelection} = this.props;
        this.soldToSelection = defaultSoldToSelection || undefined;
    }

    public render(): JSX.Element {
        return (
            <div className={styles.selectorPanel}>
                <SoldTosSelector
                    onValueChange={(value => this.soldToValueChange(value))}
                    defaultValue={this.soldToSelection}
                    setControlRef={() => this.setControlRef()}
                    key={generateUUID()}/>
            </div>
        );
    }

    private setControlRef() {
    }

    private soldToValueChange(controlValue: SoldToSelectionsControlValue) {
        const {onSoldToSelectionChange} = this.props;
        this.soldToSelection = controlValue;
        onSoldToSelectionChange(controlValue);
    }
}

export default withTranslation()(SoldTosSelectorComponent);

interface OwnProps {
    defaultSoldToSelection?: SoldToSelectionsControlValue;
    onSoldToSelectionChange: (soldToSelections: SoldToSelectionsControlValue) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
}

type AllState = OwnState;
