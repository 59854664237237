import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Route, RouteComponentProps} from 'react-router-dom';
import {Dispatch} from 'redux';
import AnalyticsPage from '../../pages/analytics/analytics.page';
import ProfilePage from '../../pages/profile/profile.page';
import {RouteUrl} from '../../routes';
import {ApplicationState} from '../../store';
import {fetchSelectedServiceProviderGroupRequest, User} from '../../store/authentication';
import {ServiceProviderGroupWithConfiguration} from '../../store/service-provider-groups';
import {getServiceProvidersForGroupRequest, ServiceProvider} from '../../store/service-providers';
import {ServiceProviderGroupMatchParameters} from '../../types/service-provider-group';
import JobsPage from '../../pages/jobs/jobs.page';
import {getSoldTosRequest, SoldTo} from '../../store/sold-tos';
import InspectionDetailsReportPage
    from '../../pages/reports/reports/inspection-details-report/inspection-details-report.page';
import ReportsPage from '../../pages/reports/reports.page';
import ReportSchedulerPage from '../../pages/reports/report-scheduler/report-scheduler.page';
import ExtendedInspectionReportPage
    from '../../pages/reports/reports/extended-inspection-report/extended-inspection-report.page';
import VehiclesDueForInspectionReportPage
    from '../../pages/reports/reports/vehicles-due-for-inpsection-report/vehicles-due-for-inspection-report.page';
import CasingsReportPage from '../../pages/reports/reports/casings-report/casings-report.page';
import {RequestStatus} from '../../store/shared/types';
import ReportDownloadPage from '../../pages/reports/report-download/report-download.page';
import TireStockReportPage from "../../pages/reports/reports/tire-stock-report/tire-stock-report.page";
import TcuSensorFitmentsReportPage
    from "../../pages/reports/reports/tcu-sensor-fitments-report/tcu-sensor-fitments-report.page";
import CustomerServiceLocationsReportPage
    from "../../pages/reports/reports/customer-service-locations-report/customer-service-locations-report.page";

class ServiceProviderGroupBoundary extends Component<AllProps> {
    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<any>, snapshot?: any): void {
        const {
            serviceProviderGroup,
            user,
            match,
            dispatchGetSelectedServiceProviderGroup,
            dispatchNavigateTo,
            serviceProviders,
            serviceProvidersForGroupRequestStatus,
            dispatchGetServiceProvidersForGroup,
            dispatchGetSoldTos,
            soldTosRequestStatus
        } = this.props;
        const {serviceProviderGroupId} = match.params;

        // we obviously need a user...
        if (user) {
            if (serviceProviderGroupId && !serviceProviderGroup) {
                // set service provider group (or go to selection screen)
                if (user.serviceProviderGroups && user.serviceProviderGroups.find((fc) => fc.id === serviceProviderGroupId)) {
                    dispatchGetSelectedServiceProviderGroup(serviceProviderGroupId);
                } else {
                    dispatchNavigateTo({pathname: RouteUrl.ServiceProviderGroupSelection});
                }
            } else if (serviceProviderGroup !== prevProps.serviceProviderGroup || !serviceProviders) {
                if (serviceProviderGroup && !serviceProviderGroup.dataAvailable) {
                    // Navigate away if there is no data available
                    dispatchNavigateTo({pathname: RouteUrl.ServiceProviderGroupNoDataIssue});
                } else {
                    // Get all service provider group specific data
                    if (!serviceProvidersForGroupRequestStatus.isInProgress) {
                        dispatchGetServiceProvidersForGroup(serviceProviderGroupId);
                    }

                    if (!soldTosRequestStatus.isInProgress) {
                        dispatchGetSoldTos(serviceProviderGroupId);
                    }
                }
            }
        }
    }

    public render(): JSX.Element {
        const {match} = this.props;

        return (
            <>
                <Route path={`${match.path}${RouteUrl.Analytics}`} component={AnalyticsPage}/>
                <Route path={`${match.path}${RouteUrl.Jobs}`} component={JobsPage}/>
                <Route path={`${match.path}${RouteUrl.Profile}`} component={ProfilePage}/>
                <Route
                    path={`${match.path}${RouteUrl.InspectionDetailsReport}`}
                    component={InspectionDetailsReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.ExtendedInspectionReport}`}
                    component={ExtendedInspectionReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.VehiclesDueForInspectionReport}`}
                    component={VehiclesDueForInspectionReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.CasingsReport}`}
                    component={CasingsReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.TireStockReport}`}
                    component={TireStockReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.TCUSensorFitmentsReport}`}
                    component={TcuSensorFitmentsReportPage}
                />
                <Route
                    path={`${match.path}${RouteUrl.CustomerServiceLocationsReport}`}
                    component={CustomerServiceLocationsReportPage}
                />
                <Route exact path={`${match.path}${RouteUrl.Reports}`} component={ReportsPage}/>
                <Route exact path={`${match.path}${RouteUrl.ReportScheduler}`} component={ReportSchedulerPage}/>
                <Route
                    path={`${match.path}${RouteUrl.ReportDownload}`}
                    component={ReportDownloadPage}
                />
            </>
        );
    }
}

const mapStateToProps = ({authentication, serviceProvider, soldTos}: ApplicationState): PropsFromState => ({
    user: authentication.user,
    serviceProviderGroup: authentication.serviceProviderGroup,
    serviceProvidersForGroupRequestStatus: serviceProvider.serviceProvidersForGroupRequestStatus,
    serviceProviders: serviceProvider.serviceProvidersForGroup,
    soldTos: soldTos.soldTos,
    soldTosRequestStatus: soldTos.soldTosRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
    dispatchGetSelectedServiceProviderGroup: (serviceProviderGroupId: string) =>
        dispatch(fetchSelectedServiceProviderGroupRequest(serviceProviderGroupId)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchGetServiceProvidersForGroup: (serviceProviderGroupId: string) => dispatch(getServiceProvidersForGroupRequest(serviceProviderGroupId)),
    dispatchGetSoldTos: (serviceProviderGroupId: string) => dispatch(getSoldTosRequest(serviceProviderGroupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProviderGroupBoundary);

interface PropsFromState {
    user?: User;
    serviceProviderGroup?: ServiceProviderGroupWithConfiguration;
    serviceProvidersForGroupRequestStatus: RequestStatus
    serviceProviders?: ServiceProvider[];
    soldTos?: SoldTo[];
    soldTosRequestStatus: RequestStatus
}

interface PropsFromDispatch {
    dispatchGetSelectedServiceProviderGroup: typeof fetchSelectedServiceProviderGroupRequest;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchGetServiceProvidersForGroup: typeof getServiceProvidersForGroupRequest;
    dispatchGetSoldTos: typeof getSoldTosRequest;
}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps<ServiceProviderGroupMatchParameters>;
