import { action } from 'typesafe-actions';
import {User, UserSummary} from '../authentication';
import { UsersActionTypes, UsersRequest } from './types';
import { Paged } from '../shared/types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const clearUsers = () => action(UsersActionTypes.CLEAR_USERS);
export const searchUsersRequest = (request: UsersRequest) => action(UsersActionTypes.SEARCH_USERS_REQUEST, request);
export const searchUsersSuccess = (users: Paged<UserSummary>) => action(UsersActionTypes.SEARCH_USERS_SUCCESS, users);
export const searchUsersFailure = (message: string) => action(UsersActionTypes.SEARCH_USERS_FAILURE, message);

export const getUserRequest = (id: string) => action(UsersActionTypes.GET_USER_REQUEST, id);
export const getUserSuccess = (user: User) => action(UsersActionTypes.GET_USER_SUCCESS, user);
export const getUserFailure = (message: string) => action(UsersActionTypes.GET_USER_FAILURE, message);

export const updateUserRequest = (user: User) => action(UsersActionTypes.UPDATE_USER_REQUEST, user);
export const updateUserSuccess = (user: User) => action(UsersActionTypes.UPDATE_USER_SUCCESS, user);
export const updateUserFailure = (message: string) => action(UsersActionTypes.UPDATE_USER_FAILURE, message);
