import DateRangeSelector from '../pages/analytics/dashboard-controls/date-range-selector/date-range-selector';
import {ControlType, DashboardControl} from '../store/dashboard';
import {Control} from '../pages/analytics/dashboard-controls/control';
import {ControlValue} from '../pages/analytics/dashboard-controls/controls-snapshot';
import ServiceProvidersSelector
    from '../pages/analytics/dashboard-controls/service-providers-selector/service-providers-selector';
import SoldTosSelector from '../pages/analytics/dashboard-controls/sold-tos-selector/sold-tos-selector';


export function renderControl(dashboardId: string,
                              dashboardControl: DashboardControl,
                              setControlRef: (control?: Control) => void,
                              valueChange: (value: ControlValue) => void,
                              defaultValue?: ControlValue): JSX.Element {
    switch (dashboardControl.type) {
        case ControlType.DateRange:
            return <DateRangeSelector onValueChange={valueChange}
                                      defaultValue={defaultValue}
                                      setControlRef={setControlRef}
                                      key={`${dashboardId}-${dashboardControl.key}`}/>;
        case ControlType.ServiceProviders:
            return <ServiceProvidersSelector onValueChange={valueChange}
                                   defaultValue={defaultValue}
                                   setControlRef={setControlRef}
                                   key={`${dashboardId}-${dashboardControl.key}`}/>;
        case ControlType.SoldTos:
            return <SoldTosSelector onValueChange={valueChange}
                                    defaultValue={defaultValue}
                                    setControlRef={setControlRef}
                                    key={`${dashboardId}-${dashboardControl.key}`}/>;
        default:
            throw new Error(`Dashboard control type ${dashboardControl.type} is not supported`);
    }
}
