import {
    ReportInfo,
    ReportDownloadUrl,
    ScheduledReport,
    InspectionDetailsReportRequest,
    ExtendedInspectionReportRequest,
    VehiclesDueForInspectionReportRequest,
    CasingsReportRequest,
    TireStockReportRequest,
    TireStockCompany,
    CustomerServiceLocationsReportRequest,
    TCUSensorFitmentsReportRequest,
} from '../store/reports';
import {ApiUrl, UrlParamBuilder} from './api-urls';
import {Http} from '../http/http';
import {ApiResult} from '../types/api-result';
import {Base64Data, Paged} from '../store/shared/types';
import {AdminScheduledReport, AdminScheduledReportRequest} from '../store/scheduled-report-management';
import {performBase64Download} from '../utils/download-helper';

class ReportsApi {
    public static async getReportInfo(reportId: string, serviceProviderGroupId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.get<ReportInfo>(ApiUrl.ReportsReportInfo,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).addReportId(reportId).build());
        return {data: result};
    }

    public static async getReportDownloadUrl(reportInfo: ReportInfo, serviceProviderGroupId: string): Promise<ApiResult<ReportDownloadUrl>> {
        const reportDownloadUrl = await Http.get<ReportDownloadUrl>(ApiUrl.ReportsDownloadUrl,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).addReportId(reportInfo.id).build());
        return {data: reportDownloadUrl};
    }

    public static async getScheduledReports(serviceProviderGroupId: string): Promise<ApiResult<ScheduledReport[]>> {
        const result = await Http.get<ScheduledReport[]>(ApiUrl.ReportsScheduledReports,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());
        return {data: result};
    }

    public static async addScheduledReport(request: ScheduledReport, serviceProviderGroupId: string): Promise<ApiResult<ScheduledReport>> {
        const result = await Http.post<ScheduledReport, ScheduledReport>(ApiUrl.ReportsScheduledReports,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());
        return {data: result};
    }

    public static async updateScheduledReport(request: ScheduledReport, serviceProviderGroupId: string): Promise<ApiResult<ScheduledReport>> {
        const result = await Http.put<ScheduledReport, ScheduledReport>(ApiUrl.ReportsScheduledReportsUpdate,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).addReportId(request.id!).build());
        return {data: result};
    }

    public static async deleteScheduledReport(reportId: string, serviceProviderGroupId: string): Promise<ApiResult<string>> {
        const result = await Http.delete<string>(ApiUrl.ReportsScheduledReportsUpdate,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).addReportId(reportId).build());
        return {data: result};
    }

    public static async getInspectionDetailsReport(request: InspectionDetailsReportRequest, serviceProviderGroupId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<InspectionDetailsReportRequest, ReportInfo>(ApiUrl.ReportsInspectionDetailsReport,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());
        return {data: result};
    }

    public static async getCustomerServiceLocationsReport(request: CustomerServiceLocationsReportRequest, serviceProviderGroupId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<CustomerServiceLocationsReportRequest, ReportInfo>(ApiUrl.ReportsCustomerServiceLocationsReport,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());
        return {data: result};
    }

    public static async getTCUSensorFitmentsReport(request: TCUSensorFitmentsReportRequest, serviceProviderGroupId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<TCUSensorFitmentsReportRequest, ReportInfo>(ApiUrl.ReportsTCUSensorFitmentsReport,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());
        return {data: result};
    }

    public static async getExtendedInspectionReport(request: ExtendedInspectionReportRequest, serviceProviderGroupId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<ExtendedInspectionReportRequest, ReportInfo>(ApiUrl.ReportsExtendedInspectionReport,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());
        return {data: result};
    }

    public static async getVehiclesDueForInspectionReport(request: VehiclesDueForInspectionReportRequest, serviceProviderGroupId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<VehiclesDueForInspectionReportRequest, ReportInfo>(ApiUrl.ReportsVehiclesDueForInspectionDetailsReport,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());
        return {data: result};
    }

    public static async getCasingsReport(request: CasingsReportRequest, serviceProviderGroupId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<CasingsReportRequest, ReportInfo>(ApiUrl.ReportsCasingsReport,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());
        return {data: result};
    }

    public static async getTireStockReport(request: TireStockReportRequest, serviceProviderGroupId: string): Promise<ApiResult<ReportInfo>> {
        const result = await Http.post<TireStockReportRequest, ReportInfo>(ApiUrl.ReportsTireStockReport,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());
        return {data: result};
    }

    public static async getTireStockCompanies(serviceProviderGroupId: string): Promise<ApiResult<TireStockCompany[]>> {
        const tireStockCompanies = await Http.get<TireStockCompany[]>(ApiUrl.ReportsTireStockCompanies,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());
        return {data: tireStockCompanies};
    }

    public static async getAdminScheduledReports(request: AdminScheduledReportRequest): Promise<ApiResult<Paged<AdminScheduledReport>>> {
        const pagedScheduledReports = await Http.post<any, Paged<AdminScheduledReport>>(ApiUrl.ReportsAdminScheduledReports, request);
        return {data: {pageData: pagedScheduledReports.pageData, totalCount: pagedScheduledReports.totalCount}};
    }

    public static async downloadScheduledReportLog(scheduledReportId: string, serviceProviderGroupId: string): Promise<void> {
        const result = await Http.get<Base64Data>(ApiUrl.ReportsScheduledReportsLog,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).addReportId(scheduledReportId).build());
        performBase64Download(result.data,"vnd.openxmlformats-officedocument.spreadsheetml.sheet", `Scheduled Report Log - ${scheduledReportId}.xlsx`);
    }
}

export default ReportsApi;
