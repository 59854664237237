import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dashboard } from '../../../store/dashboard';
import { conditionalClassLister } from '../../../utils/class-helpers';
import styles from './dashboard-menu-button.module.scss';

class DashboardMenuButton extends Component<AllProps, AllState> {
    private disableCloseOnBlur = false;

    constructor(props) {
        super(props);

        this.state = {
            isSubmenuOpen: false,
        };
    }

    public render(): JSX.Element {
        const { isActive, dashboard, t, showEditButtons } = this.props;
        const { isSubmenuOpen } = this.state;

        const menuButtonClasses = conditionalClassLister(styles)({
            menuButton: true,
            tabItemActive: isActive,
        });

        const subMenuClasses = conditionalClassLister(styles)({
            subMenu: true,
            isOpen: isSubmenuOpen,
        });

        return (
            <div className={styles.menuButtonContainer}>
                <div className={menuButtonClasses} onClick={() => this.onMenuButtonClick()}>
                    <div className={styles.caption}>{dashboard.name.toUpperCase()}</div>
                    {showEditButtons ? (
                        <div
                            className={styles.icon}
                            tabIndex={0}
                            onClick={() => this.onOpenSubmenu(!isSubmenuOpen)}
                            onBlur={() => this.onOpenSubmenu(false)}>
                            <FontAwesomeIcon icon={faBars} />
                        </div>
                    ) : null}
                    <div className={subMenuClasses}>
                        <div className={styles.submenuPanel}>
                            <div
                                className={styles.submenuItem}
                                onMouseDown={() => this.onDisableCloseOnBlur(true)}
                                onMouseUp={() => this.onDisableCloseOnBlur(false)}
                                onClick={() => this.onRenameDashboard()}>
                                {t('Rename Dashboard')}
                            </div>

                            <div
                                className={styles.submenuItem}
                                onMouseDown={() => this.onDisableCloseOnBlur(true)}
                                onMouseUp={() => this.onDisableCloseOnBlur(false)}
                                onClick={() => this.onEditDashboard()}>
                                {t('Edit Dashboard')}
                            </div>

                            <div
                                className={styles.submenuItem}
                                onMouseDown={() => this.onDisableCloseOnBlur(true)}
                                onMouseUp={() => this.onDisableCloseOnBlur(false)}
                                onClick={() => this.onDeleteDashboard()}>
                                {t('Delete Dashboard')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onMenuButtonClick(): void {
        const { onSelect } = this.props;
        onSelect();
    }

    private onDisableCloseOnBlur(disable: boolean): void {
        this.disableCloseOnBlur = disable;
    }

    private onOpenSubmenu(open: boolean): void {
        if (open || (!open && !this.disableCloseOnBlur)) {
            this.setState({ isSubmenuOpen: open });
        }
    }

    private onEditDashboard(): void {
        const { onEditDashboard } = this.props;
        onEditDashboard();
        this.setState({ isSubmenuOpen: false });
    }

    private onDeleteDashboard(): void {
        const { onDeleteDashboard } = this.props;
        onDeleteDashboard();
        this.setState({ isSubmenuOpen: false });
    }

    private onRenameDashboard(): void {
        const { onRenameDashboard } = this.props;
        onRenameDashboard();
        this.setState({ isSubmenuOpen: false });
    }
}

export default withTranslation()(DashboardMenuButton);

interface OwnProps {
    showEditButtons: boolean;
    onSelect: () => void;
    dashboard: Dashboard;
    editMode: boolean;
    isActive?: boolean;
    onDeleteDashboard: () => void;
    onRenameDashboard: () => void;
    onEditDashboard: () => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    isSubmenuOpen: boolean;
}

type AllState = OwnState;
