import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faPhoneAlt} from '@fortawesome/pro-regular-svg-icons';
import styles from './support-popup.module.scss';

class SupportPopup extends Component<AllProps, OwnState> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    public render(): JSX.Element {
        const {t} = this.props;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.title}>{t('Support')}</div>
                <div className={styles.content}>
                    <div className={styles.message}>{t('Please use the contact details below to get in touch.')}</div>
                    <div className={styles.contactRow}>
                        <div className={styles.contact}>
                            <div className={styles.contactCountry}>
                                {` ${t('Belgium')} / ${t('Netherlands')} / ${t('Luxembourg')}`}
                            </div>
                            <div className={styles.contactMail}>
                                <FontAwesomeIcon className={styles.icon} icon={faEnvelope}/>
                                <a href="mailto:benelux.fleet@goodyear.com">benelux.fleet@goodyear.com</a>
                            </div>
                            <div className={styles.contactPhone}>
                                <FontAwesomeIcon className={styles.icon} icon={faPhoneAlt}/>
                                +32 38203341
                            </div>
                        </div>

                        <div className={styles.contact}>
                            <div className={styles.contactCountry}>
                                {`${t('Bulgaria')}/${t('Slovenia')}/${t('Croatia')}`}
                            </div>
                            <div className={styles.contactMail}>
                                <FontAwesomeIcon className={styles.icon} icon={faEnvelope}/>
                                <a href="mailto:ee_truckfos@goodyear.com">ee_truckfos@goodyear.com </a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.contactRow}>
                        <div className={styles.contact}>
                            <div className={styles.contactCountry}>
                                {`  ${t('Czech Rep')} / ${t('Slovakia')} / ${t('Hungary')}`}`
                            </div>
                            <div className={styles.contactMail}>
                                <FontAwesomeIcon className={styles.icon} icon={faEnvelope}/>
                                <a href="mailto:ee_truckfos@goodyear.com">ee_truckfos@goodyear.com </a>
                            </div>
                            <div className={styles.contactPhone}>
                                <FontAwesomeIcon className={styles.icon} icon={faPhoneAlt}/>
                                +42 0234092776
                            </div>
                        </div>
                        <div className={styles.contact}>
                            <div className={styles.contactCountry}>
                                {`${t('France')}`}
                            </div>
                            <div className={styles.contactMail}>
                                <FontAwesomeIcon className={styles.icon} icon={faEnvelope}/>
                                <a href="mailto:support.fostruck.mileage@goodyear.com">support.fostruck.mileage@goodyear.com</a>
                            </div>
                            <div className={styles.contactPhone}>
                                <FontAwesomeIcon className={styles.icon} icon={faPhoneAlt}/>
                                +33 3147162891
                            </div>
                        </div>
                    </div>
                    <div className={styles.contactRow}>
                        <div className={styles.contact}>
                            <div className={styles.contactCountry}>
                                {` ${t('Germany')} / ${t('Switzerland')} / ${t('Austria')}`}`
                            </div>
                            <div className={styles.contactMail}>
                                <FontAwesomeIcon className={styles.icon} icon={faEnvelope}/>
                                <a href="mailto:team@4fleetgroup.com">team@4fleetgroup.com</a>
                            </div>
                            <div className={styles.contactPhone}>
                                <FontAwesomeIcon className={styles.icon} icon={faPhoneAlt}/>
                                +49 8002454040
                            </div>
                        </div>
                        <div className={styles.contact}>
                            <div className={styles.contactCountry}>
                                {`${t('Italy')}`}
                            </div>
                            <div className={styles.contactMail}>
                                <FontAwesomeIcon className={styles.icon} icon={faEnvelope}/>
                                <a href="mailto:emanuele_impinto@goodyear.com">emanuele_impinto@goodyear.com</a>
                            </div>
                            <div className={styles.contactPhone}>
                                <FontAwesomeIcon className={styles.icon} icon={faPhoneAlt}/>
                                +39 0257521290
                            </div>
                        </div>
                    </div>
                    <div className={styles.contactRow}>
                        <div className={styles.contact}>
                            <div className={styles.contactCountry}>
                                {`${t('Poland')}`}
                            </div>
                            <div className={styles.contactMail}>
                                <FontAwesomeIcon className={styles.icon} icon={faEnvelope}/>
                                <a href="mailto:ee_truckfos@goodyear.com">ee_truckfos@goodyear.com </a>
                            </div>
                            <div className={styles.contactPhone}>
                                <FontAwesomeIcon className={styles.icon} icon={faPhoneAlt}/>
                                +48 146801840
                            </div>
                        </div>
                        <div className={styles.contact}>
                            <div className={styles.contactCountry}>
                                {`${t('Romania')}`}
                            </div>
                            <div className={styles.contactMail}>
                                <FontAwesomeIcon className={styles.icon} icon={faEnvelope}/>
                                <a href="mailto:truckfosromania@goodyear.com">truckfosromania@goodyear.com</a>
                            </div>
                            <div className={styles.contactPhone}>
                                <FontAwesomeIcon className={styles.icon} icon={faPhoneAlt}/>
                                +40 212091712
                            </div>
                        </div>
                    </div>
                    <div className={styles.contactRow}>
                        <div className={styles.contact}>
                            <div className={styles.contactCountry}>
                                {` ${t('Spain')} / ${t('Portugal')}`}
                            </div>
                            <div className={styles.contactMail}>
                                <FontAwesomeIcon className={styles.icon} icon={faEnvelope}/>
                                <a href="mailto:soporte_fostruck@goodyear.com">soporte_fostruck@goodyear.com </a>
                            </div>
                            <div className={styles.contactPhone}>
                                <FontAwesomeIcon className={styles.icon} icon={faPhoneAlt}/>
                                +34 913622201
                            </div>
                        </div>
                        <div className={styles.contact}>
                            <div className={styles.contactCountry}>
                                {` ${t('Sweden')} / ${t('Finland')} / ${t('Norway')} / ${t('Denmark')} / ${t('Estonia')} / ${t('Latvia')} / ${t('Lithuania')}`}
                            </div>
                            <div className={styles.contactMail}>
                                <FontAwesomeIcon className={styles.icon} icon={faEnvelope}/>
                                <a href="mailto:nordic_fossupport@goodyear.com">nordic_fossupport@goodyear.com </a>
                            </div>
                            <div className={styles.contactPhone}>
                                <FontAwesomeIcon className={styles.icon} icon={faPhoneAlt}/>
                                +46 4662027
                            </div>
                        </div>
                    </div>
                    <div className={styles.contactRow}>
                        <div className={styles.contact}>
                            <div className={styles.contactCountry}>
                                {`${t('UK')} / ${t('Ireland')}`}
                            </div>
                            <div className={styles.contactMail}>
                                <FontAwesomeIcon className={styles.icon} icon={faEnvelope}/>
                                <a href="mailto:GYTM_FAX@goodyear.com">GYTM_FAX@goodyear.com </a>
                            </div>
                            <div className={styles.contactPhone}>
                                <FontAwesomeIcon className={styles.icon} icon={faPhoneAlt}/>
                                0800 533 633
                            </div>
                        </div>
                    </div>
                    <div className={styles.buttonsPanel}>
                        <button className={styles.button} type="button" onClick={() => this.onClosePopup()}>
                            {t('Close')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    private onClosePopup(): void {
        const {onClose} = this.props;
        onClose();
    }
}

export default withTranslation()(SupportPopup);

interface OwnProps {
    onClose: () => void;
}

interface OwnState {
}

type AllProps = OwnProps & WithTranslation;
