import { DateRangeWithServiceProvidersAndSoldTosRequest } from '../types';

export interface TiresDistributionRequest extends DateRangeWithServiceProvidersAndSoldTosRequest {
}

export interface TiresDistributionData {
    tireCount: number;
    tireType: TireType;
    ownBrand: boolean;
}

export enum TireType {
    RetreadTire = 'retreadTire',
    NewTire = 'newTire',
}

export enum TireBrandType {
    Corporate = 'Corporate',
    NonCorporate = 'NonCorporate',
    Unknown= 'Unknown',
}
