import {Component} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {withTranslation, WithTranslation} from 'react-i18next';
import {ApplicationState} from '../../store';
import PageHeader from '../../components/page-header/page-header';
import {Dashboard, skipDashboardsRequest} from '../../store/dashboard';
import styles from './jobs.module.scss';
import * as layoutActions from '../../store/layout/actions';
import DashboardViewer from '../analytics/dashboard-viewer';
import {getDashboardConfiguration} from './jobs-configuration';

class JobsPage extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {t,toggleSidebar, skipDashboardRequest} = this.props;
        toggleSidebar(true);
        this.state = {
            activeDashboard: getDashboardConfiguration(t),
        };
        skipDashboardRequest();
    }

    public render(): JSX.Element {
        const {t, location} = this.props;
        const {activeDashboard} = this.state;

        return (
            <div className={styles.pageContainer}>
                <PageHeader headerTitle={t('Job Explorer')}
                            location={location}/>
                <DashboardViewer activeDashboard={activeDashboard} editMode={false} />
            </div>
        );
    }
}

// eslint-disable-next-line
const mapStateToProps = ({}: ApplicationState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    skipDashboardRequest: () => dispatch(skipDashboardsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobsPage));

interface OwnState {
    activeDashboard?: Dashboard;
}

type AllState = OwnState;

interface PropsFromState {
}

interface PropsFromDispatch {
    toggleSidebar: typeof layoutActions.toggleSidebar;
    skipDashboardRequest: typeof skipDashboardsRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;
