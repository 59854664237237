import {Permission, User} from '../store/authentication';
import {UserInfo} from '../store/service-provider-groups';

export function getInitialsFromUser(user: User): string {
    if (user && user.cognitoUsername) {
        return user.cognitoUsername
            .split(' ', 2)
            .map((n) => n[0])
            .join('');
    }
    return '';
}

export function hasPermission(user: User|undefined, permission: Permission): boolean {
    if (user) {
        return user.permissions.includes(permission);
    } else {
        return false;
    }
}

export function hasAdminPermission(user: User): boolean {
    if (user) {
        return (
            hasPermission(user, Permission.ManageUsers) ||
            hasPermission(user, Permission.ViewUsers) ||
            hasPermission(user, Permission.ManageServiceProviderGroups) ||
            hasPermission(user, Permission.ViewServiceProviderGroups) ||
            hasPermission(user, Permission.ManageReports) ||
            hasPermission(user, Permission.ViewReports) ||
            hasPermission(user, Permission.ManageScheduledReports) ||
            hasPermission(user, Permission.ViewScheduledReports)
        );
    } else {
        return false;
    }
}

export function getEmailDomain(email: string): string {
    return email.split('@')[1];
}

export function getUserEmailDomain(user: UserInfo): string {
    return getEmailDomain(user.email);
}

export function isGoodyearEmail(email: string): boolean {
    const emailDomain = getEmailDomain(email);
    return ['goodyear.com', 'goodyear-dunlop.com', '4fleetgroup.com', '4fleet.de', 'trentyre.co.za', 'dunloptyres.com', 'dunlop-tires.com'].includes(emailDomain);
}
