import {Reducer} from 'redux';
import {RequestStatus, RequestStatusType, SharedActionTypes} from '../shared/types';
import {DataStatusAction, DataStatusActionTypes, DataStatusState} from './types';

function initialState(): DataStatusState {
    return {
        dataStatus: [],
    };
}

const reducer: Reducer<DataStatusState, DataStatusAction> = (state = initialState(), action): DataStatusState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_SERVICE_PROVIDER_GROUP: {
            return initialState();
        }
        case DataStatusActionTypes.FETCH_STATUS_REQUEST: {
            return { ...state, getDataStatusRequestStatus: new RequestStatus(RequestStatusType.InProgress) };
        }
        case DataStatusActionTypes.FETCH_STATUS_SUCCESS: {
            return {
                ...state,
                dataStatus: action.payload,
                getDataStatusRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case DataStatusActionTypes.FETCH_STATUS_FAILURE: {
            return { ...state, getDataStatusRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload) };
        }
        default: {
            return state;
        }
    }
};

export { reducer as dataStatusReducer };
export { initialState as initialDataStatusState };
