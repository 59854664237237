import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ApiResult } from '../../types/api-result';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import {
    getDataStatusFailure,
    getDataStatusRequest,
    getDataStatusSuccess,
} from './actions';
import {DataStatusActionTypes, DataStatusContract} from './types';
import DataStatusApi from '../../api/data-status-api';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleFetch(action: ActionType<typeof getDataStatusRequest>) {
    try {
        const result: ApiResult<DataStatusContract[]> = yield call(DataStatusApi.getDataStatus);

        if (result.error) {
            yield call(getDataStatusFailure, result.error);
        } else {
            yield put(getDataStatusSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}


function* watchFetchRequest() {
    yield takeEvery(DataStatusActionTypes.FETCH_STATUS_REQUEST, handleFetch);
}

function* dataStatusSaga() {
    yield all([fork(watchFetchRequest)]);
}

export default dataStatusSaga;
