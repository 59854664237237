export enum DropdownOptionKey {
    NoGrouping = 'noGrouping',
    WithInspectionStatus = 'includeInspections',
    BreakdownVsRegular = 'breakdownVsRegular',
    FosVsEjob = 'fosVsEjob',
    JobVsInspection = 'jobVsInspection',
    ServicesVsTires = 'servicesVsTires',
    IncludeDetails = 'includeDetails',
    ByVehicleType = 'byVehicleType',
    ByNetPrice = 'byNetPrice',
    ByJobCount = 'byJobCount',
    NewVsRetread = 'newVsRetread',
    CorporateVsNonCorporate = 'corporateVsNonCorporate',
}
