import { action } from 'typesafe-actions';
import {
    AdminScheduledReport, AdminScheduledReportRequest,
    ScheduledReportsActionTypes,
} from './types';
import {Paged} from '../shared/types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getAdminScheduledReportsRequest = (request:AdminScheduledReportRequest) => action(ScheduledReportsActionTypes.FETCH_ADMIN_SCHEDULED_REPORTS_REQUEST,request);
export const getAdminScheduledReportsSuccess = (scheduledReports: Paged<AdminScheduledReport>) => action(ScheduledReportsActionTypes.FETCH_ADMIN_SCHEDULED_REPORTS_SUCCESS, scheduledReports);
export const getAdminScheduledReportsFailure = (message: string) => action(ScheduledReportsActionTypes.FETCH_ADMIN_SCHEDULED_REPORTS_FAILURE, message);
