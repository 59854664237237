import {Component} from 'react';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {withTranslation, WithTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import * as layoutActions from '../../store/layout/actions';
import {ApplicationState} from '../../store';
import styles from './user-configuration-issue.module.scss';
import {logoutRequest} from '../../store/authentication';

class UserConfigurationIssuePage extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {toggleSidebar} = this.props;
        toggleSidebar(false);
    }

    public render(): JSX.Element {
        const {t} = this.props;
        return (
            <div className={styles.container}>
                <div className={styles.panel}>
                    <div className={styles.icon}><FontAwesomeIcon size="3x" icon={faExclamationCircle} /></div>
                    <div>{t('There seems to be something wrong with your account.')}</div>
                    <div>{t('Please contact your administrator and let him know you are receiving this message.')}</div>
                    <div className={styles.link} onClick={() => this.onLogout()}>{t('Return to login')}</div>
                </div>
            </div>
        );
    }

    public onLogout() {
        const {logoutRequest: logoutRequest1} = this.props;
        logoutRequest1();
    }
}

const mapStateToProps = ({authentication}: ApplicationState) => ({
    user: authentication.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    logoutRequest: () => dispatch(logoutRequest()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UserConfigurationIssuePage));

interface PropsFromState {
}

interface PropsFromDispatch {
    toggleSidebar: typeof layoutActions.toggleSidebar;
    logoutRequest: typeof logoutRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
}

type AllState = OwnState;
