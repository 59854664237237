import {faCheck} from '@fortawesome/pro-regular-svg-icons/faCheck';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {conditionalClassLister} from '../../../../../utils/class-helpers';
import ToggleDisplay from '../../../../../utils/toggle-display';
import styles from './service-provider-selection-item.module.scss';
import {GlobalServiceProvider} from '../../../../../store/service-providers';

class ServiceProviderSelectionItem extends Component<AllProps> {
    public render(): JSX.Element {
        const {serviceProvider, selected, canSelect} = this.props;

        const itemClasses = conditionalClassLister(styles)({
            serviceProviderItem: true,
            isDisabled: !canSelect,
        });

        const indicatorClass = conditionalClassLister(styles)({
            indicator: true,
            item: true,
        });

        const codeClass = conditionalClassLister(styles)({
            item: true,
            small: true
        });

        const nameClass = conditionalClassLister(styles)({
            item: true,
            large: true,
        });

         const cityClass = conditionalClassLister(styles)({
             item: true,
             medium:true
         });
         const countryClass = conditionalClassLister(styles)({
             item: true,
             medium:true
         });

        return (
            <div key={serviceProvider.id} className={itemClasses} onClick={() => this.onSelect()}>
                <div className={indicatorClass}>
                    <ToggleDisplay if={selected}>
                        <FontAwesomeIcon icon={faCheck}/>
                    </ToggleDisplay>
                </div>
                <div className={codeClass}>{serviceProvider.vendorId}</div>
                <div className={nameClass}>{serviceProvider.name}</div>
                <div className={cityClass}>{serviceProvider.city}</div>
                <div className={countryClass}>{serviceProvider.country}</div>
            </div>
        );
    }

    private onSelect() {
        const {onSelect} = this.props;

        if (onSelect) {
            onSelect();
        }
    }
}

export default withTranslation()(ServiceProviderSelectionItem);

interface OwnProps {
    canSelect:boolean;
    serviceProvider: GlobalServiceProvider;
    onSelect?: () => void;
    selected: boolean;
}

type AllProps = OwnProps & WithTranslation;
