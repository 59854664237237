import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import {
    fetchScheduledReportsRequest,
    fetchScheduledReportsFailure,
    fetchScheduledReportsSuccess,
    createScheduledReportsRequest,
    createScheduledReportsFailure,
    createScheduledReportsSuccess,
    updateScheduledReportsRequest,
    updateScheduledReportsSuccess,
    updateScheduledReportsFailure,
    deleteScheduledReportsRequest,
    deleteScheduledReportsFailure,
    deleteScheduledReportsSuccess,
    fetchInspectionDetailsReportRequest,
    fetchInspectionDetailsReportFailure,
    fetchInspectionDetailsReportSuccess,
    fetchExtendedInspectionReportRequest,
    fetchExtendedInspectionReportFailure,
    fetchExtendedInspectionReportSuccess,
    fetchVehiclesDueForInspectionReportRequest,
    fetchVehiclesDueForInspectionReportSuccess,
    fetchVehiclesDueForInspectionReportFailure,
    fetchCasingsReportRequest,
    fetchCasingsReportFailure,
    fetchCasingsReportSuccess,
    fetchReportDownloadRequest,
    fetchReportDownloadFailure,
    fetchReportDownloadSuccess,
    fetchReportInfoRequest,
    fetchReportInfoFailure,
    fetchReportInfoSuccess,
    fetchTireStockCompaniesRequest,
    fetchTireStockReportFailure,
    fetchTireStockCompaniesSuccess,
    fetchTireStockReportRequest,
    fetchTireStockReportSuccess,
    fetchTCUSensorFitmentsReportRequest,
    fetchTCUSensorFitmentsReportSuccess,
    fetchTCUSensorFitmentsReportFailure,
    fetchCustomerServiceLocationsReportRequest,
    fetchCustomerServiceLocationsReportFailure,
    fetchCustomerServiceLocationsReportSuccess,
} from './actions';
import {
    ReportsActionTypes,
    ReportInfo,
    ScheduledReport,
    InspectionDetailsReportRequest,
    ExtendedInspectionReportRequest,
    VehiclesDueForInspectionReportRequest,
    CasingsReportRequest,
    ReportDownloadUrl,
    TireStockCompany,
    TireStockReportRequest,
    TCUSensorFitmentsReportRequest,
    CustomerServiceLocationsReportRequest,
} from './types';
import {ApplicationState} from '../index';
import ReportsApi from '../../api/reports-api';
import {handleUnexpectedErrorWithToast} from '../http-error-handler';
import {ApiResult} from '../../types/api-result';
import {PollingResult} from '../../pages/reports/polling-result';
import {ReportDownloadHandler} from '../../pages/reports/report-download-handler';

const fetchServiceProviderGroupIdFromState = () => select((state: ApplicationState) => state.authentication.serviceProviderGroup!.id);

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleFetchInspectionDetailsReport(action: ActionType<typeof fetchInspectionDetailsReportRequest>) {
    try {
        const request: InspectionDetailsReportRequest = action.payload;
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getInspectionDetailsReport, request, serviceProviderGroupId);

        if (result.error) {
            yield put(fetchInspectionDetailsReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, serviceProviderGroupId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchInspectionDetailsReportSuccess, fetchInspectionDetailsReportFailure);
        }
    } catch (err) {
        yield put(fetchInspectionDetailsReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchCustomerServiceLocationsReport(action: ActionType<typeof fetchCustomerServiceLocationsReportRequest>) {
    try {
        const request: CustomerServiceLocationsReportRequest = action.payload;
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getCustomerServiceLocationsReport, request, serviceProviderGroupId);

        if (result.error) {
            yield put(fetchCustomerServiceLocationsReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, serviceProviderGroupId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchCustomerServiceLocationsReportSuccess, fetchCustomerServiceLocationsReportFailure);
        }
    } catch (err) {
        yield put(fetchCustomerServiceLocationsReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}


export function* handleFetchTCUSensorFitmentsReport(action: ActionType<typeof fetchTCUSensorFitmentsReportRequest>) {
    try {
        const request: TCUSensorFitmentsReportRequest = action.payload;
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getTCUSensorFitmentsReport, request, serviceProviderGroupId);

        if (result.error) {
            yield put(fetchTCUSensorFitmentsReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, serviceProviderGroupId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchTCUSensorFitmentsReportSuccess, fetchTCUSensorFitmentsReportFailure);
        }
    } catch (err) {
        yield put(fetchTCUSensorFitmentsReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchExtendedInspectionReport(action: ActionType<typeof fetchExtendedInspectionReportRequest>) {
    try {
        const request: ExtendedInspectionReportRequest = action.payload;
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getExtendedInspectionReport, request, serviceProviderGroupId);

        if (result.error) {
            yield put(fetchExtendedInspectionReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, serviceProviderGroupId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchExtendedInspectionReportSuccess, fetchExtendedInspectionReportFailure);
        }
    } catch (err) {
        yield put(fetchExtendedInspectionReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchVehiclesDueForInspectionReport(action: ActionType<typeof fetchVehiclesDueForInspectionReportRequest>) {
    try {
        const request: VehiclesDueForInspectionReportRequest = action.payload;
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getVehiclesDueForInspectionReport, request, serviceProviderGroupId);

        if (result.error) {
            yield put(fetchVehiclesDueForInspectionReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, serviceProviderGroupId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchVehiclesDueForInspectionReportSuccess, fetchVehiclesDueForInspectionReportFailure);
        }
    } catch (err) {
        yield put(fetchVehiclesDueForInspectionReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchTireStockReport(action: ActionType<typeof fetchTireStockReportRequest>) {
    try {
        const request: TireStockReportRequest = action.payload;
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getTireStockReport, request, serviceProviderGroupId);

        if (result.error) {
            yield put(fetchTireStockReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, serviceProviderGroupId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchTireStockReportSuccess, fetchTireStockReportFailure);
        }
    } catch (err) {
        yield put(fetchTireStockReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleFetchCasingsReport(action: ActionType<typeof fetchCasingsReportRequest>) {
    try {
        const request: CasingsReportRequest = action.payload;
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getCasingsReport, request, serviceProviderGroupId);

        if (result.error) {
            yield put(fetchCasingsReportFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            const pollingResult: PollingResult = yield ReportDownloadHandler.handlePolling(result.data!, serviceProviderGroupId);
            yield ReportDownloadHandler.handlePollingResult(pollingResult, fetchCasingsReportSuccess, fetchCasingsReportFailure);
        }
    } catch (err) {
        yield put(fetchCasingsReportFailure(err));
        yield call(handleUnexpectedErrorWithToast, err);
    }
}


export function* handleFetchTireStockCompanies(action: ActionType<typeof fetchTireStockCompaniesRequest>) {
    try {
        const fleetCustomerId = action.payload;
        const result: ApiResult<TireStockCompany[]> = yield call(ReportsApi.getTireStockCompanies, fleetCustomerId);
        if (result.error) {
            yield put(fetchTireStockReportFailure(result.error));
        } else {
            yield put(fetchTireStockCompaniesSuccess(result.data!));
        }
    } catch (err) {
        yield put(fetchTireStockReportFailure(err));
    }
}


export function* handleFetchScheduledReports(action: ActionType<typeof fetchScheduledReportsRequest>) {
    try {
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const result: ApiResult<ScheduledReport[]> = yield call(ReportsApi.getScheduledReports, serviceProviderGroupId);

        if (result.error) {
            yield put(fetchScheduledReportsFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(fetchScheduledReportsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleCreateScheduledReport(action: ActionType<typeof createScheduledReportsRequest>) {
    try {
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const result: ApiResult<ScheduledReport> = yield call(ReportsApi.addScheduledReport, action.payload, serviceProviderGroupId);

        if (result.error) {
            yield put(createScheduledReportsFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(createScheduledReportsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleUpdateScheduledReport(action: ActionType<typeof updateScheduledReportsRequest>) {
    try {
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const result: ApiResult<ScheduledReport> = yield call(ReportsApi.updateScheduledReport, action.payload, serviceProviderGroupId);

        if (result.error) {
            yield put(updateScheduledReportsFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(updateScheduledReportsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}
export function* handleDeleteScheduledReport(action: ActionType<typeof deleteScheduledReportsRequest>) {
    try {
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const result: ApiResult<string> = yield call(ReportsApi.deleteScheduledReport, action.payload, serviceProviderGroupId);

        if (result.error) {
            yield put(deleteScheduledReportsFailure(result.error));
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(deleteScheduledReportsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleGetReportInfo(action: ActionType<typeof fetchReportInfoRequest>) {
    try {
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const reportId = action.payload;

        const result: ApiResult<ReportInfo> = yield call(ReportsApi.getReportInfo, reportId, serviceProviderGroupId);

        if (result.error) {
            yield call(fetchReportInfoFailure, result.error);
        } else {
            const reportInfo = result.data!
            yield put(fetchReportInfoSuccess(reportInfo));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleGetReportDownloadUrl(action: ActionType<typeof fetchReportDownloadRequest>) {
    try {
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const reportInfo = action.payload;

        const result: ApiResult<ReportDownloadUrl> = yield call(ReportsApi.getReportDownloadUrl, reportInfo, serviceProviderGroupId);

        if (result.error) {
            yield call(fetchReportDownloadFailure, result.error);
        } else {
            yield put(fetchReportDownloadSuccess());
            ReportDownloadHandler.downloadReport(result.data!);
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

function* watchRequests() {
    yield takeEvery(ReportsActionTypes.FETCH_INSPECTION_DETAILS_REPORT_REQUEST, handleFetchInspectionDetailsReport);
    yield takeEvery(ReportsActionTypes.FETCH_CUSTOMER_SERVICE_LOCATIONS_REPORT_REQUEST, handleFetchCustomerServiceLocationsReport);
    yield takeEvery(ReportsActionTypes.FETCH_TCU_SENSOR_FITMENTS_REPORT_REQUEST, handleFetchTCUSensorFitmentsReport);
    yield takeEvery(ReportsActionTypes.FETCH_EXTENDED_INSPECTION_REPORT_REQUEST, handleFetchExtendedInspectionReport);
    yield takeEvery(ReportsActionTypes.FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_REQUEST, handleFetchVehiclesDueForInspectionReport);
    yield takeEvery(ReportsActionTypes.FETCH_CASINGS_REPORT_REQUEST, handleFetchCasingsReport);
    yield takeEvery(ReportsActionTypes.FETCH_SCHEDULED_REPORTS_REQUEST, handleFetchScheduledReports);
    yield takeEvery(ReportsActionTypes.CREATE_SCHEDULED_REPORT_REQUEST, handleCreateScheduledReport);
    yield takeEvery(ReportsActionTypes.UPDATE_SCHEDULED_REPORT_REQUEST, handleUpdateScheduledReport);
    yield takeEvery(ReportsActionTypes.DELETE_SCHEDULED_REPORT_REQUEST, handleDeleteScheduledReport);
    yield takeEvery(ReportsActionTypes.FETCH_REPORT_INFO_REQUEST, handleGetReportInfo);
    yield takeEvery(ReportsActionTypes.FETCH_REPORT_DOWNLOAD_REQUEST, handleGetReportDownloadUrl);
    yield takeEvery(ReportsActionTypes.FETCH_TIRE_STOCK_REPORT_REQUEST, handleFetchTireStockReport);
    yield takeEvery(ReportsActionTypes.FETCH_TIRE_STOCK_COMPANIES_REQUEST, handleFetchTireStockCompanies);
}

function* reportsSaga() {
    yield all([fork(watchRequests)]);
}

export default reportsSaga;
