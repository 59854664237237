import { ApiResult } from '../types/api-result';
import { SoldTo } from '../store/sold-tos';
import { Http } from '../http/http';
import { ApiUrl, UrlParamBuilder } from './api-urls';

class SoldTosApi {
    public static async getAll(serviceProviderGroupId: string): Promise<ApiResult<SoldTo[]>> {
        const data = await Http.get<SoldTo[]>(ApiUrl.SoldTos,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build());
        return {data};
    }
}

export default SoldTosApi;
