import {
    DateRangeWithServiceProvidersAndSoldTosAndCurrencyRequest,
} from '../types';

export interface PurchasingDistributionRequest extends DateRangeWithServiceProvidersAndSoldTosAndCurrencyRequest {
}

export interface PurchasingDistributionData {
    purchasingItemType: PurchasingItemType;
    netPrice: number;
}

export enum PurchasingItemType {
    Services = 'services',
    Tires = 'tires',
}
