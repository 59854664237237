import { ActionType } from 'typesafe-actions';
import * as sharedActions from '../shared/actions';
import * as actions from './actions';

export interface Popup {
    type: PopupType;
    content: JSX.Element;
    hideCloseButton?: boolean;
    topOffset?:number;
}

export enum PopupType {
    ServiceProviderGroupCreation = 'createServiceProviderGroup',
    UserCreation = 'createUser',
    WidgetsCatalog = 'widgetsCatalog',
    ServiceProviderGroupConfiguration = 'serviceProviderGroupConfiguration',
    ServiceProviderGroupSelection = 'serviceProviderGroupSelection',
    ServiceProviderMappingSelection = 'serviceProviderMappingSelection',
    ReportAccessSelection = 'reportAccessSelection',
    PrivacyPolicy = 'privacyPolicy',
    TermsOfUse = 'termsOfUse',
    ServiceProviderDetails = 'serviceProviderDetails',
    TireServiceDetails = 'tireServiceDetails',
    VehicleDetails = 'vehicleDetails',
    Support = 'support',
    ScheduledReport = 'scheduledReport',
}

export enum PopupActionTypes {
    SHOW_POPUP = '@@popup/ShowPopup',
    CLOSE_POPUP = '@@popup/ClosePopup',
}

export type PopupAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface PopupState {
    readonly popup?: Popup;
}
