import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import styles from './widget-editor.module.scss';

class WidgetEditor extends Component<AllProps> {

    public render(): JSX.Element {
        return (
            <div className={styles.widgetEditPanel}>
                <div className={styles.deleteButton} onClick={() => this.onDeleteClick()}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                </div>
            </div>
        );
    }

    public onDeleteClick() {
        const {onDeleteClick: onDeleteClick1} = this.props;
        onDeleteClick1();
    }
}

export default withTranslation()(WidgetEditor);

interface OwnProps {
    onDeleteClick: () => void;
}

type AllProps = OwnProps & WithTranslation;
