import {LocationDescriptorObject} from 'history';
import {PopupType} from '../store/popup';
import {SortDirection} from '../store/shared/types';

export enum QueryParameter {
    Popup = 'popup',
    VehicleId = 'vehicleId',
    Dashboard = 'dashboard',
    ReturnUrl = 'returnUrl',
    SkipMaintenance = 'skipMaintenance',
    JobId = 'jobId',
    SerialNumber = 'serialNumber',
    Page = 'page',
    ItemsPerPage = 'itemsPerPage',
    SortColumn = 'sortColumn',
    SortDirection = 'sortDirection',
    ServiceProviderNameContains = 'serviceProviderNameContains',
    JobReferenceContains = 'jobReferenceContains',
    LicensePlateContains = 'licensePlateContains',
    PurchasingRefContains = 'purchasingRefContains',
    ServiceProviderId = 'serviceProviderId',
    FleetNameContains = 'fleetNameContains',
    EjobUserContains = 'ejobUserContains',
    PurchasingDocumentId = 'purchasingDocumentId',
    ScheduledReportId = 'scheduledReportId',
    JobTypeExclude = 'jobTypeExclude',
    IsEjobExclude = 'isEjobExclude'
}

export interface QueryParameterMap {
    popup?: PopupType;
    dashboard?: string;
    returnUrl?: string;
    reload?: boolean;
    purchasingDocumentId?: string;
    serviceProviderId?: string;
    vehicleId?: string;
    jobId?: string;
    page?: number;
    itemsPerPage?: number;
    sortColumn?: string;
    sortDirection?: SortDirection;
    serviceProviderNameContains?: string;
    fleetNameContains?: string;
    scheduledReportId?: string;
    jobTypeExclude?: string;
    isEjobExclude?: string;
}

export function buildSearchParameters(parameters: QueryParameterMap, search = ''): string {
    const queryParameters = new URLSearchParams(search);

    Object
        .keys(parameters)
        .map((k) => {
            if (parameters[k] !== undefined && parameters[k] !== '' && parameters[k]!==[]) {
                queryParameters.set(k, parameters[k]);
            } else {
                queryParameters.delete(k);
            }

            return undefined;
        });

    return queryParameters.toString();
}

export function buildReturnUrlFromQueryParameters(queryParameters: URLSearchParams): LocationDescriptorObject {
    const decodedReturnUrl = decodeURIComponent(queryParameters.get(QueryParameter.ReturnUrl)!);
    const splitReturnUrl = decodedReturnUrl.split('?');
    const pathname = splitReturnUrl[0];
    const search = splitReturnUrl.length === 2 ? splitReturnUrl[1] : '';
    return {pathname, search};
}
