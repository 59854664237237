import {Reducer} from 'redux';
import {SearchAction, SearchActionTypes, SearchState} from './types';
import {SharedActionTypes} from '../shared/types';

function initialState(): SearchState {
    return {
        isSearching: false,
        searchResults: [],
        searchQuery: '',
    };
}

const reducer: Reducer<SearchState, SearchAction> = (state = initialState(), action): SearchState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS: {
            return initialState();
        }
        case SearchActionTypes.SEARCH_REQUEST: {
            return {...state, isSearching: true, searchQuery: action.payload, searchResults: []};
        }
        case SearchActionTypes.SEARCH_SUCCESS: {
            return {...state, isSearching: false, searchResults: action.payload};
        }
        case SearchActionTypes.SEARCH_CLEAR: {
            return {...state, isSearching: false, searchResults: [], searchQuery: ''};
        }
        case SearchActionTypes.SEARCH_FAILURE: {
            return {...state, isSearching: false};
        }
        default: {
            return state;
        }
    }
};

export {reducer as searchReducer};
export {initialState as initialSearchState};

