import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import {getReportName, ReportCatalogEntry, ReportType} from '../../report-catalog';
import {ScheduledReportDeliveryMethod, ScheduledReportGranularity} from '../../../../store/reports';
import styles from './report-scheduler-schedule.module.scss';
import Dropdown, {DropdownOption} from '../../../../components/dropdown/dropdown';
import {Regex} from '../../../../types/regex';
import {FileType} from '../../../../store/shared/types';

class ReportSchedulerScheduleComponent extends Component<AllProps, AllState> {

    public granularityOptions: DropdownOption[];

    public fileTypeOptions: DropdownOption[];

    public deliveryOptions: DropdownOption[];

    public scheduleTimeWeekOptions: DropdownOption[];

    public scheduleTimeMonthOptions: DropdownOption[];

    public scheduleTimeYearOptions: DropdownOption[];

    constructor(props) {
        super(props);

        const {t, granularity, reportAccess} = this.props;

        const reportTypeOptions: DropdownOption[] = []
        reportAccess.forEach(entry => {
            reportTypeOptions.push(
                {key: entry.type, label: getReportName(entry.type,t)},
            );
        });

        this.fileTypeOptions = [
            {key: FileType.ZIP, label: FileType.ZIP},
            {key: FileType.XLSX, label: FileType.XLSX},
        ];

        this.deliveryOptions = [
            {key: ScheduledReportDeliveryMethod.EMAIL_ATTACHMENT, label: t(ScheduledReportDeliveryMethod.EMAIL_ATTACHMENT)},
            {key: ScheduledReportDeliveryMethod.DOWNLOAD_LINK, label: t(ScheduledReportDeliveryMethod.DOWNLOAD_LINK)},
        ]

        this.granularityOptions = [
            {key: ScheduledReportGranularity.WEEK, label: t('Every Week')},
            {key: ScheduledReportGranularity.MONTH, label: t('Every Month')},
            {key: ScheduledReportGranularity.YEAR, label: t('Every Year')}
        ];

        this.scheduleTimeWeekOptions = [
            {key: '1', label: 'Monday'},
            {key: '2', label: 'Tuesday'},
            {key: '3', label: 'Wednesday'},
            {key: '4', label: 'Thursday'},
            {key: '5', label: 'Friday'},
            {key: '6', label: 'Saturday'},
            {key: '7', label: 'Sunday'},
        ];

        this.scheduleTimeMonthOptions = [
            {key: 'FIRST', label: t('First day of month')},
            {key: 'LAST', label: t('Last day of month')},
        ];
        for (let i = 1; i <= 28; i++) {
            this.scheduleTimeMonthOptions.push(
                {key: i.toString(), label: i.toString()}
            );
        }

        this.scheduleTimeYearOptions = [
            {key: '1', label: 'January'},
            {key: '2', label: 'February'},
            {key: '3', label: 'March'},
            {key: '4', label: 'April'},
            {key: '5', label: 'May'},
            {key: '6', label: 'June'},
            {key: '7', label: 'July'},
            {key: '8', label: 'August'},
            {key: '9', label: 'September'},
            {key: '10', label: 'October'},
            {key: '11', label: 'November'},
            {key: '12', label: 'December'},
        ];

        let selectedGranularity: ScheduledReportGranularity;
        if (granularity) {
            selectedGranularity = granularity;
        } else {
            selectedGranularity = ScheduledReportGranularity.WEEK;
        }
        this.state = {
            selectedGranularity,
            newRecipient: '',
            reportTypeOptions
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>) {
        const {reportAccess, t} = this.props;

        if (reportAccess && reportAccess !== prevProps.reportAccess) {
            const reportTypeOptions: DropdownOption[] = []
            reportAccess.forEach(entry => {
                reportTypeOptions.push(
                    {key: entry.type, label: getReportName(entry.type, t)},
                );
            });
            this.setState({reportTypeOptions});
        }
    }

    public render(): JSX.Element {
        const {reportName, reportType, granularity, scheduleTime, recipients, fileType, deliveryMethod, t} = this.props;
        const {selectedGranularity, newRecipient, reportTypeOptions} = this.state;

        const mailRegex = new RegExp(Regex.Email);

        return (
            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    <div className={styles.field}>
                        <label className={styles.label} htmlFor='name'>{t('Name')}</label>
                        <input className={styles.input}
                               type='text'
                               id='name'
                               value={reportName}
                               onChange={(event) => this.onNameChanged(event)}/>
                    </div>
                    <div className={styles.field}>
                        <span className={styles.label}>{t('Report Type')}</span>
                        <Dropdown
                            background='white'
                            width='100%'
                            options={reportTypeOptions}
                            selection={reportType ? reportTypeOptions.find(r => r.key === reportType) : reportTypeOptions[0]}
                            onSelectionChanged={(option) => this.onReportTypeChanged(option)}/>
                    </div>
                    <div className={styles.scheduleTime}>
                        <div className={styles.field}>
                            <span className={styles.label}>{t('Schedule')}</span>
                            <Dropdown
                                background='white'
                                width={selectedGranularity === ScheduledReportGranularity.YEAR ? '180px' : '280px'}
                                options={this.granularityOptions}
                                selection={granularity ? this.granularityOptions.find(g => g.key === granularity) : this.granularityOptions[0]}
                                onSelectionChanged={(option) => this.onGranularityChanged(option)}/>
                        </div>
                        {selectedGranularity === ScheduledReportGranularity.WEEK ?
                            <div className={styles.field}>
                                <span className={styles.label}>{t('Day')}</span>
                                <Dropdown
                                    background='white'
                                    width='280px'
                                    options={this.scheduleTimeWeekOptions}
                                    selection={scheduleTime ? this.scheduleTimeWeekOptions.find(s => s.key === scheduleTime) : this.scheduleTimeWeekOptions[0]}
                                    onSelectionChanged={(option) => this.onScheduleTimeChanged(option)}/>
                            </div>
                            : ''}
                        {selectedGranularity === ScheduledReportGranularity.MONTH ?
                            <div className={styles.field}>
                                <span className={styles.label}>{t('Day')}</span>
                                <Dropdown
                                    background='white'
                                    width='280px'
                                    options={this.scheduleTimeMonthOptions}
                                    selection={scheduleTime ? this.scheduleTimeMonthOptions.find(s => s.key === scheduleTime) : this.scheduleTimeMonthOptions[0]}
                                    onSelectionChanged={(option) => this.onScheduleTimeChanged(option)}/>
                            </div>
                            : ''}
                        {selectedGranularity === ScheduledReportGranularity.YEAR ?
                            <div className={styles.scheduleTimeYear}>
                                <div className={styles.field}>
                                    <span className={styles.label}>{t('Month')}</span>
                                    <Dropdown
                                        background='white'
                                        width='180px'
                                        options={this.scheduleTimeYearOptions}
                                        selection={this.determineSelection(scheduleTime, ScheduledReportGranularity.YEAR)}
                                        onSelectionChanged={(option) => this.onScheduleTimeChanged(option, ScheduledReportGranularity.YEAR)}/>
                                </div>
                                <div className={styles.field}>
                                    <span className={styles.label}>{t('Day')}</span>
                                    <Dropdown
                                        background='white'
                                        width='180px'
                                        options={this.scheduleTimeMonthOptions}
                                        selection={this.determineSelection(scheduleTime, ScheduledReportGranularity.MONTH)}
                                        onSelectionChanged={(option) => this.onScheduleTimeChanged(option, ScheduledReportGranularity.MONTH)}/>
                                </div>
                            </div>
                            : ''}
                    </div>
                    <div className={styles.field}>
                        <span className={styles.label}>{t('File Format')}</span>
                        <Dropdown
                            background='white'
                            width='100%'
                            options={this.fileTypeOptions}
                            selection={fileType ? this.fileTypeOptions.find(r => r.key === fileType) : this.fileTypeOptions[0]}
                            onSelectionChanged={(option) => this.onFileTypeChanged(option)}/>
                    </div>
                    <div className={styles.field}>
                    <span className={styles.label}>{t('Delivery Method')}
                        {deliveryMethod === ScheduledReportDeliveryMethod.EMAIL_ATTACHMENT ?
                            <>
                                <div className={styles.infoIcon}
                                     data-tip={t('Please be aware that size restrictions on the receiving mailbox might block email delivery when selecting Email Attachment.')}
                                     data-for='deliveryMethod'>
                                    <FontAwesomeIcon icon={faInfoCircle}/></div>
                                <ReactTooltip className={styles.tooltip} html  border effect="solid"
                                              backgroundColor="#000000" id='deliveryMethod' place='right'/>
                            </>
                            : null}
                    </span>
                        <Dropdown
                            background='white'
                            width='100%'
                            options={this.deliveryOptions}
                            selection={deliveryMethod ? this.deliveryOptions.find(r => r.key === deliveryMethod) : this.deliveryOptions[0]}
                            onSelectionChanged={(option) => this.onDeliveryMethodChanged(option)}/>
                    </div>
                    <div className={styles.field}>
                        <span className={styles.label}>{t('Recipients')}</span>
                        <div className={styles.recipients}>
                            {recipients && recipients.length > 0 ? recipients.map(recipient => (
                                <div className={styles.recipientLine} key={recipient}>
                                    <span className={styles.recipient}>{recipient}</span>
                                    <span className={styles.action}
                                          onClick={() => this.onRemoveRecipientClick(recipient)}>{t('remove')}</span>
                                </div>
                            )) : ''}
                        </div>
                        <div className={styles.recipientsAddLine}>
                            <input className={styles.recipientInput}
                                   type='text'
                                   value={newRecipient}
                                   onChange={(event) => this.onRecipientChanged(event)}
                                   onKeyDown={(event) => this.onKeyDown(event)}
                            />
                            <span className={mailRegex.test(newRecipient) ? styles.recipientsAddAction : styles.recipientsAddActionInvalid}
                                  onClick={() => this.onAddRecipientClick()}>{t('add')}</span>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    private onRemoveRecipientClick(recipient: string): void {
        const {recipients, onRecipientsChanged} = this.props;
        const updatedRecipients = recipients.filter(r => r !== recipient);

        onRecipientsChanged(updatedRecipients);
    }

    private onAddRecipientClick(): void {
        const {recipients, onRecipientsChanged} = this.props;
        const {newRecipient} = this.state;

        const mailRegex = new RegExp(Regex.Email);
        if (mailRegex.test(newRecipient)) {
            const updatedRecipients = [...recipients];
            updatedRecipients.push(newRecipient);

            onRecipientsChanged(updatedRecipients);

            this.setState({
                newRecipient: '',
            });
        }
    }

    private onKeyDown(event: any): void {
        if (event.keyCode === 13) {
            this.onAddRecipientClick();
        }
    }

    private onRecipientChanged(event: any): void {
        if (event) {
            this.setState({
                newRecipient: event.target.value,
            });
        }
    }

    private onScheduleTimeChanged(option: DropdownOption, granularity?: ScheduledReportGranularity): void {
        const {scheduleTime, onScheduleTimeChanged} = this.props;
        const {selectedGranularity} = this.state;

        if (option && option.key && scheduleTime) {
            let result = '';
            let scheduleParts: string[] = [];
            let monthPart = '1';
            let yearPart = '1';
            switch (selectedGranularity) {
                case ScheduledReportGranularity.WEEK:
                case ScheduledReportGranularity.MONTH:
                    result = option.key;
                    break;
                case ScheduledReportGranularity.YEAR:
                    scheduleParts = scheduleTime.split('-');
                    if (scheduleParts.length > 1) {
                        [yearPart, monthPart] = scheduleParts;
                    }
                    if (granularity === ScheduledReportGranularity.MONTH) {
                        monthPart = option.key
                    } else if (granularity === ScheduledReportGranularity.YEAR) {
                        yearPart = option.key;
                    }
                    result = `${yearPart}-${monthPart}`;
                    break
                default:
                    throw new Error(`No mapping found for granularity: ${selectedGranularity}`);
            }
            onScheduleTimeChanged(result);
        }
    }

    private onNameChanged(event: any): void {
        const {onReportNameChanged} = this.props;

        onReportNameChanged(event.target.value);
    }

    private onReportTypeChanged(option: DropdownOption): void {
        const {onReportTypeChanged} = this.props;
        if (option && option.key) {
            onReportTypeChanged(option.key as ReportType);
        }
    }

    private onFileTypeChanged(option: DropdownOption): void {
        const {onFileTypeChanged} = this.props;
        if (option && option.key) {
            onFileTypeChanged(option.key as FileType);
        }
    }

    private onDeliveryMethodChanged(option: DropdownOption): void {
        const {onDeliveryMethodChanged} = this.props;
        if (option && option.key) {
            onDeliveryMethodChanged(option.key as ScheduledReportDeliveryMethod);
        }
    }

    private onGranularityChanged(option: DropdownOption): void {
        const {onGranularityChanged} = this.props;
        if (option && option.key) {
            const granularity: ScheduledReportGranularity = option.key as ScheduledReportGranularity;
            onGranularityChanged(granularity);
            this.setState({
                selectedGranularity: granularity,
            });
        }
    }

    private determineSelection(scheduleTime: string, granularity: ScheduledReportGranularity): DropdownOption {
        const scheduleParts: string[] = scheduleTime.split('-');
        let monthPart = '1';
        let yearPart = '1';
        if (scheduleParts.length === 2) {
            [yearPart, monthPart] = scheduleParts;
        }
        if (granularity === ScheduledReportGranularity.MONTH) {
            return this.scheduleTimeMonthOptions.find(option => option.key === monthPart)!;
        } else if (granularity === ScheduledReportGranularity.YEAR) {
            return this.scheduleTimeYearOptions.find(option => option.key === yearPart)!;
        } else {
            throw new Error(`No correct mapping found for scheduleTime: ${scheduleTime}`);
        }
    }
}

export default withTranslation()(ReportSchedulerScheduleComponent);

interface OwnProps {
    reportAccess: ReportCatalogEntry[];
    reportName: string;
    reportType: ReportType;
    fileType: FileType;
    deliveryMethod: ScheduledReportDeliveryMethod;
    granularity: ScheduledReportGranularity;
    scheduleTime: string;
    recipients: string[];
    onReportNameChanged: (name: string) => void;
    onReportTypeChanged: (type: ReportType) => void;
    onFileTypeChanged: (type: FileType) => void;
    onDeliveryMethodChanged: (method: ScheduledReportDeliveryMethod) => void;
    onGranularityChanged: (granularity: ScheduledReportGranularity) => void;
    onScheduleTimeChanged: (scheduleTime: string) => void;
    onRecipientsChanged: (recipients: string[]) => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    selectedGranularity: ScheduledReportGranularity;
    newRecipient: string;
    reportTypeOptions: DropdownOption[];
}

type AllState = OwnState;
