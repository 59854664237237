import {KeyPerformanceIndicatorData} from '../key-performance-indicator.types';

export class KeyPerformanceIndicatorHelper {

    public static formatBigNumber(data: KeyPerformanceIndicatorData): void {
        if (data.value && data.value > 1000000) {
            data.value /= 1000000;
            data.unit = `M ${data.unit}`;
        } else if (data.value && data.value > 100000) {
            data.value /= 1000;
            data.unit = `k ${data.unit}`;
        }
    }
}
