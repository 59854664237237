import {TFunction} from 'i18next';
import {getMonthLabel} from '../../../../../utils/translations/date-translation-helper';
import {DateRange} from '../../../../../store/analytics';
import {endOfMonth, isBefore, startOfMonth, subYears} from '../../../../../utils/date-helper';

export const allMonthIndexes: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

// Returns an array with all month indexes for a given year that fall within a date range
export function monthIndexesThatFallWithin(year: number, dateRange: DateRange): number[] {
    const startYear = dateRange.start.getFullYear();
    const endYear = dateRange.end.getFullYear();
    if (startYear > year || endYear < year) {
        return [];
    }
    if (startYear === year && endYear === year) {
        const startMonth = dateRange.start.getMonth();
        const endMonth = dateRange.end.getMonth();
        return allMonthIndexes.filter(i => i >= startMonth && i <= endMonth);
    }
    if (startYear === year) {
        const startMonth = dateRange.start.getMonth();
        return allMonthIndexes.filter(i => i >= startMonth);
    }
    if (endYear === year) {
        const endMonth = dateRange.end.getMonth();
        return allMonthIndexes.filter(i => i <= endMonth);
    }
    return [...allMonthIndexes];
}

// Create a new month dateRange between two dates.
export function createMonthRange(date1: Date, date2: Date): DateRange {
    if (isBefore(date1, date2)) {
        return {start: startOfMonth(date1), end: endOfMonth(date2)};
    }
    if (isBefore(date2, date1)) {
        return {start: startOfMonth(date2), end: endOfMonth(date1)};
    }
    return {start: startOfMonth(date1), end: endOfMonth(date1)};
}

// Create a new month dateRange between two dates.
export function createSingleMonthRange(month: string): DateRange {
    const date = new Date(month);
    return {start: startOfMonth(date) , end: endOfMonth(date)};
}

// Returns a display string representation for the given month range.
// ex. (Jan - Jun 2020) or (Feb 2019 - Feb 2020)
export function toMonthRangeDisplay(dateRange: DateRange, translate: TFunction): string {
    let display = '';

    if (dateRange) {
        const startMonth = dateRange.start.getMonth();
        const startYear = dateRange.start.getFullYear();
        const endMonth = dateRange.end.getMonth();
        const endYear = dateRange.end.getFullYear();

        if ((startMonth === endMonth) && (startYear === endYear)) {
            display = `${getMonthLabel(startMonth, translate)} ${startYear}`;
        } else if (startYear === endYear) {
            display = `${getMonthLabel(startMonth, translate)} - ${getMonthLabel(endMonth, translate)} ${startYear}`;
        } else {
            display = `${getMonthLabel(startMonth, translate)} ${startYear} - ${getMonthLabel(endMonth, translate)} ${endYear}`;
        }
    }

    return display;
}

// Returns a display string representation for the given month range.
// ex. (Jan - Jun 2020) or (Feb 2019 - Feb 2020)
export function toBetweenMonthRangeDisplay(dateRange: DateRange, t: TFunction): string {
    let display = '';

    if (dateRange) {
        const startMonth = dateRange.start.getMonth();
        const startYear = dateRange.start.getFullYear();
        const endMonth = dateRange.end.getMonth();
        const endYear = dateRange.end.getFullYear();

        if ((startMonth === endMonth) && (startYear === endYear)) {
            display = t('On {{date}}', {date: `${getMonthLabel(startMonth, t)} ${startYear}`});
        } else if (startYear === endYear) {
            display = t('Between {{dateA}} and {{dateB}}', {
                dateA: `${getMonthLabel(startMonth, t)}`,
                dateB: `${getMonthLabel(endMonth, t)} ${startYear}`,
            });
        } else {
            display = t('Between {{dateA}} and {{dateB}}', {
                dateA: `${getMonthLabel(startMonth, t)} ${startYear}`,
                dateB: `${getMonthLabel(endMonth, t)} ${endYear}`,
            });
        }
    }

    return display;
}

export function yoyComparisonDisplay(startDate: Date, endDate: Date, t: TFunction): string {
    const currentLabel = toMonthRangeDisplay({start: startDate, end: endDate}, t);
    const previousLabel = toMonthRangeDisplay({start: subYears(startDate, 1), end: subYears(endDate, 1)}, t);
    return `${currentLabel} <> ${previousLabel}`;
}

// Returns a display string representation the end date.
// Unless the end date is in the future in that case it will take the current date.
// ex. (Jan - Jun 2020) or (Feb 2019 - Feb 2020)
export function toEndDateDisplay(dateRange: DateRange, t: TFunction): string {
    let display = '';

    if (dateRange) {
        const date = dateRange.end.getTime() < Date.now() ? dateRange.end : new Date();

        const endDay = date.getDate();
        const endMonth = date.getMonth();
        const endYear = date.getFullYear();

        display = t('On {{date}}', {date: `${endDay} ${getMonthLabel(endMonth, t)} ${endYear}`});
    }

    return display;
}
