import { ParamKey } from '../../../../store/dashboard';
import {
    DateRange,
    DateRangeRequest,
    DateRangeWithServiceProvidersAndSoldTosRequest, DateRangeWithServiceProvidersRequest,
    ServiceProviderSelection,
    SoldToSelection,
} from '../../../../store/analytics';
import { ControlsSnapshot } from '../../dashboard-controls/controls-snapshot';
import { forceDateToBrusselsTime } from '../../../../utils/date-helper';

export function createDateRangeWithServiceProvidersAndSoldTosRequest(snapshot: ControlsSnapshot): DateRangeWithServiceProvidersAndSoldTosRequest {
    const period = snapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
    const serviceProviderSelections = snapshot.getValueForParam<ServiceProviderSelection>(ParamKey.ServiceProviderSelections);
    const soldToSelections = snapshot.getValueForParam<SoldToSelection>(ParamKey.SoldToSelections);
    const start = forceDateToBrusselsTime(period.start).toISOString();
    const end = forceDateToBrusselsTime(period.end).toISOString();

    return {
        start,
        end,
        serviceProviders: serviceProviderSelections,
        soldTos: soldToSelections,
    };
}

export function createDateRangeWithServiceProvidersRequest(snapshot: ControlsSnapshot): DateRangeWithServiceProvidersRequest {
    const period = snapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
    const serviceProviderSelections = snapshot.getValueForParam<ServiceProviderSelection>(ParamKey.ServiceProviderSelections);
    const start = forceDateToBrusselsTime(period.start).toISOString();
    const end = forceDateToBrusselsTime(period.end).toISOString();

    return {
        start,
        end,
        serviceProviders: serviceProviderSelections
    };
}

export function createDateRangeRequest(snapshot: ControlsSnapshot): DateRangeRequest {
    const period = snapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
    const start = forceDateToBrusselsTime(period.start).toISOString();
    const end = forceDateToBrusselsTime(period.end).toISOString();

    return {
        start,
        end,
    };
}

export function hasInspectionPeriod(snapshot: ControlsSnapshot): boolean {
    return snapshot.has(ParamKey.InspectionPeriod);
}
