export const AvailableCurrencies:string[] = [
    'AED',
    'ALL',
    'AOA',
    'ARS',
    'ATS',
    'AUD',
    'BAM',
    'BEF',
    'BGN',
    'BHD',
    'BND',
    'BRL',
    'BWP',
    'CAD',
    'CHF',
    'CLP',
    'CNH',
    'CNY',
    'COP',
    'CZK',
    'DEM',
    'DKK',
    'DZD',
    'EEK',
    'EGP',
    'ESP',
    'EUR',
    'FIM',
    'FRF',
    'GBP',
    'GHC',
    'GHS',
    'GRD',
    'GTQ',
    'HKD',
    'HRK',
    'HUF',
    'IDR',
    'IEP',
    'INR',
    'ISK',
    'ITL',
    'JMD',
    'JOD',
    'JPY',
    'KES',
    'KRW',
    'KWD',
    'LBP',
    'LSL',
    'LTL',
    'LVL',
    'MAD',
    'MDL',
    'MKD',
    'MLF',
    'MUR',
    'MWK',
    'MXN',
    'MYR',
    'MZN',
    'NAD',
    'NGN',
    'NLG',
    'NOK',
    'NZD',
    'OMR',
    'PAB',
    'PEN',
    'PHP',
    'PLN',
    'PTE',
    'QAR',
    'RM',
    'RMB',
    'ROL',
    'RON',
    'RSD',
    'RUB',
    'RUE1',
    'RUE2',
    'RUR',
    'SAR',
    'SEK',
    'SGD',
    'SIT',
    'SKK',
    'THB',
    'TND',
    'TRL',
    'TRY',
    'TWD',
    'TZS',
    'UAH',
    'UGX',
    'USD',
    'UYU',
    'VEB',
    'VEF',
    'VEX',
    'VND',
    'XOF',
    'XPF',
    'YTL',
    'ZAR',
    'ZMK',
    'ZMW',
    'ZWD'
]
