import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDoubleRight} from '@fortawesome/pro-regular-svg-icons/faChevronDoubleRight';
import {faChevronDoubleLeft} from '@fortawesome/pro-regular-svg-icons/faChevronDoubleLeft';
import styles from './date-range-year-panel.module.scss';
import {getMonthLabel} from '../../../../utils/translations/date-translation-helper';
import {conditionalClassLister} from '../../../../utils/class-helpers';
import {allMonthIndexes, monthIndexesThatFallWithin} from './helpers/date-range-helper';
import ToggleDisplay from '../../../../utils/toggle-display';
import {DateRange} from '../../../../store/analytics';

class DateRangeYearPanel extends Component<AllProps, AllState> {

    public render(): JSX.Element {
        const {t, year, selectedRange, canCycleYearBack, canCycleYearForward} = this.props;

        const selectedMonths = monthIndexesThatFallWithin(year, selectedRange);

        return (
            <div className={styles.yearContainer}>
                <div className={styles.yearPanel}>
                    <div className={styles.cycleButton}>
                        <ToggleDisplay if={canCycleYearBack}>
                            <div onClick={() => this.onCycleYear(false)}><FontAwesomeIcon icon={faChevronDoubleLeft}/>
                            </div>
                        </ToggleDisplay>
                    </div>
                    <div className={styles.yearLabel}>{year}</div>
                    <div className={styles.cycleButton}>
                        <ToggleDisplay if={canCycleYearForward}>
                            <div onClick={() => this.onCycleYear(true)}><FontAwesomeIcon icon={faChevronDoubleRight}/>
                            </div>
                        </ToggleDisplay>
                    </div>
                </div>
                <div className={styles.monthsPanel}>
                    {allMonthIndexes.map(
                        (monthIndex: number) => {
                            const monthBoxClasses = this.determineMonthBoxClasses(monthIndex, selectedMonths);
                            return <div className={monthBoxClasses}
                                        onMouseEnter={() => this.onHover((monthIndex))}
                                        onClick={() => this.onSelectMonth(monthIndex)}
                                        key={monthIndex}>{getMonthLabel(monthIndex, t)}</div>;
                        },
                    )}
                </div>
            </div>
        );
    }

    private onCycleYear(forward: boolean) {
        const {onCycleYear} = this.props;
        onCycleYear(forward);
    }

    private isFirstElementInRow(monthIndex): boolean {
        return (monthIndex) % 4 === 0;
    }

    private isLastElementInRow(monthIndex): boolean {
        return (monthIndex + 1) % 4 === 0;
    }

    private onSelectMonth(monthIndex: number) {
        const {year, onSelect} = this.props;
        const date = new Date(year, monthIndex, 1);
        onSelect(date);
    }

    private determineMonthBoxClasses(monthIndex: number, selectedMonths: number[]): string {
        let needsLeftCornerRadius = false;
        let needsRightCornerRadius = false;
        let isHovered = false;
        const isSelected = selectedMonths.includes(monthIndex);

        // #Sam: good luck trying to understand this structure ;)
        const {hoverRange, year, editMode} = this.props;
        if (!editMode) {
            needsLeftCornerRadius = isSelected && this.isFirstElementInRow(monthIndex);
            needsRightCornerRadius = isSelected && this.isLastElementInRow(monthIndex);

            if (isSelected) {
                needsLeftCornerRadius = monthIndex === selectedMonths[0] ? true : needsLeftCornerRadius;
                needsRightCornerRadius = monthIndex === selectedMonths[selectedMonths.length - 1] ? true : needsRightCornerRadius;
            }
        } else if (hoverRange) {
                const hoverMonths = monthIndexesThatFallWithin(year, hoverRange);
                isHovered = hoverMonths.includes(monthIndex);

                if (isHovered) {
                    needsLeftCornerRadius = monthIndex === hoverMonths[0] ? true : needsLeftCornerRadius;
                    needsRightCornerRadius = monthIndex === hoverMonths[hoverMonths.length - 1] ? true : needsRightCornerRadius;
                }
            } else if (isSelected) {
                    needsLeftCornerRadius = monthIndex === selectedMonths[0];
                    needsRightCornerRadius = monthIndex === selectedMonths[selectedMonths.length - 1];
                }

        return conditionalClassLister(styles)({
            needsLeftCornerRadius,
            needsRightCornerRadius,
            isSelected,
            isHovered,
            monthBox: true,
        });
    }

    private onHover(monthIndex: number) {
        const {onHover, year, editMode} = this.props;
        if (editMode) {
            const date = new Date(year, monthIndex, 1);
            onHover(date);
        }
    }
}

export default withTranslation()(DateRangeYearPanel);

interface OwnProps {
    selectedRange: DateRange;
    hoverRange?: DateRange;
    year: number;
    editMode: boolean;
    onSelect: (date: Date) => void;
    onHover: (date: Date) => void;
    canCycleYearBack: boolean;
    canCycleYearForward: boolean;
    onCycleYear: (foward: boolean) => void;
}

interface PropsFromState {
}

interface PropsFromDispatch {
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
