import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {faChartBar, faTable} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';

import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import WidgetTable, {WidgetTableColumnType} from '../../../../components/widget-table/widget-table';
import {ApplicationState} from '../../../../store';
import { getTopSoldTosRequest} from '../../../../store/analytics';
import {ViewMode} from '../../../../types/view-mode';
import {generateUUID} from '../../../../utils/uuid-helpers';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {addStandardTooltipStyle, addStrokeToColumnChart, createXYChart} from '../utils/chart-utils';
import {hasInspectionPeriod} from '../utils/widget-helper';
import WidgetLoading from '../widget-loading';
import {buildChartData, soldToInspectionCountField, soldToNameField} from './chart-data-adapter';
import {buildRequest} from './request-builder';
import styles from './top-sold-tos-widget.module.scss';
import {TopSoldTosData, TopSoldTosRequest} from '../../../../store/analytics/types/top-sold-tos-types';

am4core.useTheme(am4themes_animated);

class TopSoldTosWidget extends SingleChartWidgetBase<AllProps, AllState, TopSoldTosData[]> {
    private chartId: string;

    constructor(props) {
        super(props);
        this.chartId = generateUUID();

        this.state = {
            viewMode: ViewMode.chart,
        };
    }

    public render(): JSX.Element {
        const { t, data } = this.props;
        const { viewMode } = this.state;

        const columns = [
            {
                title: 'Name',
                property: 'name',
                type: WidgetTableColumnType.upperText,
                width: '480px',
            },
            {
                title: '# Inspections',
                property: 'inspectionCount',
                type: WidgetTableColumnType.number,
            },
        ]

        return (
            <div className={styles.widgetContainer}>
                {data ? (
                    <div className={styles.chartView}>
                        <div className={styles.chartHeader}>
                            <div className={styles.headerTitle}>{t('Top 20 fleets by inspection count')}</div>
                            <div className={styles.interactions}>
                                <div className={styles.headerToggle} onClick={() => this.toggleViewMode()}>
                                    {viewMode === ViewMode.chart ? (
                                        <FontAwesomeIcon icon={faTable} />
                                    ) : (
                                        <FontAwesomeIcon icon={faChartBar} />
                                    )}
                                </div>
                            </div>
                        </div>
                        {viewMode === ViewMode.chart ? (
                            <div className={styles.chartContent}>
                                <div id={this.chartId} style={{ width: '100%' }} />
                            </div>
                        ) : (
                            <div className={styles.tableContent}>
                                <WidgetTable
                                    config={{columns}}
                                    data={data}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <WidgetLoading />
                )}
            </div>
        );
    }

    private toggleViewMode(): void {
        const { viewMode } = this.state;
        const updatedViewMode = viewMode === ViewMode.chart ? ViewMode.table : ViewMode.chart;
        this.setState({ viewMode: updatedViewMode }, () => {
            if (this.state.viewMode === ViewMode.chart) {
                this.refreshChart();
            } else {
                this.disposeChart();
            }
        });
    }

    protected fetchData(snapshot: ControlsSnapshot): void {
        const { dispatchGetTopServiceProviders } = this.props;
        dispatchGetTopServiceProviders(
            buildRequest(this.props.controlsSnapshot),
        );
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    protected createChart(data: TopSoldTosData[]): am4charts.XYChart {
        const chart = createXYChart(this.chartId);

        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = soldToNameField;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;

        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = this.props.t('# Inspections');

        chart.data = buildChartData(data);
        this.addSeries(chart);

        return chart;
    }

    private addSeries(chart: any): void {
        const series = chart.series.push(new am4charts.ColumnSeries());

        series.dataFields.categoryY = soldToNameField;
        series.dataFields.valueX = soldToInspectionCountField;
        series.columns.template.tooltipText = `{categoryY}: [bold]{valueX} ${this.props.t('Inspections')}[/]`;
        series.columns.template.fillOpacity = 0.8;
        series.columns.template.maxHeight = 100;

        const valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = '{valueX}';
        valueLabel.label.horizontalCenter = 'left';
        valueLabel.label.dx = 10;
        valueLabel.label.hideOversized = false;
        valueLabel.label.truncate = false;

        addStrokeToColumnChart(series);
        addStandardTooltipStyle(series);
    }
}

const mapStateToProps = ({ analytics, authentication }: ApplicationState) => ({
    data: analytics.topSoldTosData,
    serviceProviderGroupId: authentication.serviceProviderGroup?.id,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchGetTopServiceProviders: (request: TopSoldTosRequest) =>
        dispatch(getTopSoldTosRequest(request)),
    navigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopSoldTosWidget));

interface PropsFromState {
    data: TopSoldTosData[];
    serviceProviderGroupId: string;
}

interface PropsFromDispatch {
    dispatchGetTopServiceProviders: typeof getTopSoldTosRequest;
    navigateTo: typeof push;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    viewMode: ViewMode;
}

type AllState = OwnState;
