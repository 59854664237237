import {action} from 'typesafe-actions';
import {
    GlobalServiceProvider,
    ServiceProviderActionTypes,
    ServiceProviderDetails,
    ServiceProviderMapping,
    ServiceProviderRequest, ServiceProviderWithCityCountry,
} from './types';
import {Paged} from '../shared/types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getServiceProviderMappingsRequest = (serviceProviderGroupId: string) =>
    action(ServiceProviderActionTypes.GET_SERVICE_PROVIDER_MAPPINGS_REQUEST, serviceProviderGroupId);
export const getServiceProviderMappingsSuccess = (mappings: ServiceProviderMapping) =>
    action(ServiceProviderActionTypes.GET_SERVICE_PROVIDER_MAPPINGS_SUCCESS, mappings);
export const getServiceProviderMappingsFailure = (message: string) =>
    action(ServiceProviderActionTypes.GET_SERVICE_PROVIDER_MAPPINGS_FAILURE, message);

export const getServiceProvidersForGroupRequest = (serviceProviderGroupId: string) =>
    action(ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_FOR_GROUP_REQUEST, serviceProviderGroupId);
export const getServiceProvidersForGroupSuccess = (serviceProviders: ServiceProviderWithCityCountry[]) =>
    action(ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_FOR_GROUP_SUCCESS, serviceProviders);
export const getServiceProvidersForGroupFailure = (message: string) =>
    action(ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_FOR_GROUP_FAILURE, message);

export const getServiceProviderDetailsRequest = (serviceProviderGroupId: string, serviceProviderId: string) =>
    action(ServiceProviderActionTypes.GET_SERVICE_PROVIDER_DETAILS_REQUEST, { serviceProviderGroupId, serviceProviderId });
export const getServiceProviderDetailsSuccess = (serviceProvider: ServiceProviderDetails) =>
    action(ServiceProviderActionTypes.GET_SERVICE_PROVIDER_DETAILS_SUCCESS, serviceProvider);
export const getServiceProviderDetailsFailure = (message: string) =>
    action(ServiceProviderActionTypes.GET_SERVICE_PROVIDER_DETAILS_FAILURE, message);

export const getServiceProvidersRequest = (request: ServiceProviderRequest) =>
    action(ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_REQUEST, request);
export const getServiceProvidersSuccess = (serviceProviders: Paged<GlobalServiceProvider>) =>
    action(ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_SUCCESS, serviceProviders);
export const getServiceProvidersFailure = (message: string) =>
    action(ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_FAILURE, message);
