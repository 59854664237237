import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/pro-regular-svg-icons/faCheck';
import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import ToggleDisplay from '../../../../utils/toggle-display';
import {conditionalClassLister} from '../../../../utils/class-helpers';
import styles from './selection-panel.module.scss';

class SelectionPanel extends Component<AllProps, AllState> {

    constructor(props) {
        super(props)

        const {items, initialSearchText} = this.props;

        let visibleItems: Item[];
        if (initialSearchText) {
            visibleItems = items.filter(item => item.displayName.toUpperCase().includes(initialSearchText.toUpperCase()));
        } else {
            visibleItems = items;
        }

        this.state = {
            visibleItems,
            searchText: initialSearchText || '',
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any) {
        const {items, initialSearchText} = this.props;
        const {searchText} = this.state;

        if (initialSearchText !== prevProps.initialSearchText) {
            this.setState({searchText: initialSearchText});

        }

        if (searchText !== prevState.searchText) {
            let visibleItems: Item[];
            if (initialSearchText) {
                visibleItems = items.filter(item => {
                        const searchFields = [item.displayName.toUpperCase()];
                        if (item.displayField1) {
                            searchFields.push(item.displayField1.toUpperCase());
                        }
                        if (item.displayField2) {
                            searchFields.push(item.displayField2.toUpperCase());
                        }
                        return searchFields.some(sf => sf.includes(initialSearchText.toUpperCase()));
                    }
                );
            } else {
                visibleItems = items;
            }

            this.setState({
                visibleItems,
            })
        }
    }

    public render(): JSX.Element {
        const {selectedItems, partialItems, t, searchPlaceholderType} = this.props;
        const {visibleItems, searchText} = this.state;

        const allClasses = conditionalClassLister(styles)({
            selectAction: true,
            active: visibleItems.every(item => selectedItems.has(item.id)),
        });

        const noneClasses = conditionalClassLister(styles)({
            selectAction: true,
            active: visibleItems.every(item => !selectedItems.has(item.id) && !partialItems?.has(item.id)),
        });

        return <div className={styles.selectionItems}>
            <div className={styles.selectionActions}>
                <div className={styles.searchContainer}>
                    <FontAwesomeIcon className={styles.searchIcon} icon={faSearch}/>
                    <input className={styles.searchInput}
                           type="text"
                           value={searchText}
                           placeholder={`${t('Search')} ${searchPlaceholderType}`}
                           onChange={event => this.filterItems(event)}
                    />
                </div>
                <div className={styles.selectAll}>
                    <div className={styles.selectLabel}>{t('Select')}</div>
                    <div className={allClasses} onClick={() => this.onSelectAll()}> {t('All')}</div>
                    <div className={styles.selectLabel}>/</div>
                    <div className={noneClasses} onClick={() => this.onSelectNone()}> {t('None')}</div>
                </div>
            </div>
            <div className={styles.selections}>
                {visibleItems ? visibleItems!.map(
                    (item: Item, index: number) => {
                        const isSelected = !!selectedItems.get(item.id);
                        const isPartial = partialItems ? !!partialItems.get(item.id) : false;

                        const nameClasses = conditionalClassLister(styles)({
                            isSelected,
                            isPartial,
                            itemText: true,
                            name: true
                        });
                        const field1Classes = conditionalClassLister(styles)({
                            isSelected,
                            isPartial,
                            itemText: true,
                            field1: true
                        });
                        const field2Classes = conditionalClassLister(styles)({
                            isSelected,
                            isPartial,
                            itemText: true,
                            field2: true
                        });
                        return <div className={styles.serviceProviderItem}
                                    key={item.id}
                                    onClick={() => this.onToggleSelection(item)}>
                            <div className={styles.serviceProviderSelector}>
                                <ToggleDisplay if={isSelected}>
                                    <FontAwesomeIcon icon={faCheck}/>
                                </ToggleDisplay>
                                <ToggleDisplay if={isPartial}>
                                    <span>-</span>
                                </ToggleDisplay>
                            </div>
                            <div className={nameClasses}
                                 title={item.displayName.toUpperCase()}>{item.displayName.toUpperCase()}</div>
                            {item.displayField1 ?
                                <div className={field1Classes}
                                     title={item.displayField1.toUpperCase()}>{item.displayField1.toUpperCase()}</div>
                                : null}
                            {item.displayField2 ?
                                <div className={field2Classes}
                                     title={item.displayField2.toUpperCase()}>{item.displayField2.toUpperCase()}</div>
                                : null}
                        </div>;
                    },
                ) : ''}
            </div>
        </div>;
    }

    public onSelectAll() {
        const {onToggleSelectAll} = this.props;
        const {visibleItems} = this.state;
        onToggleSelectAll(true, visibleItems);
    }

    public onSelectNone() {
        const {onToggleSelectAll} = this.props;
        const {visibleItems} = this.state;
        onToggleSelectAll(false, visibleItems);
    }

    public onToggleSelection(item: Item) {
        const {onToggleSelection} = this.props;
        onToggleSelection(item);
    }

    private filterItems(event: any) {
        const {onSearchChanged} = this.props;
        const searchText = event.target.value;
        onSearchChanged(searchText);
        this.setState({searchText});
    }
}

export default connect()(withTranslation()(SelectionPanel));

interface OwnProps {
    items: Item[];
    selectedItems: Map<string, Item>;
    partialItems?: Map<string, Item>;
    onToggleSelection: (item) => void;
    onToggleSelectAll: (boolean, items) => void;
    initialSearchText?: string;
    searchPlaceholderType: string;
    onSearchChanged: (string) => void
}

type AllProps = OwnProps & WithTranslation;


interface OwnState {
    visibleItems: Item[];
    searchText?: string;
}

type AllState = OwnState;

export interface Item {
    id: string;
    displayName: string;
    displayField1?: string;
    displayField2?: string;
}
