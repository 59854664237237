import {ActionType} from 'typesafe-actions';
import * as actions from './actions';
import * as sharedActions from '../shared/actions';

export enum FilterControlSelectionActionTypes {
    FILTER_SERVICE_PROVIDERS = '@@filter-control-selection/FILTER_SERVICE_PROVIDERS',
    FILTER_SOLD_TOS = '@@filter-control-selection/FILTER_SOLD_TOS',
    FILTER_RESET = '@@filter-control-selection/FILTER_RESET',
}

export type FilterControlSelectionAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface FilterControlSelectionState {
    serviceProvidersFilter?: string;
    soldTosFilter?: string;
}
