export function retrieveFromLocalStorage(key: LocalStorageKey): string | null {
    return localStorage.getItem(key);
}

export function persistToLocalStorage(key: LocalStorageKey, value: string | number):void {
    localStorage.setItem(key, value.toString());
}

export function retrieveParsedFromLocalStorage<T>(key: LocalStorageKey): T | null {
    return JSON.parse(localStorage.getItem(key) || 'null');
}

export function persistParsedToLocalStorage<T>(key: LocalStorageKey, value: T):void {
    localStorage.setItem(key, JSON.stringify(value));
}

export function removeFromLocalStorage(key: LocalStorageKey):void {
    localStorage.removeItem(key);
}

export enum LocalStorageKey {
    RecentServiceProviderGroups = 'recentServiceProviderGroups',
}
