import {Component} from 'react';
import {Control} from './control';
import {ControlValue} from './controls-snapshot';

export abstract class ControlBase<TProps extends ControlProps<TValue>, TState, TValue> extends Component<TProps, TState> implements Control {

    public beforeRedraw(): void {
    }

    public abstract forceRefresh(value: TValue): void;

    public componentDidMount(): void {
        const {setControlRef} = this.props;
        setControlRef(this);
    }

    public componentWillUnmount(): void {
        const {setControlRef} = this.props;
        setControlRef(undefined);
    }
}

export interface ControlProps<T> {
    onValueChange: (value: T) => void;
    setControlRef: (control?: Control) => void;
    defaultValue?: ControlValue;
}
