import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import UsersApi from '../../api/users-api';
import { ApiResult } from '../../types/api-result';
import {User, UserSummary} from '../authentication';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import {
    clearUsers,
    getUserRequest,
    searchUsersRequest,
    searchUsersSuccess,
    getUserSuccess,
    updateUserSuccess
} from './actions';
import { UsersActionTypes, UsersRequest } from './types';
import { Paged } from '../shared/types';

function* handleUsersFailure(err: any) {
    yield call(handleUnexpectedErrorWithToast, err);
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleFetch(action: ActionType<typeof getUserRequest>) {
    try {
        const id = action.payload;
        const result: ApiResult<User> = yield call(UsersApi.getUser, id);

        if (result.error) {
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(getUserSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleSearch(action: ActionType<typeof searchUsersRequest>) {
    try {
        const request: UsersRequest = action.payload;
        const result: ApiResult<Paged<UserSummary>> = yield call(UsersApi.searchUsers, request);

        if (result.error) {
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(searchUsersSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleUpdateUser(action) {
    try {
        const user = action.payload;
        const result: ApiResult<User> = yield call(UsersApi.updateUser, user);
        if (result?.error) {
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(clearUsers());
            yield put(updateUserSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUsersFailure, err);
    }
}

function* watchFetchRequest() {
    yield takeEvery(UsersActionTypes.GET_USER_REQUEST, handleFetch);
    yield takeEvery(UsersActionTypes.SEARCH_USERS_REQUEST, handleSearch);
    yield takeEvery(UsersActionTypes.UPDATE_USER_REQUEST, handleUpdateUser);
}

function* usersSaga() {
    yield all([fork(watchFetchRequest)]);
}

export default usersSaga;
