import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {
    InspectionDetailsCustomReportParameters,
    InspectionDetailsScheduledReportParameters,
    ReportPeriodType,
    ScheduledReportPeriod,
} from '../../../../../../store/reports';
import styles from './inspection-details.module.scss';
import ScheduledPeriodSelectorComponent from '../../components/scheduled-period-selector.component';
import {
    ServiceProviderSelection,
    SoldToSelection
} from '../../../../../../store/analytics';
import DateRangeSelectorComponent from '../../../../components/date-range-selector.component';
import {ServiceProviderGroupWithConfiguration} from '../../../../../../store/service-provider-groups';
import {SoldToSelectionsControlValue} from '../../../../../analytics/dashboard-controls/sold-tos-selector/types/sold-tos-selections-control-value';
import {ServiceProviderSelectionsControlValue} from '../../../../../analytics/dashboard-controls/service-providers-selector/types/service-providers-selections-control-value';
import ServiceProvidersSelectorComponent from '../../../../components/service-providers-selector.component';
import SoldTosSelectorComponent from '../../../../components/sold-tos-selector.component';

class InspectionDetailsComponent extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {parameters} = this.props;

        let period: ScheduledReportPeriod = ScheduledReportPeriod.PAST_MONTH;
        let serviceProviders: ServiceProviderSelection = {includes: []};
        if (parameters && parameters.serviceProviders) {
            serviceProviders = parameters.serviceProviders;
        }
        let soldTos: SoldToSelection = {includes: [], includeAll: false};
        if (parameters && parameters.soldTos) {
            soldTos = parameters.soldTos;
        }
        if (parameters && parameters.period) {
            period = parameters.period;
        }

        this.state = {
            period,
            soldToSelection: new SoldToSelectionsControlValue('', soldTos),
            serviceProviderSelection: new ServiceProviderSelectionsControlValue('', serviceProviders),
        };
    }

    public render(): JSX.Element {
        const {periodType, serviceProviderGroup, t} = this.props;
        const {period, serviceProviderSelection, soldToSelection} = this.state;

        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <>
                        <div className={styles.title}>{t('Period')}</div>
                        <ScheduledPeriodSelectorComponent
                            defaultPeriod={period}
                            onPeriodChange={(p) => this.onPeriodChanged(p)}
                        />
                        <div className={styles.title}>{t('Parameters')}</div>
                    </>
                    : null
                }
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <div className={styles.info}>{t('Select a service provider')}</div>
                        <ServiceProvidersSelectorComponent
                            defaultServiceProviderSelection={serviceProviderSelection && serviceProviderSelection.value.includes.length > 0 ? serviceProviderSelection : undefined}
                            onServiceProviderSelectionChange={(controlValue) => this.onServiceProviderSelectionChanged(controlValue)}
                        />
                        <div className={styles.info}>{t('Select a fleet')}</div>
                        <SoldTosSelectorComponent
                            defaultSoldToSelection={soldToSelection && soldToSelection.value.includes.length > 0 ? soldToSelection : undefined}
                            onSoldToSelectionChange={(controlValue) => this.onSoldToSelectionChanged(controlValue)}
                        />
                    </div>
                    {periodType === ReportPeriodType.Custom
                        ? <div className={styles.formRow}>
                            <div className={styles.formColumn}>
                                <div className={styles.info}>{t('Specify a period')}</div>
                                <DateRangeSelectorComponent
                                    earliestDate={serviceProviderGroup?.earliestContractStartDate}
                                    latestDate={new Date()}
                                    fromDateChanged={(date => this.onFromDateChanged(date))}
                                    toDateChanged={(date => this.onToDateChanged(date))}
                                />
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
        );
    }

    private onSoldToSelectionChanged(controlValue: SoldToSelectionsControlValue): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {soldToSelection} = this.state;

        if (!soldToSelection || (soldToSelection && !controlValue.equals(soldToSelection))) {
            this.setState({
                soldToSelection: controlValue,
            }, () => {
                // Make sure previous state has been applied, as service provider and sold to selection changes will trigger at the same time
                const {serviceProviderSelection, period, fromDate, toDate} = this.state;
                if (periodType === ReportPeriodType.Custom) {
                    onCustomParametersChanged({
                        serviceProviders: serviceProviderSelection,
                        soldTos: controlValue,
                        fromDate,
                        toDate,
                    });
                }
                if (periodType === ReportPeriodType.Scheduled) {
                    onScheduledParametersChanged({
                        period,
                        serviceProviders: serviceProviderSelection?.value,
                        soldTos: controlValue.value,
                    });
                }
            });
        }
    }

    private onServiceProviderSelectionChanged(controlValue: ServiceProviderSelectionsControlValue): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {serviceProviderSelection} = this.state;

        if (!serviceProviderSelection || (serviceProviderSelection && !controlValue.equals(serviceProviderSelection))) {
            this.setState({
                serviceProviderSelection: controlValue,
            }, () => {
                // Make sure previous state has been applied, as service provider and sold to selection changes will trigger at the same time
                const {soldToSelection, period, fromDate, toDate} = this.state;

                if (periodType === ReportPeriodType.Custom) {
                    onCustomParametersChanged({
                        serviceProviders: controlValue,
                        soldTos: soldToSelection,
                        fromDate,
                        toDate,
                    });
                }
                if (periodType === ReportPeriodType.Scheduled) {
                    onScheduledParametersChanged({
                        period,
                        serviceProviders: controlValue.value,
                        soldTos: soldToSelection?.value,
                    });
                }
            });
        }
    }

    private onPeriodChanged(period: ScheduledReportPeriod) {
        const {onScheduledParametersChanged} = this.props;
        const {serviceProviderSelection, soldToSelection} = this.state;

        onScheduledParametersChanged({
            period,
            serviceProviders: serviceProviderSelection?.value,
            soldTos: soldToSelection?.value,
        });

        this.setState({
            period,
        });
    }

    private onFromDateChanged(fromDate?: Date) {
        const {onCustomParametersChanged} = this.props;

        this.setState({
            fromDate,
        }, () => {
            // Use callback and fresh state because from & to date might change simultaneously
            const {serviceProviderSelection, soldToSelection, toDate} = this.state;

            onCustomParametersChanged({
                serviceProviders: serviceProviderSelection,
                soldTos: soldToSelection,
                fromDate,
                toDate,
            });
        });
    }

    private onToDateChanged(toDate?: Date) {
        const {onCustomParametersChanged} = this.props;

        this.setState({
            toDate,
        }, () => {
            // Use callback and fresh state because from & to date might change simultaneously
            const {serviceProviderSelection, soldToSelection, fromDate} = this.state;

            onCustomParametersChanged({
                serviceProviders: serviceProviderSelection,
                soldTos: soldToSelection,
                fromDate,
                toDate,
            });
        });
    }
}

export default withTranslation()(InspectionDetailsComponent);

interface OwnProps {
    periodType: ReportPeriodType;
    serviceProviderGroup?: ServiceProviderGroupWithConfiguration;
    parameters?: InspectionDetailsScheduledReportParameters;
    onCustomParametersChanged: (parameters: InspectionDetailsCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: InspectionDetailsScheduledReportParameters) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    period: ScheduledReportPeriod;
    serviceProviderSelection?: ServiceProviderSelectionsControlValue;
    soldToSelection?: SoldToSelectionsControlValue;
    fromDate?: Date;
    toDate?: Date;
}

type AllState = OwnState;
