import {WithTranslation, withTranslation} from 'react-i18next';
import {faAngleDown, faAngleUp} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Component} from 'react';
import styles from './widget-header-dropdown.module.scss';
import {conditionalClassLister} from '../../utils/class-helpers';
import {DropdownOptionKey} from './dropdown-option-key';
import {getDropDownOptionLabel} from '../../utils/translations/dropdown-option-translation-helper';

export class WidgetHeaderDropdown extends Component<AllProps, AllState> {

    private disableCloseOnBlur = false;

    private lineHeight = 40;

    constructor(props:AllProps) {
        super(props);

        this.state = {
            isOptionsOpen: false,
        };
    }

    public render(): JSX.Element {
        const {options, noItemSelectedLabel, selection, t} = this.props;
        const {isOptionsOpen} = this.state;

        const selectionPanelClasses = conditionalClassLister(styles)({
            selectionPanel: true,
            isOpen: isOptionsOpen,
        });

        const optionsPanelClasses = conditionalClassLister(styles)({
            optionsPanel: true,
            isOpen: isOptionsOpen,
        });

        return (
            <div className={styles.dropdownPanel}>
                <div className={selectionPanelClasses}
                     tabIndex={0}
                     onClick={() => this.toggleOptionsOpen(!isOptionsOpen)}
                     onBlur={() => this.toggleOptionsOpen(false)}>
                    <div className={styles.selectionText}>
                        {selection ? getDropDownOptionLabel(selection.key, t) : noItemSelectedLabel}
                    </div>
                    <div className={styles.selectionIcon}>
                        {isOptionsOpen ?
                            <FontAwesomeIcon icon={faAngleUp}/> : <FontAwesomeIcon icon={faAngleDown}/>
                        }
                    </div>
                </div>
                <div className={optionsPanelClasses} style={isOptionsOpen ? {height: this.lineHeight * options.length} : {height: 0}}>
                    {options.map(option => (
                        <div className={styles.optionItem} key={option.id}
                             onMouseDown={() => this.onDisableCloseOnBlur(true)}
                             onMouseUp={() => this.onDisableCloseOnBlur(false)}
                             onClick={() => this.select(option)}>{getDropDownOptionLabel(option.key, t)}</div>
                    ))}
                </div>
            </div>
        );
    }

    private select(option: DropdownOption) {
        this.setState({isOptionsOpen: false});
        const {onSelectionChanged} = this.props;
        onSelectionChanged(option);
    }

    private toggleOptionsOpen(open: boolean) {
        if (open || (!open && !this.disableCloseOnBlur)) {
            this.setState({isOptionsOpen: open});
        }
    }

    private onDisableCloseOnBlur(disableOnBlur: boolean) {
        this.disableCloseOnBlur = disableOnBlur;
    }
}

export default withTranslation()(WidgetHeaderDropdown);

interface OwnProps {
    options: DropdownOption[];
    onSelectionChanged: (selection: DropdownOption) => void;
    noItemSelectedLabel?: string;
    selection?: DropdownOption;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    isOptionsOpen: boolean;
}

type AllState = OwnState;

export interface DropdownOption {
    id: string;
    key: DropdownOptionKey;
}
