import {action} from 'typesafe-actions';
import {TFunction} from 'i18next';
import {Dashboard, DashboardActionTypes} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const fetchDashboardsRequest = () =>
  action(DashboardActionTypes.FETCH_DASHBOARDS_REQUEST);
export const skipDashboardsRequest = () =>
    action(DashboardActionTypes.SKIP_DASHBOARDS_REQUEST);
export const setDashboards = (dashboards: Dashboard[]) =>
  action(DashboardActionTypes.SET_DASHBOARDS, dashboards);
export const deleteDashboardRequest = (dashboard: Dashboard) =>
    action(DashboardActionTypes.DELETE_DASHBOARD_REQUEST, dashboard);
export const dashboardsActionFailure = (message: string) =>
    action(DashboardActionTypes.DASHBOARD_ACTION_FAILURE);
export const createDashboardRequest = (dashboard: Dashboard) =>
    action(DashboardActionTypes.CREATE_DASHBOARD_REQUEST, dashboard);
export const updateDashboardRequest = (dashboard: Dashboard) =>
    action(DashboardActionTypes.UPDATE_DASHBOARD_REQUEST, dashboard);
export const restoreDefaultDashboards = (t:TFunction) =>
    action(DashboardActionTypes.RESTORE_DEFAULT_DASHBOARDS, t);
