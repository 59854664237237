import axios, {AxiosRequestConfig} from 'axios';
import {ApiEndpoint, buildBaseUrl} from '../api/api-urls';
import AuthenticationApi from '../api/authentication-api';

export class Http {
    public static async get<T>(
        endpoint: ApiEndpoint,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
        skipAuth?: boolean
    ): Promise<T> {
        const url = Http.buildUrl(endpoint, parameters);
        const requestHeaders = skipAuth ? headers : await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
        };

        const result = await axios.get<T>(url, configuration);
        return result.data;
    }

    public static async post<TBody, T>(
        endpoint: ApiEndpoint,
        body: TBody,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<T> {
        const url = Http.buildUrl(endpoint, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
        };

        const result: any = await axios.post<T>(url, body, configuration);

        // #Sam: I have encountered instances were a 200 was returned but an errorType was set
        // These errors were due to the serverless local plugin should not happen on AWS servers
        if (result.data.errorType && result.data.errorType === 'Error') {
            throw result;
        }

        return result.data;
    }

    public static async put<TBody, T>(
        endpoint: ApiEndpoint,
        body: TBody,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<T> {
        const url = Http.buildUrl(endpoint, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
        };

        const result = await axios.put<T>(url, body, configuration);
        return result.data;
    }

    public static async delete<T>(
        endpoint: ApiEndpoint,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<T> {
        const url = Http.buildUrl(endpoint, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
        };

        const result = await axios.delete<T>(url, configuration);
        return result.data;
    }

    public static async getRemoteBlob<TBody>(
        path: string,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<Blob> {
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
            responseType: 'blob',
        };

        const result = await axios.get<any>(path, configuration);
        return result.data;
    }

    public static async getBlob<TBody>(
        endpoint: ApiEndpoint,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<Blob> {
        const url = Http.buildUrl(endpoint, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
            responseType: 'blob',
        };

        const result = await axios.get<any>(url, configuration);
        return result.data;
    }

    public static buildUrl(endpoint: ApiEndpoint, parameters?: { [key: string]: string | number }): string {
        const url = `${buildBaseUrl(endpoint.apiType)}${endpoint.path}`;
        if (parameters) {
            return Object.keys(parameters).reduce((url, parameterKey) => url.replace(`{${parameterKey}}`, parameters[parameterKey].toString()), url);
        }
        return url;
    }

    public static async buildHeaders(headers?: {
        [key: string]: string | number;
    }): Promise<{ [key: string]: string | number }> {
        const token = await AuthenticationApi.getJwtIdToken();
        const baseHeaders = {
            Authorization: `Bearer ${token}`,
        };
        if (headers) {
            return {...baseHeaders, ...headers};
        }
        return baseHeaders;
    }
}
