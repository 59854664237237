import {TFunction} from 'i18next';
import {getMonthLabel} from '../../../../utils/translations/date-translation-helper';
import {
    PurchasingEvolutionData,
} from '../../../../store/analytics/types/purchasing-evolution.types';
import { PurchasingItemType } from '../../../../store/analytics/types/purchasing-distribution.types';

export const monthField = 'month';
export const yoyMonthField = 'yoy-month';
export const servicesNetPriceField = 'servicesNetPriceField';
export const tiresNetPriceField = 'tiresNetPriceField';
export const servicesNetPricePreviousYearField = 'servicesNetPricePreviousYearField';
export const tiresNetPricePreviousYearField = 'tiresNetPricePreviousYearField';
export const totalNetPricePreviousYearField = 'totalNetPricePreviousYearField';

export function buildStackedChartData(data: PurchasingEvolutionData[], translate: TFunction, hiddenFields: string[]): any[] {
    const chartData: any[] = [];

    data.forEach((row) => {
        const month = `${getMonthLabel(new Date(row.date).getMonth(), translate)} ${new Date(row.date).getFullYear()}`;
        const yoyMonth = `${getMonthLabel(new Date(row.date).getMonth(), translate)} ${new Date(row.date).getFullYear() - 1}`;
        let datapoint = chartData.find(d => d.month === month);
        if (!datapoint) {
            datapoint = {};
            datapoint[monthField] = month;
            datapoint[yoyMonthField] = yoyMonth;
            chartData.push(datapoint);
        }

        switch (row.purchasingItemType) {
            case PurchasingItemType.Services:
                datapoint[servicesNetPriceField] = row.netPrice;
                datapoint[servicesNetPricePreviousYearField] = row.netPricePreviousYear;
                break;
            case PurchasingItemType.Tires:
                datapoint[tiresNetPriceField] = row.netPrice;
                datapoint[tiresNetPricePreviousYearField] = row.netPricePreviousYear;
                break;
            default:
                throw Error(`Encountered unknown invoice item type when building chart data ${row.purchasingItemType}`);
        }
    });

    calculateTotalNetPricePreviousYearField(chartData, hiddenFields);

    return chartData;
}

export function calculateTotalNetPricePreviousYearField(chartData: any[], hiddenFields: string[]): void {
    chartData.forEach((datapoint) => {
        let newValue = 0;
        if (!hiddenFields.includes(servicesNetPriceField)) {
            newValue += datapoint[servicesNetPricePreviousYearField];
        }
        if (!hiddenFields.includes(tiresNetPriceField)) {
            newValue += datapoint[tiresNetPricePreviousYearField];
        }
        datapoint[totalNetPricePreviousYearField] = newValue;
    });
}
