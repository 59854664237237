import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import styles from './terms-of-use-popup.module.scss';
import TermsOfUse from './terms-of-use';

class TermsOfUsePopup extends Component<AllProps, OwnState> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    public render(): JSX.Element {
        const {t} = this.props;
        return (

            <div className={styles.popupContainer}>
                <div className={styles.title}>{t('Terms of use')}</div>
                <TermsOfUse/>
                <div className={styles.buttonsPanel}>
                    <button className={styles.button} type='button' onClick={() => this.onClosePopup()}>
                        {t('Close')}
                    </button>
                </div>
            </div>
        );
    }

    private onClosePopup(): void {
        const {onClose} = this.props;
        onClose();
    }
}

export default withTranslation()(TermsOfUsePopup);

interface OwnProps {
    onClose: () => void;
}

interface OwnState {
}

type AllProps = OwnProps & WithTranslation;
