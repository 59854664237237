import {WithTranslation, withTranslation} from 'react-i18next';
import {Component} from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './spare-tire-view.module.scss';
import {TireInfo} from '../../types/vehicle-configuration';
import {
    getDisplayValue,
    getLastSeenValue,
    getPressureValue,
    getTireValueType,
    getTreadDepthValue, isTreadDepthCritical, isTreadDepthRisky
} from './tire-value-helper';
import {conditionalClassLister} from '../../../../utils/class-helpers';
import {ReactComponent as PressureIcon} from '../../../../../assets/icons/noun-speedometer-799752.svg';
import {ReactComponent as TreadDepthIcon} from '../../../../../assets/icons/noun-height-3895408.svg';

export class SpareTireView extends Component<AllProps, AllState> {
    public render(): JSX.Element {
        const {tire, isLeft, t} = this.props;
        const tireValueType = getTireValueType(tire);
        const pressure = getPressureValue(tire, tireValueType);
        const treadDepth = getTreadDepthValue(tire, tireValueType);
        const lastSeen = getLastSeenValue(tire, tireValueType,t);

        const tireClasses = conditionalClassLister(styles)({
            tire: true,
            risk: isTreadDepthRisky(treadDepth),
            critical: isTreadDepthCritical(treadDepth)
        });
        return (
            <div className={styles.spareTireContainer}>
                <div className={styles.spareTireContainerHeader}>
                    <div
                        className={`${styles.spareTireContainerHeaderText} ${isLeft ? `${styles.left}` : `${styles.right}`}`}>
                        <div> {tire.brand?tire.brand:t('Unknown')} ({tire.isRetread ? t('Retread') : t('New')})</div>
                        <div> {tire.description}</div>
                    </div>
                </div>
                <div className={styles.spareTireTopConnectorWrapper}>
                    <div className={styles.spareTireTopConnector}/>
                </div>
                <div className={tireClasses} data-tip data-for={`tire-${tire.number}`}>
                    <ReactTooltip className={styles.tooltip} border effect="solid" place="top"
                                  backgroundColor="#000000" id={`tire-${tire.number}`}>
                        <div className={styles.tireInfoToolTip}>
                        <div>{t('Last Seen')}: {lastSeen}</div>
                        <div>{t('Pressure')}: {getDisplayValue(pressure, t)}</div>
                        <div>{t('Tread Depth')}: {getDisplayValue(treadDepth, t)}</div>
                            </div>
                    </ReactTooltip>
                    <div className={styles.value}><PressureIcon className={`${styles.icon} ${styles.margin}`}/>{getDisplayValue(pressure, t)}</div>
                    <div className={styles.label}>{tire.position}</div>
                    <div className={styles.value}><TreadDepthIcon className={styles.icon}/>{getDisplayValue(treadDepth, t)}</div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(SpareTireView);

interface OwnProps {
    tire: TireInfo;
    isLeft: boolean;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
