import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import styles from './privacy-policy-popup.module.scss';
import TermsOfUse from '../terms-of-use/terms-of-use';

class PrivacyPolicyPopup extends Component<AllProps, OwnState> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    public render(): JSX.Element {
        const {t, policyDocument, shouldConfirm, showTermsOfUse} = this.props;
        const policyWithStyle = `
            <style>
            .policy {
                text-align: left;
                font-size: 16px;
            }
            
            .row {
                width: 100%;
                display: flex;
            }  
            
            .text-center {
                text-align: center;
            }
            
            .col-sm {
                width: 50%;
            }
            </style><div class="policy">${policyDocument}</div>`;

        return (
            <div className={styles.popupContainer}>
                <div className={styles.title}>{t('Privacy policy')}</div>
                <div className={styles.policyDocument}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div dangerouslySetInnerHTML={{__html: policyWithStyle}}/>
                </div>
                {showTermsOfUse ?
                    <>
                        <hr className={styles.divider}/>
                        <div className={styles.title}>{t('Terms of use')}</div>
                        <TermsOfUse/>
                    </>
                    : ''}
                <div className={styles.buttonsPanel}>
                    {shouldConfirm ? (
                        <button className={styles.button} type='button' onClick={() => this.onConfirmPolicy()}>
                            {t('I confirm')}
                        </button>
                    ) : (
                        <button className={styles.button} type='button' onClick={() => this.onClosePolicy()}>
                            {t('Close')}
                        </button>
                    )}
                </div>
            </div>
        );
    }

    private onConfirmPolicy(): void {
        const {
            onConfirm
        } = this.props;
        onConfirm();
    }

    private onClosePolicy(): void {
        const {
            onClose
        } = this.props;
        onClose();
    }
}

export default withTranslation()(PrivacyPolicyPopup);

interface OwnProps {
    policyDocument: string;
    showTermsOfUse: boolean;
    shouldConfirm: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

interface OwnState {
}

type AllProps = OwnProps & WithTranslation;
