import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ApiResult } from '../../types/api-result';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import {
    getReportConfigurationsFailure,
    getReportConfigurationsRequest,
    getReportConfigurationsSuccess,
    updateReportConfigurationRequest,
    updateReportConfigurationsFailure,
    updateReportConfigurationsSuccess,
} from './actions';
import {ReportConfigurationContract, ReportConfigurationActionTypes} from './types';
import ReportManagementApi from '../../api/report-managment-api';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleFetch(action: ActionType<typeof getReportConfigurationsRequest>) {
    try {
        const result: ApiResult<ReportConfigurationContract[]> = yield call(ReportManagementApi.getReportConfigurations);

        if (result.error) {
            yield call(getReportConfigurationsFailure, result.error);
        } else {
            yield put(getReportConfigurationsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleUpdate(action: ActionType<typeof updateReportConfigurationRequest>) {
    try {
        const updateRequest = action.payload;
        const result: ApiResult<ReportConfigurationContract[]> = yield call(ReportManagementApi.updateReportConfigurations, updateRequest);

        if (result.error) {
            yield call(updateReportConfigurationsFailure, result.error);
        } else {
            yield put(updateReportConfigurationsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}


function* watchFetchRequest() {
    yield takeEvery(ReportConfigurationActionTypes.FETCH_REPORT_CONFIGURATIONS_REQUEST, handleFetch);
    yield takeEvery(ReportConfigurationActionTypes.UPDATE_REPORT_CONFIGURATIONS_REQUEST, handleUpdate);
}

function* reportConfigurationSaga() {
    yield all([fork(watchFetchRequest)]);
}

export default reportConfigurationSaga;
