import {WithTranslation, withTranslation} from 'react-i18next';
import {Component} from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './tire-view.module.scss';
import {TireInfo} from '../../types/vehicle-configuration';
import {ReactComponent as PressureIcon} from '../../../../../assets/icons/noun-speedometer-799752.svg';
import {ReactComponent as TreadDepthIcon} from '../../../../../assets/icons/noun-height-3895408.svg';
import {
    getDisplayValue,
    getLastSeenValue,
    getPressureValue,
    getTireValueType,
    getTreadDepthValue, isTreadDepthCritical, isTreadDepthRisky
} from './tire-value-helper';
import {conditionalClassLister} from '../../../../utils/class-helpers';

export class TireView extends Component<AllProps, AllState> {
    public render(): JSX.Element {
        const {tire, showTopConnector, showBottomConnector, t} = this.props;
        const tireValueType = getTireValueType(tire);
        const pressure = getPressureValue(tire, tireValueType);
        const treadDepth = getTreadDepthValue(tire, tireValueType);
        const lastSeen = getLastSeenValue(tire, tireValueType,t);

        const tireClasses = conditionalClassLister(styles)({
            tire: true,
            risk: isTreadDepthRisky(treadDepth),
            critical: isTreadDepthCritical(treadDepth)
        });
        return (
            <div className={styles.tireContainer}>
                {showTopConnector ? <div className={styles.topConnectorWrapper}>
                    <div className={styles.topConnector}/>
                </div> : null}
                <div className={tireClasses} data-tip data-for={`tire-${tire.number}`}>
                    <ReactTooltip className={styles.tooltip} border effect="solid" place="top"
                                  backgroundColor="#000000" id={`tire-${tire.number}`}>
                        <div className={styles.tireInfoToolTip}>
                            <div>{t('Last Seen')}: {lastSeen}</div>
                            <div>{t('Pressure')}: {getDisplayValue(pressure, t)}</div>
                            <div>{t('Tread Depth')}: {getDisplayValue(treadDepth, t)}</div>
                        </div>
                    </ReactTooltip>
                    <div className={styles.value}><PressureIcon className={`${styles.icon} ${styles.margin}`}/>{getDisplayValue(pressure, t)}</div>
                    <div className={styles.label}>{tire.position}</div>
                    <div className={styles.value}><TreadDepthIcon className={styles.icon}/>{getDisplayValue(treadDepth, t)}</div>
                </div>
                {showBottomConnector ? <div className={styles.bottomConnectorWrapper}>
                    <div className={styles.bottomConnector}/>
                </div> : null}
            </div>
        );
    }
}

export default withTranslation()(TireView);

interface OwnProps {
    tire: TireInfo;
    showTopConnector: boolean;
    showBottomConnector: boolean;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
