import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {ReactComponent as GoodYearIcon} from '../../../assets/images/gy-icon.svg';
import logo from '../../../assets/images/logo.png';
import {RouteUrl} from '../../routes';
import {ServiceProviderGroup} from '../../store/service-provider-groups';
import {conditionalClassLister} from '../../utils/class-helpers';
import SidebarFooterItem from './sidebar-footer-item';
import {determineActiveSidebarItem} from './sidebar-item-helper';
import {SidebarFooterItemTypes, SidebarNavItemTypes} from './sidebar-item-types';
import SidebarNavItem from './sidebar-nav-item';
import styles from './sidebar.module.scss';

class Sidebar extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    public render(): JSX.Element {
        const {t, locationPathName, serviceProviderGroup} = this.props;
        const {isOpen} = this.state;
        const activeSidebarItem = determineActiveSidebarItem(locationPathName);

        const sidebarClasses = conditionalClassLister(styles)({
            sidebar: true,
            sidebarOpen: isOpen,
        });

        const {isOpen: isOpen1} = this.state;
        return (
            <div className={sidebarClasses}>
                <div className={styles.topContainer}>
                    <div className={styles.logoPanel}>
                        {isOpen1 ? (
                            <img className={styles.logoBig} src={logo} alt={t('Goodyear logo')}/>
                        ) : (
                            <GoodYearIcon className={styles.logoSmall}/>
                        )}
                    </div>
                    <SidebarNavItem
                        label={t('Analytics').toUpperCase()}
                        type={SidebarNavItemTypes.Analytics}
                        isOpen={isOpen}
                        active={activeSidebarItem === SidebarNavItemTypes.Analytics}
                        onClick={() => this.onNavigate(`/${serviceProviderGroup?.id}${RouteUrl.Analytics}`)}
                    />
                    <SidebarNavItem
                        label={t('Explorer').toUpperCase()}
                        type={SidebarNavItemTypes.Explorer}
                        isOpen={isOpen}
                        active={activeSidebarItem === SidebarNavItemTypes.Explorer}
                        onClick={() => this.onNavigate(`/${serviceProviderGroup?.id}${RouteUrl.Jobs}`)}
                    />
                    <SidebarNavItem
                        label={t('Reports').toUpperCase()}
                        type={SidebarNavItemTypes.Inbox}
                        isOpen={isOpen}
                        active={activeSidebarItem === SidebarNavItemTypes.Inbox}
                        onClick={() => this.onNavigate(`/${serviceProviderGroup?.id}${RouteUrl.Reports}`)}
                    />
                </div>
                <div className={styles.footerContainer}>
                    <SidebarFooterItem
                        label={t('Toggle Sidebar')}
                        type={SidebarFooterItemTypes.Toggle}
                        isOpen={isOpen}
                        onClick={() => this.onToggleOpen()}
                    />
                    <SidebarFooterItem
                        label={t('Support')}
                        type={SidebarFooterItemTypes.Support}
                        isOpen={isOpen}
                        onClick={() => this.onShowSupport()}
                    />
                    <SidebarFooterItem
                        label={t('Privacy policy')}
                        type={SidebarFooterItemTypes.PrivacyPolicy}
                        isOpen={isOpen}
                        onClick={() => this.onShowPrivacyPolicy()}
                    />
                    <SidebarFooterItem
                        label={t('Terms of use')}
                        type={SidebarFooterItemTypes.TermsOfUse}
                        isOpen={isOpen}
                        onClick={() => this.onShowTermsOfUse()}
                    />
                    <SidebarFooterItem
                        label={t('Sign Out')}
                        type={SidebarFooterItemTypes.Logout}
                        isOpen={isOpen}
                        onClick={() => this.onLogout()}
                    />
                </div>
            </div>
        );
    }

    private onNavigate(URL: string) {
        const {onNavigate} = this.props;
        onNavigate(URL);
    }

    private onLogout() {
        const {onLogout} = this.props;
        onLogout();
    }

    private onToggleOpen() {
        const {isOpen} = this.state;
        this.setState({isOpen: !isOpen});
    }

    private onShowTermsOfUse(): void {
        const {onShowTermsOfUse} = this.props;
        onShowTermsOfUse();
    }

    private onShowPrivacyPolicy(): void {
        const {onShowPrivacyPolicy} = this.props;
        onShowPrivacyPolicy();
    }

    private onShowSupport(): void {
        const {onShowSupport} = this.props;
        onShowSupport();
    }
}

export default withTranslation()(Sidebar);

interface OwnProps {
    serviceProviderGroup?: ServiceProviderGroup;
    locationPathName: string;
    onShowPrivacyPolicy: () => void;
    onShowTermsOfUse: () => void;
    onShowSupport: () => void;
    onLogout: () => void;
    onNavigate: (URL: string) => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    isOpen: boolean;
}

type AllState = OwnState;
