import { action } from 'typesafe-actions';
import { SoldTo, SoldToActionTypes } from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getSoldTosRequest = (serviceProviderGroupId: string) =>
    action(SoldToActionTypes.GET_SOLD_TOS_REQUEST, serviceProviderGroupId);
export const getSoldTosSuccess = (soldTos: SoldTo[]) =>
    action(SoldToActionTypes.GET_SOLD_TOS_SUCCESS, soldTos);
export const getSoldTosFailure = (message: string) =>
    action(SoldToActionTypes.GET_SOLD_TOS_FAILURE, message);

