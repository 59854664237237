import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './widget-loading.module.scss';

class WidgetLoading extends Component<AllProps> {

    public render(): JSX.Element {
        const {t} = this.props;
        return (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} style={{margin: '0 10px 0 0'}} spin/>{t('Loading')}
            </div>
        );
    }
}

export default withTranslation()(WidgetLoading);

interface OwnProps {
}

type AllProps = OwnProps & WithTranslation;
