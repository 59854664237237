import {Auth} from 'aws-amplify';
import {AuthenticationResult, Credentials, ResetPassswordResult} from '../store/authentication';
import {ApiResult} from '../types/api-result';
import {AuthenticationErrors} from '../types/authentication-errors';

// eslint-disable-next-line @typescript-eslint/naming-convention
enum AUTH_CHALLENGES {
    NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}

const COGNITO_ERROR_USERNOTFOUND = 'UserNotFoundException';
const COGNITO_ERROR_CODEMISMATCH = 'CodeMismatchException';

export interface CustomAuthData {
    searchUrl?: string
}

class AuthenticationApi {
    public static async authenticateFederated(searchUrl?: string): Promise<ApiResult<AuthenticationResult>> {
        const customData: CustomAuthData = {
            searchUrl
        }
        await Auth.federatedSignIn({
            provider: 'GoodyearADFS',
            customState: JSON.stringify(customData)
        } as any);
        return {data: AuthenticationResult.Success};
    }

    public static async authenticate(
        credentials: Credentials,
        newPassword?: string,
    ): Promise<ApiResult<AuthenticationResult>> {
        try {
            const user = await Auth.signIn(credentials.email, credentials.password);
            if (user) {
                if (!user.challengeName) {
                    return {data: AuthenticationResult.Success};
                } else if (user.challengeName === AUTH_CHALLENGES.NEW_PASSWORD_REQUIRED) {
                    if (newPassword) {
                        const newUser = await Auth.completeNewPassword(
                            user,
                            newPassword,
                            user.challengeParam.requiredAttributes,
                        );
                        if (!newUser.challengeName) {
                            return {data: AuthenticationResult.Success};
                        } else {
                            return {error: AuthenticationErrors.GENERAL_ERROR};
                        }
                    } else {
                        return {data: AuthenticationResult.NewPasswordRequired};
                    }
                } else {
                    return {error: AuthenticationErrors.GENERAL_ERROR};
                }
            } else {
                return {error: AuthenticationErrors.INCORRECT_CREDENTIALS};
            }
        } catch (e) {
            if (e.name === 'UserLambdaValidationException' && e.message.includes('your password has expired')) {
                return {data: AuthenticationResult.PasswordExpired};
            } else {
                return {error: AuthenticationErrors.INCORRECT_CREDENTIALS};
            }
        }
    }

    public static async forgotPassword(email: string): Promise<ApiResult<ResetPassswordResult>> {
        try {
            await Auth.forgotPassword(email);
            return {data: ResetPassswordResult.Success};
        } catch (e) {
            if (e.code === COGNITO_ERROR_USERNOTFOUND) {
                return {error: AuthenticationErrors.USER_NOT_FOUND};
            } else {
                return {error: AuthenticationErrors.GENERAL_ERROR};
            }
        }
    }

    public static async confirmForgotPassword(
        email: string,
        verificationCode: string,
        newPassword: string,
    ): Promise<ApiResult<ResetPassswordResult>> {
        try {
            await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
            return {data: ResetPassswordResult.Success};
        } catch (e) {
            if (e.code === COGNITO_ERROR_CODEMISMATCH) {
                return {error: AuthenticationErrors.VERIFICATION_CODE_MISMATCH};
            } else {
                return {error: AuthenticationErrors.GENERAL_ERROR};
            }
        }
    }

    public static isAuthenticated(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            Auth.currentSession()
                .then((session) => {
                    resolve(session.isValid());
                })
                .catch((err) => {
                    resolve(false);
                });
        });
    }

    public static async getCustomerPolicyVersion(): Promise<string> {
        const idToken = await AuthenticationApi.getIdToken();
        return idToken ? idToken.payload['custom:ConfirmPolicyVersion'] : '';
    }

    public static async getJwtIdToken(): Promise<string> {
        const idToken = await AuthenticationApi.getIdToken();
        return idToken ? idToken.jwtToken : '';
    }

    private static async getIdToken(): Promise<IdToken | undefined> {
        try {
            const user = await Auth.currentAuthenticatedUser();
            if (user) {
                return user.signInUserSession.idToken;
            } else {
                throw Error('Current logged in user not found');
            }
        } catch (e) {
            // if getting the current authenticated user is throwing an exception, this means the refresh token has expired,
            // in this case we need to force refresh the page to have the user redirected to the login page
            window.location.reload();
            return new Promise((resolve) => {
                resolve(undefined);
            });
        }
    }

    public static async refreshSession(): Promise<void> {
        const user: any = await Auth.currentAuthenticatedUser();
        if (user) {
            const session = await Auth.currentSession();
            if (session) {
                user.refreshSession(session.getRefreshToken(), (err, newSession) => {
                });
            } else {
                throw Error('No valid session available to conduct session refresh');
            }
        } else {
            throw Error('Unable to refresh session because current logged in user not found');
        }
    }

    public static async logout(): Promise<void> {
        await Auth.signOut();
    }
}

export default AuthenticationApi;

interface IdToken {
    payload: any;
    jwtToken: string;
}
