import * as am4core from '@amcharts/amcharts4/core';
import {Color} from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {WithTranslation, withTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import ReactTooltip from 'react-tooltip';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {ApplicationState} from '../../../../store';
import {generateUUID} from '../../../../utils/uuid-helpers';
import {
    colorBlue5,
    colorYellow5,
} from '../utils/chart-colors';
import WidgetLoading from '../widget-loading';
import styles from './tires-distribution-widget.module.scss';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {
    createDateRangeWithServiceProvidersAndSoldTosRequest,
    hasInspectionPeriod,
} from '../utils/widget-helper';
import {
    buildCorporateVsNonCorporateChartData,
    buildRetreadVsNewTiresChartData,
    tireCountField,
    tireTypeField,
    typeField,
} from './chart-data-adapter';
import {
    addStandardTooltipStyle,
    addStrokeToColumnChart,
    createXYChart,
    dynamicallySetColorByType,
} from '../utils/chart-utils';
import {
    TireBrandType,
    TiresDistributionData,
    TiresDistributionRequest,
    TireType,
} from '../../../../store/analytics/types/tires-distribution.types';
import {getTiresDistributionRequest} from '../../../../store/analytics';
import {DropdownOptionKey} from '../../../../components/widget-header-dropdown/dropdown-option-key';
import WidgetHeaderDropdown, {DropdownOption} from '../../../../components/widget-header-dropdown/widget-header-dropdown';
import {getTooltipDateRangeTextForWidget, WidgetDateRangeUsedType} from '../../../../utils/tooltip-helper';

am4core.useTheme(am4themes_animated);

class TiresDistributionWidget extends SingleChartWidgetBase<AllProps, AllState, TiresDistributionData[]> {
    private chartId: string;

    private chartColors: Map<string, Color>;

    private readonly OPTION_NEW_VS_RETREAD = {id: '1', key: DropdownOptionKey.NewVsRetread};

    private readonly OPTION_CORPORATE_VS_NON_CORPORATE = {id: '2', key: DropdownOptionKey.CorporateVsNonCorporate};

    constructor(props) {
        super(props);
        this.chartId = generateUUID();

        this.chartColors = new Map<string, Color>();
        this.chartColors.set(TireType.NewTire, am4core.color(colorBlue5));
        this.chartColors.set(TireType.RetreadTire, am4core.color(colorYellow5));
        this.chartColors.set(TireBrandType.NonCorporate, am4core.color(colorBlue5));
        this.chartColors.set(TireBrandType.Corporate, am4core.color(colorYellow5));

        this.state = {
            options: [this.OPTION_NEW_VS_RETREAD, this.OPTION_CORPORATE_VS_NON_CORPORATE],
            selectedOption: this.OPTION_NEW_VS_RETREAD,
        };
    }

    public render(): JSX.Element {
        const {t, data} = this.props;
        const {options, selectedOption} = this.state;

        return (
            <div className={styles.widgetContainer}>
                {data ? <div className={styles.chartView}>
                    <div className={styles.chartHeader}>
                        <div className={styles.headerTitle}>
                            {t('Tires Distribution')}
                            <div className={styles.infoIcon}
                                 data-tip={getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.JOB_DATE_RANGE_USED, t)}
                                 data-for="tires-distribution-info">
                                <FontAwesomeIcon icon={faInfoCircle}/></div>
                            <ReactTooltip className={styles.tooltip} html border effect="solid"
                                          backgroundColor="#000000" id="tires-distribution-info"/>
                        </div>
                        <div className={styles.interactions}>
                            <WidgetHeaderDropdown options={options}
                                                  selection={selectedOption}
                                                  onSelectionChanged={selection => this.onSelect(selection)}/>
                        </div>
                    </div>
                    <div className={styles.chartContent}>
                        <div id={this.chartId} style={{width: '100%'}}/>
                    </div>
                </div> : <WidgetLoading/>}
            </div>
        );
    }

    protected fetchData(snapshot: ControlsSnapshot) {
        this.props.dispatchGetTiresDistributionRequest(createDateRangeWithServiceProvidersAndSoldTosRequest(this.props.controlsSnapshot));
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    protected createChart(data: TiresDistributionData[]): am4charts.XYChart {
        const {t}=this.props;
        const chart = createXYChart(this.chartId);

        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = tireTypeField;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = t('# Tires');

        if (this.state.selectedOption === this.OPTION_NEW_VS_RETREAD) {
            this.addFlatSeries(chart, tireCountField);
            chart.data = buildRetreadVsNewTiresChartData(data, this.props.t);
        } else if (this.state.selectedOption === this.OPTION_CORPORATE_VS_NON_CORPORATE) {
            this.addFlatSeries(chart, tireCountField);
            chart.data = buildCorporateVsNonCorporateChartData(data, this.props.t);
        }


        return chart;
    }

    public addFlatSeries(chart: any, seriesField: string) {
        const {t}=this.props;
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = seriesField;
        series.dataFields.categoryX = tireTypeField;
        series.columns.template.tooltipText = `{categoryX}: [bold]{valueY} ${t('tires')}[/]`;
        series.columns.template.fillOpacity = .8;

        addStandardTooltipStyle(series);
        addStrokeToColumnChart(series);
        dynamicallySetColorByType(series, typeField, (type: string) => this.chartColors.get(type)!);
    }

    private onSelect(option: DropdownOption) {
        this.setState({selectedOption: option}, () => {
            this.redraw(this.props.data);
        });
    }
}

const mapStateToProps = ({analytics}: ApplicationState) => ({
    data: analytics.tiresDistributionData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchGetTiresDistributionRequest: (request: TiresDistributionRequest) => dispatch(getTiresDistributionRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TiresDistributionWidget));

interface PropsFromState {
    data: TiresDistributionData[];
}

interface PropsFromDispatch {
    dispatchGetTiresDistributionRequest: typeof getTiresDistributionRequest;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    options: DropdownOption[];
    selectedOption: DropdownOption;
}

type AllState = OwnState;
