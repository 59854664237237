import {Reducer} from 'redux';
import {RequestStatus, RequestStatusType, SharedActionTypes} from '../shared/types';
import {AuthenticationAction, AuthenticationActionTypes, AuthenticationState, User} from './types';

export function initialState(): AuthenticationState {
    return {
        authenticated: false,
        authenticating: false,
        inProgressUserRetrieval: false,
        newPasswordRequired: false,
        passwordExpired: false,
        resetCodeRequired: false,
        updateCurrentUserSettingsRequestStatus: new RequestStatus(RequestStatusType.New),
    };
}

const reducer: Reducer<AuthenticationState, AuthenticationAction> = (
    state = initialState(),
    action,
): AuthenticationState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS: {
            return initialState();
        }
        case AuthenticationActionTypes.LOGIN_REQUEST: {
            return {...state, loginFailureMessage: undefined, authenticating: true, passwordExpired: false};
        }
        case AuthenticationActionTypes.FEDERATED_LOGIN_REQUEST: {
            return {...state, loginFailureMessage: undefined, authenticating: true, passwordExpired: false};
        }
        case AuthenticationActionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                loginFailureMessage: undefined,
                authenticating: false,
                authenticated: true,
                newPasswordRequired: false,
                passwordExpired: false
            };
        }
        case AuthenticationActionTypes.LOGIN_NEW_PASSWORD_REQUIRED: {
            return {
                ...state,
                loginFailureMessage: undefined,
                authenticating: false,
                newPasswordRequired: true,
                passwordExpired: false
            };
        }
        case AuthenticationActionTypes.LOGIN_PASSWORD_EXPIRED: {
            return {
                ...state,
                loginFailureMessage: undefined,
                authenticating: false,
                newPasswordRequired: false,
                passwordExpired: true,
            };
        }
        case AuthenticationActionTypes.LOGIN_FAILURE: {
            return {...state, loginFailureMessage: action.payload, authenticating: false, passwordExpired: false};
        }
        case AuthenticationActionTypes.LOGOUT_REQUEST: {
            return {...state};
        }
        case AuthenticationActionTypes.FETCH_CURRENT_USER_REQUEST: {
            return {
                ...state,
                userRetrievalFailureMessage: undefined,
                inProgressUserRetrieval: true,
            };
        }
        case AuthenticationActionTypes.FETCH_CURRENT_USER_SUCCESS: {
            return {
                ...state,
                user: action.payload,
                userRetrievalFailureMessage: undefined,
                inProgressUserRetrieval: false,
            };
        }
        case AuthenticationActionTypes.FETCH_CURRENT_USER_FAILURE: {
            return {
                ...state,
                userRetrievalFailureMessage: action.payload,
                inProgressUserRetrieval: false,
            };
        }
        case AuthenticationActionTypes.UPDATE_CURRENT_USER_SETTINGS_REQUEST: {
            return {
                ...state,
                updateCurrentUserSettingsRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case AuthenticationActionTypes.UPDATE_CURRENT_USER_SETTINGS_SUCCESS: {
            const loggedInUser = {...state.user} as User;
            loggedInUser.preferredCurrency = action.payload.preferredCurrency;
            loggedInUser.language = action.payload.language;

            return {
                ...state,
                user: loggedInUser,
                updateCurrentUserSettingsRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case AuthenticationActionTypes.UPDATE_CURRENT_USER_SETTINGS_FAILURE: {
            return {
                ...state,
                updateCurrentUserSettingsRequestStatus: new RequestStatus(
                    RequestStatusType.Failed,
                    action.payload,
                ),
            };
        }
        case AuthenticationActionTypes.FORGOT_PASSWORD_REQUEST: {
            return {...state, resetFailureMessage: undefined};
        }
        case AuthenticationActionTypes.FORGOT_PASSWORD_SUCCESS: {
            return {...state, resetCodeRequired: true, resetFailureMessage: undefined};
        }
        case AuthenticationActionTypes.RESET_FORGOT_PASSWORD_FORM: {
            return {...state, resetCodeRequired: false, resetFailureMessage: undefined};
        }
        case AuthenticationActionTypes.FORGOT_PASSWORD_FAILURE: {
            return {...state, resetCodeRequired: false, resetFailureMessage: action.payload};
        }
        case AuthenticationActionTypes.SET_CURRENT_USER: {
            return {...state, user: action.payload};
        }
        case AuthenticationActionTypes.FETCH_SELECTED_SERVICE_PROVIDER_NETWORK_REQUEST: {
            return {...state};
        }
        case AuthenticationActionTypes.FETCH_SELECTED_SERVICE_PROVIDER_NETWORK_SUCCESS: {
            return {
                ...state,
                serviceProviderGroup: action.payload,
            };
        }
        case SharedActionTypes.UNSELECT_SERVICE_PROVIDER_GROUP: {
            return {
                ...state,
                serviceProviderGroup: undefined,
            };
        }
        default: {
            return state;
        }
    }
};

export {reducer as authenticationReducer};
export {initialState as initialAuthenticationState};
