import {ActionType} from 'typesafe-actions';
import * as actions from './actions';
import * as sharedActions from '../shared/actions';
import {Paged, RequestStatus} from '../shared/types';

export enum ServiceProviderActionTypes {
    GET_SERVICE_PROVIDER_MAPPINGS_REQUEST = '@@serviceProvider/GET_SERVICE_PROVIDER_MAPPINGS_REQUEST',
    GET_SERVICE_PROVIDER_MAPPINGS_SUCCESS = '@@serviceProvider/GET_SERVICE_PROVIDER_MAPPINGS_SUCCESS',
    GET_SERVICE_PROVIDER_MAPPINGS_FAILURE = '@@serviceProvider/GET_SERVICE_PROVIDER_MAPPINGS_FAILURE',

    GET_SERVICE_PROVIDERS_FOR_GROUP_REQUEST = '@@serviceProvider/GET_SERVICE_PROVIDERS_FOR_GROUP_REQUEST',
    GET_SERVICE_PROVIDERS_FOR_GROUP_SUCCESS = '@@serviceProvider/GET_SERVICE_PROVIDERS_FOR_GROUP_SUCCESS',
    GET_SERVICE_PROVIDERS_FOR_GROUP_FAILURE = '@@serviceProvider/GET_SERVICE_PROVIDERS_FOR_GROUP_FAILURE',

    GET_SERVICE_PROVIDERS_REQUEST = '@@serviceProvider/GET_SERVICE_PROVIDERS_REQUEST',
    GET_SERVICE_PROVIDERS_SUCCESS = '@@serviceProvider/GET_SERVICE_PROVIDERS_SUCCESS',
    GET_SERVICE_PROVIDERS_FAILURE = '@@serviceProvider/GET_SERVICE_PROVIDERS_FAILURE',

    GET_SERVICE_PROVIDER_DETAILS_REQUEST = '@@serviceProvider/GET_SERVICE_PROVIDER_DETAILS_REQUEST',
    GET_SERVICE_PROVIDER_DETAILS_SUCCESS = '@@serviceProvider/GET_SERVICE_PROVIDER_DETAILS_SUCCESS',
    GET_SERVICE_PROVIDER_DETAILS_FAILURE = '@@serviceProvider/GET_SERVICE_PROVIDER_DETAILS_FAILURE',
}

export interface ServiceProviderState {
    readonly serviceProviders?: Paged<GlobalServiceProvider>;
    readonly serviceProvidersRequestStatus?: RequestStatus;

    readonly serviceProvidersForGroup?: ServiceProviderWithCityCountry[];
    readonly serviceProvidersForGroupRequestStatus: RequestStatus;

    readonly serviceProviderMappings?: ServiceProviderMapping;
    readonly serviceProviderMappingsRequestStatus: RequestStatus;

    readonly serviceProviderDetails?: ServiceProviderDetails;
    readonly serviceProviderDetailsRequestStatus?: RequestStatus;
}

export type ServiceProviderAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface ServiceProviderRequest {
    page: number;
    itemsPerPage: number;
    search?: string;
    serviceProviderIds?: string[];
}

export interface GlobalServiceProvider {
    id: string;
    vendorId: string;
    name: string;
    city?:string;
    country?:string;
}

export interface ServiceProvider {
    id: string;
    vendorId: string;
    name: string;
}

export interface ServiceProviderWithCityCountry {
    id: string;
    vendorId: string;
    name: string;
    city: string;
    country: string;
}

export interface ServiceProviderMapping {
    serviceProviderIds: string[];
}

export interface ServiceProviderDetailsRequest {
    serviceProviderId: string;
}

export interface ServiceProviderDetails {
    id: string;
    vendorId: string;
    name: string;
    countryKey: string;
    city: string;
    district: string;
    postalCode: string;
    region: string;
    streetAndNumber: string;
}
