import {WithTranslation, withTranslation} from 'react-i18next';
import {Component} from 'react';
import styles from './tire-group-view.module.scss';
import {GroupPosition, TireGroup} from '../../types/vehicle-configuration';
import TireView from './tire-view';

export class TireGroupView extends Component<AllProps, AllState> {
    public render(): JSX.Element {
        const {group, axlePosition,t} = this.props;

        return (
            <div
                className={`${styles.tireGroup} ${group.position === GroupPosition.Left ? `${styles.left}` : `${styles.right}`}`}>
                <div className={`${styles.header} ${group.tires.length > 0 ? `${styles.hasInfo}` : ``}`}>
                    {group.tires.length > 0 ?
                        <div
                            className={`${styles.headerText} ${group.position === GroupPosition.Left ? `${styles.left}` : `${styles.right}`}`}>
                            <div> {group.tires[0].brand?group.tires[0].brand:t('Unknown')} ({group.tires[0].isRetread?t('Retread'):t('New')})</div>
                            <div> {group.tires[0].description}</div>
                        </div> : null}
                </div>
                <div className={styles.tires}>
                    {group.tires.map((tire, i) => (
                        <div className={styles.tire} key={`${axlePosition}-${group.position}-${tire.number}`}>
                            <TireView tire={tire} showTopConnector={i === 0} showBottomConnector={i === 1}/>
                        </div>
                    ))}
                </div>
                {group.tires.length > 1 ?
                    <div className={`${styles.footer} ${group.tires.length > 1 ? `${styles.hasInfo}` : ``}`}>
                        <div
                            className={`${styles.footerText} ${group.position === GroupPosition.Left ? `${styles.left}` : `${styles.right}`}`}>
                            <div> {group.tires[1].description}</div>
                            <div> {group.tires[1].brand?group.tires[0].brand:t('Unknown')} ({group.tires[1].isRetread?t('Retread'):t('New')})</div>
                        </div>
                    </div>
                    : null}
            </div>
        );
    }
}

export default withTranslation()(TireGroupView);

interface OwnProps {
    axlePosition: number;
    group: TireGroup;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
