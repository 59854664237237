import { Http } from '../http/http';
import { ApiResult } from '../types/api-result';
import { ApiUrl } from './api-urls';
import {MaintenanceStatusContract} from '../store/maintenance';

class MaintenanceApi {
    public static async getStatus(): Promise<ApiResult<MaintenanceStatusContract>> {
        const maintenanceStatus = await Http.get<MaintenanceStatusContract>(ApiUrl.MaintenanceStatus,undefined,undefined,undefined,true);
        return { data: maintenanceStatus };
    }
}

export default MaintenanceApi;
