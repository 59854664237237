import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import styles from './vehicles-due-for-inspection.module.scss';
import {
    ReportPeriodType,
    VehiclesDueForInspectionCustomReportParameters,
    VehiclesDueForInspectionScheduledReportParameters,
} from '../../../../../../store/reports';
import {
    SoldToSelection
} from '../../../../../../store/analytics';
import {SoldToSelectionsControlValue} from '../../../../../analytics/dashboard-controls/sold-tos-selector/types/sold-tos-selections-control-value';
import SoldTosSelectorComponent from '../../../../components/sold-tos-selector.component';

class VehiclesDueForInspectionComponent extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {parameters} = this.props;

        let soldTos: SoldToSelection = {includes: [], includeAll: false};
        if (parameters && parameters.soldTos) {
            soldTos = parameters.soldTos;
        }

        this.state = {
            soldToSelection: new SoldToSelectionsControlValue('', soldTos),
        };
    }

    public render(): JSX.Element {
        const {periodType, t} = this.props;
        const {soldToSelection} = this.state;

        return (
            <div className={styles.content}>
                {periodType === ReportPeriodType.Scheduled
                    ? <div className={styles.title}>{t('Parameters')}</div>
                    : null
                }
                <div className={styles.form}>
                    <div className={styles.formRow}>
                        <div className={styles.info}>{t('Select a fleet')}</div>
                        <SoldTosSelectorComponent
                            defaultSoldToSelection={soldToSelection && soldToSelection.value.includes.length > 0 ? soldToSelection : undefined}
                            onSoldToSelectionChange={(controlValue) => this.onSoldToSelectionChanged(controlValue)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private onSoldToSelectionChanged(controlValue: SoldToSelectionsControlValue): void {
        const {periodType, onCustomParametersChanged, onScheduledParametersChanged} = this.props;
        const {soldToSelection} = this.state;

        if (!soldToSelection || (soldToSelection && !controlValue.equals(soldToSelection))) {
            this.setState({
                soldToSelection: controlValue,
            },()=>{
                // Make sure previous state has been applied, as service provider and sold to selection changes will trigger at the same time
                if (periodType === ReportPeriodType.Custom) {
                    onCustomParametersChanged({
                        soldTos: controlValue,
                    });
                }
                if (periodType === ReportPeriodType.Scheduled) {
                    onScheduledParametersChanged({
                        soldTos: controlValue.value,
                    });
                }
            });
        }
    }
}

export default withTranslation()(VehiclesDueForInspectionComponent);

interface OwnProps {
    periodType: ReportPeriodType;
    parameters?: VehiclesDueForInspectionScheduledReportParameters;
    onCustomParametersChanged: (parameters: VehiclesDueForInspectionCustomReportParameters) => void;
    onScheduledParametersChanged: (parameters: VehiclesDueForInspectionScheduledReportParameters) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    soldToSelection?: SoldToSelectionsControlValue;
}

type AllState = OwnState;
