import {TFunction} from 'i18next';
import {CorporateFitmentDistributionData} from "../../../../store/analytics/types/corporata-fitment-distribution.types";
import {getTireTypeLabel} from "../../../../utils/translations/tire-type-translation-helper";
import {groupBy} from "../../../../utils/collection-helper";
import {TireType} from "../../../../store/analytics/types/tires-distribution.types";

export const brandField = 'brandName';
export const tireTypeField = 'tireType';
export const unitsField = 'tireCount';
export const newUnitsField = 'newCount';
export const retreadUnitsField = 'retreadCount';

export function buildChartData(data: CorporateFitmentDistributionData[], translate: TFunction): any[] {
    const result: any[] = [];
    const byBrand = groupBy(data, 'brandName');
    byBrand.forEach((rows, brand) => {
        const newTiresRow = rows.find(r => r.tireType === TireType.NewTire);
        const retreadTiresRow = rows.find(r => r.tireType === TireType.RetreadTire);
        const r = {};
        r[brandField] = brand;
        r[newUnitsField] = newTiresRow ? newTiresRow.tireCount : 0;
        r[retreadUnitsField] = retreadTiresRow ? retreadTiresRow.tireCount : 0;
        result.push(r);
    });

    return result.sort((a, b) => (a[newUnitsField] + a[retreadUnitsField]) - (b[newUnitsField] + b[retreadUnitsField]));
}

export function buildTableData(data: CorporateFitmentDistributionData[], translate: TFunction): any[] {
    const result: any[] = [];

    const newData = data.filter((row) => row.tireType === TireType.NewTire);
    newData.forEach((row) => {
        const r = {};
        r[brandField] = row.brandName;
        r[tireTypeField] = getTireTypeLabel(translate, row.tireType);
        r[unitsField] = row.tireCount;
        result.push(r);
    });
    const retreadsData = data.filter((row) => row.tireType === TireType.RetreadTire);
    if(retreadsData.length > 0) {
        const retreadsSum = retreadsData.reduce((acc, row) => acc + row.tireCount, 0);
        const retreadResult = {};
        retreadResult[brandField] = translate(translate('All '));
        retreadResult[tireTypeField] = getTireTypeLabel(translate, TireType.RetreadTire);
        retreadResult[unitsField] = retreadsSum;
        result.push(retreadResult);
    }
    return result.sort((a, b) => b[unitsField] - a[unitsField]);
}
