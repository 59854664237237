import {Reducer} from 'redux';
import {ReportsAction, ReportsActionTypes, ReportsState} from './types';
import {RequestStatus, RequestStatusType, SharedActionTypes} from '../shared/types';

function initialState(): ReportsState {
    return {
        scheduledReports: [],
    };
}

const reducer: Reducer<ReportsState, ReportsAction> = (state = initialState(), action): ReportsState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_SERVICE_PROVIDER_GROUP: {
            return initialState();
        }
        case ReportsActionTypes.FETCH_INSPECTION_DETAILS_REPORT_REQUEST: {
            return {...state, inspectionDetailsReportRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.FETCH_INSPECTION_DETAILS_REPORT_SUCCESS: {
            return {...state, inspectionDetailsReportRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case ReportsActionTypes.FETCH_INSPECTION_DETAILS_REPORT_FAILURE: {
            return {
                ...state,
                inspectionDetailsReportRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)
            };
        }
        case ReportsActionTypes.FETCH_CUSTOMER_SERVICE_LOCATIONS_REPORT_REQUEST: {
            return {...state, customerServiceLocationsReportRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.FETCH_CUSTOMER_SERVICE_LOCATIONS_REPORT_SUCCESS: {
            return {...state, customerServiceLocationsReportRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case ReportsActionTypes.FETCH_CUSTOMER_SERVICE_LOCATIONS_REPORT_FAILURE: {
            return {
                ...state,
                customerServiceLocationsReportRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)
            };
        }
        case ReportsActionTypes.FETCH_TCU_SENSOR_FITMENTS_REPORT_REQUEST: {
            return {...state, tcuSensorFitmentsRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.FETCH_TCU_SENSOR_FITMENTS_REPORT_SUCCESS: {
            return {...state, tcuSensorFitmentsRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case ReportsActionTypes.FETCH_TCU_SENSOR_FITMENTS_REPORT_FAILURE: {
            return {
                ...state,
                tcuSensorFitmentsRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)
            };
        }
        case ReportsActionTypes.FETCH_CASINGS_REPORT_REQUEST: {
            return {...state, casingsReportRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.FETCH_CASINGS_REPORT_SUCCESS: {
            return {...state, casingsReportRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case ReportsActionTypes.FETCH_CASINGS_REPORT_FAILURE: {
            return {
                ...state,
                casingsReportRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)
            };
        }
        case ReportsActionTypes.FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_REQUEST: {
            return {...state, vehiclesDueForInspectionReportRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_SUCCESS: {
            return {...state, vehiclesDueForInspectionReportRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case ReportsActionTypes.FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_FAILURE: {
            return {
                ...state,
                vehiclesDueForInspectionReportRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)
            };
        }
        case ReportsActionTypes.FETCH_EXTENDED_INSPECTION_REPORT_REQUEST: {
            return {...state, extendedInspectionReportRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.FETCH_EXTENDED_INSPECTION_REPORT_SUCCESS: {
            return {...state, extendedInspectionReportRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case ReportsActionTypes.FETCH_EXTENDED_INSPECTION_REPORT_FAILURE: {
            return {
                ...state,
                extendedInspectionReportRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)
            };
        }
        case ReportsActionTypes.FETCH_TIRE_STOCK_REPORT_REQUEST: {
            return {...state, tireStockReportRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.FETCH_TIRE_STOCK_REPORT_SUCCESS: {
            return {...state, tireStockReportRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case ReportsActionTypes.FETCH_TIRE_STOCK_REPORT_FAILURE: {
            return {
                ...state,
                tireStockReportRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)
            };
        }
        case ReportsActionTypes.FETCH_TIRE_STOCK_COMPANIES_REQUEST: {
            return {...state, tireStockCompaniesRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.FETCH_TIRE_STOCK_COMPANIES_SUCCESS: {
            return {
                ...state,
                tireStockCompanies: action.payload,
                tireStockCompaniesRequestStatus: new RequestStatus(RequestStatusType.Success)
            };
        }
        case ReportsActionTypes.FETCH_TIRE_STOCK_COMPANIES_FAILURE: {
            return {
                ...state,
                tireStockCompaniesRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)
            };
        }
        case ReportsActionTypes.FETCH_SCHEDULED_REPORTS_REQUEST: {
            return {...state, getScheduledReportsRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.FETCH_SCHEDULED_REPORTS_SUCCESS: {
            return {...state, scheduledReports: action.payload, getScheduledReportsRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case ReportsActionTypes.FETCH_SCHEDULED_REPORTS_FAILURE: {
            return {...state, getScheduledReportsRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)};
        }
        case ReportsActionTypes.CREATE_SCHEDULED_REPORT_REQUEST: {
            return {...state, createScheduledReportRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.CREATE_SCHEDULED_REPORT_SUCCESS: {
            const scheduledReports = [...state.scheduledReports];
            scheduledReports.push(action.payload);
            return {...state, createScheduledReportRequestStatus: new RequestStatus(RequestStatusType.Success), scheduledReports };
        }
        case ReportsActionTypes.CREATE_SCHEDULED_REPORT_FAILURE: {
            return {...state, createScheduledReportRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)};
        }
        case ReportsActionTypes.UPDATE_SCHEDULED_REPORT_REQUEST: {
            return {...state, updateScheduledReportRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.UPDATE_SCHEDULED_REPORT_SUCCESS: {
            const scheduledReports = [...state.scheduledReports];
            const reportToReplace = scheduledReports.find(report => report.id === action.payload.id);
            const index = scheduledReports.indexOf(reportToReplace!);
            scheduledReports[index] = action.payload;
            return {...state, updateScheduledReportRequestStatus: new RequestStatus(RequestStatusType.Success), scheduledReports };
        }
        case ReportsActionTypes.UPDATE_SCHEDULED_REPORT_FAILURE: {
            return {...state, updateScheduledReportRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)};
        }
        case ReportsActionTypes.DELETE_SCHEDULED_REPORT_REQUEST: {
            return {...state, deleteScheduledReportRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.DELETE_SCHEDULED_REPORT_SUCCESS: {
            const scheduledReports = state.scheduledReports.filter(report => report.id !== action.payload);
            return {...state, scheduledReports, deleteScheduledReportRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case ReportsActionTypes.DELETE_SCHEDULED_REPORT_FAILURE: {
            return {...state, deleteScheduledReportRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)};
        }
        case ReportsActionTypes.FETCH_REPORT_INFO_REQUEST: {
            return {...state, reportInfoRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.FETCH_REPORT_INFO_SUCCESS: {
            return {...state, reportInfoRequestStatus: new RequestStatus(RequestStatusType.Success), reportInfo: action.payload};
        }
        case ReportsActionTypes.FETCH_REPORT_INFO_FAILURE: {
            return {...state, reportInfoRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)};
        }
        case ReportsActionTypes.FETCH_REPORT_DOWNLOAD_REQUEST: {
            return {...state, reportDownloadRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case ReportsActionTypes.FETCH_REPORT_DOWNLOAD_SUCCESS: {
            return {...state, reportDownloadRequestStatus: new RequestStatus(RequestStatusType.Success)};
        }
        case ReportsActionTypes.FETCH_REPORT_DOWNLOAD_FAILURE: {
            return {...state, reportDownloadRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload)};
        }
        default: {
            return state;
        }
    }
};

export {reducer as reportsReducer};
export {initialState as initialReportsState};
