import {WidgetType} from '../../../store/dashboard';
import {ControlsSnapshot} from '../dashboard-controls/controls-snapshot';
import KpiBox from './kpi-box/kpi-box';
import JobsDistributionWidget from './jobs-distribution-widget/jobs-distribution-widget';
import PurchasingDistributionWidget from './purchasing-distribution-widget/purchasing-distribution-widget';
import JobsTable from '../../jobs/jobs-table/jobs-table';
import TiresDistributionWidget from './tires-distribution-widget/tires-distribution-widget';
import PurchasingEvolutionWidget from './purchasing-evolution-widget/purchasing-evolution-widget';
import TireBrandDistributionWidget from './tire-brand-distribution-widget/tire-brand-distribution-widget';
import PurchasingDistributionKpiWidget from './purchasing-distribution-kpi-widget/purchasing-distribution-kpi-widget';
import TopSoldTosWidget from './top-sold-tos-widget/top-sold-tos-widget';
import CorporateFitmentDistributionWidget
    from "./corporate-fitment-distribution-widget/corporate-fitment-distribution-widget";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function renderWidget(type: WidgetType, configuration: any, controlsSnapshot?: ControlsSnapshot): JSX.Element {
    if (controlsSnapshot) {
        switch (type) {
            case WidgetType.KpiBox:
                return <KpiBox controlsSnapshot={controlsSnapshot} configuration={configuration} />;
            case WidgetType.JobsDistribution:
                return <JobsDistributionWidget  controlsSnapshot={controlsSnapshot} />;
            case WidgetType.TopSoldTos:
                return <TopSoldTosWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.PurchasingDistribution:
                return <PurchasingDistributionWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.PurchasingEvolution:
                return <PurchasingEvolutionWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.JobsTable:
                return <JobsTable controlsSnapshot={controlsSnapshot} />;
            case WidgetType.TiresDistribution:
                return <TiresDistributionWidget controlsSnapshot={controlsSnapshot} />;
            case WidgetType.TireBrandDistribution:
                return <TireBrandDistributionWidget controlsSnapshot={controlsSnapshot} />
            case WidgetType.CorporateFitmentDistribution:
                return <CorporateFitmentDistributionWidget controlsSnapshot={controlsSnapshot} />
            case WidgetType.PurchasingDistributionKpi:
                return <PurchasingDistributionKpiWidget controlsSnapchot={controlsSnapshot} />
            default:
                return <div />;
        }
    }
    return <div />;
}
