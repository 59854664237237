import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {VehicleConfiguration} from '../../types/vehicle-configuration';
import styles from './vehicle-configuration-view.module.scss';
import AxleView from './axle-view';
import SpareTireView from './spare-tire-view';
import {conditionalClassLister} from '../../../../utils/class-helpers';

export class VehicleConfigurationView extends Component<AllProps, AllState> {
    public render(): JSX.Element {
        const {configuration} = this.props;
        const lastAxle = configuration?.axles[configuration?.axles.length - 1];
        const verticalAxisClasses = conditionalClassLister(styles)({
            verticalAxle: true,
            lastAxisDoubleTires: lastAxle && (lastAxle.leftTires.tires.length > 1 || lastAxle.rightTires.tires.length > 1)
        });

        return (
            <div>
                {configuration ? (
                    <div className={styles.viewerContainer}>
                        <div className={styles.vehicleBase}>
                            <div className={verticalAxisClasses}/>
                            <div className={styles.axlesContainer}>
                                {configuration.axles.map((axle) => (
                                    <AxleView key={axle.position} axle={axle}/>
                                ))}
                            </div>
                        </div>
                        <div className={styles.sparesBase}>
                            <div className={styles.sparesContainer}>
                                {configuration.spareTires.map((spareTire, i) => (
                                    <div className={styles.spareTire} key={`spare-${spareTire.number}`}>
                                        <SpareTireView isLeft={i % 2 === 0} tire={spareTire}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

export default withTranslation()(VehicleConfigurationView);

interface OwnProps {
    configuration?: VehicleConfiguration;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
