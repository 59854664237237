import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import {ApiResult} from '../../types/api-result';
import {VehiclesActionTypes, VehicleWithFitmentsAndJobs} from './types';
import {handleUnexpectedErrorWithToast} from '../http-error-handler';
import VehiclesApi from '../../api/vehicles-api';
import { getVehicleFailure, getVehicleRequest, getVehicleSuccess } from './actions';

function *handleFailure(err: any) {
    yield call(handleUnexpectedErrorWithToast, err);
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleGet(action: ActionType<typeof getVehicleRequest>) {
    try {
        const request = action.payload;
        const result: ApiResult<VehicleWithFitmentsAndJobs> = yield call(VehiclesApi.getVehicle, request);

        if (result.error) {
            yield call(getVehicleFailure, result.error);
        } else {
            yield put(getVehicleSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleFailure, err);
    }
}

function* watchRequests() {
    yield takeEvery(VehiclesActionTypes.GET_VEHICLE_REQUEST, handleGet);
}

function* vehiclesSaga() {
    yield all([fork(watchRequests)]);
}

export default vehiclesSaga;
