import * as am4charts from '@amcharts/amcharts4/charts';
import { IDisposer } from '@amcharts/amcharts4/core';
import { Component } from 'react';

import { ControlsSnapshot } from '../dashboard-controls/controls-snapshot';

export abstract class SingleChartWidgetBase<TProps extends WidgetProps<TData>, TState, TData> extends Component<
    TProps,
    TState
> {
    private rawData?: TData = undefined;

    private chart?: am4charts.Chart = undefined;

    protected disposibles: IDisposer[] = [];

    public componentDidMount():void {
        const { controlsSnapshot } = this.props;

        if (controlsSnapshot && this.validateControlsSnapshot(controlsSnapshot)) {
            this.fetchData(controlsSnapshot);
        }
    }

    public componentDidUpdate(prevProps: Readonly<TProps>): void {
        const { controlsSnapshot, data } = this.props;

        if (
            controlsSnapshot &&
            !controlsSnapshot.equals(prevProps.controlsSnapshot) &&
            this.validateControlsSnapshot(controlsSnapshot)
        ) {
            // #Sam: probably better to update chart instead of redrawing it entirely

            this.fetchData(controlsSnapshot);
        }

        if (data && data !== prevProps.data) {
            this.redraw(data);
        }

        this.afterComponentDidUpdate(prevProps);
    }

    public componentWillUnmount(): void {
        this.disposeChart();
        this.disposibles.forEach((d) => d.dispose());
    }

    public redraw(data: TData): void {
        this.rawData = data;
        this.disposeChart();
        this.chart = this.createChart(data);
    }

    public refreshChart(): void {
        if (this.rawData) {
            this.redraw(this.rawData);
        }
    }

    protected disposeChart(): void {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    protected afterComponentDidUpdate(prevProps: Readonly<TProps>):void {}

    protected abstract createChart(data: TData): am4charts.Chart;

    protected abstract validateControlsSnapshot(snapshot: ControlsSnapshot): boolean;

    protected abstract fetchData(snapshot: ControlsSnapshot): void;
}

export interface WidgetProps<TData> {
    controlsSnapshot: ControlsSnapshot;
    data: TData;
}
