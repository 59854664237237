import {Reducer} from 'redux';
import {SoldToAction, SoldToActionTypes, SoldToState} from './types';
import {RequestStatus, RequestStatusType, SharedActionTypes} from '../shared/types';

function initialState(): SoldToState {
    return {
        soldTosRequestStatus: new RequestStatus(RequestStatusType.New)
    };
}

const reducer: Reducer<SoldToState, SoldToAction> = (state = initialState(), action): SoldToState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_SERVICE_PROVIDER_GROUP: {
            return initialState();
        }
        case SoldToActionTypes.GET_SOLD_TOS_REQUEST: {
            return {...state, soldTosRequestStatus: new RequestStatus(RequestStatusType.InProgress)};
        }
        case SoldToActionTypes.GET_SOLD_TOS_SUCCESS: {
            return {
                ...state,
                soldTos: action.payload,
                soldTosRequestStatus: new RequestStatus(RequestStatusType.Success)
            };
        }
        case SoldToActionTypes.GET_SOLD_TOS_FAILURE: {
            return {...state, soldTosRequestStatus: new RequestStatus(RequestStatusType.Failed)};
        }

        default: {
            return state;
        }
    }
};

export {reducer as soldTosReducer};
export {initialState as initialSoldTosState};
