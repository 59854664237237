import { action } from 'typesafe-actions';
import {ReportConfigurationActionTypes, ReportConfigurationContract, UpdateReportConfigurationsRequest} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getReportConfigurationsRequest = () => action(ReportConfigurationActionTypes.FETCH_REPORT_CONFIGURATIONS_REQUEST);
export const getReportConfigurationsSuccess = (reportConfigurations: ReportConfigurationContract[]) => action(ReportConfigurationActionTypes.FETCH_REPORT_CONFIGURATIONS_SUCCESS, reportConfigurations);
export const getReportConfigurationsFailure = (message: string) => action(ReportConfigurationActionTypes.FETCH_REPORT_CONFIGURATIONS_FAILURE, message);
export const updateReportConfigurationRequest = (updateRequest: UpdateReportConfigurationsRequest) => action(ReportConfigurationActionTypes.UPDATE_REPORT_CONFIGURATIONS_REQUEST, updateRequest);
export const updateReportConfigurationsSuccess = (reportConfigurations: ReportConfigurationContract[]) => action(ReportConfigurationActionTypes.UPDATE_REPORT_CONFIGURATIONS_SUCCESS, reportConfigurations);
export const updateReportConfigurationsFailure = (message: string) => action(ReportConfigurationActionTypes.UPDATE_REPORT_CONFIGURATIONS_FAILURE, message);
