import {action} from 'typesafe-actions';
import {Job, JobsActionTypes, JobsExplorerRequest, TireServiceDetails, TireServiceDetailsRequest} from './types';
import {Paged} from '../shared/types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getJobsRequest = (request: JobsExplorerRequest) =>
  action(JobsActionTypes.GET_JOBS_REQUEST, request);
export const getJobsSuccess = (jobs: Paged<Job>) =>
  action(JobsActionTypes.GET_JOBS_SUCCESS, jobs);
export const getJobsFailure = (message: string) =>
    action(JobsActionTypes.GET_JOBS_FAILURE, message)

export const getTireServiceRequest = (request:TireServiceDetailsRequest) =>
    action(JobsActionTypes.GET_TIRE_SERVICE_REQUEST, request);
export const getTireServiceSuccess = (tireService: TireServiceDetails) =>
    action(JobsActionTypes.GET_TIRE_SERVICE_SUCCESS, tireService);
export const getTireServiceFailure = (message: string) =>
    action(JobsActionTypes.GET_TIRE_SERVICE_FAILURE, message)
