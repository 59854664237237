import {ActionType} from 'typesafe-actions';
import * as actions from './actions';
import * as sharedActions from '../shared/actions';
import {LocalLanguageType, RequestStatus} from '../shared/types';
import {InspectionType, JobType} from '../jobs';

export enum VehiclesActionTypes {
    GET_VEHICLE_REQUEST = '@@vehicles/GET_VEHICLE_REQUEST',
    GET_VEHICLE_SUCCESS = '@@vehicles/GET_VEHICLE_SUCCESS',
    GET_VEHICLE_FAILURE = '@@vehicles/GET_VEHICLE_FAILURE',
}

export type VehiclesAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface VehiclesState {
    readonly vehicle?: VehicleWithFitmentsAndJobs;
    readonly vehicleRequestStatus?: RequestStatus;
}

export interface VehicleRequest {
    vehicleId: string;
    serviceProviderGroupId: string;
    language: LocalLanguageType
}
export interface Vehicle {
    id: string;
    type: string;
    licensePlate: string;
    hasTPMS:boolean;
    hasDOR:boolean;
    activeFrom?: string;
    activeUntil?: string;
    chassisNr: string;
    assetManufacturer: string;
    reference: string;
}

export interface Tire {
    id: string;
    brand: string;
    serialNumber: string;
    materialType: string;
    description: string;
    sizeTypeId:string;
    rimDiameter:string;
    design:string;
}

export interface TireFitment {
    id: string;
    axle: number;
    positionType: string;
    positionFromEdge: number;
    activeFrom: string;
    activeUntil: string;
    tire: Tire;
    displayNumber?: number;
    lastSeen?: string,
    treadDepthLastSeen?: number,
    pressureLastSeen?:number;
    lastSeenDOR?:string;
    treadDepthLastSeenDOR?:number;
    pressureLastSeenDOR?:number;
}

export interface VehicleJobs {
    jobId:string;
    job: string;
    type: JobType;
    inspectionTypeIndicator: InspectionType;
    requestedDate: string;
    startDate: string;
    odometerReading: string;
}

export interface VehicleWithFitmentsAndJobs {
    vehicle: Vehicle;
    tireFitments: TireFitment[];
    vehicleJobs: VehicleJobs[];
}
