import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {ReportType} from '../../report-catalog';
import styles from './report-scheduler-data.module.scss';
import {
    CasingsScheduledReportParameters, CustomerServiceLocationsScheduledReportParameters,
    ExtendedInspectionScheduledReportParameters,
    InspectionDetailsScheduledReportParameters,
    ReportPeriodType,
    ScheduledReportParameters,
    TCUSensorFitmentsScheduledReportParameters,
    TireStockScheduledReportParameters,
    VehiclesDueForInspectionScheduledReportParameters,
} from '../../../../store/reports';
import InspectionDetailsComponent from './report-data-components/inspection-details/inspection-details.component';
import ExtendedInspectionComponent from './report-data-components/extended-inspection/extended-inspection.component';
import VehiclesDueForInspectionComponent
    from './report-data-components/vehicles-due-for-inspection/vehicles-due-for-inspection.component';
import CasingsComponent from './report-data-components/casings/casings.component';
import TireStockComponent from "./report-data-components/tire-stock/tire-stock.component";
import TcuSensorFitmentsComponent from "./report-data-components/tcu-sensor-fitments/tcu-sensor-fitments.component";
import CustomerServiceLocationsComponent
    from "./report-data-components/customer-service-locations/customer-service-locations.component";

class ReportSchedulerDataComponent extends Component<AllProps, AllState> {

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any) {
    }

    public render(): JSX.Element {
        const {reportType, parameters} = this.props;

        return (
            <div className={styles.container}>
                {reportType === ReportType.InspectionDetails ? (
                    <InspectionDetailsComponent
                        periodType={ReportPeriodType.Scheduled}
                        parameters={parameters as InspectionDetailsScheduledReportParameters}
                        onCustomParametersChanged={() => {}}
                        onScheduledParametersChanged={(p) => this.onParametersChanged(p)}
                    />
                ) : ''}
                {reportType === ReportType.ExtendedInspection ? (
                    <ExtendedInspectionComponent
                        periodType={ReportPeriodType.Scheduled}
                        parameters={parameters as ExtendedInspectionScheduledReportParameters}
                        onCustomParametersChanged={() => {}}
                        onScheduledParametersChanged={(p) => this.onParametersChanged(p)}
                    />
                ) : ''}
                {reportType === ReportType.VehiclesDueForInspectionDetails ? (
                    <VehiclesDueForInspectionComponent
                        periodType={ReportPeriodType.Scheduled}
                        parameters={parameters as VehiclesDueForInspectionScheduledReportParameters}
                        onCustomParametersChanged={() => {}}
                        onScheduledParametersChanged={(p) => this.onParametersChanged(p)}
                    />
                ) : ''}
                {reportType === ReportType.Casings ? (
                    <CasingsComponent
                        periodType={ReportPeriodType.Scheduled}
                        parameters={parameters as CasingsScheduledReportParameters}
                        onCustomParametersChanged={() => {}}
                        onScheduledParametersChanged={(p) => this.onParametersChanged(p)}
                    />
                ) : ''}
                {reportType === ReportType.TireStock ? (
                    <TireStockComponent
                        periodType={ReportPeriodType.Scheduled}
                        parameters={parameters as TireStockScheduledReportParameters}
                        onCustomParametersChanged={() => {}}
                        onScheduledParametersChanged={(p) => this.onParametersChanged(p)}
                    />
                ) : ''}
                {reportType === ReportType.TCUSensorFitments ? (
                    <TcuSensorFitmentsComponent
                        periodType={ReportPeriodType.Scheduled}
                        parameters={parameters as TCUSensorFitmentsScheduledReportParameters}
                        onCustomParametersChanged={() => {}}
                        onScheduledParametersChanged={(p) => this.onParametersChanged(p)}
                    />
                ) : ''}
                {reportType === ReportType.CustomerServiceLocations ? (
                    <CustomerServiceLocationsComponent
                        periodType={ReportPeriodType.Scheduled}
                        parameters={parameters as CustomerServiceLocationsScheduledReportParameters}
                        onCustomParametersChanged={() => {}}
                        onScheduledParametersChanged={(p) => this.onParametersChanged(p)}
                    />
                ) : ''}
            </div>

        );
    }

    private onParametersChanged(parameters: ScheduledReportParameters): void {
        const {onParametersChanged} = this.props;

        onParametersChanged(parameters);
    }

}

export default withTranslation()(ReportSchedulerDataComponent);

interface OwnProps {
    reportType: ReportType
    parameters: ScheduledReportParameters;
    onParametersChanged: (parameters: ScheduledReportParameters) => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {

}

type AllState = OwnState;
