import { Dispatch } from 'redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import {
    PurchasingDistributionData,
    PurchasingDistributionRequest,
    PurchasingItemType,
} from '../../../../store/analytics/types/purchasing-distribution.types';
import { ApplicationState } from '../../../../store';
import { getPurchasingDistributionDataRequest } from '../../../../store/analytics';
import { ControlsSnapshot } from '../../dashboard-controls/controls-snapshot';
import { CurrencyInfo } from '../../../../store/service-provider-groups';
import { buildRequest, isValidSnapshot } from './request-builder';
import styles from './purchasing-distribution-kpi-widget.module.scss';
import WidgetLoading from '../widget-loading';
import { conditionalClassList } from '../../../../utils/class-helpers';
import {getTooltipDateRangeTextForWidget, WidgetDateRangeUsedType} from '../../../../utils/tooltip-helper';

class PurchasingDistributionKpiWidget extends Component<AllProps, AllState> {
    public constructor(props) {
        super(props);

        const {data} = this.props;

        if (data) {
            let tires = 0;
            let services = 0;

            if (data) {
                data.forEach((row) => {
                    switch (row.purchasingItemType) {
                        case PurchasingItemType.Tires:
                            tires += row.netPrice;
                            break;
                        case PurchasingItemType.Services:
                            services += row.netPrice;
                            break;
                        default:
                            throw Error(`Encountered unknown purchasing item type when building chart data ${row.purchasingItemType}`);
                    }
                });
            }

            const total = tires + services;
            this.state = {
                tiresPercent: total !== 0 ? tires / total : 0,
                servicesPercent: total !== 0 ? services / total : 0,
            }
        } else {
            this.state = {
                tiresPercent: 0,
                servicesPercent: 0,
            }
        }
    }

    public componentDidMount(): void {
        const {controlsSnapshot, dispatchGetPurchasingDistributionRequest, activeCurrency} = this.props;
        if (controlsSnapshot && isValidSnapshot(controlsSnapshot) && activeCurrency) {
            dispatchGetPurchasingDistributionRequest(buildRequest(controlsSnapshot, activeCurrency));
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {controlsSnapshot, dispatchGetPurchasingDistributionRequest, activeCurrency, data} = this.props;
        if (controlsSnapshot && !controlsSnapshot.equals(prevProps.controlsSnapshot) && isValidSnapshot(controlsSnapshot) && activeCurrency) {
            dispatchGetPurchasingDistributionRequest(buildRequest(controlsSnapshot, activeCurrency));
        }

        if (data && data !== prevProps.data) {
            let tires = 0;
            let services = 0;

            if (data) {
                data.forEach((row) => {
                    switch (row.purchasingItemType) {
                        case PurchasingItemType.Tires:
                            tires += row.netPrice;
                            break;
                        case PurchasingItemType.Services:
                            services += row.netPrice;
                            break;
                        default:
                            throw Error(`Encountered unknown purchasing item type when building chart data ${row.purchasingItemType}`);
                    }
                });
            }

            const total = tires + services;
            if (total !== 0) {
                this.setState({
                    tiresPercent: tires / total,
                    servicesPercent: services / total,
                });
            }
        }
    }

    public render(): JSX.Element {
        const {data, t} = this.props;
        const {tiresPercent, servicesPercent} = this.state;

        const servicesClasses = conditionalClassList(styles, {
            servicesSection: true,
            allBorderRadius: servicesPercent > 0.01 && tiresPercent < 0.01,
        });

        const tiresClasses = conditionalClassList(styles, {
            tiresSection: true,
            allBorderRadius: tiresPercent > 0.01 && servicesPercent < 0.01,
        });

        return (
            <div className={styles.widgetContainer}>
                {data ? (
                    <>
                        <ReactTooltip className={styles.tooltip} html border effect='solid' backgroundColor='#000000' id="tires"/>
                        <ReactTooltip className={styles.tooltip} html border effect='solid' backgroundColor='#000000' id="services"/>
                        <div className={styles.chartView}>
                            <div className={styles.titlePanel}>
                                {t('Purchasing Distribution').toUpperCase()}
                                        <div className={styles.infoIcon}
                                             data-tip={getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.PURCHASING_DATE_RANGE_USED,t)}
                                             data-for="purchasing-distribution-kpi-info">
                                            <FontAwesomeIcon icon={faInfoCircle}/></div>
                                        <ReactTooltip className={styles.tooltip} html border effect="solid"
                                                      backgroundColor="#000000" id="purchasing-distribution-kpi-info"/>
                            </div>
                            <div className={styles.contentPanel}>
                                <div className={styles.graphContainer}>
                                    <div className={styles.barGraph}>
                                        <div
                                            style={{width: `${servicesPercent > 0.01 ? servicesPercent * 100 : 0}%`}}
                                            data-tip={`Services: <span style='font-weight:bold'>${this.formatValue(servicesPercent)}%</span>`}
                                            data-for="services">
                                            <div className={servicesClasses}>
                                                {servicesPercent > 0.15
                                                    ? `${this.formatValue(servicesPercent)}%`
                                                    : ''}
                                            </div>
                                        </div>
                                        <div
                                            style={{width: `${tiresPercent > 0.01 ? tiresPercent * 100 : 0}%`}}
                                            data-tip={`Tires: <span style='font-weight:bold'>${this.formatValue(tiresPercent)}%</span>`}
                                            data-for="tires">
                                            <div className={tiresClasses}>
                                                {tiresPercent > 0.15
                                                    ? `${this.formatValue(tiresPercent)}%`
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.legend}>
                                        {servicesPercent > 0.01 ? <div className={styles.sectionLabel}><span
                                            className={styles.servicesDot}/>{t('Services')}</div> : null}
                                        {tiresPercent > 0.01 ? <div className={styles.sectionLabel}><span
                                            className={styles.tiresDot}/>{t('Tires')}</div> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <WidgetLoading/>
                )}
            </div>
        );
    }

    private formatValue(num: number): string {
        return (num * 100).toFixed(1);
    }
}

const mapStateToProps = ({analytics, authentication}: ApplicationState) => ({
    data: analytics.purchasingDistributionData,
    activeCurrency: authentication.serviceProviderGroup!.activeCurrency,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchGetPurchasingDistributionRequest:(request: PurchasingDistributionRequest) => dispatch(getPurchasingDistributionDataRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PurchasingDistributionKpiWidget));

interface PropsFromState {
    data: PurchasingDistributionData[];
    activeCurrency: CurrencyInfo;
}

interface PropsFromDispatch {
    dispatchGetPurchasingDistributionRequest: typeof getPurchasingDistributionDataRequest;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    tiresPercent: number;
    servicesPercent: number;
}

type AllState = OwnState;
