import { Http } from '../http/http';
import {
    CreateServiceProviderGroupRequest,
    ServiceProviderGroup,
    ServiceProviderGroupConfiguration, ServiceProviderGroupsSearchRequest,
    ServiceProviderGroupWithConfiguration,
    UpdateServiceProviderGroupRequest,
} from '../store/service-provider-groups';
import { ApiResult } from '../types/api-result';
import { ApiUrl, UrlParamBuilder } from './api-urls';
import { Paged } from '../store/shared/types';

class ServiceProviderGroupsApi {
    public static async createServiceProviderGroup(
        serviceProviderGroupRequest: CreateServiceProviderGroupRequest,
    ): Promise<ApiResult<ServiceProviderGroup>> {
        const result = await Http.post<CreateServiceProviderGroupRequest, ServiceProviderGroup>(ApiUrl.ServiceProviderGroupCreate, serviceProviderGroupRequest);
        return { data: result };
    }

    public static async deleteServiceProviderGroup(serviceProviderGroup: ServiceProviderGroup): Promise<ApiResult<string>> {
        const result = await Http.delete<string>(
            ApiUrl.ServiceProviderGroup,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroup.id).build(),
        );
        return { data: result };
    }

    public static async getServiceProviderGroups(): Promise<ApiResult<ServiceProviderGroup[]>> {
        const result = await Http.get<ServiceProviderGroup[]>(ApiUrl.ServiceProviderGroups);
        return { data: result };
    }

    public static async getServiceProviderGroupWithConfiguration(
        serviceProviderGroupId: string,
    ): Promise<ApiResult<ServiceProviderGroupWithConfiguration>> {
        const result = await Http.get<ServiceProviderGroupWithConfiguration>(
            ApiUrl.ServiceProviderGroup,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return { data: result };
    }

    public static async updateServiceProviderGroup(
        serviceProviderGroupId: string,
        request: UpdateServiceProviderGroupRequest,
    ): Promise<ApiResult<ServiceProviderGroupConfiguration>> {
        const result = await Http.put<UpdateServiceProviderGroupRequest, ServiceProviderGroupConfiguration>(
            ApiUrl.ServiceProviderGroup,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return { data: result };
    }

    public static async searchServiceProviderGroups(request: ServiceProviderGroupsSearchRequest): Promise<ApiResult<Paged<ServiceProviderGroup>>> {
        const result = await Http.post<ServiceProviderGroupsSearchRequest, Paged<ServiceProviderGroup>>(
            ApiUrl.ServiceProviderGroupsSearch,
            request,
        );
        return {data: result};
    }
}

export default ServiceProviderGroupsApi;
