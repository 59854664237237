import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {ApplicationState} from '../../../../../store';
import {ServiceProviderGroup, getServiceProviderGroupsRequest} from '../../../../../store/service-provider-groups';
import {closePopup} from '../../../../../store/popup';
import {RequestStatus} from '../../../../../store/shared/types';
import {conditionalClassLister} from '../../../../../utils/class-helpers';
import {getEmailDomain} from '../../../../../utils/user-helper';
import ServiceProviderGroupSelectionItem from './service-provider-group-selection-item';
import styles from './service-provider-group-selection-popup.module.scss';

class ServiceProviderGroupSelectionPopup extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {dispatchGetAllServiceProviderGroups, originalSelectedServiceProviderGroups} = this.props;
        dispatchGetAllServiceProviderGroups();

        this.state = {
            selectedServiceProviderGroups: [...originalSelectedServiceProviderGroups],
            visibleServiceProviderGroups: [],
            allowedServiceProviderGroups: [],
            showSelectedOnly: false,
            lastSearchQuery: '',
            hiddenCount: 0,
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {serviceProviderGroups, email} = this.props;
        if (serviceProviderGroups && prevProps && prevProps.serviceProviderGroups && serviceProviderGroups !== prevProps.serviceProviderGroups) {
            const userDomain = getEmailDomain(email);
            const allowedServiceProviderGroups = serviceProviderGroups.filter((fc) => fc.allowedDomains.includes(userDomain));
            const hiddenCount = serviceProviderGroups.length - allowedServiceProviderGroups.length;
            this.setState({
                hiddenCount,
                allowedServiceProviderGroups,
                visibleServiceProviderGroups: allowedServiceProviderGroups,
            });
        }
    }

    public render(): JSX.Element {
        const {email, t, serviceProviderGroupsRequestStatus,canChange} = this.props;
        const {selectedServiceProviderGroups, visibleServiceProviderGroups, showSelectedOnly, hiddenCount} = this.state;
        const userDomain = getEmailDomain(email);

        const showSelectedOnlyClass = conditionalClassLister(styles)({
            link: true,
            active: showSelectedOnly,
        });

        const showAllClass = conditionalClassLister(styles)({
            link: true,
            active: !showSelectedOnly,
        });

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupHeader}/>
                <div className={styles.popupBody}>
                    <div className={styles.popupBodyHeader}>
                        <div className={styles.title}>{t('Make your selection')}</div>
                        <div className={styles.searchContainer}>
                            <FontAwesomeIcon className={styles.searchIcon} icon={faSearch}/>
                            <input
                                className={styles.searchInput}
                                type="text"
                                placeholder={t('Filter')}
                                autoFocus
                                onChange={(event) => this.filterServiceProviderGroups(event)}
                            />
                        </div>
                    </div>
                    <div className={styles.toolbar}>
                        <div className={styles.toolbarItem}>
                            {t('Select')}{' '}
                            <div className={styles.link} onClick={() => this.selectAll()}>
                                {t('All')}
                            </div>
                            |
                            <div className={styles.link} onClick={() => this.selectNone()}>
                                {t('None')}
                            </div>
                        </div>
                        <div className={styles.toolbarItem}>
                            {t('Show')}{' '}
                            <div className={showAllClass} onClick={() => this.onShowSelectedOnly(false)}>
                                {t('All')}
                            </div>
                            |
                            <div className={showSelectedOnlyClass} onClick={() => this.onShowSelectedOnly(true)}>
                                {t('Selected Only')}
                            </div>
                        </div>
                    </div>
                    <div className={styles.selectionItems}>
                        {serviceProviderGroupsRequestStatus?.isInProgress ? (
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} spin/>
                            </div>
                        ) : visibleServiceProviderGroups && visibleServiceProviderGroups.length > 0 ? (
                            visibleServiceProviderGroups.map((fc) => {
                                const includes = selectedServiceProviderGroups.map((f) => f.id).includes(fc.id);
                                return (
                                    <ServiceProviderGroupSelectionItem
                                        canSelect={fc.allowedDomains.includes(userDomain) && canChange}
                                        serviceProviderGroup={fc}
                                        selected={includes}
                                        onSelect={(): void => this.onToggleServiceProviderGroup(!includes, fc)}
                                        key={fc.id}
                                    />
                                );
                            })
                        ) : (
                            <div className={styles.emptyResults}>{t('No data found.')}</div>
                        )}
                    </div>
                    <div className={styles.buttons}>
                        <button className={styles.button} type="button" onClick={() => this.closePopup()}>
                            {t('Close')}
                        </button>
                        {hiddenCount > 0 ? (
                            <div className={styles.footnote}>
                                {t('Hiding {{itemCount}} service provider groups that do not correspond with the user\'s domain', {itemCount: hiddenCount})}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }

    private filterServiceProviderGroups(event: any): void {
        const {showSelectedOnly} = this.state;
        this.onUpdateVisibleServiceProviderGroups(event.target.value, showSelectedOnly);
    }

    private onUpdateVisibleServiceProviderGroups(query: string, showSelectedOnly: boolean): void {
        const {allowedServiceProviderGroups, selectedServiceProviderGroups} = this.state;

        const searchValue = query;
        let visibleServiceProviderGroups: ServiceProviderGroup[];
        if (searchValue) {
            visibleServiceProviderGroups = allowedServiceProviderGroups.filter((fc) => fc.name ? fc.name.toUpperCase().includes(searchValue.toUpperCase()) : true);
        } else {
            visibleServiceProviderGroups = allowedServiceProviderGroups;
        }

        if (showSelectedOnly) {
            this.setState({
                showSelectedOnly,
                visibleServiceProviderGroups: visibleServiceProviderGroups.filter(
                    (v) => selectedServiceProviderGroups.findIndex((s) => s.id === v.id) !== -1,
                ),
                lastSearchQuery: query,
            });
        } else {
            this.setState({showSelectedOnly, visibleServiceProviderGroups, lastSearchQuery: query});
        }
    }

    private selectAll(): void {
        const {visibleServiceProviderGroups, selectedServiceProviderGroups} = this.state;

        const updatedServiceProviderGroups = [...selectedServiceProviderGroups, ...visibleServiceProviderGroups].filter(
            (v, i, a) => a.indexOf(v) === i,
        );

        this.updateFleet(updatedServiceProviderGroups);
    }

    private selectNone(): void {
        this.updateFleet([]);
    }

    private onShowSelectedOnly(showSelectedOnly: boolean): void {
        const {lastSearchQuery} = this.state;
        this.onUpdateVisibleServiceProviderGroups(lastSearchQuery, showSelectedOnly);
    }

    private onToggleServiceProviderGroup(select: boolean, serviceProviderGroup: ServiceProviderGroup): void {
        const {canChange} = this.props;
        const {selectedServiceProviderGroups} = this.state;
        const updatedServiceProviderGroups = [...selectedServiceProviderGroups];
        if (canChange) {
            if (select) {
                updatedServiceProviderGroups.push(serviceProviderGroup);
            } else {
                const index = updatedServiceProviderGroups.findIndex((f) => f.id === serviceProviderGroup.id);
                if (index !== -1) {
                    updatedServiceProviderGroups.splice(index, 1);
                }
            }
            this.updateFleet(updatedServiceProviderGroups);
        }
    }

    private updateFleet(serviceProviderGroups: ServiceProviderGroup[]): void {
        const {onServiceProviderGroupsChanged} = this.props;

        onServiceProviderGroupsChanged(serviceProviderGroups);
        this.setState({selectedServiceProviderGroups: serviceProviderGroups});
    }

    private closePopup(): void {
        const {dispatchClosePopup} = this.props;
        dispatchClosePopup();
    }
}

const mapStateToProps = ({serviceProviderGroups}: ApplicationState): PropsFromState => ({
    serviceProviderGroups: serviceProviderGroups.serviceProviderGroups,
    serviceProviderGroupsRequestStatus: serviceProviderGroups.getServiceProviderGroupsRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchGetAllServiceProviderGroups: () => dispatch(getServiceProviderGroupsRequest()),
    dispatchClosePopup: () => dispatch(closePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ServiceProviderGroupSelectionPopup));

interface PropsFromState {
    serviceProviderGroups: ServiceProviderGroup[];
    serviceProviderGroupsRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchGetAllServiceProviderGroups: typeof getServiceProviderGroupsRequest;
    dispatchClosePopup: typeof closePopup;
}

interface OwnProps {
    canChange: boolean;
    originalSelectedServiceProviderGroups: ServiceProviderGroup[];
    email: string;
    onServiceProviderGroupsChanged: (serviceProviderGroups: ServiceProviderGroup[]) => void;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
    allowedServiceProviderGroups: ServiceProviderGroup[];
    selectedServiceProviderGroups: ServiceProviderGroup[];
    visibleServiceProviderGroups: ServiceProviderGroup[];
    showSelectedOnly: boolean;
    lastSearchQuery: string;
    hiddenCount: number;
}

type AllState = OwnState;
