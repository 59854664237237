import {TFunction} from 'i18next';
import {ServiceProvidersMonthlyOverviewData} from '../../store/analytics/types/service-providers-monthly-overview.types';
import {getMonthLabel} from '../../utils/translations/date-translation-helper';

export const monthField = 'month';
export const netPriceField = 'netPrice';
export const jobsCountField = 'jobCount';

export function buildChartData(data: ServiceProvidersMonthlyOverviewData[], serviceProviderId: string, translate: TFunction): any[] {
    const datapoints: any[] = [];
    data.filter(r => r.serviceProviderId === serviceProviderId).forEach((row: ServiceProvidersMonthlyOverviewData) => {
        const datapoint = {};
        datapoint[monthField] = `${getMonthLabel(new Date(row.month).getMonth(), translate)} ${new Date(row.month).getFullYear()}`;
        datapoint[netPriceField] = row.netPrice;
        datapoint[jobsCountField] = row.jobCount;
        datapoints.push(datapoint);
    });
    return datapoints;
}
