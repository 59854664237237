import { ActionType } from 'typesafe-actions';
import { BaseCurrencyType } from '../analytics';
import * as sharedActions from '../shared/actions';
import { LocalLanguageType, Paged, RequestStatus } from '../shared/types';
import * as actions from './actions';
import {ServiceProviderMapping} from '../service-providers';

export enum ServiceProviderGroupActionTypes {
    CREATE_SERVICE_PROVIDER_GROUP_REQUEST = '@@serviceProviderGroups/CREATE_SERVICE_PROVIDER_GROUP_REQUEST',
    CREATE_SERVICE_PROVIDER_GROUP_SUCCESS = '@@serviceProviderGroups/CREATE_SERVICE_PROVIDER_GROUP_SUCCESS',

    UPDATE_SERVICE_PROVIDER_GROUP_REQUEST = '@@serviceProviderGroups/UPDATE_SERVICE_PROVIDER_GROUP_REQUEST',
    UPDATE_SERVICE_PROVIDER_GROUP_SUCCESS = '@@serviceProviderGroups/UPDATE_SERVICE_PROVIDER_GROUP_SUCCESS',

    GET_SERVICE_PROVIDER_GROUPS_REQUEST = '@@serviceProviderGroups/GET_SERVICE_PROVIDER_GROUPS_REQUEST',
    GET_SERVICE_PROVIDER_GROUPS_SUCCESS = '@@serviceProviderGroups/GET_SERVICE_PROVIDER_GROUPS_SUCCESS',
    GET_SERVICE_PROVIDER_GROUPS_FAILURE = '@@serviceProviderGroups/GET_SERVICE_PROVIDER_GROUPS_FAILURE',

    SEARCH_SERVICE_PROVIDER_GROUPS_REQUEST = '@@serviceProviderGroups/SEARCH_SERVICE_PROVIDER_GROUPS_REQUEST',
    SEARCH_SERVICE_PROVIDER_GROUPS_SUCCESS = '@@serviceProviderGroups/SEARCH_SERVICE_PROVIDER_GROUPS_SUCCESS',
    SEARCH_SERVICE_PROVIDER_GROUPS_FAILURE = '@@serviceProviderGroups/SEARCH_SERVICE_PROVIDER_GROUPS_FAILURE',

    DELETE_SERVICE_PROVIDER_GROUP_REQUEST = '@@serviceProviderGroups/DELETE_SERVICE_PROVIDER_GROUP_REQUEST',
    DELETE_SERVICE_PROVIDER_GROUP_SUCCESS = '@@serviceProviderGroups/DELETE_SERVICE_PROVIDER_GROUP_SUCCESS',
    DELETE_SERVICE_PROVIDER_GROUP_FAILURE = '@@serviceProviderGroups/DELETE_SERVICE_PROVIDER_GROUP_FAILURE',

    CLEAR_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS = '@@serviceProviderGroups/CLEAR_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS',
    GET_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS_REQUEST = '@@serviceProviderGroups/GET_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS_REQUEST',
    GET_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS_SUCCESS = '@@serviceProviderGroups/GET_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS_SUCCESS',
    GET_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS_FAILURE = '@@serviceProviderGroups/GET_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS_FAILURE',
}

export type ServiceProviderGroupAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface ServiceProviderGroupsState {
    readonly serviceProviderGroups: ServiceProviderGroup[];
    readonly serviceProviderGroupsWithConfigurationAndUsers?: ServiceProviderGroupWithConfigurationAndUsers;
    readonly pagedServiceProviderGroups?: Paged<ServiceProviderGroup>;

    getServiceProviderGroupsRequestStatus?: RequestStatus;
    deleteServiceProviderGroupRequestStatus?: RequestStatus;
    getServiceProviderGroupWithConfigurationAndUsersRequestStatus?: RequestStatus;
    searchServiceProviderGroupsRequestStatus?: RequestStatus;
}

export interface ServiceProviderGroup {
    id: string;
    name: string;
    allowedDomains: string[];
}

export interface CurrencyInfo {
    name: string;
    type: BaseCurrencyType;
}

export interface LanguageInfo {
    name: string;
    type: LocalLanguageType;
}

export interface UserInfo {
    id: string;
    cognitoUsername: string;
    email: string;
    lastLogin?: Date;
}

export interface ServiceProviderGroupWithConfiguration {
    id: string;
    name: string;
    allowedDomains: string[];
    configuration: ServiceProviderGroupConfiguration;
    dataAvailable: boolean;
    earliestContractStartDate?: Date;
    activeCurrency?: CurrencyInfo;
    activeLanguage: LanguageInfo;
}

export interface ServiceProviderGroupWithConfigurationAndUsers extends ServiceProviderGroupWithConfiguration {
    users:UserInfo[];
}

export interface ServiceProviderGroupConfiguration {
    baseCurrency1: string;
    baseCurrency2?: string;
    baseCurrency3?: string;
    baseCurrency1Processed: boolean;
    baseCurrency2Processed: boolean;
    baseCurrency3Processed: boolean;
    localLanguage1: string;
    localLanguage2?: string;
    localLanguage3?: string;
    localLanguage1Processed: boolean;
    localLanguage2Processed: boolean;
    localLanguage3Processed: boolean;
}

export interface UpdatableConfiguration {
    baseCurrency1?: string;
    baseCurrency2?: string;
    baseCurrency3?: string;
    localLanguage1?: string;
    localLanguage2?: string;
    localLanguage3?: string;
}

export interface CreateServiceProviderGroupRequest {
    serviceProviderGroupName: string;
    serviceProviderGroupAllowedDomains: string[];
    configuration: UpdatableConfiguration;
    serviceProviderMappings: ServiceProviderMapping;
}

export interface UpdateServiceProviderGroupRequest {
    serviceProviderGroupId: string;
    serviceProviderGroupName: string;
    serviceProviderGroupAllowedDomains: string[];
    configuration: UpdatableConfiguration;
    serviceProviderMappings: ServiceProviderMapping;
}

export interface ServiceProviderGroupsSearchRequest {
    page: number;
    itemsPerPage: number;
    search?: string;
}
