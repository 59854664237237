import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import styles from './report-scheduler-popup.module.scss';
import {ApplicationState} from '../../../../store';
import {ServiceProviderGroupWithConfiguration} from '../../../../store/service-provider-groups';
import {closePopup} from '../../../../store/popup';
import {ReportCatalogEntry, ReportType} from '../../report-catalog';
import {
    createScheduledReportsRequest,
    SCHEDULED_REPORT_PARAMETER_VERSION,
    ScheduledReport, ScheduledReportDeliveryMethod,
    ScheduledReportGranularity,
    ScheduledReportParameters, updateScheduledReportsRequest
} from '../../../../store/reports';
import ReportSchedulerScheduleComponent from './report-scheduler-schedule.component';
import {conditionalClassLister} from '../../../../utils/class-helpers';
import ReportSchedulerDataComponent from './report-scheduler-data.component';
import {User} from '../../../../store/authentication';
import {
    getReportConfigurationsRequest,
    ReportConfigurationContract,
} from '../../../../store/report-management';
import {getAllowedReports} from '../../report-access-helper';
import {FileType} from '../../../../store/shared/types';

enum PopupTab {
    Schedule,
    Data
}

class ReportSchedulerPopup extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {scheduledReport, user, reportConfigurations, dispatchGetReportConfigurations} = this.props;

        let reportAccess: ReportCatalogEntry[] = [];
        if (user && reportConfigurations) {
            reportAccess = getAllowedReports(user, reportConfigurations);
        }

        if (!reportConfigurations || reportConfigurations.length === 0) {
            dispatchGetReportConfigurations();
        }

        if (scheduledReport) {
            this.state = {
                activeTab: PopupTab.Schedule,
                id: scheduledReport.id,
                reportName: scheduledReport.name,
                reportType: scheduledReport.reportType,
                granularity: scheduledReport.granularity,
                fileType: scheduledReport.fileType,
                deliveryMethod: scheduledReport.deliveryMethod,
                scheduleTime: scheduledReport.scheduleTime,
                recipients: scheduledReport.recipients,
                parameters: scheduledReport.parameters,
                reportAccess,
            }
        } else {
            this.state = {
                activeTab: PopupTab.Schedule,
                reportName: '',
                reportType: ReportType.InspectionDetails,
                granularity: ScheduledReportGranularity.WEEK,
                fileType: FileType.XLSX,
                deliveryMethod: ScheduledReportDeliveryMethod.DOWNLOAD_LINK,
                scheduleTime: '1',
                recipients: [],
                parameters: {},
                reportAccess,
            }
        }
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {user, reportConfigurations} = this.props;

        if (user && reportConfigurations &&
            (user !== prevProps.user || reportConfigurations !== prevProps.reportConfigurations)) {
            this.setState({reportAccess: getAllowedReports(user, reportConfigurations)});
        }
    }

    public render(): JSX.Element {
        const {t} = this.props;
        const {
            activeTab,
            reportName,
            reportType,
            fileType,
            granularity,
            scheduleTime,
            recipients,
            parameters,
            id,
            reportAccess,
            deliveryMethod,
        } = this.state;

        const canGoNext = reportName !== '' && reportType && fileType && granularity && scheduleTime !== '' && recipients.length > 0 && parameters;
        const isValid = canGoNext && Object.keys(parameters).length > 0;


        const closeButtonClasses = conditionalClassLister(styles)({
            button: true,
            isNegative: true,
        });

        const saveButtonClasses = conditionalClassLister(styles)({
            button: true,
        });

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupHeader}>
                    <div className={styles.titleTop}>{t('Report Schedule')}</div>
                    <div className={styles.title}>{reportName.toUpperCase()}</div>
                </div>
                <div className={styles.popupBody}>
                    <div className={styles.tabsContainer}>
                        <div className={styles.tabs}>
                            <div className={`${styles.tab} ${activeTab === PopupTab.Schedule ? styles.activeTab : ''}`}
                                 onClick={() => this.onTabClick(PopupTab.Schedule)}>
                                <div className={styles.caption}>1. {t('Schedule').toUpperCase()}</div>
                            </div>
                            <div className={`${styles.tab} ${activeTab === PopupTab.Data ? styles.activeTab : ''}`}
                                 onClick={() => this.onTabClick(PopupTab.Data)}>
                                <div className={styles.caption}>2. {t('Data').toUpperCase()}</div>
                            </div>
                        </div>
                    </div>
                    {activeTab === PopupTab.Schedule ? <ReportSchedulerScheduleComponent
                        reportAccess={reportAccess}
                        reportName={reportName}
                        reportType={reportType}
                        fileType={fileType}
                        deliveryMethod={deliveryMethod}
                        granularity={granularity}
                        scheduleTime={scheduleTime}
                        recipients={recipients}
                        onReportNameChanged={(name) => this.onNameChanged(name)}
                        onReportTypeChanged={(type) => this.onReportTypeChanged(type)}
                        onFileTypeChanged={(type) => this.onFileTypeChanged(type)}
                        onDeliveryMethodChanged={(method) => this.onDeliveryMethodChanged(method)}
                        onGranularityChanged={(g) => this.onGranularityChanged(g)}
                        onScheduleTimeChanged={(st) => this.onScheduleTimeChanged(st)}
                        onRecipientsChanged={(r) => this.onRecipientsChanged(r)}
                    /> : ''}
                    {activeTab === PopupTab.Data ? <ReportSchedulerDataComponent
                        reportType={reportType}
                        parameters={parameters}
                        onParametersChanged={(p) => this.onParametersChanged(p)}
                    /> : ''}
                    <div className={styles.buttons}>
                        <button type="button" className={closeButtonClasses} onClick={() => this.closePopup()}>
                            {t('Close')}
                        </button>
                        {activeTab === PopupTab.Schedule ?
                            <>
                                <button type="button"
                                        className={saveButtonClasses}
                                        disabled={!canGoNext}
                                        onClick={() => this.onNextClick()}>
                                    {t('Next')}
                                </button>
                                {id ? <button type="button"
                                              className={saveButtonClasses}
                                              disabled={!isValid}
                                              onClick={() => this.onSaveClick()}>
                                    {t('Save')}
                                </button> : ''}
                            </>
                            :
                            <>
                                <button type="button"
                                        className={closeButtonClasses}
                                        onClick={() => this.onPreviousClick()}>
                                    {t('Previous')}
                                </button>
                                <button type="button"
                                        className={saveButtonClasses}
                                        disabled={!isValid}
                                        onClick={() => this.onSaveClick()}>
                                    {t('Save')}
                                </button>
                            </>
                        }

                    </div>
                </div>
            </div>
        )
    }

    private onSaveClick(): void {
        const {id, reportName, reportType, fileType, parameters, granularity, scheduleTime, recipients, deliveryMethod} = this.state;
        const {dispatchCreateScheduledReport, dispatchUpdateScheduledReport} = this.props;

        if (reportName && reportType && fileType && parameters && Object.keys(parameters).length > 0 && granularity && scheduleTime && recipients && deliveryMethod) {
            const scheduledReport: ScheduledReport = {
                name: reportName,
                reportType,
                parametersVersion: SCHEDULED_REPORT_PARAMETER_VERSION,
                deliveryMethod,
                fileType,
                parameters,
                granularity,
                scheduleTime,
                recipients,
            };
            if (id) {
                scheduledReport.id = id;
                dispatchUpdateScheduledReport(scheduledReport);
            } else {
                dispatchCreateScheduledReport(scheduledReport);
            }

            this.closePopup();
        }
    }

    private onParametersChanged(parameters: any): void {
        this.setState({
            parameters,
        })
    }

    private onPreviousClick(): void {
        this.setState({
            activeTab: PopupTab.Schedule,
        });
    }

    private onNextClick(): void {
        this.setState({
            activeTab: PopupTab.Data,
        });
    }

    private onRecipientsChanged(recipients: string[]): void {
        this.setState({
            recipients,
        });
    }

    private onScheduleTimeChanged(scheduleTime: string): void {
        if (scheduleTime) {
            this.setState({
                scheduleTime,
            });
        }
    }

    private onGranularityChanged(granularity: ScheduledReportGranularity): void {
        if (granularity) {
            let scheduleTime = '1';
            if (granularity === ScheduledReportGranularity.YEAR) {
                scheduleTime = '1-1';
            }
            this.setState({
                granularity,
                scheduleTime,
            });
        }
    }

    private onReportTypeChanged(reportType: ReportType): void {
        if (reportType) {
            this.setState({
                reportType,
                parameters: {},
            });
        }
    }

    private onFileTypeChanged(fileType: FileType): void {
        if (fileType) {
            this.setState({
                fileType
            });
        }
    }

    private onDeliveryMethodChanged(deliveryMethod: ScheduledReportDeliveryMethod): void {
        if (deliveryMethod) {
            this.setState({
                deliveryMethod
            });
        }
    }

    private onNameChanged(name: string): void {
        this.setState({
            reportName: name,
        });
    }

    private onTabClick(tab: PopupTab): void {
        const {reportName, reportType, granularity, scheduleTime, recipients} = this.state;

        if (tab === PopupTab.Data) {
            if (reportName !== '' && reportType && granularity && scheduleTime !== '' && recipients.length > 0) {
                this.setState({
                    activeTab: tab,
                });
            }
        } else {
            this.setState({
                activeTab: tab,
            });
        }
    }

    private closePopup(): void {
        const {dispatchClosePopup, onClosePopup} = this.props;
        dispatchClosePopup();
        onClosePopup();
    }
}

const mapStateToProps = ({authentication, reportConfigurations}: ApplicationState): PropsFromState => ({
    serviceProviderGroup: authentication.serviceProviderGroup,
    user: authentication.user,
    reportConfigurations: reportConfigurations.reportConfigurations,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchCreateScheduledReport: (scheduledReport: ScheduledReport) => dispatch(createScheduledReportsRequest(scheduledReport)),
    dispatchUpdateScheduledReport: (scheduledReport: ScheduledReport) => dispatch(updateScheduledReportsRequest(scheduledReport)),
    dispatchGetReportConfigurations: () => dispatch(getReportConfigurationsRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReportSchedulerPopup));

interface PropsFromState {
    serviceProviderGroup?: ServiceProviderGroupWithConfiguration;
    user?: User;
    reportConfigurations: ReportConfigurationContract[];
}

interface PropsFromDispatch {
    dispatchClosePopup: typeof closePopup;
    dispatchCreateScheduledReport: typeof createScheduledReportsRequest;
    dispatchUpdateScheduledReport: typeof updateScheduledReportsRequest;
    dispatchGetReportConfigurations: typeof getReportConfigurationsRequest;
}

interface OwnProps {
    scheduledReport?: ScheduledReport;
    onClosePopup: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
    id?: string;
    activeTab: PopupTab
    reportName: string;
    reportType: ReportType;
    deliveryMethod: ScheduledReportDeliveryMethod;
    granularity: ScheduledReportGranularity;
    fileType: FileType;
    scheduleTime: string;
    recipients: string[];
    parameters: ScheduledReportParameters;
    reportAccess: ReportCatalogEntry[];
}

type AllState = OwnState;
