import {faSpinner} from '@fortawesome/pro-regular-svg-icons/faSpinner';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {Location, LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {updateDefaultControlValues} from '../../store/analytics';
import {Dashboard, DashboardWidget, ParamKey} from '../../store/dashboard';
import {closePopup, Popup, PopupType, showPopup} from '../../store/popup';
import {conditionalClassLister} from '../../utils/class-helpers';
import {buildSearchParameters} from '../../utils/query-parameter-helpers';
import WidgetsCatalogPopup from './catalog/widgets-catalog-popup';
import ControlsContainer from './dashboard-controls/controls-container';
import {ControlsSnapshot, ControlValue} from './dashboard-controls/controls-snapshot';
import DashboardEditorBar from './dashboard-editor-bar';
import styles from './dashboard-viewer.module.scss';
import WidgetsContainer from './dashboard-widgets/widgets-container';

class DashboardViewer extends Component<AllProps, AllState> {
    private currentValues: Map<ParamKey, ControlValue>;

    private controlsContainerRef: any;

    private widgetsContainerRef: any;

    private latestWidgetsConfiguration: DashboardWidget[] = [];

    constructor(props) {
        super(props);
        this.currentValues = new Map<ParamKey, ControlValue>();

        this.state = {
            isDirty: false,
            cleanControlsSnapshot: undefined,
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any): void {
        if (this.forceRefresh()) {
            this.controlsContainerRef.forceRefresh();
            this.doneWithForceRefresh();
        }
    }

    public componentDidMount(): void {
        const { setDashboardViewerRef } = this.props;

        if (setDashboardViewerRef) {
            setDashboardViewerRef(this);
        }
    }

    public componentWillUnmount(): void {
        const { setDashboardViewerRef } = this.props;

        if (setDashboardViewerRef) {
            setDashboardViewerRef(undefined);
        }
    }

    public render(): JSX.Element {
        const { t, editMode, activeDashboard, inProgress, defaultControlValues } = this.props;
        const { cleanControlsSnapshot, isDirty } = this.state;

        const pageContentClasses = conditionalClassLister(styles)({
            pageContent: true,
            isEdit: editMode,
        });

        return (
            <div className={pageContentClasses}>
                {editMode ? (
                    <DashboardEditorBar
                        onAddWidget={() => this.onShowWidgetCatalog()}
                        onSaveDashboardChanges={() => this.onSaveDashboardChanges()}
                        onDiscardDashboardChanges={() => this.onDiscardDashboardChanges()}
                    />
                ) : null}
                {!inProgress && activeDashboard ? (
                    <div className={styles.scrollPanel}>
                        <ControlsContainer
                            setControlsContainerRef={(ref) => (this.controlsContainerRef = ref)}
                            key={activeDashboard.id}
                            controls={activeDashboard.controls}
                            dashboardId={activeDashboard.id}
                            defaultControlValues={defaultControlValues}
                            onControlsStateChanged={(controlValues) => this.onControlsStateChanged(controlValues)}
                            onControlsInitialized={(controlValues) => this.onControlsInitialized(controlValues)}
                        />
                        <WidgetsContainer
                            setWidgetsContainerRef={(ref) => (this.widgetsContainerRef = ref)}
                            editMode={editMode}
                            originalWidgets={activeDashboard.widgets}
                            cleanControlsSnapshot={cleanControlsSnapshot}
                            isDirty={isDirty}
                            onRedrawTriggered={() => this.redraw()}
                            onWidgetsConfigurationChanged={(widgets) => this.onWidgetsConfigurationChanged(widgets)}
                        />
                    </div>
                ) : inProgress ? (
                    <div className={styles.loadingPanel}>
                        <FontAwesomeIcon icon={faSpinner} spin style={{ margin: '0 10px 0 0' }} />
                        {t('Loading configuration')}
                    </div>
                ) : (
                    <div className={styles.createPanel}>
                        {t('Please select a dashboard.')}
                    </div>
                )}
            </div>
        );
    }

    private onControlsInitialized(values: Map<ParamKey, ControlValue>): void {
        this.currentValues = values;
        this.redraw();
    }

    private onControlsStateChanged(values: Map<ParamKey, ControlValue>): void {
        this.currentValues = values;
        this.setState({ isDirty: true });
    }

    private onSaveDashboardChanges(): void {
        const { onSaveDashboardChanges } = this.props;
        if (onSaveDashboardChanges) {
            onSaveDashboardChanges();
        }
    }

    private onDiscardDashboardChanges(): void {
        const { onDiscardDashboardChanges } = this.props;
        if (onDiscardDashboardChanges) {
            onDiscardDashboardChanges();
        }
    }

    private onShowWidgetCatalog(): void {
        const { dispatchShowPopup, dispatchClosePopup } = this.props;

        dispatchShowPopup({
            type: PopupType.WidgetsCatalog,
            content: (
                <WidgetsCatalogPopup
                    onAddWidget={(widget, close) => {
                        this.onAddWidget(widget);
                        if (close) {
                            dispatchClosePopup();
                        }
                    }}
                />
            ),
        });
    }

    private forceRefresh(): boolean {
        const { currentLocation } = this.props;
        const queryParameters = new URLSearchParams(currentLocation.search);
        return !!queryParameters.get('reload');
    }

    private doneWithForceRefresh(): void {
        const { dispatchNavigateTo, currentLocation } = this.props;
        dispatchNavigateTo({
            search: buildSearchParameters({ reload: undefined }, currentLocation.search),
        });
    }

    private onAddWidget(widget: DashboardWidget): void {
        if (this.widgetsContainerRef) {
            this.widgetsContainerRef.addWidget(widget);
        }
    }

    private redraw(): void {
        const { dispatchUpdateDefaultControlValues } = this.props;
        // updating this snapshot triggers a redraw on all components
        const cleanControlsSnapshot = new ControlsSnapshot(new Map<ParamKey, ControlValue>(this.currentValues));
        // update the latest default values so they can be carried over to the next dashboard
        dispatchUpdateDefaultControlValues(this.currentValues);

        this.controlsContainerRef.beforeRedraw(() => {
            this.setState({
                cleanControlsSnapshot,
                isDirty: false,
            });
        });
    }

    private onWidgetsConfigurationChanged(widgets: DashboardWidget[]): void {
        this.latestWidgetsConfiguration = widgets;
    }

    public getConfiguration(): Dashboard {
        const { activeDashboard } = this.props;
        // @ts-ignore
        return { ...activeDashboard, widgets: this.latestWidgetsConfiguration };
    }
}

const mapStateToProps = ({ router, dashboard, authentication, analytics }: ApplicationState) => ({
        currentLocation: router.location,
        inProgress: dashboard.inProgress,
        user: authentication.user,
        defaultControlValues: analytics.defaultControlValues,
    });

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchUpdateDefaultControlValues: (defaultValues) => dispatch(updateDefaultControlValues(defaultValues)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchClosePopup: () => dispatch(closePopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardViewer));

interface OwnState {
    isDirty: boolean;
    cleanControlsSnapshot?: ControlsSnapshot;
}

type AllState = OwnState;

interface PropsFromState {
    currentLocation: Location;
    inProgress: boolean;
    defaultControlValues: Map<ParamKey, ControlValue>;
}

interface PropsFromDispatch {
    dispatchUpdateDefaultControlValues: typeof updateDefaultControlValues;
    dispatchShowPopup: typeof showPopup;
    dispatchNavigateTo: typeof push;
    dispatchClosePopup: typeof closePopup;
}

interface OwnProps {
    setDashboardViewerRef?: (ref: any) => void;
    activeDashboard?: Dashboard;
    editMode: boolean;
    onSaveDashboardChanges?: () => void;
    onDiscardDashboardChanges?: () => void;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;
