import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import Dropdown, {DropdownOption} from '../../../components/dropdown/dropdown';
import styles from './company-selector.module.scss';
import {TireStockCompany} from '../../../store/reports';

export const  COMPANIES_ALL = 'companies_all';
class CompanySelectorComponent extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {t, companies, defaultCompany} = this.props;
        this.state = this.getInitialState(t, companies, defaultCompany);
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>) {
        const {t, companies, defaultCompany} = this.props;
        if (companies && companies !== prevProps.companies) {
            this.setState(this.getInitialState(t, companies, defaultCompany));
        }
    }

    public render(): JSX.Element {
        const {selectedCompanyNr, companyOptions} = this.state;

        const width = '340px';

        return (
            <div>
                <div className={styles.select}>
                    <Dropdown options={companyOptions} width={width}
                              selection={selectedCompanyNr ? companyOptions.find(company => company.id === selectedCompanyNr) : undefined}
                              onSelectionChanged={company => this.onCompanyChanged(company)}/>
                </div>
            </div>
        )
    }

    private onCompanyChanged(option: DropdownOption) {
        const {companyChanged} = this.props;
        this.setState({
            selectedCompanyNr: option.id,
        });
        companyChanged(option.id!);
    }

    private getInitialState(t, companies?: TireStockCompany[], defaultCompany?: TireStockCompany) {
        const options = [{id: COMPANIES_ALL, label: t('All Companies').toUpperCase()}];

        if (companies) {
            options.push(...companies.map(c => ({id: c.companyNr, label: c.companyName})));
        }

        return {
            selectedCompanyNr: defaultCompany ? defaultCompany.companyNr : COMPANIES_ALL,
            companyOptions: options,
        };
    }
}

export default withTranslation()(CompanySelectorComponent);

interface OwnProps {
    defaultCompany?: TireStockCompany;
    companies?: TireStockCompany[];
    companyChanged: (soldToId: string) => void;
}

type AllProps = WithTranslation & OwnProps;

interface OwnState {
    selectedCompanyNr?: string;
    companyOptions: DropdownOption[];
}

type AllState = OwnState;

