export const classLister = (styleObject: { [key: string]: string }): any => (...classList) =>
    classList.reduce((list, myClass) => {
        let output = list;
        if (styleObject[myClass]) {
            if (list) output += ' ';
            output += styleObject[myClass];
        }
        return output;
    }, '');

export const conditionalClassLister = (styleObject: { [key: string]: string }): any => (toJoin) => {
    const classList = Object.getOwnPropertyNames(toJoin).map((x) => {
        const value = toJoin[x];
        if (!value) {
            return '';
        }
        return x;
    });

    return classLister(styleObject)(...classList);
};

export function classListToString(styles: { [key: string]: string }, classList: string[]): string {
    return classList.reduce((classes, currentClass, index) => {
        if (index === 0) {
            return classes + styles[currentClass];
        } else {
            return `${classes} ${styles[currentClass]}`;
        }
    }, '');
}

export function conditionalClassList(
    styles: { [key: string]: string },
    conditionalStyles: { [key: string]: boolean },
): string {
    const classList = Object.getOwnPropertyNames(conditionalStyles).map((conditionalStyleKey) => conditionalStyles[conditionalStyleKey] ? conditionalStyleKey : '');

    return classListToString(styles, classList);
}
