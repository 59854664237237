import { ApiResult } from '../types/api-result';
import {ReportConfigurationContract, UpdateReportConfigurationsRequest} from "../store/report-management";
import {ApiUrl} from './api-urls';
import {Http} from '../http/http';

class ReportManagementApi {
    public static async getReportConfigurations(): Promise<ApiResult<ReportConfigurationContract[]>> {
        const reportConfigurations = await Http.get<ReportConfigurationContract[]>(ApiUrl.ReportConfigurations);
        return { data: reportConfigurations };
    }

    public static async updateReportConfigurations(updateRequest: UpdateReportConfigurationsRequest): Promise<ApiResult<ReportConfigurationContract[]>> {
        const reportConfigurations = await Http.put<UpdateReportConfigurationsRequest, ReportConfigurationContract[]>(ApiUrl.ReportConfigurations, updateRequest);
        return { data: reportConfigurations };
    }
}

export default ReportManagementApi;
