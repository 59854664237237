import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import {initReactI18next} from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import {DEFAULT_LANGUAGE} from './app/utils/translations/translations-helper';

i18n
    .use(Backend)
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
        nsSeparator: false,
        keySeparator: false,
        fallbackLng: DEFAULT_LANGUAGE,
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
        },
        returnEmptyString: false,
    })
    .catch(() => null);

export default i18n;
