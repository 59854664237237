import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { ApiResult } from '../../types/api-result';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import {
    getAdminScheduledReportsFailure, getAdminScheduledReportsRequest,
    getAdminScheduledReportsSuccess,
} from './actions';
import {
    ScheduledReportsActionTypes,
    AdminScheduledReport
} from './types';
import ReportsApi from '../../api/reports-api';
import {Paged} from '../shared/types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleFetch(action: ActionType<typeof getAdminScheduledReportsRequest>) {
    try {
        const result: ApiResult<Paged<AdminScheduledReport>> = yield call(ReportsApi.getAdminScheduledReports,action.payload);

        if (result.error) {
            yield call(getAdminScheduledReportsFailure, result.error);
        } else {
            yield put(getAdminScheduledReportsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

function* watchFetchRequest() {
    yield takeEvery(ScheduledReportsActionTypes.FETCH_ADMIN_SCHEDULED_REPORTS_REQUEST, handleFetch);
}

function* scheduledReportsSaga() {
    yield all([fork(watchFetchRequest)]);
}

export default scheduledReportsSaga;
