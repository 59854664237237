import { Http } from '../http/http';
import { ApiResult } from '../types/api-result';
import { ApiUrl } from './api-urls';
import {DataStatusContract} from '../store/data-status';

class DataStatusApi {
    public static async getDataStatus(): Promise<ApiResult<DataStatusContract[]>> {
        const dataStatus = await Http.get<DataStatusContract[]>(ApiUrl.DataStatus);
        return { data: dataStatus };
    }
}

export default DataStatusApi;
