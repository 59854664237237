import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {TFunction} from 'i18next';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import ConfirmationPopup from '../../../components/confirmation-popup/confirmation-popup';
import {RouteUrl} from '../../../routes';
import {ApplicationState} from '../../../store';
import {
    deleteServiceProviderGroupRequest,
    ServiceProviderGroup,
    searchServiceProviderGroupsRequest,
    ServiceProviderGroupsSearchRequest,
} from '../../../store/service-provider-groups';
import * as layoutActions from '../../../store/layout/actions';
import {closePopup, Popup, PopupType, showPopup} from '../../../store/popup';
import { Paged, RequestStatus } from '../../../store/shared/types';
import {addToast, defaultRemoveAfterMilliseconds, ToastType} from '../../../store/toast';
import {generateUUID} from '../../../utils/uuid-helpers';
import ActionsMenu, {ActionItem, ActionType} from '../components/actions-menu/actions-menu/actions-menu';
import AdminPageHeader from '../components/admin-page-header/admin-page-header';
import styles from './service-provider-groups-management.module.scss';
import {hasPermission} from '../../../utils/user-helper';
import {Permission, User} from '../../../store/authentication';
import ServiceProvidersApi from '../../../api/service-providers-api';
import Paging from '../../jobs/jobs-table/paging/paging';

class ServiceProviderGroupsManagementPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {dispatchToggleSidebar} = this.props;
        dispatchToggleSidebar(false);

        this.state = {
            page: 1,
            itemsPerPage: 15,
            searchText: undefined,
        };
    }

    public componentDidMount() {
        this.fetchData();
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>): void {
        const {deleteServiceProviderGroupsRequestStatus} = this.props;
        const {page, itemsPerPage} = this.state;

        if (
            deleteServiceProviderGroupsRequestStatus &&
            prevProps.deleteServiceProviderGroupsRequestStatus !== deleteServiceProviderGroupsRequestStatus &&
            deleteServiceProviderGroupsRequestStatus.isSuccess
        ) {
            this.showSuccessToast('Service Provider Group Successfully Deleted');
        }

        if (
            prevState.page !== page ||
            prevState.itemsPerPage !== itemsPerPage
        ) {
            this.fetchData();
        }
    }

    public render(): JSX.Element {
        const {t, serviceProviderGroups, searchServiceProviderGroupsRequestStatus, dispatchNavigateTo, loggedInUser} = this.props;
        const {page, itemsPerPage} = this.state;
        const hasEditPermission = hasPermission(loggedInUser, Permission.ManageServiceProviderGroups)

        return serviceProviderGroups && serviceProviderGroups.pageData ? (
            <div className={styles.container}>
                <AdminPageHeader
                    title={t('Service Provider Management')}
                    onBack={() => dispatchNavigateTo({pathname: RouteUrl.Administration})}
                />
                <div className={styles.content}>
                    <div className={styles.contentPanel}>
                        <div className={styles.topBar}>
                            <div className={styles.searchContainer}>
                                <FontAwesomeIcon className={styles.searchIcon} icon={faSearch}/>
                                <input
                                    className={styles.searchInput}
                                    type='text'
                                    placeholder={t('Filter')}
                                    autoFocus
                                    onChange={(event) => this.onSearch(event)}
                                    onKeyUp={(event) => this.onKeyUp(event)}
                                />
                            </div>
                            {hasEditPermission ?
                                <button
                                    type='button'
                                    className={styles.button}
                                    onClick={() => this.onCreateServiceProviderGroup()}>
                                    {t('Create Service Provider Group')}
                                </button>:null
                            }
                        </div>
                        <div className={styles.table}>
                            <div className={`${styles.row} ${styles.header}`}>
                                <div className={styles.cell}>{t('Name')}</div>
                                <div className={styles.cell}>{t('Allowed Domains')}</div>
                                <div className={styles.optionsCellSpacer}/>
                            </div>
                            <div className={styles.rows}>
                                {searchServiceProviderGroupsRequestStatus?.isInProgress ? (
                                    <div className={styles.loading}>
                                        <FontAwesomeIcon icon={faSpinner} spin/>
                                    </div>
                                ) : serviceProviderGroups.pageData.length > 0 ? (
                                        serviceProviderGroups.pageData.map((spg) => (
                                            <div
                                                className={styles.row}
                                                key={spg.id}
                                                onClick={() => this.onSelectServiceProviderGroup(spg)}>
                                                <div className={styles.cell}>{spg.name}</div>
                                                <div className={styles.cell}>{spg.allowedDomains.join(', ')}</div>
                                                <div className={styles.optionsCell}>
                                                    <ActionsMenu actions={this.getActionsForUser(hasEditPermission, spg, t)} />
                                                </div>
                                            </div>
                                        ))
                                ) : (
                                    <div className={styles.noData}>
                                        {t('No data found.')}
                                    </div>
                                    )}
                            </div>
                        </div>
                    </div>
                    {serviceProviderGroups ? (
                        <div className={styles.paging}>
                            <Paging
                                currentPage={page}
                                initialItemsPerPage={itemsPerPage}
                                onPreviousPage={() => this.onPreviousPage()}
                                onNextPage={() => this.onNextPage()}
                                onJumpToPage={(index: number) => this.onJumpToPage(index)}
                                total={serviceProviderGroups.totalCount}
                                onItemsPerPageChanged={(items: number) => this.onItemsPerPageChanged(items)}
                                itemsPerPageBackground="white"
                            />
                        </div>
                    ) : null}
                    <div className={styles.buttons}>
                        <button
                            type='button'
                            className={styles.button}
                            onClick={() => this.onDownloadServiceProviderGroupsStructure()}>
                            {t('Download service provider groups details')}
                        </button>
                    </div>
                </div>
            </div>
        ) : (
            <div className={styles.loading}>
                <FontAwesomeIcon icon={faSpinner} spin/>
            </div>
        );
    }

    private fetchData(): void {
        const {dispatchSearchServiceProviderGroups} = this.props;
        const {page, itemsPerPage, searchText} = this.state;
        const request: ServiceProviderGroupsSearchRequest = {
            page,
            itemsPerPage,
            search: searchText
        };
        dispatchSearchServiceProviderGroups(request);
    }

    private onSearch(event: any) {
        const searchValue = event.target.value;
        this.setState({
            searchText: searchValue,
        });
    }

    private onKeyUp(event: any) {
        if (event.key === 'Enter') {
            this.fetchData();
        }
    }

    private getActionsForUser(hasEditPermission:boolean,serviceProviderGroup: ServiceProviderGroup, t: TFunction): ActionItem[] {
        const actions: ActionItem[] = [];
        if(hasEditPermission) {
            actions.push({
                label: t('Delete Service Provider Group'),
                onClick: (): void => this.onDeleteServiceProviderGroup(serviceProviderGroup),
                type: ActionType.negative,
            });
        }
        return actions;
    }

    public onSelectServiceProviderGroup(serviceProviderGroup: ServiceProviderGroup): void {
        const {dispatchNavigateTo} = this.props;
        dispatchNavigateTo({pathname: RouteUrl.ServiceProviderGroupDetails.replace(':serviceProviderGroupId', serviceProviderGroup.id)});
    }

    public onCreateServiceProviderGroup(): void {
        const {dispatchNavigateTo} = this.props;
        dispatchNavigateTo({pathname: RouteUrl.ServiceProviderGroupDetails.replace(':serviceProviderGroupId', 'new')});
    }

    private onItemsPerPageChanged(items: number): void {
        this.setState({
            itemsPerPage: items,
        });
    }

    private onPreviousPage(): void {
        const {page} = this.state;
        this.setState({
            page: page - 1,
        });
    }

    private onNextPage(): void {
        const {page} = this.state;
        this.setState({
            page: page + 1,
        });
    }

    private onJumpToPage(pageIndex: number): void {
        if (!Number.isNaN(pageIndex)) {
            this.setState({
                page: pageIndex,
            })
        }
    }

    private onDownloadServiceProviderGroupsStructure(): void {
        // eslint-disable-next-line react/destructuring-assignment
        ServiceProvidersApi.downloadServiceProviderGroupsStructure().then(() => {
        });
    }

    private onDeleteServiceProviderGroup(serviceProviderGroup: ServiceProviderGroup): void {
        const {dispatchShowPopup, dispatchClosePopup, t, dispatchDeleteServiceProviderGroup} = this.props;

        dispatchShowPopup({
            type: PopupType.UserCreation,
            content: (
                <ConfirmationPopup
                    message={t('Are you sure you want to delete this Service Provider Group?')}
                    onCancel={() => dispatchClosePopup()}
                    onConfirm={() => {
                        dispatchDeleteServiceProviderGroup(serviceProviderGroup);
                        dispatchClosePopup();
                    }}
                />
            ),
        });
    }

    private showSuccessToast(message): void {
        const {dispatchAddToast, t} = this.props;

        dispatchAddToast({
            id: generateUUID(),
            messages: [t(message)],
            type: ToastType.Success,
            removeAfterMilliseconds: defaultRemoveAfterMilliseconds,
        });
    }
}

const mapStateToProps = ({serviceProviderGroups,authentication}: ApplicationState): PropsFromState => ({
    loggedInUser: authentication.user,
    serviceProviderGroups: serviceProviderGroups.pagedServiceProviderGroups,
    deleteServiceProviderGroupsRequestStatus: serviceProviderGroups.deleteServiceProviderGroupRequestStatus,
    searchServiceProviderGroupsRequestStatus: serviceProviderGroups.searchServiceProviderGroupsRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchAddToast: (toast) => dispatch(addToast(toast)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchSearchServiceProviderGroups: (request: ServiceProviderGroupsSearchRequest) => dispatch(searchServiceProviderGroupsRequest(request)),
    dispatchDeleteServiceProviderGroup: (serviceProviderGroup: ServiceProviderGroup) => dispatch(deleteServiceProviderGroupRequest(serviceProviderGroup)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ServiceProviderGroupsManagementPage));

interface PropsFromState {
    loggedInUser?: User;
    serviceProviderGroups?: Paged<ServiceProviderGroup>;
    deleteServiceProviderGroupsRequestStatus?: RequestStatus;
    searchServiceProviderGroupsRequestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchShowPopup: typeof showPopup;
    dispatchClosePopup: typeof closePopup;
    dispatchAddToast: typeof addToast;
    dispatchSearchServiceProviderGroups: typeof searchServiceProviderGroupsRequest;
    dispatchDeleteServiceProviderGroup: typeof deleteServiceProviderGroupRequest;
}

interface OwnProps {
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
    page: number;
    itemsPerPage: number;
    searchText: string | undefined;
}

type AllState = OwnState;
