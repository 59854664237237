import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import styles from './dashboard-editor-bar.module.scss';

class DashboardEditorBar extends Component<AllProps> {

    public render(): JSX.Element {
        const {t} = this.props;

        return (
            <div className={styles.editorBar}>
                {t('Edit mode').toUpperCase()}:
                <div className={styles.addAction} onClick={() => this.onAddWidget()}>{t('Add widget').toUpperCase()}</div>
                <div className={styles.saveAction} onClick={() => this.onSaveDashboardChanges()}>{t('Save').toUpperCase()}</div>
                <div className={styles.deleteAction} onClick={() => this.onDiscardDashboardChanges()}>{t('Discard').toUpperCase()}</div>
            </div>
        );
    }

    public onSaveDashboardChanges() {
        const {onSaveDashboardChanges: onSaveDashboardChanges1} = this.props;
        onSaveDashboardChanges1();
    }

    public onDiscardDashboardChanges() {
        const {onDiscardDashboardChanges: onDiscardDashboardChanges1} = this.props;
        onDiscardDashboardChanges1();
    }

    public onAddWidget() {
        const {onAddWidget: onAddWidget1} = this.props;
        onAddWidget1();
    }
}

export default (withTranslation()(DashboardEditorBar));

interface OwnProps {
    onSaveDashboardChanges: () => void;
    onDiscardDashboardChanges: () => void;
    onAddWidget: () => void;
}

type AllProps = WithTranslation & OwnProps;
