import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';

import ReactDOM from 'react-dom';
import './styles/global.scss';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {Provider} from 'react-redux';
import {createBrowserHistory} from 'history';
import {I18nextProvider} from 'react-i18next';
import Amplify from 'aws-amplify';
import Main from './app/main';
import * as serviceWorker from './serviceWorker';
import configureStore from './app/store/configure-store';
import i18n from './i18n';
import {RuntimeConfig} from './app/config/runtime-config';

const history = createBrowserHistory();
const store = configureStore(history);
const cognitoConfig = {
    IdentityPoolId: RuntimeConfig.getCognitoIdentityPoolId(),
    UserPoolId: RuntimeConfig.getCognitoUserPoolId(),
    ClientId: RuntimeConfig.getCognitoClientId(),
    AWSRegion: RuntimeConfig.getCognitoRegion(),
    OAuthDomain: RuntimeConfig.getCognitoOAuthDomain(),
};

export function configureAmplify():void {
    Amplify.configure({
        Auth: {
            region: cognitoConfig.AWSRegion,
            userPoolId: cognitoConfig.UserPoolId,
            userPoolWebClientId: cognitoConfig.ClientId,
            options: {
                AdvancedSecurityDataCollectionFlag: true,
            },
        },
        oauth: {
            domain: cognitoConfig.OAuthDomain,
            scope: [
                'email',
                'openid',
                'profile',
            ],
            // eslint-disable-next-line no-restricted-globals
            redirectSignIn: location.origin,
            // eslint-disable-next-line no-restricted-globals
            redirectSignOut: location.origin,
            responseType: 'code',
            options: {
                AdvancedSecurityDataCollectionFlag: true,
            },
        },
    });
}

ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <Main history={history} i18n={i18n}/>
        </I18nextProvider>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
configureAmplify();
