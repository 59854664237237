import { ActionType } from 'typesafe-actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getSoldTosFailure, getSoldTosRequest, getSoldTosSuccess } from './actions';
import { ApiResult } from '../../types/api-result';
import { SoldTo, SoldToActionTypes } from './types';
import SoldTosApi from '../../api/sold-tos-api';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleGetAllSoldTos(action: ActionType<typeof getSoldTosRequest>) {
    try {
        const serviceProviderGroupId = action.payload;
        const result: ApiResult<SoldTo[]> = yield call(SoldTosApi.getAll, serviceProviderGroupId);

        if (result.error) {
            yield call(getSoldTosFailure, result.error);
        } else {
            yield put(getSoldTosSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

function* watchGetRequest() {
    yield takeEvery(SoldToActionTypes.GET_SOLD_TOS_REQUEST, handleGetAllSoldTos);
}

function* soldTosSaga() {
    yield all([fork(watchGetRequest)])
}

export default soldTosSaga;
