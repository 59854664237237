import { ActionType } from 'typesafe-actions';
import { RequestStatus } from '../shared/types';
import * as actions from './actions';
import * as sharedActions from '../shared/actions';

export enum SoldToActionTypes {
    GET_SOLD_TOS_REQUEST = '@@soldTos/GET_SOLD_TOS_REQUEST',
    GET_SOLD_TOS_SUCCESS = '@@soldTos/GET_SOLD_TOS_SUCCESS',
    GET_SOLD_TOS_FAILURE = '@@soldTos/GET_SOLD_TOS_FAILURE',
}

export interface SoldToState {
    readonly soldTos?: SoldTo[];
    readonly soldTosRequestStatus: RequestStatus;
}

export type SoldToAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface SoldTo {
    id: string;
    name: string;
    code: string;
    soldToCustomer: string;
}
