import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/pro-regular-svg-icons';
import styles from './allowed-domain.module.scss';

class AllowedDomain extends Component<AllProps> {

    public render(): JSX.Element {
        const {value, canRemove, isReadonly} = this.props;

        return (
            <div className={styles.domain}>
                <input type="text"
                       defaultValue={value}
                       className={styles.fieldInput}
                       onBlur={() => this.onBlur()}
                       onInput={e => this.onChange(e)}
                       disabled={isReadonly}/>
                {canRemove ?
                    <div className={styles.action}>
                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => this.onRemove()}/>
                    </div>
                    : ''}
            </div>
        );
    }

    private onBlur() {
        const {onBlur} = this.props;
        onBlur();
    }

    private onRemove() {
        const {onRemove} = this.props;
        onRemove();
    }

    private onChange(e) {
        const {onChange} = this.props;
        onChange(e.target.value);
    }
}

export default withTranslation()(AllowedDomain);

interface OwnProps {
    value: string;
    isReadonly: boolean;
    canRemove: boolean;
    onChange: (value) => void;
    onRemove: () => void;
    onBlur: () => void;
}

type AllProps = OwnProps & WithTranslation;
