import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dashboard } from '../../../store/dashboard';
import DashboardGlobalMenuButton from './dashboard-global-menu-button';
import DashboardMenuButton from './dashboard-menu-button';
import styles from './dashboards-menu.module.scss';

class DashboardsMenu extends Component<AllProps> {
    public render(): JSX.Element {
        const { dashboards, activeDashboard, editMode, showEditButtons } = this.props;

        return (
            <div className={styles.tabsContainer}>
                <div className={styles.dashboards}>
                    {dashboards.map((dashboard: Dashboard) => (
                            <DashboardMenuButton
                                dashboard={dashboard}
                                showEditButtons={showEditButtons && dashboard.isConfigurable}
                                editMode={editMode}
                                key={dashboard.id}
                                onDeleteDashboard={() => this.onDeleteDashboard(dashboard)}
                                onEditDashboard={() => this.onEditDashboard()}
                                onRenameDashboard={() => this.onRenameDashboard()}
                                onSelect={() => this.onClicked(dashboard)}
                                isActive={dashboard === activeDashboard}
                            />
                        ))}
                </div>
                <div className={styles.actions}>
                    {showEditButtons ? (
                        <DashboardGlobalMenuButton
                            onCreateDashboard={() => this.onCreateDashboard()}
                            onRestoreDefault={() => this.onRestoreDefault()}
                        />
                    ) : null}
                </div>
            </div>
        );
    }

    private onClicked(dashboard: Dashboard): void {
        const { onSelectDashboard } = this.props;
        onSelectDashboard(dashboard);
    }

    private onDeleteDashboard(dashboard: Dashboard): void {
        const { onDeleteDashboard } = this.props;
        onDeleteDashboard(dashboard);
    }

    private onEditDashboard(): void {
        const { onEditDashboard } = this.props;
        onEditDashboard();
    }

    private onRenameDashboard(): void {
        const { onRenameDashboard } = this.props;
        onRenameDashboard();
    }

    private onCreateDashboard(): void {
        const { onCreateDashboard } = this.props;
        onCreateDashboard();
    }

    private onRestoreDefault(): void {
        const { onRestoreDefault } = this.props;
        onRestoreDefault();
    }
}

export default withTranslation()(DashboardsMenu);

interface OwnProps {
    showEditButtons: boolean;
    editMode: boolean;
    dashboards: Dashboard[];
    activeDashboard?: Dashboard;
    onSelectDashboard: (dashboard: Dashboard) => void;
    onEditDashboard: () => void;
    onDeleteDashboard: (dashboard: Dashboard) => void;
    onRenameDashboard: () => void;
    onCreateDashboard: () => void;
    onRestoreDefault: () => void;
}

type AllProps = OwnProps & WithTranslation;
