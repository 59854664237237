import { Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './components/authenticated-route/authenticated-route';
import AdminMenuPage from './pages/admin/admin-menu.page';
import ServiceProviderGroupDetailsPage from './pages/admin/service-provider-groups/service-provider-group-details.page';
import ServiceProviderGroupsManagementPage from './pages/admin/service-provider-groups/service-provider-groups-management.page';
import UserDetailsPage from './pages/admin/users/user-details.page';
import UserManagementPage from './pages/admin/users/user-management.page';
import ServiceProviderGroupNoDataPage from './pages/service-provider-group-no-data/service-provider-group-no-data.page';
import ServiceProviderGroupSelectionPage from './pages/service-provider-group-selection/service-provider-group-selection.page';
import LoginPage from './pages/login/login.page';
import UserConfigurationIssuePage from './pages/user-configuration-issue/user-configuration-issue.page';
import ServiceProviderGroupBoundary
    from './components/service-provider-group-boundary/service-provider-group-boundary';
import DataStatusPage from './pages/admin/data-status/data-status.page';
import ReportManagementPage from './pages/admin/report-management/report-management.page';
import ScheduledReportManagementPage from './pages/admin/scheduled-report-management/scheduled-report-management.page';
import ResetPasswordPage from './pages/reset-password/reset-password.page';

export enum RouteUrl {
    Root = '/',
    Login = '/login',
    ResetPassword = '/ResetPassword',
    Analytics = '/analytics',
    Jobs = '/jobs',
    Profile = '/profile',
    ServiceProviderGroupSelection = '/service-provider-group',
    UserConfigurationIssue = '/user-config-issue',
    ServiceProviderGroupNoDataIssue = '/service-provider-group-no-data-issue',
    Administration = '/admin',
    ServiceProviderGroupsManagement = '/admin/service-provider-groups',
    ServiceProviderGroupDetails = '/admin/service-provider-groups/:serviceProviderGroupId',
    UserManagement = '/admin/users',
    UserDetails = '/admin/users/:userId',
    DataStatus = '/admin/data-status',

    Reports = '/reports',
    ReportScheduler = '/reports/scheduler',
    ReportManagement = '/admin/report-management',
    ScheduledReportManagement = '/admin/scheduled-report-management',
    InspectionDetailsReport = '/reports/inspectionDetails',
    TCUSensorFitmentsReport = '/reports/tcuSensorFitments',
    CustomerServiceLocationsReport = '/reports/customer-service-locations',
    ExtendedInspectionReport = '/reports/extendedInspection',
    VehiclesDueForInspectionReport = '/reports/vehiclesDueForInspection',
    CasingsReport = '/reports/casings',
    TireStockReport = '/reports/tireStock',
    ReportDownload = '/reports/:reportId/download',
}

const routes: React.FunctionComponent = () => (
    <Switch>
        <Route exact path={RouteUrl.Root} component={LoginPage} />
        <Route exact path={RouteUrl.Login} component={LoginPage} />
        <Route exact path={RouteUrl.ResetPassword} component={ResetPasswordPage} />
        <Route exact path={RouteUrl.UserConfigurationIssue} component={UserConfigurationIssuePage} />
        <Route exact path={RouteUrl.ServiceProviderGroupNoDataIssue} component={ServiceProviderGroupNoDataPage} />
        <AuthenticatedRoute exact path={RouteUrl.ServiceProviderGroupSelection} component={ServiceProviderGroupSelectionPage} />
        <AuthenticatedRoute exact path={RouteUrl.UserDetails} component={UserDetailsPage} />
        <AuthenticatedRoute exact path={RouteUrl.UserManagement} component={UserManagementPage} />
        <AuthenticatedRoute exact path={RouteUrl.ServiceProviderGroupDetails} component={ServiceProviderGroupDetailsPage} />
        <AuthenticatedRoute exact path={RouteUrl.ServiceProviderGroupsManagement} component={ServiceProviderGroupsManagementPage} />
        <AuthenticatedRoute exact path={RouteUrl.DataStatus} component={DataStatusPage} />
        <AuthenticatedRoute exact path={RouteUrl.ReportManagement} component={ReportManagementPage} />
        <AuthenticatedRoute exact path={RouteUrl.ScheduledReportManagement} component={ScheduledReportManagementPage} />
        <AuthenticatedRoute exact path={RouteUrl.Administration} component={AdminMenuPage} />
        <AuthenticatedRoute path="/:serviceProviderGroupId" component={ServiceProviderGroupBoundary} />
        <Route component={() => <div>Not Found</div>} />
    </Switch>
);

export default routes;
