import {TFunction} from 'i18next';
import {TireInfo} from '../../types/vehicle-configuration';
import {format, isBefore} from '../../../../utils/date-helper';

export function getTireValueType(tire: TireInfo): TireValueType {
    if (tire) {
        if (tire.values) {
            if (tire.dorValues) {
                if (isBefore(new Date(tire.values.date), new Date(tire.dorValues.date))) {
                    return TireValueType.DOR;
                } else {
                    return TireValueType.Task
                }
            } else {
                return TireValueType.Task
            }
        } else if (tire.dorValues) {
            return TireValueType.DOR;
        } else {
            return TireValueType.Missing;
        }
    } else {
        return TireValueType.Missing;
    }
}

export function getLastSeenValue(tire: TireInfo, tireValueType: TireValueType, t: TFunction): string {
    if (tire && tireValueType) {
        if (tireValueType === TireValueType.Task) {
            return getDateDisplayValue(tire.values?.date);
        } else if (tire.dorValues) {
            return getDateDisplayValue(tire.dorValues?.date);
        } else {
            return getDisplayValue(undefined, t);
        }
    } else {
        return getDisplayValue(undefined, t);
    }
}

export function getPressureValue(tire: TireInfo, tireValueType: TireValueType): number | undefined {
    if (tire && tireValueType) {
        if (tireValueType === TireValueType.Task) {
            return tire.values?.pressure;
        } else if (tire.dorValues) {
            return tire.dorValues?.pressure;
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
}

export function isTreadDepthRisky(treadDepth: number | undefined): boolean {
    return treadDepth !== undefined && treadDepth >= 4 && treadDepth <= 6;
}

export function isTreadDepthCritical(treadDepth: number | undefined): boolean {
    return treadDepth !== undefined && treadDepth < 4
}

export function getTreadDepthValue(tire: TireInfo, tireValueType: TireValueType): number | undefined {
    if (tire && tireValueType) {
        if (tireValueType === TireValueType.Task) {
            return tire.values?.treadDepth;
        } else if (tire.dorValues) {
            return tire.dorValues?.treadDepth;
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
}

export function getDisplayValue(value: number | undefined, t: TFunction): string {
    if (value === undefined) {
        return t('N/A');
    } else {
        return value.toString();
    }
}

function getDateDisplayValue(value: string | undefined): string {
    if (value === undefined) {
        return 'NA'
    } else {
        return format(new Date(value), 'MMM D, YYYY');
    }
}

export enum TireValueType {
    Task = 'Task',
    DOR = 'DOR',
    Missing = 'Missing'
}
