import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {ActionType} from 'typesafe-actions';
import {ApiResult} from '../../types/api-result';
import {
    GlobalServiceProvider,
    ServiceProviderActionTypes,
    ServiceProviderDetails,
    ServiceProviderMapping, ServiceProviderWithCityCountry
} from './types';
import {
    getServiceProviderDetailsFailure,
    getServiceProviderDetailsRequest,
    getServiceProviderDetailsSuccess,
    getServiceProviderMappingsFailure,
    getServiceProviderMappingsRequest,
    getServiceProviderMappingsSuccess, getServiceProvidersFailure, getServiceProvidersForGroupFailure,
    getServiceProvidersForGroupRequest,
    getServiceProvidersForGroupSuccess, getServiceProvidersRequest, getServiceProvidersSuccess,
} from './actions';
import ServiceProvidersApi from '../../api/service-providers-api';
import {handleUnexpectedErrorWithToast} from '../http-error-handler';
import {Paged} from '../shared/types';

function *handleMappingsFailure(err: any) {
    yield call(handleUnexpectedErrorWithToast, err);
    yield put(getServiceProviderMappingsFailure(err));
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* handleGetMappingsForServiceProviderGroup(action: ActionType<typeof getServiceProviderMappingsRequest>) {
    try {
        const serviceProviderGroupId = action.payload;
        const result: ApiResult<ServiceProviderMapping> = yield call(ServiceProvidersApi.getAllMappingsForServiceProviderGroup, serviceProviderGroupId);

        if (result.error) {
            yield call(handleMappingsFailure, result.error);
        } else {
            yield put(getServiceProviderMappingsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleMappingsFailure, err);
    }
}

export function* handleGetServiceProvidersForGroup(action: ActionType<typeof getServiceProvidersForGroupRequest>) {
    try {
        const serviceProviderGroupId = action.payload;
        const result: ApiResult<ServiceProviderWithCityCountry[]> = yield call(ServiceProvidersApi.getAllForGroup, serviceProviderGroupId);

        if (result.error) {
            yield call(getServiceProvidersForGroupFailure, result.error);
        } else {
            yield put(getServiceProvidersForGroupSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleGetServiceProviders(action: ActionType<typeof getServiceProvidersRequest>) {
    try {
        const request = action.payload;
        const result: ApiResult<Paged<GlobalServiceProvider>> = yield call(ServiceProvidersApi.getAll, request);

        if (result.error) {
            yield call(getServiceProvidersFailure, result.error);
        } else {
            yield put(getServiceProvidersSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleGetServiceProviderDetails(action: ActionType<typeof getServiceProviderDetailsRequest>) {
    try {
        const { serviceProviderGroupId, serviceProviderId } = action.payload;
        const result: ApiResult<ServiceProviderDetails> = yield call(ServiceProvidersApi.getById, serviceProviderGroupId, serviceProviderId);

        if (result.error) {
            yield call(getServiceProviderDetailsFailure, result.error);
        } else {
            yield put(getServiceProviderDetailsSuccess(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

function* watchFetchRequest() {
    yield takeEvery(ServiceProviderActionTypes.GET_SERVICE_PROVIDER_MAPPINGS_REQUEST, handleGetMappingsForServiceProviderGroup);
    yield takeEvery(ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_FOR_GROUP_REQUEST, handleGetServiceProvidersForGroup);
    yield takeEvery(ServiceProviderActionTypes.GET_SERVICE_PROVIDER_DETAILS_REQUEST, handleGetServiceProviderDetails);
    yield takeEvery(ServiceProviderActionTypes.GET_SERVICE_PROVIDERS_REQUEST, handleGetServiceProviders);
}

function* serviceProviderSaga() {
    yield all([fork(watchFetchRequest)]);
}

export default serviceProviderSaga;
