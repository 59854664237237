import { push } from 'connected-react-router';
import { LocationDescriptorObject } from 'history';

import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {Component} from 'react';
import { ReactComponent as UserIcon } from '../../../assets/icons/noun_user_697795.svg';
import { RouteUrl } from '../../routes';
import { ApplicationState } from '../../store';
import { logoutRequest, Permission, User } from '../../store/authentication';
import { ServiceProviderGroup } from '../../store/service-provider-groups';
import { conditionalClassLister } from '../../utils/class-helpers';
import { hasAdminPermission, hasPermission } from '../../utils/user-helper';
import styles from './menu-button.module.scss';
import MenuPanel from './menu-panel';

export class MenuButton extends Component<AllProps, AllState> {
    private disableCloseOnBlur = false;

    constructor(props:AllProps) {
        super(props);
        const { user } = this.props;
        this.state = {
            isProfilePanelOpen: false,
            showAdminLink: hasAdminPermission(user),
            showAnalyticsLink: hasPermission(user, Permission.ViewAnalytics),
        };
    }

    public render(): JSX.Element {
        const { user, serviceProviderGroup } = this.props;
        const { isProfilePanelOpen, showAdminLink, showAnalyticsLink } = this.state;

        const profilePanelClasses = conditionalClassLister(styles)({
            profilePanel: true,
            isOpen: isProfilePanelOpen,
        });

        return (
            <div className={styles.loggedInUserContainer}>
                <div
                    className={styles.loggedInUserPanel}
                    tabIndex={0}
                    onFocus={() => this.toggleProfileBox(true)}
                    onBlur={() => this.toggleProfileBox(false)}>
                    <div className={styles.textFields}>
                        <div className={styles.emailField}>{user ? user.email : ''}</div>
                        {user && serviceProviderGroup ? <div className={styles.accountField}>{serviceProviderGroup.name}</div> : null}
                    </div>
                    <div className={styles.avatar}>
                        <UserIcon className={styles.avatarSvg} />
                    </div>
                </div>
                <div className={profilePanelClasses}>
                    <MenuPanel
                        showAnalyticsLink={showAnalyticsLink}
                        showAdminLink={showAdminLink}
                        user={user}
                        serviceProviderGroup={serviceProviderGroup}
                        onViewProfile={() => this.onViewProfile()}
                        onDisableCloseOnBlur={(disable: boolean) => this.onDisableCloseOnBlur(disable)}
                        onSignOut={() => this.onSignOut()}
                        onAdministration={() => this.onAdministration()}
                        onChangeServiceProviderGroup={() => this.onChangeServiceProviderGroup()}
                    />
                </div>
            </div>
        );
    }

    private onDisableCloseOnBlur(disable: boolean): void {
        this.disableCloseOnBlur = disable;
    }

    private onSignOut(): void {
        const { dispatchLogoutRequest } = this.props;
        dispatchLogoutRequest();
    }

    private onViewProfile(): void {
        const { navigateTo, serviceProviderGroup } = this.props;
        navigateTo({ pathname: `/${serviceProviderGroup!.id}${RouteUrl.Profile}` });
    }

    private onChangeServiceProviderGroup(): void {
        const { navigateTo } = this.props;
        navigateTo(RouteUrl.ServiceProviderGroupSelection);
    }

    private onAdministration(): void {
        const { navigateTo } = this.props;
        navigateTo(RouteUrl.Administration);
    }

    private toggleProfileBox(open: boolean): void {
        if (open || (!open && !this.disableCloseOnBlur)) {
            this.setState({ isProfilePanelOpen: open });
        }
    }
}

const mapStateToProps = ({ authentication, search }: ApplicationState) => ({
    user: authentication.user,
    serviceProviderGroup: authentication.serviceProviderGroup,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    navigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchLogoutRequest: () => dispatch(logoutRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MenuButton));

interface OwnProps {}

interface PropsFromState {
    user: User;
    serviceProviderGroup: ServiceProviderGroup;
}

interface PropsFromDispatch {
    dispatchLogoutRequest: typeof logoutRequest;
    navigateTo: typeof push;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    isProfilePanelOpen: boolean;
    showAdminLink: boolean;
    showAnalyticsLink: boolean;
}

type AllState = OwnState;
