import {Reducer} from 'redux';
import {RequestStatus, RequestStatusType, SharedActionTypes} from '../shared/types';
import {ReportConfigurationAction, ReportConfigurationActionTypes, ReportConfigurationState} from './types';

function initialState(): ReportConfigurationState {
    return {
        reportConfigurations: [],
    };
}

const reducer: Reducer<ReportConfigurationState, ReportConfigurationAction> = (state = initialState(), action): ReportConfigurationState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_SERVICE_PROVIDER_GROUP: {
            return initialState();
        }
        case ReportConfigurationActionTypes.FETCH_REPORT_CONFIGURATIONS_REQUEST: {
            return { ...state, getReportConfigurationsRequestStatus: new RequestStatus(RequestStatusType.InProgress) };
        }
        case ReportConfigurationActionTypes.FETCH_REPORT_CONFIGURATIONS_SUCCESS: {
            return {
                ...state,
                reportConfigurations: action.payload,
                getReportConfigurationsRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case ReportConfigurationActionTypes.FETCH_REPORT_CONFIGURATIONS_FAILURE: {
            return { ...state, getReportConfigurationsRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload) };
        }
        case ReportConfigurationActionTypes.UPDATE_REPORT_CONFIGURATIONS_REQUEST: {
            return { ...state, updateReportConfigurationsRequestStatus: new RequestStatus(RequestStatusType.InProgress) };
        }
        case ReportConfigurationActionTypes.UPDATE_REPORT_CONFIGURATIONS_SUCCESS: {
            return {
                ...state,
                reportConfigurations: action.payload,
                updateReportConfigurationsRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case ReportConfigurationActionTypes.UPDATE_REPORT_CONFIGURATIONS_FAILURE: {
            return { ...state, updateReportConfigurationsRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload) };
        }
        default: {
            return state;
        }
    }
};

export { reducer as reportConfigurationReducer };
export { initialState as initialReportConfigurationState };
