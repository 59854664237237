import axios, {AxiosRequestConfig} from 'axios';
import {RuntimeConfig} from '../config/runtime-config';
import {ApiResult} from '../types/api-result';
import {Http} from '../http/http';
import {User} from '../store/authentication';
import {ApiUrl} from './api-urls';

enum UmfApiUrl {
    Policy = 'policy',
    ConfirmPolicy = 'policy/confirm',
}

export interface PrivacyPolicyInfo {
    url: string;
    date: string;
}

class PrivacyPolicyApi {
    public static async getAvailablePrivacyPolicies(): Promise<{ [languageKey: string]: PrivacyPolicyInfo }> {
        const response = await this.getUnsecured<{ [key: string]: PrivacyPolicyInfo }>(UmfApiUrl.Policy);
        return response;
    }

    public static async downloadPrivacyPolicy(policyUrl: string): Promise<string> {
        const policyResponse = await axios.get(policyUrl);
        return policyResponse.data;
    }

    public static async confirmPrivacyPolicy(): Promise<ApiResult<any>> {
        const data = await Http.put<any, User>(
            ApiUrl.CurrentUserPrivacyPolicy,
            {},
        );

        return {data};
    }

    // private static async get<T>(path: string): Promise<T> {
    //     const url = this.buildUrl(path);
    //     const requestHeaders = await this.buildHeaders();
    //     const configuration: AxiosRequestConfig = {
    //         headers: requestHeaders,
    //     };
    //
    //     const result = await axios.get<T>(url, configuration);
    //     return result.data;
    // }

    private static async getUnsecured<T>(path: string): Promise<T> {
        const url = this.buildUrl(path);
        const configuration: AxiosRequestConfig = {
            headers: {},
        };

        const result = await axios.get<T>(url, configuration);
        return result.data;
    }

    /* private static async post<TBody, T>(path: string, body: TBody): Promise<T> {
         const url = this.buildUrl(path);
         const requestHeaders = await this.buildHeaders();
         const configuration: AxiosRequestConfig = {
             headers: requestHeaders,
         };

         const result = await axios.post<T>(url, body, configuration);
         return result.data;
     } */

    private static buildUrl(path: string): string {
        return `${RuntimeConfig.getUMFApiURL()}${path}`;
    }

    /* private static async buildHeaders(): Promise<{ [key: string]: string | number }> {
        const token = await AuthenticationApi.getJwtIdToken();
        return {
            Authorization: `Bearer ${token}`,
        };
    } */
}

export default PrivacyPolicyApi;
