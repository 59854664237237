import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {conditionalClassLister} from '../../../utils/class-helpers';
import styles from './dashboard-name-popup.module.scss';

class DashboardNamePopup extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
        };
    }

    public render(): JSX.Element {
        const { t, oldName } = this.props;
        const negativeButtonClasses = conditionalClassLister(styles)({
            button: true,
            isNegative: true,
        });

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupHeader} />
                <div className={styles.popupBody}>
                    <div className={styles.caption}>{t('Please provide a name')}</div>
                    <input
                        type='text'
                        defaultValue={oldName}
                        autoFocus
                        className={styles.fieldInput}
                        onKeyUp={(e) => this.onKeyUp(e)}
                    />
                    <div className={styles.buttons}>
                        <button type='button' className={negativeButtonClasses} onClick={() => this.onCancelClick()}>
                            {t('Cancel')}
                        </button>
                        <button type='button' className={styles.button} onClick={() => this.onConfirmClick()}>
                            {t('Confirm')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    private onKeyUp(e) {
        this.setState({ name: e.target.value });

        if (e.keyCode === 13) {
            this.onConfirmClick();
        }
    }

    private onConfirmClick() {
        const {name} = this.state;
        if (name !== '') {
            const {onConfirm} = this.props;
            onConfirm(name);
        }
    }

    private onCancelClick() {
        const {onCancel} = this.props;
        onCancel();
    }
}

export default withTranslation()(DashboardNamePopup);

interface OwnProps {
    oldName: string;
    onConfirm: (name: string) => void;
    onCancel: () => void;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
    name: string;
}

type AllState = OwnState;
