import {TFunction} from 'i18next';
import {
    PurchasingDistributionData,
    PurchasingItemType,
} from '../../../../store/analytics/types/purchasing-distribution.types';
import { getPurchasingTypeLabel } from '../../../../utils/translations/purchasing-type-translation-helper';

export const purchasingItemTypeField = 'purchasingItemType';
export const netPriceField = 'netPrice';
export const typeField = 'type';

export function buildChartData(data: PurchasingDistributionData[], translate: TFunction): any[]  {
    const materialsRow = {};
    materialsRow[purchasingItemTypeField] = getPurchasingTypeLabel(PurchasingItemType.Tires, translate);
    materialsRow[typeField] = PurchasingItemType.Tires;
    materialsRow[netPriceField] = 0;

    const servicesRow = {};
    servicesRow[purchasingItemTypeField] = getPurchasingTypeLabel(PurchasingItemType.Services, translate);
    servicesRow[typeField] = PurchasingItemType.Services;
    servicesRow[netPriceField] = 0;

    data.forEach((row) => {
        switch (row.purchasingItemType) {
            case PurchasingItemType.Tires:
                materialsRow[netPriceField] += row.netPrice;
                break;
            case PurchasingItemType.Services:
                servicesRow[netPriceField] += row.netPrice;
                break;
            default:
                throw Error(`Encountered unknown purchasing item type when building chart data ${row.purchasingItemType}`);
        }
    });

    return [servicesRow, materialsRow];
}
