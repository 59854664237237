import {TFunction} from 'i18next';
import {DropdownOptionKey} from '../../components/widget-header-dropdown/dropdown-option-key';

export function getDropDownOptionLabel(option: DropdownOptionKey, t: TFunction): string {
    switch (option) {
        case DropdownOptionKey.ServicesVsTires: {
            return t('dropdownOption_ServicesVsTires');
        }
        case DropdownOptionKey.IncludeDetails: {
            return t('dropdownOption_IncludeDetails');
        }
        case DropdownOptionKey.BreakdownVsRegular: {
            return t('dropdownOption_BreakdownVsRegular');
        }
        case DropdownOptionKey.WithInspectionStatus: {
            return t('dropdownOption_WithInspectionStatus');
        }
        case DropdownOptionKey.FosVsEjob: {
            return t('dropdownOption_FosVsEjob');
        }
        case DropdownOptionKey.JobVsInspection: {
            return t('dropdownOption_JobVsInspection');
        }
        case DropdownOptionKey.NoGrouping: {
            return t('dropdownOption_NoGrouping');
        }
        case DropdownOptionKey.ByVehicleType: {
            return t('dropdownOption_ByVehicleType');
        }
        case DropdownOptionKey.ByNetPrice: {
            return t('dropdownOption_ByNetPrice');
        }
        case DropdownOptionKey.ByJobCount: {
            return t('dropdownOption_ByJobCount');
        }
        case DropdownOptionKey.NewVsRetread: {
            return t('dropdownOption_NewVsRetread');
        }
        case DropdownOptionKey.CorporateVsNonCorporate: {
            return t('dropdownOption_CorporateVsNonCorporate');
        }
        default:
            throw new Error(`Drop down option ${option} is not supported.`);
    }
}
