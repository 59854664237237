import {ActionType} from 'typesafe-actions';
import {ControlValue} from '../../pages/analytics/dashboard-controls/controls-snapshot';
import {ParamKey} from '../dashboard';
import * as sharedActions from '../shared/actions';
import * as actions from './actions';
import {
    KeyPerformanceIndicatorData,
} from './types/key-performance-indicator.types';
import {JobsDistributionData} from './types/jobs-distribution.types';
import {PurchasingDistributionData} from './types/purchasing-distribution.types';
import {ServiceProvidersMonthlyOverviewData} from './types/service-providers-monthly-overview.types';
import {TiresDistributionData} from './types/tires-distribution.types';
import {PurchasingEvolutionData} from './types/purchasing-evolution.types';
import {TireBrandDistributionData} from './types/tire-brand-distribution.types';
import {TopSoldTosData} from './types/top-sold-tos-types';
import {LocalLanguageType} from '../shared/types';
import {CorporateFitmentDistributionData} from "./types/corporata-fitment-distribution.types";

export enum AnalyticsActionTypes {
    KEY_PERFORMANCE_INDICATOR_REQUEST = '@@analytics/KEY_PERFORMANCE_INDICATOR_REQUEST',
    KEY_PERFORMANCE_INDICATOR_SUCCESS = '@@analytics/KEY_PERFORMANCE_INDICATOR_SUCCESS',

    UPDATE_DEFAULT_CONTROL_VALUES = '@@analytics/UPDATE_DEFAULT_CONTROL_VALUES',
    UPDATE_SINGLE_DEFAULT_CONTROL_VALUE = '@@analytics/UPDATE_SINGLE_DEFAULT_CONTROL_VALUE',

    JOBS_DISTRIBUTION_REQUEST = '@@analytics/JOBS_DISTRIBUTION_REQUEST',
    JOBS_DISTRIBUTION_SUCCESS = '@@analytics/JOBS_DISTRIBUTION_SUCCESS',

    TOP_SOLD_TOS_REQUEST = '@@analytics/TOP_SOLD_TOS_REQUEST',
    TOP_SOLD_TOS_SUCCESS = '@@analytics/TOP_SOLD_TOS_SUCCESS',

    PURCHASING_DISTRIBUTION_REQUEST = '@@analytics/PURCHASING_DISTRIBUTION_REQUEST',
    PURCHASING_DISTRIBUTION_SUCCESS = '@@analytics/PURCHASING_DISTRIBUTION_SUCCESS',

    PURCHASING_EVOLUTION_REQUEST = '@@analytics/PURCHASING_EVOLUTION_REQUEST',
    PURCHASING_EVOLUTION_SUCCESS = '@@analytics/PURCHASING_EVOLUTION_SUCCESS',

    SERVICE_PROVIDERS_MONTHLY_OVERVIEW_REQUEST = '@@analytics/SERVICE_PROVIDERS_MONTHLY_OVERVIEW_REQUEST',
    SERVICE_PROVIDERS_MONTHLY_OVERVIEW_SUCCESS = '@@analytics/SERVICE_PROVIDERS_MONTHLY_OVERVIEW_SUCCESS',

    TIRES_DISTRIBUTION_REQUEST = '@@analytics/TIRES_DISTRIBUTION_REQUEST',
    TIRES_DISTRIBUTION_SUCCESS = '@@analytics/TIRES_DISTRIBUTION_SUCCESS',

    TIRE_BRAND_DISTRIBUTION_REQUEST = '@@analytics/TIRE_BRAND_DISTRIBUTION_REQUEST',
    TIRE_BRAND_DISTRIBUTION_SUCCESS = '@@analytics/TIRE_BRAND_DISTRIBUTION_SUCCESS',

    CORPORATE_FITMENT_DISTRIBUTION_REQUEST = '@@analytics/CORPORATE_FITMENT_DISTRIBUTION_REQUEST',
    CORPORATE_FITMENT_DISTRIBUTION_SUCCESS = '@@analytics/CORPORATE_FITMENT_DISTRIBUTION_SUCCESS',
}

export type AnalyticsAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface AnalyticsState {
    readonly defaultControlValues: Map<ParamKey, ControlValue>;

    readonly keyPerformanceIndicatorData: KeyPerformanceIndicatorData[];
    readonly jobsDistributionData?: JobsDistributionData[];
    readonly purchasingDistributionData?: PurchasingDistributionData[];
    readonly serviceProvidersMonthlyOverviewData?: ServiceProvidersMonthlyOverviewData[];
    readonly tiresDistributionData?: TiresDistributionData[];
    readonly purchasingEvolutionData?: PurchasingEvolutionData[];
    readonly tireBrandDistributionData?: TireBrandDistributionData[];
    readonly corporateFitmentDistributionData?: CorporateFitmentDistributionData[];
    readonly topSoldTosData?: TopSoldTosData[];
}

export enum KpiTypes {
    TotalInvoiced = 'InvoiceTotal',
    TotalJobCount = 'JobCountTotal',
    TotalInspectionCount = 'InspectionCountTotal',
    InspectionRatio = 'InspectionRatio',
    EjobUtilization = 'EjobUtilization',
    RetreadRatio = 'RetreadRatio',
    ECasingReturnsRatio = 'ECasingReturnsRatio',
    TotalTiresCount = 'TotalTiresCount',
    JobsAvgProcessTime = 'JobsAvgProcessTime',
    JobsAvgAcceptanceTime = 'JobsAvgAcceptanceTime',
    CorporateBrandRatio = 'CorporateBrandRatio',
    AverageTreadDepth = 'AverageTreadDepth',
    RegrooveRatio = 'RegrooveRatio',
    RegrooveCount = 'RegrooveCount',
    PWTStockAge = 'PWTStockAge',
    PWTCycleTime = 'PWTCycleTime',
    PWTCurrentStock = 'PWTCurrentStock',
}

export interface LanguageRequest {
    language: LocalLanguageType;
}

export interface DateRangeRequest {
    start: string;
    end: string;
}

export interface DateRangeWithServiceProvidersAndSoldTosAndLanguageRequest {
    start: string;
    end: string;
    serviceProviders: ServiceProviderSelection;
    soldTos: SoldToSelection;
    language: LocalLanguageType;
}

export interface DateRangeWithServiceProvidersAndSoldTosRequest {
    start: string;
    end: string;
    serviceProviders: ServiceProviderSelection;
    soldTos: SoldToSelection;
}

export interface DateRangeWithServiceProvidersRequest {
    start: string;
    end: string;
    serviceProviders: ServiceProviderSelection;
}

export interface DateRangeWithServiceProvidersAndSoldTosAndCurrencyRequest {
    start: string;
    end: string;
    serviceProviders: ServiceProviderSelection;
    soldTos: SoldToSelection;
    currencyType: BaseCurrencyType;
}

export interface DateWithServiceProvidersRequest {
    date: string;
    serviceProviders: ServiceProviderSelection;
}

export interface DateRange {
    start: Date;
    end: Date;
}

export interface ServiceProviderSelection {
    includes: string[];
}

export interface SoldToSelection {
    includes: string[];
    includeAll: boolean;
}

export enum Frequency {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
}

export enum BaseCurrencyType {
    BASE_CURRENCY_1 = 'baseCurrency1',
    BASE_CURRENCY_2 = 'baseCurrency2',
    BASE_CURRENCY_3 = 'baseCurrency3',
}
