import {action} from 'typesafe-actions';
import {
    ReportsActionTypes,
    ReportInfo,
    ScheduledReport,
    InspectionDetailsReportRequest,
    ExtendedInspectionReportRequest,
    VehiclesDueForInspectionReportRequest,
    CasingsReportRequest,
    TireStockCompany,
    TireStockReportRequest,
    TCUSensorFitmentsReportRequest,
    CustomerServiceLocationsReportRequest,
} from './types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const fetchInspectionDetailsReportRequest = (request: InspectionDetailsReportRequest) =>
    action(ReportsActionTypes.FETCH_INSPECTION_DETAILS_REPORT_REQUEST, request);
export const fetchInspectionDetailsReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_INSPECTION_DETAILS_REPORT_SUCCESS, reportInfo);
export const fetchInspectionDetailsReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_INSPECTION_DETAILS_REPORT_FAILURE, message);
export const fetchCustomerServiceLocationsReportRequest = (request: CustomerServiceLocationsReportRequest) =>
    action(ReportsActionTypes.FETCH_CUSTOMER_SERVICE_LOCATIONS_REPORT_REQUEST, request);
export const fetchCustomerServiceLocationsReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_CUSTOMER_SERVICE_LOCATIONS_REPORT_SUCCESS, reportInfo);
export const fetchCustomerServiceLocationsReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_CUSTOMER_SERVICE_LOCATIONS_REPORT_FAILURE, message);
export const fetchTCUSensorFitmentsReportRequest = (request: TCUSensorFitmentsReportRequest) =>
    action(ReportsActionTypes.FETCH_TCU_SENSOR_FITMENTS_REPORT_REQUEST, request);
export const fetchTCUSensorFitmentsReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_TCU_SENSOR_FITMENTS_REPORT_SUCCESS, reportInfo);
export const fetchTCUSensorFitmentsReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_TCU_SENSOR_FITMENTS_REPORT_FAILURE, message);
export const fetchExtendedInspectionReportRequest = (request: ExtendedInspectionReportRequest) =>
    action(ReportsActionTypes.FETCH_EXTENDED_INSPECTION_REPORT_REQUEST, request);
export const fetchExtendedInspectionReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_EXTENDED_INSPECTION_REPORT_SUCCESS, reportInfo);
export const fetchExtendedInspectionReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_EXTENDED_INSPECTION_REPORT_FAILURE, message);
export const fetchVehiclesDueForInspectionReportRequest = (request: VehiclesDueForInspectionReportRequest) =>
    action(ReportsActionTypes.FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_REQUEST, request);
export const fetchVehiclesDueForInspectionReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_SUCCESS, reportInfo);
export const fetchVehiclesDueForInspectionReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_FAILURE, message);
export const fetchCasingsReportRequest = (request: CasingsReportRequest) =>
    action(ReportsActionTypes.FETCH_CASINGS_REPORT_REQUEST, request);
export const fetchCasingsReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_CASINGS_REPORT_SUCCESS, reportInfo);
export const fetchCasingsReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_CASINGS_REPORT_FAILURE, message);
export const fetchTireStockCompaniesRequest = (serviceProviderGroupId: string) =>
    action(ReportsActionTypes.FETCH_TIRE_STOCK_COMPANIES_REQUEST, serviceProviderGroupId);
export const fetchTireStockCompaniesSuccess = (companies:TireStockCompany[]) =>
    action(ReportsActionTypes.FETCH_TIRE_STOCK_COMPANIES_SUCCESS, companies);
export const fetchTireStockCompaniesFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_TIRE_STOCK_COMPANIES_FAILURE, message);
export const fetchTireStockReportRequest = (request: TireStockReportRequest) =>
    action(ReportsActionTypes.FETCH_TIRE_STOCK_REPORT_REQUEST, request);
export const fetchTireStockReportSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_TIRE_STOCK_REPORT_SUCCESS, reportInfo);
export const fetchTireStockReportFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_TIRE_STOCK_REPORT_FAILURE, message);


export const fetchScheduledReportsRequest = () =>
    action(ReportsActionTypes.FETCH_SCHEDULED_REPORTS_REQUEST);
export const fetchScheduledReportsSuccess = (scheduledReports: ScheduledReport[]) =>
    action(ReportsActionTypes.FETCH_SCHEDULED_REPORTS_SUCCESS, scheduledReports);
export const fetchScheduledReportsFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_SCHEDULED_REPORTS_FAILURE, message);
export const createScheduledReportsRequest = (scheduledReport: ScheduledReport) =>
    action(ReportsActionTypes.CREATE_SCHEDULED_REPORT_REQUEST, scheduledReport);
export const createScheduledReportsSuccess = (scheduledReport: ScheduledReport) =>
    action(ReportsActionTypes.CREATE_SCHEDULED_REPORT_SUCCESS, scheduledReport);
export const createScheduledReportsFailure = (message: string) =>
    action(ReportsActionTypes.CREATE_SCHEDULED_REPORT_FAILURE, message);
export const updateScheduledReportsRequest = (scheduledReport: ScheduledReport) =>
    action(ReportsActionTypes.UPDATE_SCHEDULED_REPORT_REQUEST, scheduledReport);
export const updateScheduledReportsSuccess = (scheduledReport: ScheduledReport) =>
    action(ReportsActionTypes.UPDATE_SCHEDULED_REPORT_SUCCESS, scheduledReport);
export const updateScheduledReportsFailure = (message: string) =>
    action(ReportsActionTypes.UPDATE_SCHEDULED_REPORT_FAILURE, message);
export const deleteScheduledReportsRequest = (id: string) =>
    action(ReportsActionTypes.DELETE_SCHEDULED_REPORT_REQUEST, id);
export const deleteScheduledReportsSuccess = (id: string) =>
    action(ReportsActionTypes.DELETE_SCHEDULED_REPORT_SUCCESS, id);
export const deleteScheduledReportsFailure = (message: string) =>
    action(ReportsActionTypes.DELETE_SCHEDULED_REPORT_FAILURE, message);

export const fetchReportInfoRequest = (id: string) =>
    action(ReportsActionTypes.FETCH_REPORT_INFO_REQUEST, id);
export const fetchReportInfoSuccess = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_REPORT_INFO_SUCCESS, reportInfo);
export const fetchReportInfoFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_REPORT_INFO_FAILURE, message);

export const fetchReportDownloadRequest = (reportInfo: ReportInfo) =>
    action(ReportsActionTypes.FETCH_REPORT_DOWNLOAD_REQUEST, reportInfo);
export const fetchReportDownloadSuccess = () =>
    action(ReportsActionTypes.FETCH_REPORT_DOWNLOAD_SUCCESS);
export const fetchReportDownloadFailure = (message: string) =>
    action(ReportsActionTypes.FETCH_REPORT_DOWNLOAD_FAILURE, message);
