import {SidebarNavItemTypes} from './sidebar-item-types';
import {RouteUrl} from '../../routes';

export function determineActiveSidebarItem(locationPathName: string): SidebarNavItemTypes {
  if (locationPathName.includes(RouteUrl.Analytics)) {
    return SidebarNavItemTypes.Analytics;
  }
  if (locationPathName.includes(RouteUrl.Jobs)) {
    return SidebarNavItemTypes.Explorer;
  }
  if (locationPathName.includes(RouteUrl.Reports)) {
    return SidebarNavItemTypes.Inbox;
  }
  return SidebarNavItemTypes.Analytics;
}
