import {ControlValue} from '../../controls-snapshot';
import {ServiceProviderSelection} from '../../../../../store/analytics';

export class ServiceProviderSelectionsControlValue implements ControlValue {
    constructor(public displayValue: string,
                public value: ServiceProviderSelection) {}

    public equals(other: ControlValue):boolean {
        if (!other ||
            !other.value ||
            !(other instanceof ServiceProviderSelectionsControlValue) ||
            this.value.includes.length !== other.value.includes.length) {
            return false;
        }

        return this.value.includes.sort().toString() === other.value.includes.sort().toString();
    }
}
