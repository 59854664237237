export function groupBy<T, TKey extends keyof T>(collection: T[], key: TKey): Map<T[TKey], T[]> {
    return collection.reduce((result, item) => {
        if (result.has(item[key])) {
            result.get(item[key])!.push(item);
        } else {
            result.set(item[key], [item]);
        }
        return result;
    }, new Map<T[TKey], T[]>());
}

export function onlyUnique<T>(value: T, index: number, collection: T[]): boolean {
    return collection.indexOf(value) === index;
}
