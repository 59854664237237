import {WithTranslation, withTranslation} from 'react-i18next';
import {Component} from 'react';
import styles from './sidebar-footer-item.module.scss';
import {ReactComponent as SupportIcon} from '../../../assets/icons/noun_support_4335804.svg';
import {ReactComponent as PrivacyPolicyIcon} from '../../../assets/icons/noun_lock_document_552848.svg';
import {ReactComponent as TermsOfUseIcon} from '../../../assets/icons/noun_contract_587925.svg';
import {ReactComponent as CollapseIcon} from '../../../assets/icons/noun_sidebar_385157.svg';
import {ReactComponent as LogoutIcon} from '../../../assets/icons/noun_power off_108691.svg';
import {conditionalClassLister} from '../../utils/class-helpers';
import {SidebarFooterItemTypes} from './sidebar-item-types';

class SidebarFooterItem extends Component<AllProps, AllState> {

    public render(): JSX.Element {
        const {label, isOpen} = this.props;

        const itemClasses = conditionalClassLister(styles)({
            footerItemContainer: true,
            sidebarIsOpen: isOpen,
        });
        return (
            <div className={itemClasses} onClick={() => this.onClicked()}>
                <div className={styles.sidebarIconPanel}>
                    {(() => {
                        const {type} = this.props;
                        switch (type) {
                            case SidebarFooterItemTypes.Toggle:
                                return <CollapseIcon className={styles.sidebarIcon}/>;
                            case SidebarFooterItemTypes.Support:
                                return <SupportIcon className={styles.supportIcon}/>;
                            case SidebarFooterItemTypes.PrivacyPolicy:
                                return <PrivacyPolicyIcon className={styles.privacyPolicyIcon}/>;
                            case SidebarFooterItemTypes.TermsOfUse:
                                return <TermsOfUseIcon className={styles.termsOfUseIcon}/>;
                            case SidebarFooterItemTypes.Logout:
                                return <LogoutIcon className={styles.logoutIcon}/>;
                            default:
                                return null;
                        }
                    })()}
                </div>
                <div className={styles.sidebarTextPanel}>{label}</div>
            </div>
        );
    }

    private onClicked() {
        const {onClick} = this.props;
        onClick();
    }
}

export default withTranslation()(SidebarFooterItem);

interface OwnProps {
    type: SidebarFooterItemTypes;
    label: string;
    onClick: () => void;
    isOpen: boolean;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
