import * as React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import styles from './widget-table.module.scss';
import {generateUUID} from '../../utils/uuid-helpers';
import {conditionalClassLister} from '../../utils/class-helpers';
import EllipsisWithTooltip from '../ellipsis-with-tooltip/ellipsis-with-tooltip';

export enum WidgetTableColumnType {
    text,
    upperText,
    number,
    currency,
}

export interface WidgetColumnConfig {
    title: string;
    property: string;
    type: WidgetTableColumnType;
    width?: string;
    clickAction?: (columnValue: any,row:any) => void
    skipClick?:(columnValue: any,row:any) => boolean
}

export class WidgetTable extends React.Component<AllProps, AllState> {

    public render(): JSX.Element {
        const {config, data} = this.props;
        const rowClasses = conditionalClassLister(styles)({
            row: true,
            noLastRowBorder: config.hideLastRecordBorder,
        });

        return (
            <div className={styles.table}>
                <div className={`${styles.row} ${styles.header}`}>
                    {config.columns.map((column) => <div className={styles.cell} key={column.title}
                                                         style={column.width ? {width: column.width} : {}}>
                        <EllipsisWithTooltip id={generateUUID()}
                                             placement="top"> {column.title}
                        </EllipsisWithTooltip>
                    </div>)}
                </div>
                <div className={styles.rows}>
                    {data.map((row) => <div className={rowClasses} key={generateUUID()}>
                        {config.columns.map((column) => {
                            const cellClasses = conditionalClassLister(styles)({
                                cell: true,
                                cellLink: column.clickAction !== undefined && column.skipClick !== undefined && !column.skipClick(row[column.property],row)
                            });
                            return <div className={cellClasses} style={column.width ? {width: column.width} : {}}
                                        key={generateUUID()}
                                        onClick={() => this.onColumnValueClick(column, row[column.property],row)}>
                                {row[column.property] || row[column.property] === 0 ? this.printValue(row, column) : 'NA'}
                            </div>;
                        })}
                    </div>)}
                </div>
            </div>
        );
    }

    public onColumnValueClick(config: WidgetColumnConfig, value: any,row:any): void {
        if (config.clickAction && config.skipClick !== undefined && !config.skipClick(value,row)) {
            config.clickAction(value,row);
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public printValue(row: any, column: WidgetColumnConfig): JSX.Element {
        switch (column.type) {
            case WidgetTableColumnType.number:
                return <span><NumberFormat value={row[column.property]}
                                           displayType="text"
                                           decimalSeparator=","
                                           thousandSeparator="."
                                           fixedDecimalScale={false}
                                           decimalScale={0}/></span>;
            case WidgetTableColumnType.currency:
                return <span><NumberFormat value={row[column.property]}
                                           displayType="text"
                                           decimalSeparator=","
                                           thousandSeparator="."
                                           fixedDecimalScale
                                           decimalScale={2}/></span>;
            case WidgetTableColumnType.upperText:
                return <span> <EllipsisWithTooltip id={generateUUID()}
                                                   placement="top"> {row[column.property].toUpperCase()}
                            </EllipsisWithTooltip></span>;
            default:
                return <span><EllipsisWithTooltip id={generateUUID()}
                                                  placement="top"> {row[column.property]}
                            </EllipsisWithTooltip></span>;
        }
    }
}

export default withTranslation()(WidgetTable);

interface OwnProps {
    config: WidgetTableConfig;
    data: any[];
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
}

type AllState = OwnState;

export interface DropdownOption {
}

export interface WidgetTableConfig {
    columns: WidgetColumnConfig[];
    hideLastRecordBorder?: boolean;
}
