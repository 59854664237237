import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import {Color} from '@amcharts/amcharts4/core';
import {faChartBar, faTable} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import ReactTooltip from 'react-tooltip';
import {TFunction} from "i18next";
import WidgetTable, {WidgetTableColumnType} from '../../../../components/widget-table/widget-table';
import {ApplicationState} from '../../../../store';
import {ViewMode} from '../../../../types/view-mode';
import {generateUUID} from '../../../../utils/uuid-helpers';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {colorBlue5, colorYellow5} from '../utils/chart-colors';
import {
    addStandardTooltipStyle,
    addStrokeToColumnChart,
    createXYChart,
} from '../utils/chart-utils';
import {createDateRangeWithServiceProvidersAndSoldTosRequest, hasInspectionPeriod,} from '../utils/widget-helper';
import WidgetLoading from '../widget-loading';
import {
    brandField,
    buildChartData, buildTableData,
    newUnitsField,
    retreadUnitsField,
    tireTypeField,
    unitsField
} from './chart-data-adapter';
import styles from './corporate-fitment-distribution-widget.module.scss';
import {getCorporateFitmentDistributionRequest} from '../../../../store/analytics';
import {TireType} from '../../../../store/analytics/types/tires-distribution.types';
import {getTooltipDateRangeTextForWidget, WidgetDateRangeUsedType} from '../../../../utils/tooltip-helper';
import {
    CorporateFitmentDistributionData,
    CorporateFitmentDistributionRequest
} from "../../../../store/analytics/types/corporata-fitment-distribution.types";
import {getTireTypeLabel} from "../../../../utils/translations/tire-type-translation-helper";

class CorporateFitmentDistributionWidget extends SingleChartWidgetBase<AllProps, AllState, CorporateFitmentDistributionData[]> {
    private chartId: string;

    private chartColors: Map<string, Color>;

    private hiddenFields: string[];

    constructor(props) {
        super(props);
        this.chartId = generateUUID();
        this.chartColors = new Map<string, Color>();
        this.chartColors.set(TireType.NewTire, am4core.color(colorBlue5));
        this.chartColors.set(TireType.RetreadTire, am4core.color(colorYellow5));
        this.hiddenFields = [];

        this.state = {
            viewMode: ViewMode.chart,
            chartData: [],
            tableData: []
        };
    }

    public render(): JSX.Element {
        const {t, data} = this.props;
        const {viewMode, tableData} = this.state;

        return (
            <div className={styles.widgetContainer}>
                {data ? (
                    <div className={styles.chartView}>
                        <div className={styles.chartHeader}>
                            <div className={styles.headerTitle}>{t('Corporate Fitment Distribution')}
                                <div className={styles.infoIcon}
                                     data-tip={this.getTooltipContent(t)}
                                     data-for="brand-distribution-info">
                                    <FontAwesomeIcon icon={faInfoCircle}/></div>
                                <ReactTooltip className={styles.tooltip} html border effect="solid" place={'right'}
                                              backgroundColor="#000000" id="brand-distribution-info"/></div>
                            <div className={styles.interactions}>
                                <div className={styles.headerToggle} onClick={() => this.toggleViewMode()}>
                                    {viewMode === ViewMode.chart ? (
                                        <FontAwesomeIcon icon={faTable}/>
                                    ) : (
                                        <FontAwesomeIcon icon={faChartBar}/>
                                    )}
                                </div>
                            </div>
                        </div>
                        {viewMode === ViewMode.chart ? (
                            <div className={styles.chartContent}>
                                <div id={this.chartId} style={{width: '100%'}}/>
                            </div>
                        ) : (
                            <div className={styles.tableContent}>
                                <WidgetTable
                                    config={{
                                        columns: [
                                            {
                                                title: t('Brand'),
                                                property: brandField,
                                                type: WidgetTableColumnType.upperText,
                                                width: '320px',
                                            },
                                            {
                                                title: t('Type'),
                                                property: tireTypeField,
                                                type: WidgetTableColumnType.upperText,
                                            },
                                            {
                                                title: t('# Units'),
                                                property: unitsField,
                                                type: WidgetTableColumnType.number,
                                            },
                                        ],
                                    }}
                                    data={tableData}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <WidgetLoading/>
                )}
            </div>
        );
    }

    private getTooltipContent(t: TFunction): string {
        const infoText = t(`To achieve the 'Direct Tyre Purchases' objective, all FOS fitted tyres must be bought directly from Goodyear. Ensure direct tyre purchases from Goodyear are at least equal to the below FOS fitments over the same period.`);
        return `${infoText}</br>${getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.JOB_DATE_RANGE_USED, t)}`;
    }

    protected fetchData(snapshot: ControlsSnapshot): void {
        const {dispatchGetCorporateFitmentDistributionRequest} = this.props;
        dispatchGetCorporateFitmentDistributionRequest(createDateRangeWithServiceProvidersAndSoldTosRequest(this.props.controlsSnapshot));
    }

    private toggleViewMode(): void {
        const {viewMode} = this.state;
        const updatedViewMode = viewMode === ViewMode.chart ? ViewMode.table : ViewMode.chart;
        this.setState({viewMode: updatedViewMode}, () => {
            if (this.state.viewMode === ViewMode.chart) {
                this.refreshChart();
            } else {
                this.disposeChart();
            }
        });
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    protected createChart(data: CorporateFitmentDistributionData[]): am4charts.XYChart {
        const {t} = this.props;
        const chart = createXYChart(this.chartId);
        const tableData = buildTableData(data, t);
        const chartData = buildChartData(data, t);
        this.setState({chartData, tableData});

        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = brandField;
        categoryAxis.title.text = this.props.t('Brand');
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 15;

        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = this.props.t('# Units');

        this.addStackedSeries(chart, newUnitsField, TireType.NewTire, t);
        this.addStackedSeries(chart, retreadUnitsField, TireType.RetreadTire, t);
        chart.data = chartData;

        chart.legend = new am4charts.Legend();
        chart.legend.position = 'bottom';
        chart.legend.itemContainers.template.events.on('hit', (event) => {
            this.onLegendClick(event);
        });

        return chart;
    }

    private onLegendClick(event: any) {
        this.updateFilteredFields(event);
        this.redraw(this.props.data);
    }

    private updateFilteredFields(event: any) {
        console.log(event);
        const fieldValue = event.target.dataItem!.dataContext.dataFields.valueX;
        if (!this.hiddenFields.includes(fieldValue)) {
            this.hiddenFields.push(fieldValue);
        } else {
            const index = this.hiddenFields.indexOf(fieldValue);
            this.hiddenFields.splice(index, 1);
        }
    }

    public addStackedSeries(chart: any, unitsField: string, tireType: TireType, translate: TFunction): void {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.stacked = true;
        series.name = getTireTypeLabel(translate, tireType);
        ;
        series.dataFields.valueX = unitsField;
        series.dataFields.categoryY = brandField;
        series.columns.template.tooltipText = `{categoryY} - ${getTireTypeLabel(translate, tireType)}: [bold]{valueX} ${this.props.t('units')}[/]`;
        series.columns.template.fillOpacity = 0.8;
        if (this.hiddenFields.includes(unitsField)) {
            series.hidden = true;
        }

        addStandardTooltipStyle(series);
        addStrokeToColumnChart(series);
        series.columns.template.adapter.add('fill', (fill, target) => this.chartColors.get(tireType)!);
        series.columns.template.adapter.add('stroke', (fill, target) => this.chartColors.get(tireType)!);
    }
}

const mapStateToProps = ({analytics}: ApplicationState) => ({
    data: analytics.corporateFitmentDistributionData,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchGetCorporateFitmentDistributionRequest: (request: CorporateFitmentDistributionRequest) =>
        dispatch(getCorporateFitmentDistributionRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CorporateFitmentDistributionWidget));

interface PropsFromState {
    data: CorporateFitmentDistributionData[];
}

interface PropsFromDispatch {
    dispatchGetCorporateFitmentDistributionRequest: typeof getCorporateFitmentDistributionRequest;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    viewMode: ViewMode;
    chartData: any[];
    tableData: any[];
}

type AllState = OwnState;
