export class RuntimeConfig {
    public static getRootApiURL() :string{
        return this.getRuntimeConfig('rootApiUrl');
    }

    public static getDashboardApiURL() :string{
        return this.getRuntimeConfig('dashboardApiUrl');
    }

    public static getReportsApiURL() :string{
        return this.getRuntimeConfig('reportsApiUrl');
    }

    public static getUMFApiURL():string {
        return this.getRuntimeConfig('umfApiUrl');
    }

    public static getCognitoUserPoolId():string {
        return this.getRuntimeConfig('cognitoUserPoolId');
    }

    public static getCognitoOAuthDomain():string {
        return this.getRuntimeConfig('cognitoOAuthDomain');
    }

    public static getCognitoRegion():string {
        return this.getRuntimeConfig('cognitoRegion');
    }

    public static getCognitoIdentityPoolId():string {
        return this.getRuntimeConfig('cognitoIdentityPoolId');
    }

    public static getCognitoClientId():string {
        return this.getRuntimeConfig('cognitoClientId');
    }

    private static getRuntimeConfig(configProp: string):string {
        // @ts-ignore
        return window.runConfig[configProp];
    }
}
