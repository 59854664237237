import { TFunction } from 'i18next';
import { TireBrandDistributionData } from '../../../../store/analytics/types/tire-brand-distribution.types';
import { TireBrandType } from '../../../../store/analytics/types/tires-distribution.types';

export const brandField = 'brandName';
export const unitsField = 'tireCount';
export const ownBrandField = 'ownBrand';

export function buildChartData(data: TireBrandDistributionData[], translate: TFunction): any[] {
    const result: any[] = [];
    data.forEach((row) => {
        const r = {};
        r[brandField] = row.brandName;
        r[unitsField] = row.tireCount;
        if (row.brandName === 'UNKNOWN') {
            r[ownBrandField] = TireBrandType.Unknown;
        } else {
            r[ownBrandField] = row.ownBrand ? TireBrandType.Corporate : TireBrandType.NonCorporate;
        }
        result.push(r);
    });

    return result.reverse();
}
