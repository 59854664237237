import {TFunction} from 'i18next';
import {KpiTypes} from '../../../store/analytics';
import {
    ControlType,
    Dashboard,
    DashboardControl,
    DashboardWidget,
    ParamKey,
    WidgetType,
} from '../../../store/dashboard';
import {generateUUID} from '../../../utils/uuid-helpers';

function createStandardControls(t: TFunction): DashboardControl[] {
    return [
        {
            type: ControlType.DateRange,
            key: ParamKey.InspectionPeriod,
            summaryTitle: t('Date Range'),
            configuration: {}
        },
        {
            type: ControlType.ServiceProviders,
            key: ParamKey.ServiceProviderSelections,
            summaryTitle: t('Service Providers'),
            configuration: {},
        },
        {
            type: ControlType.SoldTos,
            key: ParamKey.SoldToSelections,
            summaryTitle: t('Fleets'),
            configuration: {},
        },
    ];
}

function createFinancialWidgets(): DashboardWidget[] {
    const totalInvoicedId = generateUUID();
    const purchasingDistributionId = generateUUID();
    const purchasingDistributionKpiId = generateUUID();
    const purchasingEvolutionId = generateUUID();

    return [
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.TotalInvoiced},
            id: totalInvoicedId,
            layout: {i: totalInvoicedId, w: 3, h: 3, x: 0, y: 0},
        },
        {
            type: WidgetType.PurchasingDistributionKpi,
            configuration: {},
            id: purchasingDistributionKpiId,
            layout: {i: purchasingDistributionKpiId, w: 3, h: 3, x: 3, y: 0},
        },
        {
            type: WidgetType.PurchasingDistribution,
            configuration: {},
            id: purchasingDistributionId,
            layout: {i: purchasingDistributionId, w: 6, h: 6, x: 0, y: 3},
        },
        {
            type: WidgetType.PurchasingEvolution,
            configuration: {},
            id: purchasingEvolutionId,
            layout: {i: purchasingEvolutionId, w: 12, h: 7, x: 0, y: 9},
        },
    ];
}

function createJobsWidgets(): DashboardWidget[] {
    const totalJobCountId = generateUUID();
    const totalInspectionCountId = generateUUID();
    const inspectionRatioId = generateUUID();
    const ejobUtilizationId = generateUUID();
    const jobsDistributionId = generateUUID();
    const avgProcessTimeId = generateUUID();

    return [
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.TotalJobCount},
            id: totalJobCountId,
            layout: {i: totalJobCountId, w: 3, h: 3, x: 0, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.TotalInspectionCount},
            id: totalInspectionCountId,
            layout: {i: totalInspectionCountId, w: 3, h: 3, x: 3, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.InspectionRatio},
            id: inspectionRatioId,
            layout: {i: inspectionRatioId, w: 3, h: 3, x: 6, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.EjobUtilization},
            id: ejobUtilizationId,
            layout: {i: ejobUtilizationId, w: 3, h: 3, x: 0, y: 3},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.JobsAvgProcessTime},
            id: avgProcessTimeId,
            layout: {i: avgProcessTimeId, w: 3, h: 3, x: 3, y: 3},
        },
        {
            type: WidgetType.JobsDistribution,
            configuration: {},
            id: jobsDistributionId,
            layout: {i: jobsDistributionId, w: 6, h: 6, x: 0, y: 6},
        }
    ];
}

function createTiresWidgets(): DashboardWidget[] {
    const retreadRatioId = generateUUID();
    const corporateRatioId = generateUUID();
    const regrooveRatioId = generateUUID();
    const regrooveCountId = generateUUID();
    const totalTiresCountId = generateUUID();
    const tiresDistributionId = generateUUID();
    const tireBrandDistributionId = generateUUID();

    return [
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.TotalTiresCount},
            id: totalTiresCountId,
            layout: {i: totalTiresCountId, w: 3, h: 3, x: 0, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.RetreadRatio},
            id: retreadRatioId,
            layout: {i: retreadRatioId, w: 3, h: 3, x: 3, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.RegrooveCount},
            id: regrooveCountId,
            layout: {i: regrooveCountId, w: 3, h: 3, x: 0, y: 3},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.RegrooveRatio},
            id: regrooveRatioId,
            layout: {i: regrooveRatioId, w: 3, h: 3, x: 3, y: 3},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.CorporateBrandRatio},
            id: corporateRatioId,
            layout: {i: corporateRatioId, w: 3, h: 3, x: 0, y: 6},
        },
        {
            type: WidgetType.TiresDistribution,
            configuration: {},
            id: tiresDistributionId,
            layout: {i: tiresDistributionId, w: 6, h: 6, x: 0, y: 9},
        },
        {
            type: WidgetType.TireBrandDistribution,
            configuration: {},
            id: tireBrandDistributionId,
            layout: {i: tireBrandDistributionId, w: 6, h: 15, x: 6, y: 0},
        },
    ]
}

function createInspectionsWidgets(): DashboardWidget[] {
    const topSoldTosId = generateUUID();

    return [
        {
            type: WidgetType.TopSoldTos,
            configuration: {},
            id: topSoldTosId,
            layout: {i: topSoldTosId, w: 8, h: 12, x: 0, y: 0},
        },
    ]
}

function createKPIWidgets(): DashboardWidget[] {
    const inspectionRatioId = generateUUID();
    const corporateBrandRatioId = generateUUID();
    const regrooveCountId = generateUUID();
    const regrooveRatioId = generateUUID();
    const retreadRatioId = generateUUID();
    const ejobUtilizationId = generateUUID();
    const eCasingReturnsRatioId = generateUUID();
    const jobsAvgProcessTimeId = generateUUID();
    const averageTreadDepthId = generateUUID();
    const corporateFitmentDistributionId = generateUUID();
    const pwtCycleTime = generateUUID();
    const pwtCurrentStock = generateUUID();

    return [
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.InspectionRatio},
            id: inspectionRatioId,
            layout: {i: inspectionRatioId, w: 3, h: 3, x: 0, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.EjobUtilization},
            id: ejobUtilizationId,
            layout: {i: ejobUtilizationId, w: 3, h: 3, x: 3, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.RegrooveCount},
            id: regrooveCountId,
            layout: {i: regrooveCountId, w: 3, h: 3, x: 0, y: 3},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.RegrooveRatio},
            id: regrooveRatioId,
            layout: {i: regrooveRatioId, w: 3, h: 3, x: 3, y: 3},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.RetreadRatio},
            id: retreadRatioId,
            layout: {i: retreadRatioId, w: 3, h: 3, x: 0, y: 6},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.ECasingReturnsRatio},
            id: eCasingReturnsRatioId,
            layout: {i: eCasingReturnsRatioId, w: 3, h: 3, x: 3, y: 6},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.CorporateBrandRatio},
            id: corporateBrandRatioId,
            layout: {i: corporateBrandRatioId, w: 3, h: 3, x: 0, y: 9},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.AverageTreadDepth},
            id: averageTreadDepthId,
            layout: {i: averageTreadDepthId, w: 3, h: 3, x: 3, y: 9},
        },
        {
            type: WidgetType.CorporateFitmentDistribution,
            configuration: {},
            id: corporateFitmentDistributionId,
            layout: {i: corporateFitmentDistributionId, w: 6, h: 15, x: 9, y: 0},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.JobsAvgProcessTime},
            id: jobsAvgProcessTimeId,
            layout: {i: jobsAvgProcessTimeId, w: 3, h: 3, x: 0, y: 12},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.PWTCycleTime},
            id: pwtCycleTime,
            layout: {i: pwtCycleTime, w: 3, h: 3, x: 3, y: 12},
        },
        {
            type: WidgetType.KpiBox,
            configuration: {type: KpiTypes.PWTCurrentStock},
            id: pwtCurrentStock,
            layout: {i: pwtCurrentStock, w: 3, h: 3, x: 0, y: 15},
        },
    ]
}

export function createDefaultDashboardConfig(t: TFunction): Dashboard[] {
    const financialOverview: Dashboard = {
        id: 'financial-overview',
        name: t('Financial overview'),
        index: 0,
        controls: createStandardControls(t),
        widgets: createFinancialWidgets(),
        isConfigurable:false
    };

    const jobDetails: Dashboard = {
        id: 'job-details',
        name: t('Job details'),
        index: 1,
        controls: createStandardControls(t),
        widgets: createJobsWidgets(),
        isConfigurable:false
    };

    const tireAnalysis: Dashboard = {
        id: 'tire-analysis',
        name: t('Tire analysis'),
        index: 2,
        controls: createStandardControls(t),
        widgets: createTiresWidgets(),
        isConfigurable:false
    };

    const inspections: Dashboard = {
        id: 'inspections',
        name: t('Inspections'),
        index: 3,
        controls: createStandardControls(t),
        widgets: createInspectionsWidgets(),
        isConfigurable:false
    };

    const kpis: Dashboard = {
        id: 'kpis',
        name: t(`KPI's`),
        index: 4,
        controls: createStandardControls(t),
        widgets: createKPIWidgets(),
        isConfigurable:false
    };

    return [financialOverview, jobDetails, tireAnalysis, inspections,kpis];
}
