import { ActionType } from 'typesafe-actions';
import {User, UserSummary} from '../authentication';
import * as sharedActions from '../shared/actions';
import { Paged, RequestStatus } from '../shared/types';
import * as actions from './actions';

export enum UsersActionTypes {
    CLEAR_USERS = '@@users/CLEAR_USERS',
    SEARCH_USERS_REQUEST = '@@users/SEARCH_USERS_REQUEST',
    SEARCH_USERS_SUCCESS = '@@users/SEARCH_USERS_SUCCESS',
    SEARCH_USERS_FAILURE = '@@users/SEARCH_USERS_FAILURE',

    GET_USER_REQUEST = '@@users/GET_USER_REQUEST',
    GET_USER_SUCCESS = '@@users/GET_USER_SUCCESS',
    GET_USER_FAILURE = '@@users/GET_USER_FAILURE',

    UPDATE_USER_REQUEST = '@@users/UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS = '@@users/UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE = '@@users/UPDATE_USER_FAILURE',
}

export type UsersAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface UsersState {
    readonly user?: User;
    readonly users?: Paged<UserSummary>;

    readonly updateUserRequestStatus?: RequestStatus;
    readonly getUserRequestStatus?: RequestStatus;
    readonly searchUsersRequestStatus?: RequestStatus;
}

export interface UsersRequest {
    page: number;
    itemsPerPage: number;
    search?: string;
}
