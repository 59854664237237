import {ActionType} from 'typesafe-actions';
import {ServiceProviderGroup, ServiceProviderGroupWithConfiguration} from '../service-provider-groups';
import * as sharedActions from '../shared/actions';
import {RequestStatus} from '../shared/types';
import * as actions from './actions';

export enum AuthenticationActionTypes {
    FEDERATED_LOGIN_REQUEST = '@@auth/FEDERATED_LOGIN_REQUEST',
    LOGIN_REQUEST = '@@auth/LOGIN_REQUEST',
    RESET_FORGOT_PASSWORD_FORM = '@@auth/RESET_FORGOT_PASSWORD_FORM',
    CONFIRM_RESET_PASSWORD_REQUEST = '@@auth/CONFIRM_RESET_PASSWORD_REQUEST',
    CONFIRM_RESET_PASSWORD_SUCCESS = '@@auth/CONFIRM_RESET_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_REQUEST = '@@auth/RESET_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS = '@@auth/RESET_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE = '@@auth/RESET_PASSWORD_FAILURE',
    LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS',
    LOGIN_NEW_PASSWORD_REQUIRED = '@@auth/LOGIN_NEW_PASSWORD_REQUIRED',
    LOGIN_PASSWORD_EXPIRED = '@@auth/LOGIN_PASSWORD_EXPIRED',
    LOGIN_FAILURE = '@@auth/LOGIN_FAILURE',
    LOGOUT_REQUEST = '@@auth/LOGOUT_REQUEST',
    FETCH_CURRENT_USER_REQUEST = '@@auth/FETCH_CURRENT_USER_REQUEST',
    FETCH_CURRENT_USER_SUCCESS = '@@auth/FETCH_CURRENT_USER_SUCCESS',
    FETCH_CURRENT_USER_FAILURE = '@@auth/FETCH_CURRENT_USER_FAILURE',
    UPDATE_CURRENT_USER_SETTINGS_REQUEST = '@@auth/UPDATE_CURRENT_USER_SETTINGS_REQUEST',
    UPDATE_CURRENT_USER_SETTINGS_SUCCESS = '@@auth/UPDATE_CURRENT_USER_SETTINGS_SUCCESS',
    UPDATE_CURRENT_USER_SETTINGS_FAILURE = '@@auth/UPDATE_CURRENT_USER_SETTINGS_FAILURE',
    SET_CURRENT_USER = '@@auth/SET_CURRENT_USER',
    FETCH_SELECTED_SERVICE_PROVIDER_NETWORK_REQUEST = '@@auth/SET_SELECTED_SERVICE_PROVIDER_NETWORK_REQUEST',
    FETCH_SELECTED_SERVICE_PROVIDER_NETWORK_SUCCESS = '@@auth/SET_SELECTED_SERVICE_PROVIDER_NETWORK_SUCCESS',
}

export type AuthenticationAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface Credentials {
    email: string;
    password: string;
}

export interface User {
    id: string;
    cognitoUsername: string;
    email: string;
    permissions: Permission[];
    preferredCurrency: string;
    language: string;
    reportAccess: string[];
    serviceProviderGroups: ServiceProviderGroup[];
    lastLogin: Date;
    confirmPolicyVersion: string;
}

export interface UserSummary {
    id: string;
    cognitoUsername: string;
    email: string;
    numberOfServiceProviderGroups: number;
    numberOfPermissions: number;
    lastLogin: Date;
}

export interface UserSettings {
    preferredCurrency: string;
    language: string;
}

export enum Permission {
    ViewAnalytics = 'GLOBAL_PERMISSION::VIEW_ANALYTICS',
    EditDashboards = 'GLOBAL_PERMISSION::EDIT_DASHBOARDS',
    ViewUsers = 'GLOBAL_PERMISSION::VIEW_USERS',
    ManageUsers = 'GLOBAL_PERMISSION::MANAGE_USERS',
    ViewServiceProviderGroups = 'GLOBAL_PERMISSION::VIEW_SERVICE_PROVIDER_GROUPS',
    ManageServiceProviderGroups = 'GLOBAL_PERMISSION::MANAGE_SERVICE_PROVIDER_GROUPS',
    ViewReports = 'GLOBAL_PERMISSION::VIEW_REPORTS',
    ManageReports = 'GLOBAL_PERMISSION::MANAGE_REPORTS',
    ViewScheduledReports = 'GLOBAL_PERMISSION::VIEW_SCHEDULED_REPORTS',
    ManageScheduledReports = 'GLOBAL_PERMISSION::MANAGE_SCHEDULED_REPORTS',
}

export interface FederatedAuthenticationRequest {
    searchUrl?: string;
}

export interface AuthenticationRequest {
    credentials: Credentials;
    newPassword?: string;
}

export interface ForgotPasswordRequestPayload {
    email: string;
}

export interface ConfirmResetPasswordRequestPayload {
    email: string;
    newPassword: string;
    verificationCode: string;
}

export interface ResetPasswordConfirmationRequest {
    email: string;
}

export enum AuthenticationResult {
    Success,
    NewPasswordRequired,
    PasswordExpired,
}

export enum ResetPassswordResult {
    Success,
}

export interface UpdateCurrentUserSettingsRequest {
    preferredCurrency: string;
    language: string;
}

export interface AuthenticationState {
    readonly resetCodeRequired: boolean;
    readonly resetFailureMessage?: string;
    readonly authenticating: boolean;
    readonly authenticated: boolean;
    readonly loginFailureMessage?: string;
    readonly inProgressUserRetrieval: boolean;
    readonly userRetrievalFailureMessage?: string;
    readonly user?: User;
    readonly serviceProviderGroup?: ServiceProviderGroupWithConfiguration;
    readonly newPasswordRequired: boolean;
    readonly passwordExpired: boolean;
    readonly updateCurrentUserSettingsRequestStatus: RequestStatus;
}
