import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import styles from './information-popup.module.scss';

class InformationPopup extends Component<AllProps> {
    public render(): JSX.Element {
        const {t, message, confirmButtonText, headerText} = this.props;
        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupHeader}>
                    {headerText}
                </div>
                <div className={styles.popupBody}>
                    <div className={styles.caption}>{t(message)}</div>
                    <div className={styles.buttons}>
                        <button type="button" className={styles.button} onClick={() => this.onConfirmClick()}>
                            {confirmButtonText}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    private onConfirmClick() {
        const {onConfirm} = this.props;
        onConfirm();
    }
}

export default withTranslation()(InformationPopup);

interface OwnProps {
    headerText: string;
    message: string;
    confirmButtonText: string;
    onConfirm: () => void;
}

type AllProps = OwnProps & WithTranslation;
