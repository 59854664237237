import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {push} from 'connected-react-router';
import {LocationDescriptorObject} from 'history';
import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import MenuButton from '../../components/header-menu/menu-button';
import {RouteUrl} from '../../routes';
import {ApplicationState} from '../../store';
import {logoutRequest, User} from '../../store/authentication';
import {ServiceProviderGroup} from '../../store/service-provider-groups';
import {toggleSidebar} from '../../store/layout';
import {unselectServiceProviderGroup} from '../../store/shared/actions';
import {RecentServiceProvidersMap} from '../../types/service-provider-group';
import {compareDesc, format} from '../../utils/date-helper';
import {
    LocalStorageKey,
    persistParsedToLocalStorage,
    retrieveParsedFromLocalStorage,
} from '../../utils/local-storage-helpers';
import styles from './service-provider-group-selection.module.scss';
import {conditionalClassLister} from "../../utils/class-helpers";

class ServiceProviderGroupSelectionPage extends Component<AllProps, AllState> {
    private readonly maximumRecent = 5;

    private selectedElement?: HTMLDivElement;

    constructor(props) {
        super(props);

        const {user, dispatchToggleSidebar, dispatchUnselectServiceProviderGroup} = this.props;
        const recentServiceProviderGroups =
            retrieveParsedFromLocalStorage<RecentServiceProvidersMap>(LocalStorageKey.RecentServiceProviderGroups) || {};
        const sortedRecentServiceProviderGroups = this.sortRecentServiceProviderGroups(recentServiceProviderGroups);
        dispatchUnselectServiceProviderGroup();

        this.state = {
            search: '',
            selectedIndex: -1,
            serviceProviderGroups: user ? user.serviceProviderGroups.sort((a, b) => a.name.localeCompare(b.name)) : [],
            recentServiceProviderGroups: sortedRecentServiceProviderGroups,
        };
        dispatchToggleSidebar(false);
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>, snapshot?: any): void {
        const {user} = this.props;
        const {selectedIndex} = this.state;

        if (user !== prevProps.user) {
            const recentServiceProviderGroups =
                retrieveParsedFromLocalStorage<RecentServiceProvidersMap>(LocalStorageKey.RecentServiceProviderGroups) || {};
            const sortedRecentServiceProviderGroups = this.sortRecentServiceProviderGroups(recentServiceProviderGroups);

            this.setState({
                serviceProviderGroups: user ? user.serviceProviderGroups.sort((a, b) => a.name.localeCompare(b.name)) : [],
                recentServiceProviderGroups: sortedRecentServiceProviderGroups,
            });
            this.onFilter('');
        }

        if (selectedIndex > -1 && selectedIndex !== prevState.selectedIndex) {
            this.selectedElement?.scrollIntoView({behavior: 'smooth'});
        }

    }

    public render() {
        const {t, user} = this.props;
        const {search, serviceProviderGroups, recentServiceProviderGroups, selectedIndex} = this.state;

        return user ? (
            <div className={styles.container}>
                <div className={styles.header}>
                    <MenuButton/>
                </div>
                <div className={styles.content}>
                    <div className={styles.serviceProviderGroupsContainer}>
                        <div className={styles.availableServiceProviderGroups}>
                            <div className={styles.availableTitle}>{t('Please select a Service Provider Group')}</div>
                            <div className={styles.searchContainer}>
                                <FontAwesomeIcon className={styles.searchIcon} icon={faSearch}/>
                                <input
                                    className={styles.searchInput}
                                    type='text'
                                    onKeyDown={(e) => this.handleKeyDown(e)}
                                    placeholder={t('Service Provider Group name')}
                                    autoFocus
                                    onInput={(event) => this.onFilter(event.currentTarget.value)}
                                />
                            </div>
                            <div className={styles.resultContainer}>
                                {serviceProviderGroups.map((fc, index: number) => {
                                    const isSelected = index === selectedIndex;
                                    const serviceProviderGroupClasses = conditionalClassLister(styles)({
                                        isSelected,
                                        resultItem: true,
                                    });
                                    return (
                                        <div
                                            className={serviceProviderGroupClasses}
                                            ref={(r) => {
                                                if (isSelected) {
                                                    this.selectedElement = r!
                                                }
                                            }
                                            }
                                            key={fc.id}
                                            onClick={() => this.onSelect(fc)}>
                                            {Array.from(fc.name).map((c, i) => (
                                                    <span
                                                        className={
                                                            search.toLowerCase().includes(c.toLowerCase())
                                                                ? styles.matchingCharacter
                                                                : undefined
                                                        }
                                                        key={i}>
                                                        {c !== ' ' ? c : <>&nbsp;</>}
                                                    </span>
                                                ))}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={styles.recentServiceProviderGroups}>
                            <div className={styles.recentServiceProviderGroupsTitle}>{t('Recent')}</div>
                            {recentServiceProviderGroups.hasOwnProperty(user.id) &&
                            Object.keys(recentServiceProviderGroups[user.id]).length > 0 ? (
                                Object.keys(recentServiceProviderGroups[user.id]).map((name) => (
                                        <div
                                            className={styles.recentServiceProviderGroup}
                                            key={name}
                                            onClick={() => this.onSelectRecent(name)}>
                                            <div className={styles.recentName}>{name}</div>
                                            <div className={styles.recentDate}>
                                                {t('Last opened: {{date}}', {
                                                    date: format(
                                                        new Date(recentServiceProviderGroups[user.id][name]),
                                                        'MMM D, YYYY',
                                                    ),
                                                })}
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                <div className={`${styles.recentServiceProviderGroup} ${styles.recentServiceProviderGroupNone}`}>
                                    {t('Nothing yet')}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    }

    private onFilter(text: string): void {
        const {user} = this.props;

        if (user) {
            const {serviceProviderGroups} = user;
            this.setState({
                search: text,
                selectedIndex: -1,
                serviceProviderGroups: serviceProviderGroups
                    .filter((fc) => fc.name.toLowerCase().includes(text.toLowerCase()))
                    .sort((a, b) => a.name.localeCompare(b.name)),
            });
        }
    }

    private onSelect(serviceProviderGroup: ServiceProviderGroup): void {
        const {user, dispatchNavigateTo} = this.props;
        const {recentServiceProviderGroups} = this.state;
        const updatedRecentServiceProviderGroups = {
            ...recentServiceProviderGroups,
            [user!.id]: {
                ...recentServiceProviderGroups[user!.id],
                [serviceProviderGroup.name]: new Date().toISOString(),
            },
        };
        const sortedUpdatedRecentServiceProviderGroups = this.sortRecentServiceProviderGroups(updatedRecentServiceProviderGroups);

        persistParsedToLocalStorage(LocalStorageKey.RecentServiceProviderGroups, sortedUpdatedRecentServiceProviderGroups);

        this.setState({
            recentServiceProviderGroups: sortedUpdatedRecentServiceProviderGroups,
        });
        dispatchNavigateTo({pathname: `/${serviceProviderGroup.id}${RouteUrl.Analytics}`});
    }

    private onSelectRecent(serviceProviderGroupName: string): void {
        const {serviceProviderGroups} = this.state;
        const serviceProviderGroup = serviceProviderGroups.find((f) => f.name === serviceProviderGroupName);

        this.onSelect(serviceProviderGroup!);
    }

    private handleKeyDown(e: any): void {
        const {selectedIndex, serviceProviderGroups} = this.state;

        if (e.key === 'Enter') {
            if (selectedIndex !== -1) {
                this.onSelect(serviceProviderGroups[selectedIndex]);
            }
        }
        if (serviceProviderGroups) {
            let newIndex = -1;
            if (e.key === 'ArrowDown') {
                newIndex = selectedIndex + 1;
                if (newIndex < serviceProviderGroups.length) {
                    this.setState({selectedIndex: newIndex});
                }
            }
            if (e.key === 'ArrowUp') {
                newIndex = selectedIndex - 1;
                if (newIndex >= -1) {
                    this.setState({selectedIndex: newIndex});
                }
            }
        }
    }

    private sortRecentServiceProviderGroups(recentServiceProviderGroups: RecentServiceProvidersMap): RecentServiceProvidersMap {
        return Object.keys(recentServiceProviderGroups).reduce((map, user) => {
            map[user] = Object.keys(recentServiceProviderGroups[user])
                .sort((a, b) =>
                    compareDesc(new Date(recentServiceProviderGroups[user][a]), new Date(recentServiceProviderGroups[user][b])),
                )
                .slice(0, this.maximumRecent)
                .reduce((map, serviceProviderGroup) => {
                    map[serviceProviderGroup] = recentServiceProviderGroups[user][serviceProviderGroup];
                    return map;
                }, {});
            return map;
        }, {});
    }
}

const mapStateToProps = ({authentication}: ApplicationState): PropsFromState => ({
    user: authentication.user,
});

const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => ({
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchLogoutRequest: () => dispatch(logoutRequest()),
    dispatchUnselectServiceProviderGroup: () => dispatch(unselectServiceProviderGroup()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ServiceProviderGroupSelectionPage));

interface PropsFromState {
    user?: User;
}

interface PropsFromDispatch {
    dispatchToggleSidebar: typeof toggleSidebar;
    dispatchNavigateTo: (location: LocationDescriptorObject) => void;
    dispatchLogoutRequest: typeof logoutRequest;
    dispatchUnselectServiceProviderGroup: typeof unselectServiceProviderGroup;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
    search: string;
    serviceProviderGroups: ServiceProviderGroup[];
    selectedIndex: number;
    recentServiceProviderGroups: RecentServiceProvidersMap;
}

type AllState = OwnState;
