import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import AnalyticsApi from '../../api/analytics-api';
import { ApiResult } from '../../types/api-result';
import { handleUnexpectedErrorWithToast } from '../http-error-handler';
import { ApplicationState } from '../index';
import {
    getCorporateFitmentDistributionRequest,
    getCorporateFitmentDistributionSuccess,
    getJobsDistributionDataRequest,
    getJobsDistributionDataSuccess,
    getKPIDataRequest,
    getKPIDataSuccess,
    getPurchasingDistributionDataRequest,
    getPurchasingDistributionDataSuccess,
    getPurchasingEvolutionDataRequest,
    getPurchasingEvolutionDataSuccess,
    getServiceProvidersMonthlyOverviewRequest,
    getServiceProvidersMonthlyOverviewSuccess,
    getTireBrandDistributionRequest, getTireBrandDistributionSuccess,
    getTiresDistributionRequest,
    getTiresDistributionSuccess, getTopSoldTosRequest, getTopSoldTosSuccess,
} from './actions';
import {AnalyticsActionTypes, KpiTypes} from './types';

const fetchServiceProviderGroupIdFromState = () => select((state: ApplicationState) => state.authentication.serviceProviderGroup!.id);

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function* processAnalyticsCall<TRequest>(requestParameters: TRequest, apiCall: any, dispatchData: any) {
    try {
        const serviceProviderGroupId = yield fetchServiceProviderGroupIdFromState();
        const result: ApiResult<any> = yield call(apiCall, requestParameters, serviceProviderGroupId);
        if (result.error) {
            yield call(handleUnexpectedErrorWithToast, result.error);
        } else {
            yield put(dispatchData(result.data!));
        }
    } catch (err) {
        yield call(handleUnexpectedErrorWithToast, err);
    }
}

export function* handleGetKPIWidgetData(action: ActionType<typeof getKPIDataRequest>) {
    const request = action.payload;
    switch (request.type) {
        case KpiTypes.TotalInvoiced:
            yield call(processAnalyticsCall, request, AnalyticsApi.getTotalInvoicedKPI, getKPIDataSuccess);
            break;
        case KpiTypes.TotalJobCount:
            yield call(processAnalyticsCall, request, AnalyticsApi.getTotalJobCountKPI, getKPIDataSuccess);
            break;
        case KpiTypes.TotalInspectionCount:
            yield call(processAnalyticsCall, request, AnalyticsApi.getTotalInspectionCountKPI, getKPIDataSuccess);
            break;
        case KpiTypes.InspectionRatio:
            yield call(processAnalyticsCall, request, AnalyticsApi.getInspectionRatioKPI, getKPIDataSuccess);
            break;
        case KpiTypes.EjobUtilization:
            yield call(processAnalyticsCall, request, AnalyticsApi.getEjobUtilizationKPI, getKPIDataSuccess);
            break;
        case KpiTypes.RetreadRatio:
            yield call(processAnalyticsCall, request, AnalyticsApi.getRetreadRatioKPI, getKPIDataSuccess);
            break;
        case KpiTypes.ECasingReturnsRatio:
            yield call(processAnalyticsCall, request, AnalyticsApi.getECasingReturnsRatioKPI, getKPIDataSuccess);
            break;
        case KpiTypes.TotalTiresCount:
            yield call(processAnalyticsCall, request, AnalyticsApi.getTotalTireCountKPI, getKPIDataSuccess);
            break;
        case KpiTypes.JobsAvgProcessTime:
            yield call(processAnalyticsCall, request, AnalyticsApi.getJobsAverageProcessTime, getKPIDataSuccess);
            break;
        case KpiTypes.JobsAvgAcceptanceTime:
            yield call(processAnalyticsCall, request, AnalyticsApi.getJobsAverageAcceptanceTime, getKPIDataSuccess);
            break;
        case KpiTypes.CorporateBrandRatio:
            yield call(processAnalyticsCall, request, AnalyticsApi.getCorporateBrandRatioKPI, getKPIDataSuccess);
            break;
        case KpiTypes.AverageTreadDepth:
            yield call(processAnalyticsCall, request, AnalyticsApi.getAverageTreadDepthKPI, getKPIDataSuccess);
            break;
        case KpiTypes.RegrooveRatio:
            yield call(processAnalyticsCall, request, AnalyticsApi.getRegrooveRatioKPI, getKPIDataSuccess);
            break;
        case KpiTypes.RegrooveCount:
            yield call(processAnalyticsCall, request, AnalyticsApi.getRegrooveCountKPI, getKPIDataSuccess);
            break;
        case KpiTypes.PWTStockAge:
            yield call(processAnalyticsCall, request, AnalyticsApi.getPWTStockAgeKPI, getKPIDataSuccess);
            break;
        case KpiTypes.PWTCycleTime:
            yield call(processAnalyticsCall, request, AnalyticsApi.getPWTCycleTimeKPI, getKPIDataSuccess);
            break;
        case KpiTypes.PWTCurrentStock:
            yield call(processAnalyticsCall, request, AnalyticsApi.getPWTCurrentStockKPI, getKPIDataSuccess);
            break;
        default:
            yield call(handleUnexpectedErrorWithToast, `No Mapping found in sagas handler for ${request.type}`);
    }
}

export function* handleGetJobsDistributionData(action: ActionType<typeof getJobsDistributionDataRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getJobsDistribution,
        getJobsDistributionDataSuccess,
    );
}

export function* handleGetTopSoldTos(action: ActionType<typeof getTopSoldTosRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getTopSoldTosRequest,
        getTopSoldTosSuccess,
    );
}

export function* handleGetPurchasingDistributionData(action: ActionType<typeof getPurchasingDistributionDataRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getPurchasingDistribution,
        getPurchasingDistributionDataSuccess,
    );
}

export function* handleGetPurchasingEvolutionData(action: ActionType<typeof getPurchasingEvolutionDataRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getPurchasingEvolution,
        getPurchasingEvolutionDataSuccess,
    );
}

export function* handleServiceProvidersMonthlyOverview(
    action: ActionType<typeof getServiceProvidersMonthlyOverviewRequest>,
) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getServiceProvidersMonthlyOverview,
        getServiceProvidersMonthlyOverviewSuccess,
    );
}

export function* handleTiresDistributionData(
    action: ActionType<typeof getTiresDistributionRequest>,
) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getTiresDistribution,
        getTiresDistributionSuccess,
    );
}

export function* handleTireBrandDistribution(action: ActionType<typeof getTireBrandDistributionRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getTireBrandDistribution,
        getTireBrandDistributionSuccess,
    );
}

export function* handleCorporateFitmentDistribution(action: ActionType<typeof getCorporateFitmentDistributionRequest>) {
    yield call(
        processAnalyticsCall,
        action.payload,
        AnalyticsApi.getCorporateFitmentDistribution,
        getCorporateFitmentDistributionSuccess,
    );
}

function* watchFetchRequest() {
    yield takeEvery(AnalyticsActionTypes.KEY_PERFORMANCE_INDICATOR_REQUEST, handleGetKPIWidgetData);
    yield takeEvery(AnalyticsActionTypes.JOBS_DISTRIBUTION_REQUEST, handleGetJobsDistributionData);
    yield takeEvery(AnalyticsActionTypes.TOP_SOLD_TOS_REQUEST, handleGetTopSoldTos);
    yield takeEvery(AnalyticsActionTypes.PURCHASING_DISTRIBUTION_REQUEST, handleGetPurchasingDistributionData);
    yield takeEvery(AnalyticsActionTypes.PURCHASING_EVOLUTION_REQUEST, handleGetPurchasingEvolutionData);
    yield takeEvery(AnalyticsActionTypes.SERVICE_PROVIDERS_MONTHLY_OVERVIEW_REQUEST, handleServiceProvidersMonthlyOverview);
    yield takeEvery(AnalyticsActionTypes.TIRES_DISTRIBUTION_REQUEST, handleTiresDistributionData);
    yield takeEvery(AnalyticsActionTypes.TIRE_BRAND_DISTRIBUTION_REQUEST, handleTireBrandDistribution);
    yield takeEvery(AnalyticsActionTypes.CORPORATE_FITMENT_DISTRIBUTION_REQUEST, handleCorporateFitmentDistribution);
}

function* analyticsSaga() {
    yield all([fork(watchFetchRequest)]);
}

export default analyticsSaga;
