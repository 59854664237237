import {Http} from '../http/http';
import {
    DateRangeWithServiceProvidersAndSoldTosAndCurrencyRequest, DateRangeWithServiceProvidersRequest,
} from '../store/analytics';
import {
    KeyPerformanceIndicatorData,
    KeyPerformanceIndicatorRequest,
} from '../store/analytics/types/key-performance-indicator.types';
import {ApiResult} from '../types/api-result';
import {ApiUrl, UrlParamBuilder} from './api-urls';
import {TotalInvoicedData} from '../store/analytics/types/total-invoiced.types';
import {JobsDistributionData, JobsDistributionRequest} from '../store/analytics/types/jobs-distribution.types';
import {
    PurchasingDistributionRequest,
    PurchasingDistributionData,
} from '../store/analytics/types/purchasing-distribution.types';
import {JobCountTotal} from '../store/analytics/types/jobs-count-total.types';
import {
    ServiceProvidersMonthlyOverviewData,
    ServiceProvidersMonthlyOverviewRequest,
} from '../store/analytics/types/service-providers-monthly-overview.types';
import {EjobUtilization} from '../store/analytics/types/jobs-ejob-utilization.types';
import {RetreadRatio} from '../store/analytics/types/tires-retread-ratio.types';
import {TiresDistributionData, TiresDistributionRequest} from '../store/analytics/types/tires-distribution.types';
import {TiresCountTotal} from '../store/analytics/types/tires-count-total.types';
import {
    PurchasingEvolutionData,
    PurchasingEvolutionRequest,
} from '../store/analytics/types/purchasing-evolution.types';
import {
    TireBrandDistributionData,
    TireBrandDistributionRequest,
} from '../store/analytics/types/tire-brand-distribution.types';
import {ProcessTimeData} from '../store/analytics/types/jobs-average-process-time.types';
import {CorporateBrandRatio} from '../store/analytics/types/tires-corporate-ratio.types';
import {RegrooveRatio} from '../store/analytics/types/tires-regroove-ratio.types';
import {RegrooveCount} from '../store/analytics/types/tires-regroove-count.types';
import {InspectionCountTotal} from '../store/analytics/types/inspections-count-total.types';
import {InspectionRatio} from '../store/analytics/types/inspections-ratio-total.types';
import {TopSoldTosData, TopSoldTosRequest} from '../store/analytics/types/top-sold-tos-types';
import {AcceptanceTimeData} from '../store/analytics/types/jobs-average-acceptance-time.types';
import {ECasingReturnsRatio} from '../store/analytics/types/tires-e-casing-returns-ratio.types';
import {AverageTreadDepth} from "../store/analytics/types/tires-average-tread-depth.types";
import {PWTStockAge} from "../store/analytics/types/tires-pwt-stock-age.types";
import {
    CorporateFitmentDistributionData,
    CorporateFitmentDistributionRequest
} from "../store/analytics/types/corporata-fitment-distribution.types";
import { PWTCycleTime } from '../store/analytics/types/tires-pwt-cycle-time.types';
import { PWTCurrentStock } from '../store/analytics/types/tires-pwt-current-stock.types';

class AnalyticsApi {
    public static async getTotalInvoicedKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const requestWithCurrency: DateRangeWithServiceProvidersAndSoldTosAndCurrencyRequest = {
            ...request,
            currencyType: request.currency.type,
        };
        const data = await Http.post<DateRangeWithServiceProvidersAndSoldTosAndCurrencyRequest,
            TotalInvoicedData>(
            ApiUrl.TotalInvoiced,
            requestWithCurrency,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data: {type: request.type, value: data.total ? Number(data.total) : 0, unit: request.currency.name}};
    }

    public static async getPurchasingDistribution(
        request: PurchasingDistributionRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<PurchasingDistributionData>> {
        const data = await Http.post<PurchasingDistributionRequest, PurchasingDistributionData>(
            ApiUrl.PurchasingDistribution,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data};
    }

    public static async getPurchasingEvolution(
        request: PurchasingEvolutionRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<PurchasingEvolutionData>> {
        const data = await Http.post<PurchasingDistributionRequest, PurchasingEvolutionData>(
            ApiUrl.PurchasingEvolution,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data};
    }

    public static async getTotalJobCountKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, JobCountTotal>(
            ApiUrl.JobsTotalCount,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data: {type: request.type, value: data.total, unit: '#'}};
    }

    public static async getTotalInspectionCountKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, InspectionCountTotal>(
            ApiUrl.InspectionsTotalCount,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data: {type: request.type, value: data.total, unit: '#'}};
    }

    public static async getInspectionRatioKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, InspectionRatio>(
            ApiUrl.InspectionRatio,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        const percentage = data.inspectionRatio * 100;
        return {data: {type: request.type, value: percentage, unit: '%'}};
    }

    public static async getEjobUtilizationKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, EjobUtilization>(
            ApiUrl.JobsEjobUtilization,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        const percentage = data.ejobUtilization * 100;
        return {data: {type: request.type, value: percentage, unit: '%'}};
    }

    public static async getJobsAverageProcessTime(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, ProcessTimeData>(
            ApiUrl.JobsProcessTime,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data: {type: request.type, stringValue: data.avgProcessTime, unit: ''}};
    }

    public static async getJobsAverageAcceptanceTime(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, AcceptanceTimeData>(
            ApiUrl.JobsAcceptanceTime,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data: {type: request.type, stringValue: data.avgAcceptanceTime, unit: ''}};
    }

    public static async getJobsDistribution(
        request: JobsDistributionRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<JobsDistributionData[]>> {
        const data = await Http.post<JobsDistributionRequest, JobsDistributionData[]>(
            ApiUrl.JobsDistribution,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data};
    }

    public static async getTopSoldTosRequest(
        request: TopSoldTosRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<TopSoldTosData[]>> {
        const data = await Http.post<TopSoldTosRequest, TopSoldTosData[]>(
            ApiUrl.TopSoldTos,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return { data };
    }

    public static async getServiceProvidersMonthlyOverview(
        request: ServiceProvidersMonthlyOverviewRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<ServiceProvidersMonthlyOverviewData[]>> {
        const data = await Http.post<ServiceProvidersMonthlyOverviewRequest, ServiceProvidersMonthlyOverviewData[]>(
            ApiUrl.ServiceProvidersMonthlyOverview,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data};
    }

    public static async getRetreadRatioKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, RetreadRatio>(
            ApiUrl.TiresRetreadRatio,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        const percentage = data.retreadRatio * 100;
        return {data: {type: request.type, value: percentage, unit: '%'}};
    }

    public static async getECasingReturnsRatioKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, ECasingReturnsRatio>(
            ApiUrl.TiresECasingReturnsRatio,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        const percentage = data.returnsRatio * 100;
        return {data: {type: request.type, value: percentage, unit: '%'}};
    }

    public static async getCorporateBrandRatioKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, CorporateBrandRatio>(
            ApiUrl.TiresCorporateRatio,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        const percentage = data.corporateRatio * 100;
        return {data: {type: request.type, value: percentage, unit: '%'}};
    }

    public static async getAverageTreadDepthKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, AverageTreadDepth>(
            ApiUrl.TiresAverageTreadDepth,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        const {average} = data;
        return {data: {type: request.type, value: average, unit: 'mm'}};
    }

    public static async getRegrooveRatioKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, RegrooveRatio>(
            ApiUrl.TiresRegrooveRatio,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        const percentage = data.regrooveRatio * 100;
        return {data: {type: request.type, value: percentage, unit: '%'}};
    }

    public static async getRegrooveCountKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, RegrooveCount>(
            ApiUrl.TiresRegrooveCount,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data: {type: request.type, value: data.regrooveCount, unit: '#'}};
    }

    public static async getPWTStockAgeKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, PWTStockAge>(
            ApiUrl.TiresPWTStockAge,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        const percentage = data.pwtStockAge * 100;
        return {data: {type: request.type, value: percentage, unit: '%'}};
        
    }

    public static async getPWTCycleTimeKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, PWTCycleTime>(
            ApiUrl.TiresPWTCycleTime,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data: {type: request.type, stringValue: data.cycleTime, unit: ''}};
    }

    public static async getPWTCurrentStockKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, PWTCurrentStock>(
            ApiUrl.TiresPWTCurrentStock,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data: {type: request.type, value: data.currentStock, unit: '#'}};
    }

    public static async getTotalTireCountKPI(
        request: KeyPerformanceIndicatorRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<KeyPerformanceIndicatorData>> {
        const data = await Http.post<DateRangeWithServiceProvidersRequest, TiresCountTotal>(
            ApiUrl.TiresFitmentsCount,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data: {type: request.type, value: data.total, unit: '#'}};
    }

    public static async getTiresDistribution(
        request: TiresDistributionRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<TiresDistributionData[]>> {
        const data = await Http.post<TiresDistributionRequest, TiresDistributionData[]>(
            ApiUrl.TiresFitmentsDistribution,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data};
    }

    public static async getTireBrandDistribution(
        request: TireBrandDistributionRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<TireBrandDistributionData[]>> {
        const data = await Http.post<TireBrandDistributionRequest, TireBrandDistributionData[]>(
            ApiUrl.TireBrandDistribution,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data};
    }

    public static async getCorporateFitmentDistribution(
        request: CorporateFitmentDistributionRequest,
        serviceProviderGroupId: string,
    ): Promise<ApiResult<CorporateFitmentDistributionData[]>> {
        const data = await Http.post<CorporateFitmentDistributionRequest, CorporateFitmentDistributionData[]>(
            ApiUrl.CorporateFitmentDistribution,
            request,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId).build(),
        );
        return {data};
    }
}

export default AnalyticsApi;
