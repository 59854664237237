import {TFunction} from 'i18next';
import {DateRange, KpiTypes} from '../../../../store/analytics';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {ParamKey} from '../../../../store/dashboard';
import {
    toBetweenMonthRangeDisplay,
} from '../../dashboard-controls/date-range-selector/helpers/date-range-helper';

export interface KpiMetaData {
    numberFormatter: NumberFormatter;
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => string;
    subtitleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => string;
}

export class NumberFormatter {
    constructor(
        public thousandsSeparator: string,
        public decimalSeparator: string,
        public fixedDecimalScale: boolean,
        public decimalScale: number) {
    }
}

function betweenMonthRangeDisplay(controlsSnapshot: ControlsSnapshot, t: TFunction): string {
    if (controlsSnapshot.has(ParamKey.InspectionPeriod)) {
        const dateRange = controlsSnapshot.getValueForParam<DateRange>(ParamKey.InspectionPeriod);
        return toBetweenMonthRangeDisplay(dateRange, t);
    }
    return '';
}

export const kpiMetaStore = new Map<KpiTypes, KpiMetaData>();
kpiMetaStore.set(KpiTypes.TotalInvoiced, {
    numberFormatter: new NumberFormatter('.', ',', true, 2),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Total Invoiced'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.TotalJobCount, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Jobs Executed'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.TotalInspectionCount, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Inspections Completed'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.InspectionRatio, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Inspection Ratio'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.EjobUtilization, {
    numberFormatter: new NumberFormatter('.', ',', false, 2),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('eJob Utilization'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.RetreadRatio, {
    numberFormatter: new NumberFormatter('.', ',', false, 2),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Retread Ratio'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.ECasingReturnsRatio, {
    numberFormatter: new NumberFormatter('.', ',', false, 2),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('eCasing Returns Ratio'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.CorporateBrandRatio, {
    numberFormatter: new NumberFormatter('.', ',', false, 2),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Corporate Brand Ratio'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.AverageTreadDepth, {
    numberFormatter: new NumberFormatter('.', ',', false, 2),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Average Tread Depth'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.RegrooveRatio, {
    numberFormatter: new NumberFormatter('.', ',', false, 2),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Regroove Ratio'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.RegrooveCount, {
    numberFormatter: new NumberFormatter('.', ',', false, 2),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Regroove Count'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.PWTStockAge, {
    numberFormatter: new NumberFormatter('.', ',', false, 2),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Part Worn Tyre Usage'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.PWTCycleTime, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Part Worn Tyre Cycle Time'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.PWTCurrentStock, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Part Worn Tyre Current Stock'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.TotalTiresCount, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Tires Fitted'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.JobsAvgProcessTime, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Average Process Time'),
    subtitleFunction: betweenMonthRangeDisplay});
kpiMetaStore.set(KpiTypes.JobsAvgAcceptanceTime, {
    numberFormatter: new NumberFormatter('.', ',', false, 0),
    titleFunction: (controlsSnapshot: ControlsSnapshot, t: TFunction) => t('Average Acceptance Time'),
    subtitleFunction: betweenMonthRangeDisplay});
