import { Reducer } from 'redux';
import { RequestStatus, RequestStatusType, SharedActionTypes } from '../shared/types';
import {
    ServiceProviderGroup,
    ServiceProviderGroupAction,
    ServiceProviderGroupActionTypes,
    ServiceProviderGroupsState,
} from './types';

function initialState(): ServiceProviderGroupsState {
    return {
        serviceProviderGroups: [],
    };
}

const sortByName = (a: ServiceProviderGroup, b: ServiceProviderGroup) => a.name.localeCompare(b.name)

const reducer: Reducer<ServiceProviderGroupsState, ServiceProviderGroupAction> = (
    state = initialState(),
    action,
): ServiceProviderGroupsState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS:
        case SharedActionTypes.UNSELECT_SERVICE_PROVIDER_GROUP: {
            return initialState();
        }
        case ServiceProviderGroupActionTypes.CREATE_SERVICE_PROVIDER_GROUP_REQUEST: {
            return { ...state };
        }
        case ServiceProviderGroupActionTypes.CREATE_SERVICE_PROVIDER_GROUP_SUCCESS: {
            const serviceProviderGroups = state.serviceProviderGroups.concat(action.payload);
            return { ...state, serviceProviderGroups: serviceProviderGroups.sort(sortByName) };
        }
        case ServiceProviderGroupActionTypes.DELETE_SERVICE_PROVIDER_GROUP_REQUEST: {
            return { ...state, deleteServiceProviderGroupRequestStatus: new RequestStatus(RequestStatusType.InProgress) };
        }
        case ServiceProviderGroupActionTypes.DELETE_SERVICE_PROVIDER_GROUP_SUCCESS: {
            const serviceProviderGroups = state.serviceProviderGroups.filter((fc) => fc.id !== action.payload.id);
            return {
                ...state,
                serviceProviderGroups,
                deleteServiceProviderGroupRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case ServiceProviderGroupActionTypes.DELETE_SERVICE_PROVIDER_GROUP_FAILURE: {
            return {
                ...state,
                deleteServiceProviderGroupRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUPS_REQUEST: {
            return {
                ...state,
                serviceProviderGroups: [],
                getServiceProviderGroupsRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUPS_SUCCESS: {
            return {
                ...state,
                serviceProviderGroups: action.payload.sort(sortByName),
                getServiceProviderGroupsRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUPS_FAILURE: {
            return {
                ...state,
                getServiceProviderGroupsRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case ServiceProviderGroupActionTypes.UPDATE_SERVICE_PROVIDER_GROUP_REQUEST: {
            return { ...state };
        }
        case ServiceProviderGroupActionTypes.UPDATE_SERVICE_PROVIDER_GROUP_SUCCESS: {
            const result = action.payload;
            const serviceProviderGroups = JSON.parse(JSON.stringify(state.serviceProviderGroups));
            serviceProviderGroups.forEach((fc) => {
                if (fc.id === result.serviceProviderGroupId) {
                    fc.name = result.serviceProviderGroupName;
                    fc.allowedDomains = result.serviceProviderGroupAllowedDomains;
                }
            });

            return { ...state, serviceProviderGroups: serviceProviderGroups.sort(sortByName) };
        }
        case ServiceProviderGroupActionTypes.CLEAR_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS: {
            return {
                ...state,
                serviceProviderGroupsWithConfigurationAndUsers:undefined,
                getServiceProviderGroupWithConfigurationAndUsersRequestStatus: new RequestStatus(RequestStatusType.New),
            };
        }
        case ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS_REQUEST: {
            return {
                ...state,
                getServiceProviderGroupWithConfigurationAndUsersRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            };
        }
        case ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS_SUCCESS: {
            return {
                ...state,
                serviceProviderGroupsWithConfigurationAndUsers: action.payload,
                getServiceProviderGroupWithConfigurationAndUsersRequestStatus: new RequestStatus(RequestStatusType.Success),
            };
        }
        case ServiceProviderGroupActionTypes.GET_SERVICE_PROVIDER_GROUP_WITH_CONFIGURATION_AND_USERS_FAILURE: {
            return {
                ...state,
                getServiceProviderGroupWithConfigurationAndUsersRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            };
        }
        case ServiceProviderGroupActionTypes.SEARCH_SERVICE_PROVIDER_GROUPS_REQUEST: {
            return {
                ...state, searchServiceProviderGroupsRequestStatus: new RequestStatus(RequestStatusType.InProgress),
            }
        }
        case ServiceProviderGroupActionTypes.SEARCH_SERVICE_PROVIDER_GROUPS_SUCCESS: {
            return {
                ...state,
                pagedServiceProviderGroups: action.payload,
                searchServiceProviderGroupsRequestStatus: new RequestStatus(RequestStatusType.Success),
            }
        }
        case ServiceProviderGroupActionTypes.SEARCH_SERVICE_PROVIDER_GROUPS_FAILURE: {
            return {
                ...state,
                searchServiceProviderGroupsRequestStatus: new RequestStatus(RequestStatusType.Failed, action.payload),
            }
        }
        default: {
            return state;
        }
    }
};

export { reducer as serviceProviderGroupsReducer };
export { initialState as initialServiceProviderGroupsState };
