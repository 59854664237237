import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { ApplicationState, rootReducer, rootSaga } from '.';
import { initialAnalyticsState } from './analytics';
import { initialAuthenticationState } from './authentication';
import { initialDashboardState } from './dashboard';
import { initialServiceProviderGroupsState } from './service-provider-groups';
import { initialLayoutState } from './layout';
import { initialPopupState } from './popup';
import { initialSearchState } from './search';
import { initialServiceProviderState } from './service-providers';
import { initialToastState } from './toast';
import { initialUsersState } from './users';
import { initialFilterControlSelectionState } from "./filter-control-selection";
import { initialMaintenanceState} from './maintenance';
import { initialJobsState } from './jobs';
import { initialVehiclesState } from './vehicles';
import { initialSoldTosState } from './sold-tos';
import { initialDataStatusState } from './data-status';
import {initialReportConfigurationState} from './report-management';
import {initialReportsState} from './reports';
import {initialScheduledReportsState} from './scheduled-report-management';

const initState: ApplicationState = {
    router: {
        location: {
            key: undefined,
            hash: '',
            pathname: '',
            search: '',
            state: '',
        },
        action: 'PUSH',
    },
    layout: initialLayoutState(),
    authentication: initialAuthenticationState(),
    dashboard: initialDashboardState(),
    toast: initialToastState(),
    search: initialSearchState(),
    popup: initialPopupState(),
    serviceProvider: initialServiceProviderState(),
    users: initialUsersState(),
    maintenance: initialMaintenanceState(),
    serviceProviderGroups: initialServiceProviderGroupsState(),
    analytics: initialAnalyticsState(),
    filterControlSelection: initialFilterControlSelectionState(),
    jobs: initialJobsState(),
    vehicles: initialVehiclesState(),
    soldTos: initialSoldTosState(),
    reports: initialReportsState(),
    dataStatus: initialDataStatusState(),
    reportConfigurations: initialReportConfigurationState(),
    scheduledReports:initialScheduledReportsState(),
};

export default function configureStore(
    history: History,
    initialState: ApplicationState = initState,
): Store<ApplicationState> {
    const composeEnhancers = composeWithDevTools({});
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        rootReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)),
    );

    sagaMiddleware.run(rootSaga);
    return store;
}
