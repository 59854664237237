import {ActionType} from 'typesafe-actions';
import * as actions from './actions';
import * as sharedActions from '../shared/actions';
import {ServiceProviderSelection, SoldToSelection} from '../analytics';
import {FileType, RequestStatus} from '../shared/types';
import {ReportType} from '../../pages/reports/report-catalog';
import {ServiceProviderSelectionsControlValue} from '../../pages/analytics/dashboard-controls/service-providers-selector/types/service-providers-selections-control-value';
import {SoldToSelectionsControlValue} from '../../pages/analytics/dashboard-controls/sold-tos-selector/types/sold-tos-selections-control-value';

export enum ReportsActionTypes {
    FETCH_INSPECTION_DETAILS_REPORT_REQUEST = '@@reports/FETCH_INSPECTION_DETAILS_REPORT_REQUEST',
    FETCH_INSPECTION_DETAILS_REPORT_SUCCESS = '@@reports/FETCH_INSPECTION_DETAILS_REPORT_SUCCESS',
    FETCH_INSPECTION_DETAILS_REPORT_FAILURE = '@@reports/FETCH_INSPECTION_DETAILS_REPORT_FAILURE',
    FETCH_CUSTOMER_SERVICE_LOCATIONS_REPORT_REQUEST = '@@reports/FETCH_CUSTOMER_SERVICE_LOCATIONS_REPORT_REQUEST',
    FETCH_CUSTOMER_SERVICE_LOCATIONS_REPORT_SUCCESS = '@@reports/FETCH_CUSTOMER_SERVICE_LOCATIONS_REPORT_SUCCESS',
    FETCH_CUSTOMER_SERVICE_LOCATIONS_REPORT_FAILURE = '@@reports/FETCH_CUSTOMER_SERVICE_LOCATIONS_REPORT_FAILURE',
    FETCH_TCU_SENSOR_FITMENTS_REPORT_REQUEST = '@@reports/FETCH_TCU_SENSOR_FITMENTS_REPORT_REQUEST',
    FETCH_TCU_SENSOR_FITMENTS_REPORT_SUCCESS = '@@reports/FETCH_TCU_SENSOR_FITMENTS_REPORT_SUCCESS',
    FETCH_TCU_SENSOR_FITMENTS_REPORT_FAILURE = '@@reports/FETCH_TCU_SENSOR_FITMENTS_REPORT_FAILURE',
    FETCH_EXTENDED_INSPECTION_REPORT_REQUEST = '@@reports/FETCH_EXTENDED_INSPECTION_REPORT_REQUEST',
    FETCH_EXTENDED_INSPECTION_REPORT_SUCCESS = '@@reports/FETCH_EXTENDED_INSPECTION_REPORT_SUCCESS',
    FETCH_EXTENDED_INSPECTION_REPORT_FAILURE = '@@reports/FETCH_EXTENDED_INSPECTION_REPORT_FAILURE',
    FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_REQUEST = '@@reports/FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_REQUEST',
    FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_SUCCESS = '@@reports/FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_SUCCESS',
    FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_FAILURE = '@@reports/FETCH_VEHICLES_DUE_FOR_INSPECTION_REPORT_FAILURE',
    FETCH_CASINGS_REPORT_REQUEST = '@@reports/FETCH_CASINGS_REPORT_REQUEST',
    FETCH_CASINGS_REPORT_SUCCESS = '@@reports/FETCH_CASINGS_REPORT_SUCCESS',
    FETCH_CASINGS_REPORT_FAILURE = '@@reports/FETCH_CASINGS_REPORT_FAILURE',
    FETCH_TIRE_STOCK_COMPANIES_REQUEST = '@@reports/FETCH_TIRE_STOCK_COMPANIES_REQUEST',
    FETCH_TIRE_STOCK_COMPANIES_SUCCESS = '@@reports/FETCH_TIRE_STOCK_COMPANIES_SUCCESS',
    FETCH_TIRE_STOCK_COMPANIES_FAILURE = '@@reports/FETCH_TIRE_STOCK_COMPANIES_FAILURE',
    FETCH_TIRE_STOCK_REPORT_REQUEST = '@@reports/FETCH_TIRE_STOCK_REPORT_REQUEST',
    FETCH_TIRE_STOCK_REPORT_SUCCESS = '@@reports/FETCH_TIRE_STOCK_REPORT_SUCCESS',
    FETCH_TIRE_STOCK_REPORT_FAILURE = '@@reports/FETCH_TIRE_STOCK_REPORT_FAILURE',

    FETCH_SCHEDULED_REPORTS_REQUEST = '@@reports/FETCH_SCHEDULED_REPORTS_REQUEST',
    FETCH_SCHEDULED_REPORTS_SUCCESS = '@@reports/FETCH_SCHEDULED_REPORTS_SUCCESS',
    FETCH_SCHEDULED_REPORTS_FAILURE = '@@reports/FETCH_SCHEDULED_REPORTS_FAILURE',
    CREATE_SCHEDULED_REPORT_REQUEST = '@@reports/CREATE_SCHEDULED_REPORT_REQUEST',
    CREATE_SCHEDULED_REPORT_SUCCESS = '@@reports/CREATE_SCHEDULED_REPORT_SUCCESS',
    CREATE_SCHEDULED_REPORT_FAILURE = '@@reports/CREATE_SCHEDULED_REPORT_FAILURE',
    UPDATE_SCHEDULED_REPORT_REQUEST = '@@reports/UPDATE_SCHEDULED_REPORT_REQUEST',
    UPDATE_SCHEDULED_REPORT_SUCCESS = '@@reports/UPDATE_SCHEDULED_REPORT_SUCCESS',
    UPDATE_SCHEDULED_REPORT_FAILURE = '@@reports/UPDATE_SCHEDULED_REPORT_FAILURE',
    DELETE_SCHEDULED_REPORT_REQUEST = '@@reports/DELETE_SCHEDULED_REPORT_REQUEST',
    DELETE_SCHEDULED_REPORT_SUCCESS = '@@reports/DELETE_SCHEDULED_REPORT_SUCCESS',
    DELETE_SCHEDULED_REPORT_FAILURE = '@@reports/DELETE_SCHEDULED_REPORT_FAILURE',

    FETCH_REPORT_INFO_REQUEST = '@@reports/FETCH_REPORT_INFO_REQUEST',
    FETCH_REPORT_INFO_SUCCESS = '@@reports/FETCH_REPORT_INFO_SUCCESS',
    FETCH_REPORT_INFO_FAILURE = '@@reports/FETCH_REPORT_INFO_FAILURE',

    FETCH_REPORT_DOWNLOAD_REQUEST = '@@reports/FETCH_REPORT_DOWNLOAD_REQUEST',
    FETCH_REPORT_DOWNLOAD_SUCCESS = '@@reports/FETCH_REPORT_DOWNLOAD_SUCCESS',
    FETCH_REPORT_DOWNLOAD_FAILURE = '@@reports/FETCH_REPORT_DOWNLOAD_FAILURE',
}

export type ReportsAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface ReportsState {
    inspectionDetailsReportRequestStatus?: RequestStatus;
    customerServiceLocationsReportRequestStatus?: RequestStatus;
    tcuSensorFitmentsRequestStatus?: RequestStatus;
    extendedInspectionReportRequestStatus?: RequestStatus;
    vehiclesDueForInspectionReportRequestStatus?: RequestStatus;
    casingsReportRequestStatus?: RequestStatus;
    tireStockReportRequestStatus?: RequestStatus;
    tireStockCompaniesRequestStatus?: RequestStatus;

    scheduledReports: ScheduledReport[];
    getScheduledReportsRequestStatus?: RequestStatus;
    createScheduledReportRequestStatus?: RequestStatus;
    updateScheduledReportRequestStatus?: RequestStatus;
    deleteScheduledReportRequestStatus?: RequestStatus;

    tireStockCompanies?: TireStockCompany[];

    reportInfo?: ReportInfo;
    reportInfoRequestStatus?: RequestStatus;
    reportDownloadRequestStatus?: RequestStatus;
}

export enum SortColumn {
    byLicensePlate = 'licensePlate',
    byChassisNumber = 'chassisNumber',
}

export enum ActiveStatus {
    All = 'all',
    Activated = 'activated',
    Deactivated = 'deactivated'
}

export enum InspectionStatus {
    All = 'all',
    NotSeenLastThreeMonths = 'notSeenLastThreeMonths',
    NotSeenLastMonth = 'notSeenLastMonth'
}

export interface ReportRequest{
    fileType:FileType
}

export interface TireStockReportRequest extends ReportRequest {
    companies: TireStockCompany[];
}

export interface TireStockCompaniesRequest {
}

export interface InspectionDetailsReportRequest extends ReportRequest {
    serviceProviders: ServiceProviderSelection;
    soldTos: SoldToSelection;
    fromDate: string;
    toDate: string;
}

export interface CustomerServiceLocationsReportRequest extends ReportRequest {
    serviceProviders: ServiceProviderSelection;
    soldTos: SoldToSelection;
}

export interface TCUSensorFitmentsReportRequest extends ReportRequest {
    serviceProviders: ServiceProviderSelection;
    soldTos: SoldToSelection;
    fromDate: string;
    toDate: string;
}

export interface ExtendedInspectionReportRequest extends ReportRequest {
    serviceProviders: ServiceProviderSelection;
    soldTos: SoldToSelection;
    inspectionStatus: string;
}

export interface VehiclesDueForInspectionReportRequest extends ReportRequest {
    soldTos: SoldToSelection;
}

export interface CasingsReportRequest extends ReportRequest {
    serviceProviders: ServiceProviderSelection;
    fromDate: string;
    toDate: string;
}

export interface TireStockCompany {
    companyNr: string;
    companyName: string;
}

export enum ReportStatus {
    NEW = 'NEW',
    RUNNING = 'RUNNING',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
}

export interface ReportInfo {
    id: string;
    fileName: string;
    type: ReportType;
    status: ReportStatus;
    requestedBy: string;
    requestedOn: string;
    startedOn?: string;
    generatedOn?: string;
    expiresOn?: string;
    deletedOn?: string;
}

export interface ReportDownloadUrl {
    url: string;
}

export enum ReportPeriodType {
    Custom,
    Scheduled
}

export enum ScheduledReportGranularity {
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export enum ScheduledReportDeliveryMethod {
    EMAIL_ATTACHMENT = 'EMAIL_ATTACHMENT',
    DOWNLOAD_LINK = 'DOWNLOAD_LINK',
}

export interface ScheduledReport {
    id?: string;
    name: string;
    reportType: ReportType;
    parametersVersion: number;
    parameters: any;
    deliveryMethod: ScheduledReportDeliveryMethod;
    fileType:FileType;
    granularity: ScheduledReportGranularity;
    scheduleTime: string;
    recipients: string[];
}

export const SCHEDULED_REPORT_PARAMETER_VERSION = 1;

export enum ScheduledReportPeriod {
    PAST_WEEK = 'PAST_WEEK',
    PAST_MONTH = 'PAST_MONTH',
    PAST_YEAR = 'PAST_YEAR',
    MONTH_TO_DATE = 'MONTH_TO_DATE',
    YEAR_TO_DATE = 'YEAR_TO_DATE',
}

export interface ScheduledReportParameters {
}

export interface InspectionDetailsScheduledReportParameters extends ScheduledReportParameters {
    serviceProviders?: ServiceProviderSelection;
    soldTos?: SoldToSelection;
    period: ScheduledReportPeriod;
}

export interface TCUSensorFitmentsScheduledReportParameters extends ScheduledReportParameters {
    serviceProviders?: ServiceProviderSelection;
    soldTos?: SoldToSelection;
    period: ScheduledReportPeriod;
}

export interface CustomerServiceLocationsScheduledReportParameters extends ScheduledReportParameters {
    serviceProviders?: ServiceProviderSelection;
    soldTos?: SoldToSelection;
}

export interface ExtendedInspectionScheduledReportParameters extends ScheduledReportParameters {
    serviceProviders?: ServiceProviderSelection;
    soldTos?: SoldToSelection;
    inspectionStatus: InspectionStatus;
}

export interface VehiclesDueForInspectionScheduledReportParameters extends ScheduledReportParameters {
    soldTos?: SoldToSelection;
}

export interface CasingsScheduledReportParameters extends ScheduledReportParameters {
    serviceProviders?: ServiceProviderSelection;
    period: ScheduledReportPeriod;
}

export interface TireStockScheduledReportParameters extends ScheduledReportParameters {
    companies: TireStockCompany[];
}

export interface TCUSensorFitmentsCustomReportParameters{
    fromDate?: Date;
    toDate?: Date;
    serviceProviders?: ServiceProviderSelectionsControlValue;
    soldTos?: SoldToSelectionsControlValue;
}

export interface InspectionDetailsCustomReportParameters {
    fromDate?: Date;
    toDate?: Date;
    serviceProviders?: ServiceProviderSelectionsControlValue;
    soldTos?: SoldToSelectionsControlValue;
}

export interface CustomerServiceLocationsCustomReportParameters {
    serviceProviders?: ServiceProviderSelectionsControlValue;
    soldTos?: SoldToSelectionsControlValue;
}

export interface ExtendedInspectionCustomReportParameters {
    selectedInspectionStatus?: InspectionStatus;
    serviceProviders?: ServiceProviderSelectionsControlValue;
    soldTos?: SoldToSelectionsControlValue;
}

export interface VehiclesDueForInspectionCustomReportParameters {
    soldTos?: SoldToSelectionsControlValue;
}

export interface CasingsCustomReportParameters {
    fromDate?: Date;
    toDate?: Date;
    serviceProviders?: ServiceProviderSelectionsControlValue;
}

export interface TireStockCustomReportParameters {
    companies: TireStockCompany[];
}
