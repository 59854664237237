import {faSearch} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Component} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {faCheckSquare, faMinusSquare, faSquare} from '@fortawesome/pro-light-svg-icons';
import {closePopup} from '../../../../../store/popup';
import {conditionalClassLister} from '../../../../../utils/class-helpers';
import styles from './service-provider-selection-popup.module.scss';
import {
    getServiceProvidersRequest, GlobalServiceProvider,
    ServiceProvider,
    ServiceProviderRequest,
} from '../../../../../store/service-providers';
import ServiceProviderSelectionItem from './service-provider-selection-item';
import Paging from '../../../../jobs/jobs-table/paging/paging';
import {ApplicationState} from '../../../../../store';
import {Paged, RequestStatus} from '../../../../../store/shared/types';

class ServiceProviderSelectionPopup extends Component<AllProps, AllState> {

    constructor(props) {
        super(props);
        const {originalServiceProviderMappedIds, dispatchGetServiceProviders} = this.props;

        this.state = {
            mappedServiceProviderIds: [...originalServiceProviderMappedIds],
            showSelectedOnly: false,
            currentPage: 1,
            itemsPerPage: 15,
            search: '',
        };

        dispatchGetServiceProviders({page: 1, itemsPerPage: 15});
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>) {
        const {currentPage, itemsPerPage, showSelectedOnly} = this.state;

        if (currentPage !== prevState.currentPage
            || showSelectedOnly !== prevState.showSelectedOnly || itemsPerPage !== prevState.itemsPerPage) {
            this.performSearch();
        }
    }

    public render(): JSX.Element {
        const {t, serviceProviders, requestStatus, canChange} = this.props;
        const {showSelectedOnly, mappedServiceProviderIds, currentPage, itemsPerPage} = this.state;
        const searchInProgress: boolean = requestStatus ? requestStatus.isInProgress : true;

        const showSelectedOnlyClass = conditionalClassLister(styles)({
            link: true,
            disabled: searchInProgress,
            active: showSelectedOnly,
        });

        const showAllClass = conditionalClassLister(styles)({
            link: true,
            disabled: searchInProgress,
            active: !showSelectedOnly,
        });

        const indicatorClass = conditionalClassLister(styles)({
            indicator: true,
            item: true,
        });

        const codeClass = conditionalClassLister(styles)({
            item: true,
            small: true
        });

        const nameClass = conditionalClassLister(styles)({
            item: true,
            large: true,
        });

        const cityClass = conditionalClassLister(styles)({
            item: true,
            medium: true
        });

        const countryClass = conditionalClassLister(styles)({
            item: true,
            medium: true
        });

        const toolbarClass = conditionalClassLister(styles)({
            toolbar: true,
            disabled: searchInProgress
        });

        const searchButtonClass = conditionalClassLister(styles)({
            button: true,
            searchButton: true,
        });

        return (
            <div className={styles.popupContainer}>
                <div className={styles.popupHeader}/>
                <div className={styles.popupBody}>
                    <div className={styles.popupBodyHeader}>
                        <div className={styles.title}>{t('Make your selection')}</div>
                        <div className={styles.searchContainer}>
                            <div className={styles.searchInputContainer}>
                                <FontAwesomeIcon className={styles.searchIcon} icon={faSearch}/>
                                <input
                                    className={styles.searchInput}
                                    type="text"
                                    placeholder={t('Filter')}
                                    autoFocus
                                    onKeyDown={(event) => this.onKeyPressed(event)}
                                    onChange={(event) => this.updateSearchValue(event)}
                                />
                            </div>
                            <button type="button" disabled={searchInProgress} className={searchButtonClass}
                                    onClick={() => this.searchClick()}>
                                {t('Search')}
                            </button>
                        </div>
                    </div>
                    <div className={toolbarClass}>
                        <div className={styles.toolbarItem}>
                            Show{' '}
                            <div className={showAllClass} onClick={() => this.onShowSelectedOnly(false)}>
                                All
                            </div>
                            |
                            <div className={showSelectedOnlyClass} onClick={() => this.onShowSelectedOnly(true)}>
                                Selected Only
                            </div>
                        </div>
                    </div>
                    <div className={styles.selectionItemsHeader}>
                        <div className={indicatorClass} onClick={() => this.onSelectAllNoneVisible()}>
                            {serviceProviders&& serviceProviders.pageData.length > 0 && serviceProviders.pageData.every(sp => mappedServiceProviderIds.includes(sp.id))
                                ? <FontAwesomeIcon icon={faCheckSquare}/>
                                : serviceProviders&& serviceProviders.pageData.length > 0 && serviceProviders.pageData.some(sp => mappedServiceProviderIds.includes(sp.id))
                                    ? <FontAwesomeIcon icon={faMinusSquare}/>
                                    : <FontAwesomeIcon icon={faSquare}/>}</div>
                        <div className={codeClass}>{t('Code')}</div>
                        <div className={nameClass}>{t('Name')}</div>
                        <div className={cityClass}>{t('City')}</div>
                        <div className={countryClass}>{t('Country')}</div>
                    </div>
                    <div className={styles.selectionItems}>
                        {searchInProgress ?
                            <div className={styles.loading}>
                                <FontAwesomeIcon icon={faSpinner} spin/>
                            </div> :
                            serviceProviders && serviceProviders.pageData.length > 0 ? (
                                serviceProviders.pageData.map((sp) => {
                                    const includes = !!mappedServiceProviderIds.find(serviceProviderId => serviceProviderId === sp.id);
                                    return (
                                        <ServiceProviderSelectionItem
                                            serviceProvider={sp}
                                            selected={includes}
                                            canSelect={canChange}
                                            onSelect={(): void => this.onToggleServiceProviderMapping(!includes, sp)}
                                            key={sp.id}
                                        />
                                    );
                                })
                            ) : (
                                <div className={styles.emptyResults}>{t('No data found.')}</div>
                            )}
                    </div>
                    {serviceProviders ? (
                        <Paging
                            currentPage={currentPage}
                            initialItemsPerPage={itemsPerPage}
                            onPreviousPage={() => this.onPreviousPage()}
                            onNextPage={() => this.onNextPage()}
                            onJumpToPage={(index) => this.onJumpToPage(index)}
                            total={serviceProviders.totalCount}
                            onItemsPerPageChanged={(items: number) => this.onItemsPerPageChanged(items)}
                            disabled={searchInProgress}
                        />
                    ) : null}
                    <div className={styles.buttons}>
                        <button type="button" className={styles.button} onClick={() => this.closePopup()}>
                            {t('Close')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    private onSelectAllNoneVisible(): void {
        const {mappedServiceProviderIds} = this.state;
        const {onServiceProviderMappedIdsChanged, serviceProviders} = this.props;
        if (serviceProviders) {
            const serviceProviderIds=serviceProviders.pageData.map(sp=>sp.id);
            let newServiceProviderIds: string[] = [];
            if (!serviceProviders?.pageData.every(sp => mappedServiceProviderIds.includes(sp.id))) {
                newServiceProviderIds = serviceProviders?.pageData.map(sp => sp.id);
            } else {
                newServiceProviderIds = mappedServiceProviderIds.filter(spId=>!serviceProviderIds.includes(spId));
            }

            onServiceProviderMappedIdsChanged(newServiceProviderIds);
            this.setState({mappedServiceProviderIds: newServiceProviderIds});
        }
    }

    private onKeyPressed(e) {
        if (e.keyCode === 13) {
            this.performSearch();
        }
    }

    private onItemsPerPageChanged(items: number): void {
        this.setState({itemsPerPage: items});
    }

    private onPreviousPage(): void {
        const {currentPage} = this.state;
        const page = currentPage - 1;
        this.setState({currentPage: page});
    }

    private onNextPage(): void {
        const {currentPage} = this.state;
        const page = currentPage + 1;
        this.setState({currentPage: page});
    }

    private onJumpToPage(pageIndex: number): void {
        if (!Number.isNaN(pageIndex)) {
            this.setState({currentPage: pageIndex});
        }
    }

    private updateSearchValue(event: any): void {
        this.setState({search: event.target.value, currentPage: 1});
    }

    private searchClick(): void {
        this.performSearch();
    }

    private performSearch() {
        const {dispatchGetServiceProviders, requestStatus} = this.props;
        const {currentPage, itemsPerPage, search, mappedServiceProviderIds, showSelectedOnly} = this.state;

        if (requestStatus && !requestStatus.isInProgress) {
            dispatchGetServiceProviders({
                page: currentPage,
                itemsPerPage,
                search,
                serviceProviderIds: showSelectedOnly ? mappedServiceProviderIds : []
            });
        }
    }

    private onShowSelectedOnly(showSelectedOnly: boolean): void {
        this.setState({showSelectedOnly, currentPage: 1});
    }

    private onToggleServiceProviderMapping(select: boolean, serviceProvider: ServiceProvider): void {
        const {mappedServiceProviderIds} = this.state;
        const {onServiceProviderMappedIdsChanged} = this.props;

        if (select) {
            mappedServiceProviderIds.push(serviceProvider.id);
        } else {
            const index = mappedServiceProviderIds.indexOf(serviceProvider.id);
            if (index !== -1) {
                mappedServiceProviderIds.splice(index, 1);
            }
        }
        onServiceProviderMappedIdsChanged(mappedServiceProviderIds);
        this.setState({mappedServiceProviderIds});
    }

    private closePopup(): void {
        const {dispatchClosePopup} = this.props;
        dispatchClosePopup();
    }
}

const mapStateToProps = ({serviceProvider}: ApplicationState): PropsFromState => ({
    serviceProviders: serviceProvider.serviceProviders,
    requestStatus: serviceProvider.serviceProvidersRequestStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchClosePopup: () => dispatch(closePopup()),
    dispatchGetServiceProviders: (request: ServiceProviderRequest) => dispatch(getServiceProvidersRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ServiceProviderSelectionPopup));

interface PropsFromState {
    serviceProviders?: Paged<GlobalServiceProvider>;
    requestStatus?: RequestStatus;
}

interface PropsFromDispatch {
    dispatchClosePopup: typeof closePopup;
    dispatchGetServiceProviders: typeof getServiceProvidersRequest;
}

interface OwnProps {
    canChange: boolean;
    originalServiceProviderMappedIds: string[];
    onServiceProviderMappedIdsChanged: (serviceProviderIds: string[]) => void;
}

type AllProps = PropsFromState & PropsFromDispatch & WithTranslation & OwnProps;

interface OwnState {
    showSelectedOnly: boolean;
    mappedServiceProviderIds: string[];
    currentPage: number;
    itemsPerPage: number;
    search: string;
}

type AllState = OwnState;
