import { TFunction } from 'i18next';
import {
    TireBrandType,
    TiresDistributionData,
    TireType,
} from '../../../../store/analytics/types/tires-distribution.types';
import { getTireBrandTypeLabel, getTireTypeLabel } from '../../../../utils/translations/tire-type-translation-helper';

export const tireTypeField = 'tireType';
export const tireCountField = 'tireCount';
export const typeField = 'type';

export function buildRetreadVsNewTiresChartData(data: TiresDistributionData[], translate: TFunction): any[] {
    const retreadRow = {};
    retreadRow[tireTypeField] = getTireTypeLabel(translate, TireType.RetreadTire);
    retreadRow[typeField] = TireType.RetreadTire;
    retreadRow[tireCountField] = 0;

    const newTiresRow = {};
    newTiresRow[tireTypeField] = getTireTypeLabel(translate, TireType.NewTire);
    newTiresRow[typeField] = TireType.NewTire;
    newTiresRow[tireCountField] = 0;

    data.forEach(row => {
        if (row.tireType === TireType.NewTire) {
            newTiresRow[tireCountField] += row.tireCount;
        } else {
            retreadRow[tireCountField] += row.tireCount;
        }
    });
    return [retreadRow, newTiresRow];
}

export function buildCorporateVsNonCorporateChartData(data: TiresDistributionData[], translate: TFunction): any[] {
    const corporateRow = {};
    corporateRow[tireTypeField] = getTireBrandTypeLabel(translate, TireBrandType.Corporate);
    corporateRow[typeField] = TireBrandType.Corporate;
    corporateRow[tireCountField] = 0;

    const nonCorporateRow = {};
    nonCorporateRow[tireTypeField] = getTireBrandTypeLabel(translate, TireBrandType.NonCorporate);
    nonCorporateRow[typeField] = TireBrandType.NonCorporate;
    nonCorporateRow[tireCountField] = 0;

    data.forEach(row => {
        if (row.ownBrand) {
            corporateRow[tireCountField] += row.tireCount;
        } else {
            nonCorporateRow[tireCountField] += row.tireCount;
        }
    });
    return [corporateRow, nonCorporateRow];
}
