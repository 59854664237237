import {TFunction} from 'i18next';
import {ScheduledReport, ScheduledReportGranularity} from '../../../store/reports';

export function getReportScheduleDisplay(scheduledReport: ScheduledReport, t: TFunction): string {
    let granularity = '';
    let occurrence = '';
    let yearParts: string[] = [];
    switch (scheduledReport.granularity) {
        case ScheduledReportGranularity.WEEK:
            granularity = t('Weekly');
            switch (scheduledReport.scheduleTime) {
                case '1':
                    occurrence = t('on Mondays');
                    break;
                case '2':
                    occurrence = t('on Tuesdays');
                    break;
                case '3':
                    occurrence = t('on Wednesdays');
                    break;
                case '4':
                    occurrence = t('on Thursdays');
                    break;
                case '5':
                    occurrence = t('on Fridays');
                    break;
                case '6':
                    occurrence = t('on Saturdays');
                    break;
                case '7':
                    occurrence = t('on Sundays');
                    break;
                default:
                    throw new Error(`No mapping found for weekly schedule time: ${scheduledReport.scheduleTime}`);
            }
            break;
        case ScheduledReportGranularity.MONTH:
            granularity = t('Monthly');
            if (scheduledReport.scheduleTime === 'first' || scheduledReport.scheduleTime === 'last') {
                occurrence = t(`on ${scheduledReport.scheduleTime} day`);
            } else {
                occurrence = `${t('on day')} ${scheduledReport.scheduleTime}`;
            }

            break;
        case ScheduledReportGranularity.YEAR:
            granularity = t('Yearly');
            yearParts = scheduledReport.scheduleTime.split('-');
            switch (yearParts[0]) {
                case '1':
                    occurrence = t('in January');
                    break;
                case '2':
                    occurrence = t('in February');
                    break;
                case '3':
                    occurrence = t('in March');
                    break;
                case '4':
                    occurrence = t('in April');
                    break;
                case '5':
                    occurrence = t('in May');
                    break;
                case '6':
                    occurrence = t('in June');
                    break;
                case '7':
                    occurrence = t('in July');
                    break;
                case '8':
                    occurrence = t('in August');
                    break;
                case '9':
                    occurrence = t('in September');
                    break;
                case '10':
                    occurrence = t('in October');
                    break;
                case '11':
                    occurrence = t('in November');
                    break;
                case '12':
                    occurrence = t('in December');
                    break;
                default:
                    throw new Error(`No mapping found for yearly schedule time: ${scheduledReport.scheduleTime}`);
            }
            break;
        default:
            throw new Error(`No mapping found for granularity: ${scheduledReport.granularity}`);
    }
    return `${granularity} ${occurrence}`;
}

export function getRecipientsDisplay(recipients: string[],t:TFunction): string {
    if (recipients.length <= 2) {
        return recipients.join(', ');
    } else {
        return `${recipients.length} ${t('recipients')}`;
    }
}
