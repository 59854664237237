import { ActionType } from 'typesafe-actions';
import * as sharedActions from '../shared/actions';
import { RequestStatus } from '../shared/types';
import * as actions from './actions';

export enum MaintenanceActionTypes {
    FETCH_STATUS_REQUEST = '@@users/FETCH_STATUS_REQUEST',
    FETCH_STATUS_SUCCESS = '@@users/FETCH_STATUS_SUCCESS',
    FETCH_STATUS_FAILURE = '@@users/FETCH_STATUS_FAILURE',
}

export type MaintenanceAction = ActionType<typeof actions> | ActionType<typeof sharedActions>;

export interface MaintenanceState {
    readonly underMaintenance: boolean;

    readonly getMaintenanceStatusRequestStatus?: RequestStatus;
}

export interface MaintenanceStatusContract {
    underMaintenance: boolean;
}
