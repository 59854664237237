import {Reducer} from 'redux';
import {ToastAction, ToastActionTypes, ToastState} from './types';
import {SharedActionTypes} from '../shared/types';

function initialState(): ToastState {
    return {toasts: []};
}

const reducer: Reducer<ToastState, ToastAction> = (state = initialState(), action): ToastState => {
    switch (action.type) {
        case SharedActionTypes.LOGOUT_SUCCESS: {
            return initialState();
        }
        case ToastActionTypes.ADD_TOAST: {
            return {...state, toasts: state.toasts.concat(action.payload)};
        }
        case ToastActionTypes.REMOVE_TOAST: {
            return {...state, toasts: state.toasts.filter(t => t.id !== action.payload.id)};
        }
        default: {
            return state;
        }
    }
};

export {reducer as toastReducer};
export {initialState as initialToastState};
