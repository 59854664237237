import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTools} from '@fortawesome/pro-regular-svg-icons/faTools';
import styles from './maintenance-notification.module.scss';
import logo from '../../../assets/images/logo.png';

class MaintenanceNotification extends Component<AllProps> {

    public render(): JSX.Element {
        const {t} = this.props;

        return (
            <div className={styles.pageContainer}>
                <div className={styles.leftContainer}>
                    <img className={styles.logo} src={logo} alt={t('Goodyear logo')}/>
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.notificationContainer}>
                        <div className={styles.notificationImage}>
                            <FontAwesomeIcon icon={faTools} size='4x'/>
                        </div>
                        <div className={styles.notificationContent}>
                            <div className={styles.notificationTitle}>
                                {t('The application is currently down for maintenance.')}
                            </div>
                            <div className={styles.notificationSubTitle}>
                                {t(`We'll be back online shortly.`)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(MaintenanceNotification);

interface OwnProps {
}

type AllProps = OwnProps & WithTranslation;
