export enum GroupPosition{
    Left='Left',
    Right='Right',
}

export class TireValues {
    constructor(public date: string,public pressure?: number,public treadDepth?:number) {
    }
}

export class TireInfo {
    constructor(public number: number, public position:string,public description:string, public brand:string, public size:string, public serialNumber:string,public isRetread:boolean,public values?:TireValues, public dorValues?:TireValues) {
    }
}

export class TireGroup {
    constructor(public position:GroupPosition,public tires: TireInfo[]) {
    }
}

export class Axle {
    constructor(public position: number, public leftTires: TireGroup, public rightTires: TireGroup) {
    }
}

export class VehicleConfiguration {
    constructor(public axles: Axle[], public spareTires: TireInfo[]) {
    }
}
