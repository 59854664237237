import {connectRouter, RouterState} from 'connected-react-router';
import {CombinedState, combineReducers, Reducer} from 'redux';
import {all, fork} from 'redux-saga/effects';
import {
    History,
} from 'history';
import {analyticsReducer, AnalyticsState} from './analytics';
import analyticsSaga from './analytics/sagas';
import {authenticationReducer, AuthenticationState} from './authentication';
import authenticationSaga from './authentication/sagas';
import {dashboardReducer, DashboardState} from './dashboard';
import dashboardSaga from './dashboard/sagas';
import {serviceProviderGroupsReducer, ServiceProviderGroupsState} from './service-provider-groups';
import serviceProviderGroupsSaga from './service-provider-groups/sagas';
import {layoutReducer, LayoutState} from './layout';
import {popupReducer, PopupState} from './popup';
import {searchReducer, SearchState} from './search';
import searchSaga from './search/sagas';
import {serviceProviderReducer, ServiceProviderState} from './service-providers';
import serviceProviderSaga from './service-providers/sagas';
import {toastReducer, ToastState} from './toast';
import {usersReducer, UsersState} from './users';
import usersSaga from './users/sagas';
import {filterControlSelectionReducer, FilterControlSelectionState} from './filter-control-selection';
import {maintenanceReducer, MaintenanceState} from './maintenance';
import maintenanceSaga from './maintenance/sagas';
import { jobsReducer, JobsState } from './jobs';
import jobsSaga from './jobs/sagas';
import { vehiclesReducer, VehiclesState } from './vehicles';
import vehcilesSaga from './vehicles/sagas';
import soldTosSaga from './sold-tos/sagas';
import { soldTosReducer, SoldToState } from './sold-tos';
import { dataStatusReducer, DataStatusState } from './data-status';
import dataStatusSaga from './data-status/sagas';
import reportsSaga from './reports/sagas';
import reportConfigurationSaga from './report-management/sagas';
import {reportsReducer, ReportsState} from './reports';
import {reportConfigurationReducer, ReportConfigurationState} from './report-management';
import {scheduledReportsReducer, ScheduledReportsState} from './scheduled-report-management';
import scheduledReportsSaga from './scheduled-report-management/sagas';

export interface ApplicationState {
    router: RouterState;
    layout: LayoutState;
    authentication: AuthenticationState;
    dashboard: DashboardState;
    toast: ToastState;
    popup: PopupState;
    search: SearchState;
    serviceProvider: ServiceProviderState;
    serviceProviderGroups: ServiceProviderGroupsState;
    analytics: AnalyticsState;
    users: UsersState;
    maintenance: MaintenanceState;
    filterControlSelection: FilterControlSelectionState;
    jobs: JobsState;
    vehicles: VehiclesState;
    soldTos: SoldToState;
    reports: ReportsState;
    dataStatus: DataStatusState;
    reportConfigurations: ReportConfigurationState;
    scheduledReports: ScheduledReportsState;
}

export const rootReducer = (history:History): Reducer<CombinedState<ApplicationState>> =>
    combineReducers<ApplicationState>({
        router: connectRouter(history),
        layout: layoutReducer,
        authentication: authenticationReducer,
        dashboard: dashboardReducer,
        toast: toastReducer,
        search: searchReducer,
        serviceProviderGroups: serviceProviderGroupsReducer,
        popup: popupReducer,
        serviceProvider: serviceProviderReducer,
        analytics: analyticsReducer,
        users: usersReducer,
        maintenance: maintenanceReducer,
        filterControlSelection: filterControlSelectionReducer,
        jobs: jobsReducer,
        vehicles: vehiclesReducer,
        soldTos: soldTosReducer,
        reports: reportsReducer,
        dataStatus: dataStatusReducer,
        reportConfigurations: reportConfigurationReducer,
        scheduledReports:scheduledReportsReducer,
    });


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* rootSaga() {
    yield all([
        fork(authenticationSaga),
        fork(dashboardSaga),
        fork(usersSaga),
        fork(maintenanceSaga),
        fork(searchSaga),
        fork(serviceProviderSaga),
        fork(analyticsSaga),
        fork(serviceProviderGroupsSaga),
        fork(jobsSaga),
        fork(vehcilesSaga),
        fork(soldTosSaga),
        fork(reportsSaga),
        fork(dataStatusSaga),
        fork(reportConfigurationSaga),
        fork(scheduledReportsSaga),
    ]);
}
