import {Component} from 'react';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {Location, LocationDescriptorObject} from 'history';
import {push} from 'connected-react-router';
import {WithTranslation, withTranslation} from 'react-i18next';
import {RouteComponentProps} from 'react-router';
import ReportsMenuComponent, {ReportTab} from '../components/reports-menu.component';
import styles from './report-scheduler.module.scss';
import PageHeader from '../../../components/page-header/page-header';
import {RouteUrl} from '../../../routes';
import {User} from '../../../store/authentication';
import {ApplicationState} from '../../../store';
import * as layoutActions from '../../../store/layout/actions';
import {ServiceProviderGroupMatchParameters} from '../../../types/service-provider-group';
import ReportSchedulerTableComponent from './report-scheduler-table.component';
import {Popup, PopupType, showPopup} from '../../../store/popup';
import ReportSchedulerPopup from './popup/report-scheduler-popup';
import {deleteScheduledReportsRequest, fetchScheduledReportsRequest, ScheduledReport} from '../../../store/reports';
import {ServiceProviderGroup} from '../../../store/service-provider-groups';
import {
    buildReturnUrlFromQueryParameters,
    buildSearchParameters,
    QueryParameter
} from '../../../utils/query-parameter-helpers';
import {RequestStatus} from '../../../store/shared/types';

class ReportSchedulerPage extends Component<AllProps, AllState> {
    constructor(props) {
        super(props);
        const {dispatchToggleSidebar, serviceProviderGroup} = this.props;
        dispatchToggleSidebar(true);

        if (serviceProviderGroup) {
            this.fetchData();
        }

        this.state = {
            scheduledReportId: undefined,
            returnUrl: undefined,
            reportTabs: [ReportTab.DownloadableReports, ReportTab.ReportScheduler],
            activeTab: ReportTab.ReportScheduler,
        };
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>, prevState: Readonly<AllState>) {
        const {serviceProviderGroup, currentLocation, scheduledReports} = this.props;
        this.handleReturnUrlParameter(currentLocation.search);

        if (scheduledReports && scheduledReports.length > 0) {
            this.handleScheduledReportIdParameter(currentLocation.search);
        }

        if (serviceProviderGroup !== prevProps.serviceProviderGroup) {
            this.fetchData();
        }
    }

    private handleReturnUrlParameter(queryParameters: string): void {
        const {returnUrl} = this.state;
        const map = new URLSearchParams(queryParameters);
        if (map.has(QueryParameter.ReturnUrl)) {
            const returnUrlFromParams = map.get(QueryParameter.ReturnUrl)!;
            if (returnUrlFromParams !== returnUrl) {
                this.setState({returnUrl: returnUrlFromParams})
            }
        } else if (returnUrl !== undefined) {
            this.setState({scheduledReportId: undefined})
        }
    }

    private handleScheduledReportIdParameter(queryParameters: string): void {
        const {scheduledReportId} = this.state;
        const map = new URLSearchParams(queryParameters);
        if (map.has(QueryParameter.ScheduledReportId)) {
            const scheduledReportIdFromParams = map.get(QueryParameter.ScheduledReportId)!;
            if (scheduledReportIdFromParams !== scheduledReportId) {
                this.setState({scheduledReportId: scheduledReportIdFromParams})
                this.onUpdateScheduledReportById(scheduledReportIdFromParams);
            }
        } else if (scheduledReportId !== undefined) {
            this.setState({scheduledReportId: undefined})
        }
    }

    public render(): JSX.Element {
        const {t, location, scheduledReports, getScheduledReportsRequestStatus} = this.props;
        const {reportTabs, activeTab, returnUrl} = this.state;
        return (
            <div className={styles.pageContainer}>
                <PageHeader headerTitle={t('Reports')}
                            location={location}
                            bottomComponent={
                                <ReportsMenuComponent
                                    reportTabs={reportTabs}
                                    activeReportTab={activeTab}
                                    onSelectReportTab={(tab) => this.onSelectTab(tab)}
                                />
                            }
                />
                <div className={styles.pageContent}>
                    <div className={styles.addNewScheduleButton}
                         onClick={() => this.onCreateScheduledReport()}>{t('Create new schedule')}</div>
                    <ReportSchedulerTableComponent
                        scheduledReports={scheduledReports}
                        onUpdateScheduledReportClick={(scheduledReport) => this.onUpdateScheduledReport(scheduledReport)}
                        onDeleteScheduledReportClick={(id) => this.onDeleteScheduledReport(id)}/>
                    {returnUrl ? <button type="button"
                                         className={`${styles.button} ${styles.isNegative}`}
                                         disabled={getScheduledReportsRequestStatus?.isInProgress}
                                         onClick={() => this.goBack()}>
                        {t('Back')}
                    </button> : null}
                </div>
            </div>
        );
    }

    private onDeleteScheduledReport(id: string): void {
        const {dispatchDeleteScheduledReport} = this.props;
        dispatchDeleteScheduledReport(id);
    }

    private onCreateScheduledReport(): void {
        const {dispatchShowPopup} = this.props;

        dispatchShowPopup({
            type: PopupType.ScheduledReport,
            content: (
                <ReportSchedulerPopup onClosePopup={() => {}}/>
            ),
        });
    }

    private onUpdateScheduledReport(scheduledReport: ScheduledReport): void {
        const {dispatchNavigateTo, serviceProviderGroup} = this.props;
        const queryParameters = buildSearchParameters(
            {
                scheduledReportId: scheduledReport.id,
            },
        );
        dispatchNavigateTo({pathname: `/${serviceProviderGroup!.id}${RouteUrl.ReportScheduler}`, search: queryParameters});
    }

    private onUpdateScheduledReportById(scheduledReportId: string): void {
        const {scheduledReports} = this.props;
        const scheduledReport = scheduledReports.find(s => s.id === scheduledReportId);
        if (scheduledReport) {
            this.openUpdateScheduledReportPopup(scheduledReport);
        }
    }

    private openUpdateScheduledReportPopup(scheduledReport: ScheduledReport): void {
        const {dispatchShowPopup} = this.props;

        dispatchShowPopup({
            type: PopupType.ScheduledReport,
            content: (
                <ReportSchedulerPopup
                    scheduledReport={scheduledReport}
                    onClosePopup={() => this.onClosePopup()}
                />
            ),
        });
    }

    private onSelectTab(tab: ReportTab): void {
        if (tab === ReportTab.DownloadableReports) {
            this.navigateTo(RouteUrl.Reports);
        } else if (tab === ReportTab.ReportScheduler) {
            this.navigateTo(RouteUrl.ReportScheduler);
        }
    }

    private onClosePopup() {
        const {scheduledReportId} = this.state;
        if (scheduledReportId) {
            const {currentLocation, dispatchNavigateTo} = this.props;
            const queryParameters = buildSearchParameters(
                {
                    scheduledReportId: undefined
                },
                currentLocation.search,
            );
            dispatchNavigateTo({search: queryParameters});
        }
    }

    private goBack(): void {
        const {dispatchNavigateTo, serviceProviderGroup, location} = this.props;
        const queryParameters = new URLSearchParams(location.search);
        if (queryParameters.has(QueryParameter.ReturnUrl)) {
            dispatchNavigateTo(buildReturnUrlFromQueryParameters(queryParameters));
        } else {
            dispatchNavigateTo({pathname: `/${serviceProviderGroup?.id}${RouteUrl.Reports}`});
        }
    }

    private navigateTo(route: RouteUrl): void {
        const { dispatchNavigateTo, serviceProviderGroup } = this.props;
        dispatchNavigateTo({ pathname: `/${serviceProviderGroup?.id}${route}` });
    }

    private fetchData(): void {
        const {dispatchFetchScheduledReports} = this.props;
        dispatchFetchScheduledReports();
    }
}

// eslint-disable-next-line
const mapStateToProps = ({authentication, router, reports}: ApplicationState) => ({
    getScheduledReportsRequestStatus: reports.getScheduledReportsRequestStatus,
    scheduledReports: reports.scheduledReports,
    serviceProviderGroup: authentication.serviceProviderGroup,
    user: authentication.user,
    currentLocation: router.location,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchShowPopup: (popup: Popup) => dispatch(showPopup(popup)),
    dispatchToggleSidebar: (showSidebar: boolean) => dispatch(layoutActions.toggleSidebar(showSidebar)),
    dispatchNavigateTo: (location: LocationDescriptorObject) => dispatch(push(location)),
    dispatchFetchScheduledReports: () => dispatch(fetchScheduledReportsRequest()),
    dispatchDeleteScheduledReport: (id: string) => dispatch(deleteScheduledReportsRequest(id)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ReportSchedulerPage));

interface PropsFromState {
    currentLocation: Location;
    scheduledReports: ScheduledReport[];
    getScheduledReportsRequestStatus: RequestStatus;
    serviceProviderGroup?: ServiceProviderGroup;
    user: User;
}

interface PropsFromDispatch {
    dispatchShowPopup: typeof showPopup;
    dispatchToggleSidebar: typeof layoutActions.toggleSidebar;
    dispatchNavigateTo: typeof push;
    dispatchFetchScheduledReports: typeof fetchScheduledReportsRequest;
    dispatchDeleteScheduledReport: typeof deleteScheduledReportsRequest;
}

interface OwnProps {
    location: Location;
}

type AllProps = PropsFromState &
    PropsFromDispatch &
    WithTranslation &
    OwnProps &
    RouteComponentProps<ServiceProviderGroupMatchParameters>;

interface OwnState {
    scheduledReportId?: string;
    returnUrl?: string;
    reportTabs: ReportTab[];
    activeTab: ReportTab;
}

type AllState = OwnState;
