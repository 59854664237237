import React, {Component, RefObject} from 'react';
import GridLayout, {Layout} from 'react-grid-layout';
import {withTranslation, WithTranslation} from 'react-i18next';
import {DashboardWidget} from '../../../store/dashboard';
import RedrawMask from '../../../components/redraw-mask/redraw-mask';
import {renderWidget} from './widget-render-helper';
import styles from './widgets-container.module.scss';
import ToggleDisplay from '../../../utils/toggle-display';
import WidgetEditor from './widget-editor';
import {ControlsSnapshot} from '../dashboard-controls/controls-snapshot';

function debounce(func, wait, immediate = false) {
    let timeout;
    return function db() {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context: any = this;
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        const later = function lt() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}


class WidgetsContainer extends Component<AllProps, AllState> {

    private containerRef: RefObject<HTMLDivElement>;

    private resizeHandler = debounce(this.containerResized, 350);

    constructor(props) {
        super(props);
        const {originalWidgets} = this.props;
        const widgets = [...originalWidgets];
        this.containerRef = React.createRef();

        this.state = {
            widgets,
            isRedrawing: false,
            refreshConfig: 0,
        };
    }

    public componentDidMount() {
        const {setWidgetsContainerRef} = this.props;
        setWidgetsContainerRef(this);
        // eslint-disable-next-line no-undef
        window.addEventListener('resize', () => this.resizeHandler());
    }

    public componentWillUnmount() {
        const {setWidgetsContainerRef} = this.props;
        setWidgetsContainerRef(undefined);
        // eslint-disable-next-line no-undef
        window.removeEventListener('resize', () => this.resizeHandler());
    }

    public containerResized() {
        this.setState({});
    }

    public determineWidth(): number {
        let width = 1200;
        if (this.containerRef.current) {
            width = this.containerRef.current.clientWidth! - 40;
            width = width! > 1600 ? 1600 : width;
            width = width! < 1200 ? 1200 : width;
        }
        return width;
    }

    public componentDidUpdate(prevProps: Readonly<AllProps>): void {
        const {editMode, originalWidgets} = this.props;
        if ((originalWidgets !== prevProps.originalWidgets) ||
            (editMode !== prevProps.editMode)) {
            const {refreshConfig: refreshConfig1} = this.state;
            const refreshConfig = refreshConfig1 + 1;
            this.setState({refreshConfig, widgets: [...originalWidgets]});
        }
    }

    public render(): JSX.Element {
        const {isDirty, cleanControlsSnapshot, editMode} = this.props;
        const {isRedrawing, widgets, refreshConfig} = this.state;
        const gridLayoutWidth = this.determineWidth();

        return (
            <div className={styles.dashboardContainer} ref={this.containerRef}>
                <ToggleDisplay if={isDirty}>
                    <RedrawMask isRedrawing={isRedrawing}
                                onRedrawTriggered={() => this.onRedrawTriggered()}/>
                </ToggleDisplay>
                <div className={styles.widgetsPanel} key={refreshConfig}>
                    <GridLayout cols={12}
                                rowHeight={30}
                                width={gridLayoutWidth}
                                margin={[20, 20]}
                                onLayoutChange={layout => this.onLayoutChange(layout)}>
                        {widgets.map((widget: DashboardWidget) => {
                                const layout = {...widget.layout, static: !editMode};
                                return <div key={widget.id} data-grid={layout} className={styles.gridCell}>
                                    {renderWidget(widget.type, widget.configuration, cleanControlsSnapshot)}
                                    {editMode ? <WidgetEditor onDeleteClick={() => this.deleteWidget(widget)}/> : null}
                                </div>;
                            },
                        )}
                    </GridLayout>
                </div>
            </div>
        );
    }

    private deleteWidget(widget: DashboardWidget) {
        const {onWidgetsConfigurationChanged} = this.props;
        if (onWidgetsConfigurationChanged) {
            const {widgets: widgets1} = this.state;
            const widgets = [...widgets1];
            const index = widgets.findIndex(w => w.id === widget.id);
            widgets.splice(index, 1);
            onWidgetsConfigurationChanged(widgets);
            this.setState({widgets});
        }
    }

    public addWidget(widget: DashboardWidget) {
        const {widgets: widgets1} = this.state;
        const widgets = [...widgets1];
        widgets.push(widget);
        const {onWidgetsConfigurationChanged} = this.props;
        onWidgetsConfigurationChanged(widgets);
        this.setState({widgets});
    }

    private onRedrawTriggered() {
        const {onRedrawTriggered} = this.props;
        onRedrawTriggered();
    }

    private onLayoutChange(layout: Layout[]) {
        const {onWidgetsConfigurationChanged} = this.props;
        if (onWidgetsConfigurationChanged) {
            const widgets: DashboardWidget[] = layout.map((l) => {
                const {widgets: widgets1} = this.state;
                const existingItem = widgets1.find(item => item.id === l.i);
                return {
                    type: existingItem!.type,
                    id: existingItem!.id,
                    configuration: existingItem!.configuration,
                    layout: l,
                };
            });

            onWidgetsConfigurationChanged(widgets);
        }
    }
}

export default withTranslation()(WidgetsContainer);

interface OwnState {
    isRedrawing: boolean;
    refreshConfig: number;
    widgets: DashboardWidget[];
}

type AllState = OwnState;

interface OwnProps {
    setWidgetsContainerRef: (ref: any) => void;
    cleanControlsSnapshot?: ControlsSnapshot;
    originalWidgets: DashboardWidget[];
    isDirty: boolean;
    onRedrawTriggered: () => void;
    onWidgetsConfigurationChanged: (widgets: DashboardWidget[]) => void;
    editMode: boolean;
}

type AllProps = OwnProps & WithTranslation;
