import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Color} from '@amcharts/amcharts4/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import ReactTooltip from 'react-tooltip';
import {generateUUID} from '../../../../utils/uuid-helpers';
import styles from './purchasing-distribution-widget.module.scss';
import {ApplicationState} from '../../../../store';
import WidgetLoading from '../widget-loading';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {
    buildChartData,
    purchasingItemTypeField,
    netPriceField, typeField,
} from './chart-data-adapter';
import {hasInspectionPeriod} from '../utils/widget-helper';
import {colorBlue5, colorYellow5} from '../utils/chart-colors';
import {
    addStandardTooltipStyle,
    addStrokeToColumnChart,
    createXYChart,
    dynamicallySetColorByType,
} from '../utils/chart-utils';
import {SingleChartWidgetBase} from '../single-chart-widget-base';
import {
    PurchasingDistributionData, PurchasingDistributionRequest,
    PurchasingItemType,
} from '../../../../store/analytics/types/purchasing-distribution.types';
import {buildRequest} from './request-builder';
import {CurrencyInfo} from '../../../../store/service-provider-groups';
import {getPurchasingDistributionDataRequest} from '../../../../store/analytics';
import {getTooltipDateRangeTextForWidget, WidgetDateRangeUsedType} from '../../../../utils/tooltip-helper';

am4core.useTheme(am4themes_animated);

class PurchasingDistributionWidget extends SingleChartWidgetBase<AllProps, AllState, PurchasingDistributionData[]> {

    private chartId: string;

    private chartColors: Map<string, Color>;

    constructor(props) {
        super(props);
        this.chartId = generateUUID();
        this.chartColors = new Map<string, Color>();
        this.chartColors.set(PurchasingItemType.Services, am4core.color(colorBlue5));
        this.chartColors.set(PurchasingItemType.Tires, am4core.color(colorYellow5));
    }

    public render(): JSX.Element {
        const {t, data} = this.props;

        return (
            <div className={styles.widgetContainer}>
                {data ? <div className={styles.chartView}>
                    <div className={styles.chartHeader}>
                        <div className={styles.headerTitle}>
                            {t('Purchasing Distribution')}
                            <div className={styles.infoIcon}
                                 data-tip={getTooltipDateRangeTextForWidget(WidgetDateRangeUsedType.PURCHASING_DATE_RANGE_USED, t)}
                                 data-for="purchasing-distribution-info">
                                <FontAwesomeIcon icon={faInfoCircle}/></div>
                            <ReactTooltip className={styles.tooltip} html border effect="solid"
                                          backgroundColor="#000000" id="purchasing-distribution-info"/>
                        </div>
                    </div>
                    <div className={styles.chartContent}>
                        <div id={this.chartId} style={{width: '100%'}}/>
                    </div>
                </div> : <WidgetLoading/>}
            </div>
        );
    }

    protected fetchData(snapshot: ControlsSnapshot) {
        const {activeCurrency} = this.props;
        this.props.getPurchasingDistributionRequest(buildRequest(snapshot, activeCurrency));
    }

    protected validateControlsSnapshot(snapshot: ControlsSnapshot): boolean {
        return hasInspectionPeriod(snapshot);
    }

    protected createChart(data: PurchasingDistributionData[]): am4charts.XYChart {
        const chart = createXYChart(this.chartId);

        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = purchasingItemTypeField;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = this.props.activeCurrency.name;

        this.addFlatSeries(chart);
        chart.data = buildChartData(data, this.props.t);

        return chart;
    }

    public addFlatSeries(chart: any) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = netPriceField;
        series.dataFields.categoryX = purchasingItemTypeField;
        series.columns.template.tooltipText = `{categoryX}: [bold]{valueY} ${this.props.activeCurrency.name}[/]`;
        series.columns.template.fillOpacity = .8;

        addStrokeToColumnChart(series);
        addStandardTooltipStyle(series);
        dynamicallySetColorByType(series, typeField, (categoryX: string) => {
            if (!this.chartColors.has(categoryX)) {
                throw Error(`No color found for category ${categoryX}`);
            }
            return this.chartColors.get(categoryX)!;
        });
    }
}

const mapStateToProps = ({analytics, authentication}: ApplicationState) => ({
    data: analytics.purchasingDistributionData,
    activeCurrency: authentication.serviceProviderGroup!.activeCurrency,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getPurchasingDistributionRequest: (request: PurchasingDistributionRequest) => dispatch(getPurchasingDistributionDataRequest(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PurchasingDistributionWidget));

interface PropsFromState {
    data: PurchasingDistributionData[];
    activeCurrency: CurrencyInfo;
}

interface PropsFromDispatch {
    getPurchasingDistributionRequest: typeof getPurchasingDistributionDataRequest;
}

interface OwnProps {
    controlsSnapshot: ControlsSnapshot;
}

type AllProps = OwnProps & PropsFromState & PropsFromDispatch & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
