import moment from 'moment';
import momentTimezone from 'moment-timezone';

export function forceDateToBrusselsTime(date: Date): Date {
    const stringWithoutTz = moment(date).format('YYYY-MM-DD HH:mm:ss.SSS');
    const m = momentTimezone.tz(stringWithoutTz, 'Europe/Brussels');
    return m.toDate();
}

export function compareAsc(date1: Date, date2: Date): number {
    return date1.getTime() - date2.getTime();
}

export function compareDesc(date1: Date, date2: Date): number {
    return date2.getTime() - date1.getTime();
}

export function addDays(date: Date, amount: number): Date {
    return moment(date).add(amount, 'days').toDate();
}

export function addMonths(date: Date, amount: number): Date {
    return moment(date).add(amount, 'months').toDate();
}

export function addYears(date: Date, amount: number): Date {
    return moment(date).add(amount, 'years').toDate();
}

export function subDays(date: Date, amount: number): Date {
    return moment(date).subtract(amount, 'days').toDate();
}

export function subMonths(date: Date, amount: number): Date {
    return moment(date).subtract(amount, 'months').toDate();
}

export function subYears(date: Date, amount: number): Date {
    return moment(date).subtract(amount, 'years').toDate();
}

export function format(date: Date, pattern: string): string {
    return moment(date).format(pattern);
}

export function formatDateInBrusselsTime(date: Date, pattern: string): string {
    const m = momentTimezone.tz(date, 'Europe/Brussels');
    return m.format(pattern);
}

export function formatStringInBrusselsTime(date: string, pattern: string): string {
    const m = momentTimezone.tz(date, 'Europe/Brussels');
    return m.format(pattern);
}

export function startOfYear(date: Date): Date {
    return moment(date).startOf('year').toDate();
}

export function endOfMonth(date: Date): Date {
    return moment(date).endOf('month').toDate();
}

export function startOfMonth(date: Date): Date {
    return moment(date).startOf('month').toDate();
}

export function endOfDay(date: Date): Date {
    return moment(date).endOf('day').toDate();
}

export function startOfDay(date: Date): Date {
    return moment(date).startOf('day').toDate();
}

export function isBefore(date1: Date, date2: Date): boolean {
    return date1.getTime() < date2.getTime();
}

export function getEarliestDate(date1: Date, date2: Date): Date {
    return isBefore(date1, date2) ? date1 : date2;
}

export function getLatestDate(date1: Date, date2: Date): Date {
    return isBefore(date1, date2) ? date2 : date1;
}

export function getDifferenceInMonths(date1: Date, date2: Date): number {
    return moment(date1).diff(moment(date2), 'months', true);
}

export function getDifferenceInDays(date1: Date, date2: Date): number {
    return moment(date1).diff(moment(date2), 'days', true);
}
