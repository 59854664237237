import {faTimes} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Component} from 'react';
import {Popup} from '../../store/popup';
import {conditionalClassLister} from '../../utils/class-helpers';
import styles from './popup-container.module.scss';

class PopupContainer extends Component<AllProps> {
    public render(): JSX.Element | null {
        const {popup, close} = this.props;

        if (popup) {
            const popupContainerClasses = conditionalClassLister(styles)({
                popupContainer: true,
            });

            return (
                <div className={styles.container}>
                    <div className={styles.overlay}/>
                    <div className={popupContainerClasses} style={popup.topOffset? {top: popup.topOffset}:{top:60}}>
                        {!popup.hideCloseButton ? (
                            <FontAwesomeIcon
                                size='lg'
                                className={styles.close}
                                icon={faTimes}
                                onClick={() => close()}
                            />
                        ) : null}
                        {popup.content}
                    </div>
                </div>
            );
        }
        return null;
    }
}

export default PopupContainer;

interface OwnProps {
    popup?: Popup;
    close: () => void;
}

type AllProps = OwnProps;
