import {ParamKey} from '../../../../store/dashboard';
import {KeyPerformanceIndicatorRequest} from '../../../../store/analytics/types/key-performance-indicator.types';
import {KpiTypes} from '../../../../store/analytics';
import {ControlsSnapshot} from '../../dashboard-controls/controls-snapshot';
import {
    createDateRangeWithServiceProvidersAndSoldTosRequest,
} from '../utils/widget-helper';
import {CurrencyInfo} from '../../../../store/service-provider-groups';

export function buildRequest(snapshot: ControlsSnapshot, type: KpiTypes, currency: CurrencyInfo): KeyPerformanceIndicatorRequest {
    return Object.assign(
        createDateRangeWithServiceProvidersAndSoldTosRequest(snapshot), {
            type,
            currency
        });
}

export function isValidSnapshot(snapshot: ControlsSnapshot): boolean {
    return snapshot.has(ParamKey.InspectionPeriod);
}
