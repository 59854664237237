import {call, delay, put} from 'redux-saga/effects';
import {
    fetchReportDownloadRequest,
    ReportDownloadUrl,
    ReportInfo,
    ReportStatus
} from '../../store/reports';
import ReportsApi from '../../api/reports-api';
import {ApiResult} from '../../types/api-result';
import {PollingResult} from './polling-result';
import {handleUnexpectedErrorWithToast} from '../../store/http-error-handler';
import {generateUUID} from '../../utils/uuid-helpers';

export class ReportDownloadHandler {
    private static pollingIntervalMillis = 5000;

    private static maxPollingTimeMinutes = 10;

    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    public static *handlePolling(reportInfo: ReportInfo, serviceProviderGroupId: string) {
        try {
            let keepPolling = true;
            let pollCount = 0;
            let result: ApiResult<ReportInfo>;
            let reportInfoResponse: ReportInfo | undefined;
            while (keepPolling && !this.pollingTimeExceeded(pollCount)) {
                yield delay(this.pollingIntervalMillis);
                result = yield call(ReportsApi.getReportInfo, reportInfo.id, serviceProviderGroupId);
                if (result && result.data && result.data.status !== ReportStatus.NEW && result.data.status !== ReportStatus.RUNNING) {
                    keepPolling = false;
                    reportInfoResponse = result.data;
                }
                pollCount++;
            }
            if (this.pollingTimeExceeded(pollCount)) {
                return yield new PollingResult(undefined, 'Polling timed out.');
            } else if (reportInfoResponse!.status === ReportStatus.SUCCEEDED) {
                return yield new PollingResult(reportInfoResponse);
            } else {
                return yield new PollingResult(undefined, 'Something went wrong while generating the report');
            }
        } catch (error) {
            return yield new PollingResult(undefined, error);
        }
    }

    public static *handlePollingResult(pollingResult: PollingResult, successFunction: (reportInfo) => {type, payload}, failureFunction: (error: string) => {type, payload}) {
        if (pollingResult.error) {
            yield put(failureFunction(pollingResult.error));
            yield call(handleUnexpectedErrorWithToast, pollingResult.error);
        } else {
            yield put(fetchReportDownloadRequest(pollingResult.data!))
            yield put(successFunction(pollingResult.data!));
        }
    }

    public static downloadReport(reportDownloadUrl: ReportDownloadUrl){
        const iframe = document.createElement('iframe');
        const id = generateUUID();

        const element: HTMLIFrameElement = document.getElementById(id) as HTMLIFrameElement;
        if (element && element.contentWindow) {
            const elementDocument = element.contentDocument || element.contentWindow.document;
            if (elementDocument.readyState === 'complete' || elementDocument.readyState === 'interactive') {
                document.body.removeChild(element);
            }
        }

        iframe.id = id;
        iframe.style.display = 'none';
        iframe.src = reportDownloadUrl.url;
        document.body.appendChild(iframe);
    }

    private static pollingTimeExceeded(numberOfPolls: number) {
        return numberOfPolls * this.pollingIntervalMillis > (this.maxPollingTimeMinutes * 60000);
    }
}
