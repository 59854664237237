import {TFunction} from 'i18next';

export function getMonthLabel(monthIndex: number, t: TFunction): string {
    switch (monthIndex) {
        case 0: {
            return t('month_Jan');
        }
        case 1: {
            return t('month_Feb');
        }
        case 2: {
            return t('month_Mar');
        }
        case 3: {
            return t('month_Apr');
        }
        case 4: {
            return t('month_May');
        }
        case 5: {
            return t('month_Jun');
        }
        case 6: {
            return t('month_Jul');
        }
        case 7: {
            return t('month_Aug');
        }
        case 8: {
            return t('month_Sep');
        }
        case 9: {
            return t('month_Oct');
        }
        case 10: {
            return t('month_Nov');
        }
        case 11: {
            return t('month_Dec');
        }
        default:
            throw new Error(`Month index ${monthIndex} is not supported.`);
    }
}
