import {Component} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {ReactComponent as AnalyticsIcon} from '../../../assets/icons/noun_analytics_428870.svg';
import {ReactComponent as ExplorerIcon} from '../../../assets/icons/noun_binoculars_1236068.svg';
import {ReactComponent as InboxIcon} from '../../../assets/icons/noun_inbox_146533.svg';
import {conditionalClassLister} from '../../utils/class-helpers';
import {SidebarNavItemTypes} from './sidebar-item-types';
import styles from './sidebar-nav-item.module.scss';

class SidebarNavItem extends Component<AllProps, AllState> {
    public render(): JSX.Element {
        const {label, active, isOpen} = this.props;

        const itemClasses = conditionalClassLister(styles)({
            sidebarItemContainer: true,
            sidebarItemActiveContainer: active,
            sidebarIsOpen: isOpen,
        });
        const {type, active: active1} = this.props;
        return (
            <div className={itemClasses} onClick={() => this.onClicked()}>
                <div className={styles.sidebarIconPanel}>
                    {(() => {
                        switch (type) {
                            case SidebarNavItemTypes.Analytics:
                                return <AnalyticsIcon className={styles.sidebarIcon}/>;
                            case SidebarNavItemTypes.Explorer:
                                return <ExplorerIcon className={styles.sidebarIcon}/>;
                            case SidebarNavItemTypes.Inbox:
                                return <InboxIcon className={styles.sidebarIcon}/>;
                            default:
                                return null;
                        }
                    })()}
                    <div className={styles.sidebarIcon}/>
                </div>
                <div className={styles.sidebarTextPanel}>
                    {label} {active1}
                </div>
            </div>
        );
    }

    private onClicked() {
        const {onClick} = this.props;
        onClick();
    }
}

export default withTranslation()(SidebarNavItem);

interface OwnProps {
    label: string;
    type: SidebarNavItemTypes;
    onClick: () => void;
    active: boolean;
    isOpen: boolean;
}

type AllProps = OwnProps & WithTranslation;

interface OwnState {
}

type AllState = OwnState;
