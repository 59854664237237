import {Http} from '../http/http';
import {ApiResult} from '../types/api-result';
import {Dashboard} from '../store/dashboard';
import {ApiUrl, UrlParamBuilder} from './api-urls';

class DashboardsApi {

    public static async getDashboards(serviceProviderGroupId: string): Promise<ApiResult<Dashboard[]>> {
        const data = await Http.get<Dashboard[]>(ApiUrl.Dashboards,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId.toString()).build());
        return {data};
    }

    public static async updateDashboards(serviceProviderGroupId: string, dashboards: Dashboard[]): Promise<ApiResult<Dashboard[]>> {
        const data = await Http.post<Dashboard[], Dashboard[]>(ApiUrl.Dashboards, dashboards,
            new UrlParamBuilder().addServiceProviderGroupId(serviceProviderGroupId.toString()).build());
        return {data};
    }
}

export default DashboardsApi;
